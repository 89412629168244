// TableContext.js
import React, { createContext, useEffect, useContext, useState } from "react";

const RadioFilterContext = createContext();

export const RadioFilterProvider = ({ children }) => {

    const [dateRange, setDateRange] = useState(null);
    const [aiFilter, setAiFilter] = useState([]);
    const [sexFilter, setSexFilter] = useState([]);
    const [bodyPartFilter, setBodyPartFilter] = useState([]);
    const [modalityFilter, setModalityFilter] = useState([]);
    const [reportStatusFilter, setReportStatusFilter] = useState([]);
    const [radiologistFilter, setRadiologistFilter] = useState([]);
    const [ageRange, setAgeRange] = useState([]);


    const [aiSelected, setAiSelected] = useState([]);
    const [sexSelected, setSexSelected] = useState([]);
    const [bodyPartSelected, setBodyPartSelected] = useState([]);
    const [modalitySelected, setModalitySelected] = useState([]);
    const [reportStatusSelected, setReportStatusSelected] = useState([]);
    const [radiologistSelected, setRadiologistSelected] = useState([]);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [selectedAgeKeys, setSelectedAgeKeys] = useState([0, 100])

    useEffect(() => {
        const anyFilterActive = (
            (aiFilter && aiFilter.length > 0) ||
            (dateRange && dateRange.length > 0) ||
            (ageRange && ((ageRange[0] > 0 && ageRange[1] == 100) || (ageRange[0] == 0 && ageRange[1] < 100) || (ageRange[0] > 0 && ageRange[1] < 100))) ||
            (bodyPartFilter && bodyPartFilter.length > 0) ||
            (sexFilter && sexFilter.length > 0) ||
            (modalityFilter && modalityFilter.length > 0) ||
            (radiologistFilter && radiologistFilter.length > 0) ||
            (reportStatusFilter && reportStatusFilter.length > 0)
        );
        setIsFilterActive(anyFilterActive);
    }, [aiFilter, ageRange, bodyPartFilter, sexFilter, modalityFilter, radiologistFilter, reportStatusFilter, dateRange]);

    const clearAllFilters = () => {

        setDateRange(null);
        setAiFilter([]);
        setBodyPartFilter([]);
        setSexFilter([]);
        setModalityFilter([]);
        setRadiologistFilter([]);
        setReportStatusFilter([]);
        setAgeRange([])
        setAiSelected([]);
        setSexSelected([]);
        setModalitySelected([]);
        setBodyPartSelected([]);
        setRadiologistSelected([]);
        setReportStatusSelected([]);
        setSelectedAgeKeys([0, 100]);
    };
    return (
        <RadioFilterContext.Provider value={{ dateRange, setDateRange, aiFilter, setAiFilter, ageRange, setAgeRange, sexFilter, setSexFilter, bodyPartFilter, setBodyPartFilter, modalityFilter, setModalityFilter, reportStatusFilter, setReportStatusFilter, radiologistFilter, setRadiologistFilter, aiSelected, setAiSelected, sexSelected, setSexSelected, bodyPartSelected, setBodyPartSelected, modalitySelected, setModalitySelected, reportStatusSelected, setReportStatusSelected, radiologistSelected, selectedAgeKeys, setSelectedAgeKeys, setRadiologistSelected, clearAllFilters, isFilterActive }}>
            {children}
        </RadioFilterContext.Provider>
    );
};

export const useRadioFilterContext = () => useContext(RadioFilterContext);

