import React, { useEffect, useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { PiCloudArrowDown } from "react-icons/pi";
import { FaPen } from "react-icons/fa";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { RxCross2 } from "react-icons/rx";
import DOMPurify from "dompurify";
import CTReportPagination from "./CT_Reportpages";
import DXReportPagination from "./DX_Reportpages";
import axios from "axios";
import Settings from "../../Utils/Settings1";
import { useSelection } from "./selectionContext";
import { currentUserID, currentUserRights } from "../../Utils/USER";
import { Rings } from "react-loader-spinner";
import { toast } from "react-hot-toast";
import Datatable from "./Datatable";
import { formatInTimeZone } from 'date-fns-tz';

import { IoCloudUploadOutline } from "react-icons/io5";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { dwvLink } from "../../Utils/dwv_settings";
import { act } from "react-dom/test-utils";
import { FaMinusCircle } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
import { v4 as uuidv4 } from 'uuid';
import CTPreview from "./CTpreview";
import DXPreview from "./DXPreview";
import RadioReport from "./Radioreport";
import CTReport from "./Ct_report";
import DXReport from "./Dx_report";
import Aireportbox from "./AiReportbox";

const { link, consultedRadiologistDashboard, consultingRadiologistReport, radiologistReport ,download} = Settings;

function TableComponent({
  items,
  flag,
  logo,
  active,
  currentItems,
  key,
  setOpenDetails,
  reloadTable,
  base,
  radiologistData,
  consultingradiologistData,
}) {
  const { handleCheckboxChange, selectedItems, loading, searchQuery } =
    useSelection();
  const [showModal, setShowModal] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal2, setShowModal2] = useState(false);
  const [showModalh, setShowModalh] = useState(false);

  const [datatableData, setDatatableData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [modelview, setModelview] = useState("");
  const [recordData, setRecordData] = useState([]);

  const itemsPerPage = 8;

  // const id=item._id.$oid;
  const reload = () => {
    console.log("meow")
    reloadTable()

  };
  const [formData, setFormData] = useState({
    Radiologist_id: currentUserID,
    Patient_uid: "",
    Report: [],
  });

  // function to render and display styling from quill
  function renderHTML(htmlString) {
    if (!htmlString || typeof htmlString !== 'string') {
      return null;
    }

    const tagsToReplace = {
      '<li>': '<li class="ml-2">',
      '<h1>': '<h1 class="text-2xl font-bold">',
      '<h2>': '<h2 class="text-xl font-semibold">',
      '<h3>': '<h3 class="text-lg font-semibold">',
      '<h4>': '<h4 class="text-base font-semibold">',
      '<h5>': '<h5 class="text-sm font-semibold">',
      '<h6>': '<h6 class="text-xs font-semibold">',
      '<ul>': '<ul class="list-disc list-inside">',
      '<ol>': '<ol class="list-decimal list-inside">',
    };

    const replacedHTML = htmlString.replace(
      /(<li>|<h1>|<h2>|<h3>|<h4>|<h5>|<h6>|<ul>|<ol>)/g,
      (tag) => tagsToReplace[tag]
    );

    return <div dangerouslySetInnerHTML={{ __html: replacedHTML }} />;
  }

  const generateUniqueId = () => {
    // Function to generate a unique identifier
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (Math.random() * 16)) >> (c / 4) & 15
    ).toString(16);
  }
  useEffect(() => {

    console.log("meow");
    console.log(currentUserID)
    const fetchData = async () => {
      const consultedpayload = {
        "RequestId": generateUniqueId(),
        "Requestor": "Username",
        "Messages": {
          "MessageId": generateUniqueId(),
          "MessageType": consultedRadiologistDashboard.split('/').pop(),
          "Timestamp": new Date().toISOString(),
          "Body": {

          },
          "Parameters": {
            "UserId": currentUserID,
            "UserAccessToken": "",
            "APIVersion": ""
          },
          "Headers": {}
        }
      }
      try {
        const res = await axios.post(consultedRadiologistDashboard, consultedpayload);
        console.log("res.data.Responses[0].Body.data.Consulted_RADIO")

        console.log(res.data.Responses[0].Body.data.Consulted_RADIO[0]._id.$oid)

        // setlogo(res.data.HOSPITAL.Logo.$binary.base64);
        // setbase(res.data.Responses[0].Body.data.Consulted_RADIO[0].Signature.$binary.base64);

        console.log("b521ase")


        // // Log the response data for debugging
        // console.log(
        //   "Response data:",
        //   res.data.currentUserRadiologist.Signature.$binary.base64
        // );

        // Handle the response data as needed
        // (This part is not specified in the code snippet)
      } catch (error) {
        console.error("Error fetching data from Radiologist Dashboard:", error);
      }
    };
    fetchData()



    // console.log(radiologistData)

  }, []);
  const handleRemoveFile = (index) => {
    // Create a copy of the formData with the file removed
    const updatedScans = [...formData.Report];
    updatedScans.splice(index, 1);
    setFormData({ ...formData, Report: updatedScans });
  };

  async function handleFileSubmit() {
    const formDataToSend = new FormData();
    formDataToSend.append("Radiologist_id", currentUserID);
    formDataToSend.append("Patient_uid", formData.Patient_uid);

    // Validate each file in the Report array
    if (formData.Report && formData.Report.length > 0) {
      for (let i = 0; i < formData.Report.length; i++) {
        const file = formData.Report[i];
        if (file.type !== "application/pdf") {
          toast.error("Only PDF files are allowed");
          return; // Stop further processing
        }
        formDataToSend.append("Report", file);
      }
    } else {
      toast.error("No files selected.");
      return;
    }

    console.log(formDataToSend);
    try {
      setBtnLoading(true);
      let endpoint =
        active === 1
          ? `${link}/Consult_Radiologist_Report_Upload`
          : `${link}/Radiologist_Report_Upload`;

      const res = await axios.post(endpoint, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(res);
      if (res.status === 200) {
        toast.success("Added");
        console.log("Form submitted successfully");
        setTimeout(function () {
          reload()
        }, 1000);
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Unable to submit");
      console.error("Error submitting form:", error);
    } finally {
      setBtnLoading(false);
      setShowModal(false);
    }
  }

  async function handleDownload(item, report) {
    console.log("click", report);
    const id = item._id.$oid;
    const payload = {
      "RequestId": generateUniqueId(),
      "Requestor": "Username",
      "Messages": {
        "MessageId": generateUniqueId(),
        "MessageType": download.split('/').slice(-2).join('/'),
        "Timestamp": new Date().toISOString(),
        "Body": {
          "data": {
            "PatientID": id,
            "fileName": report
          }
        },
        "Parameters": {
          "UserId": currentUserID,
          "UserAccessToken": "",
          "APIVersion": ""
        },
        "Headers": {}
      }
    };
  
    try {
      const res = await axios.post(download, payload, {
        responseType: 'blob'  // Important for binary data
      });
  
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: 'application/pdf' });  // Adjust MIME type as needed
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = report;  // Use 'report' if it contains the filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toast.success("Downloaded");
      } else {
        console.log("Download failed");
        toast.error("Download failed");
      }
    } catch (error) {
      console.log("Error downloading", error);
      toast.error("Error downloading");
    }
  }
  

  const handleView = async (item, modality, id) => {
    try {
      const RadiologistID = currentUserID;
      const directory = `${item.UID_backup}_${item.Name}_${item.Age}_${item.Sex}`;
      let scanId = id;
  
      // Constructing the path for the data
      const data = `Hospitals/${item.Hospital}/Patients/${directory}/${modality}/${scanId}/DCM/`;
  
      // Logging the selected data for debugging
      console.log("Selected Modality:", modality);
      console.log("Selected ID:", id);
      console.log("Constructed Data Path:", data);
  
      // Encrypting query parameters
      const encryptedPatientID = encrypt(RadiologistID);
      const encryptedData = encrypt(data);
      const encryptedModality = encrypt(modality);
      const encryptedParams = {
        encryptedData: encryptedData,
        encryptedModality: encryptedModality
      };
   
      // Encoding the encrypted parameters as a query string
      const encryptedParamsString = encodeURIComponent(JSON.stringify(encryptedParams));
  
      // Constructing the URL with encrypted query parameters
      const url = `${dwvLink}?ID=${encryptedPatientID}&data=${encryptedParamsString}`;
  
      // Opening the URL in a new tab
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error in handleView:", error);
    }
  };
  const base64Image = base;
  const logo64Image = logo;


  const handleModelView = async ( modality,record) => {
    setModelview(modality);
    setRecordData(record)
    console.log("Selected Model:", record);

  };
  // Encryption function (example)
  function encrypt(data) {
    // Implement your encryption algorithm here
    // For demonstration purposes, let's assume simple base64 encoding
    return btoa(data);
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFormData({ ...formData, Report: [...formData.Report, ...droppedFiles] });
    setIsDragging(true);
    // setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFormData({
      ...formData,
      Report: [...formData.Report, ...selectedFiles],
    });
    // setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };
 
  useEffect(() => {

    console.log("losos")

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items?.slice(indexOfFirstItem, indexOfLastItem);;

    setDatatableData(
      currentItems
        ?.filter((item) => {
          if (searchQuery) {
            return (
              (item.Name && item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
              (item.UID_backup && item.UID_backup.includes(searchQuery.toLowerCase())) ||
              (item.Assigned_Radiologist_Name && item.Assigned_Radiologist_Name.toLowerCase()?.includes(searchQuery.toLowerCase()))
            );
          }
          return true;
        })
        .map((item) => ({
          id: item._id,
          ai: item.AI_Report_Status,
          uid: item.UID_backup,
          name: item.Name,
          age: typeof item.Age === 'string' ? parseInt(item.Age.replace(/\D/g, ""), 10) : undefined,
          sex: item.Sex,
          date: item.date.$date,
          bodyPart: item.Body_part,
          modality: item.Modality,
          radiologist: item.Assigned_Radiologist_Name || "",
          reportStatus: item.Report_Status,
          finalReport: "",
          ...item,
        }))
    );
  }, [currentPage, searchQuery, items]);

  useEffect(() => {
    console.log("lolll", datatableData);
  }, [datatableData]);

  const ConfirmationModal = ({
    showModal,
    active,
    onConfirm,
    onCancel,
    data,
  }) => {
    const [editableData, setEditableData] = useState({ ...data });
    const [preview, setPreview] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [sections, setSections] = useState(null);

    // Function to add a new section
    const addSection = () => {
      if (sections === null) {
        setSections([{ title: "", content: "" }]);
      } else {
        setSections([...sections, { title: "", content: "" }]);
      }
    };
    const removeSection = (index) => {
      const updatedSections = [...sections];
      updatedSections.splice(index, 1);
      setSections(updatedSections);
    };
    // Function to handle changes in section heading or content
    const handleSectionChange = (index, key, value) => {
      const updatedSections = [...sections];
      updatedSections[index][key] = value;
      setSections(updatedSections);
    };

    useEffect(() => {
      const imageUrl =
        "http://localhost:3000/static/media/medical_logo.a633323168d685b54e1f.png";
      setImageSrc(imageUrl);
    }, []);

    console.log(data);
    const [textareaData, setTextareaData] = useState(data.AI_Report);
   
    // pass data to function of general entries
    function handleEditChange(event) {
      const { name, value } = event.target;
      setEditableData({ ...editableData, [name]: value });
    }
    const handleEditChange1 = ({ name, value }) => {
      setEditableData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };
    // pass data of quill entries
    const handleQuillChange = (name, content) => {
      setEditableData((prevData) => ({
        ...prevData,
        [name]: content,
      }));
    };
    const parseTestsArray = (tests) => {
      if (typeof tests === 'string') {
        const cleanedString = tests.replace(/^\[|\]$/g, '').trim();
        return cleanedString.split(',').map(item => item.trim());
      }
      return [];
    };
    const [symptoms, setSymptoms] = useState('');
    useEffect(() => {
      if (editableData.Symptoms) {
        setSymptoms(editableData.Symptoms.join(', '));
      }
    }, [editableData.Symptoms]);
  
    const handleInputChange = (e) => {
      setSymptoms(e.target.value);
    };
    const testsArray = parseTestsArray(editableData.AI_Tests);
  
    const parseTestsArray1 = (tests) => {
      if (typeof tests === 'string') {
        const cleanedString = tests.replace(/^\[|\]$/g, '').trim();
        return cleanedString.split(',').map(item => item.trim());
      }
      return [];
    };
    const symptomsArray = parseTestsArray1(editableData.Symptoms);

  
    useEffect(() => {
      setTextareaData(data.AI_Report);

    }, [data]);

  // Convert testsArray to a comma-separated string for display

  

    const handleQuillChange2 = (index, content) => {
      const updatedSections = [...sections];
      updatedSections[index].content = content;
      setSections(updatedSections);
    };

    const handleEditorChange = (name, content) => {
      setEditableData((prevData) => ({
        ...prevData,
        [name]: content,
      }));
    };

    function handleSubmission() {
      const isConfirmed = window.confirm("Are you sure you want to submit?");
      if (isConfirmed) {
        handleSubmit();

        reload()
      }
    }

    const formatDate = (date) => {
      try {
        // Use 'UTC' time zone for consistent formatting
        return formatInTimeZone(new Date(date), 'UTC', 'dd-MM-yyyy');
      } catch {
        return "Invalid Date";
      }
    };
    const date = data.date && data.date.$date ? formatDate(new Date(data.date.$date)) : null;
    const formatText = (text) => {
      return text.replace(/\. /g, '.<br />');
    };
    const cleanText = (text) => {
      if (!text) return "";
    
      // Regex to match JSON-like structures, including arrays and objects
      const jsonStructureRegex = /(\[.*?\]|\{.*?\})/g;
    
      return text.replace(jsonStructureRegex, (match) => {
        try {
          // Ensure proper JSON format
          const jsonString = match
            .replace(/'/g, '"') // Replace single quotes with double quotes
            .replace(/,\s*(}|\])/g, '$1'); // Remove trailing commas
    
          // Parse JSON
          const obj = JSON.parse(jsonString);
    
          // Format array or single object
          if (Array.isArray(obj)) {
            return obj.map(item => {
              const title = item.title ? `Title: ${item.title}` : '';
              const content = item.content ? `Content: ${item.content}` : '';
              return `${title}\n${content}`;
            }).join('\n\n');
          } else {
            const title = obj.title ? `Title: ${obj.title}` : '';
            const content = obj.content ? `Content: ${obj.content}` : '';
            return `${title}\n${content}`;
          }
        } catch (e) {
          console.error("Failed to parse JSON:", match, e);
          return `Invalid JSON: ${match}`;
        }
      }).replace(/[\r\n]+/g, '\n').trim();
    };
    const symptomarr=symptoms.split(",")
    async function handleSubmit() {
      try {
        const data = {
          RequestId: uuidv4(),
          Messages: {
            MessageId: uuidv4(),
            MessageType: "ConsultingRadiologistReport",
            Parameters: {
              UserId: currentUserID,
            },
            Body: {
              data: {
                section1: {
                  UID_backup: editableData.UID_backup,
                  Name: editableData.Name,
                  Age: editableData.Age,
                  Sex:editableData.Sex,
                  Height:editableData.Height,
                  Weight:editableData.Weight,
                  SPO2:editableData.SPO2,
                  SBP:editableData.SBP,
                  DBP:editableData.DBP,
                  Temp:editableData.Temp,
                  MedicalHistory:editableData.MedicalHistory,

                   Radiologist_Name: editableData.Radiologist_Name,
                  Assigned_Radiologist_Name: editableData.Assigned_Radiologist_Name,
                  Date: editableData.date ? editableData.date.$date : '',
                  Symptoms:symptomarr,
                  AI_Disease_Diagnosis_Status: editableData.AI_Disease_Diagnosis_Status,
                  AI_Disease_Label: editableData.AI_Disease_Label,
                  AI_Disease_confidence: editableData.AI_Disease_confidence,
                  AI_Tests: editableData.AI_Tests,
                },
                section2: {
                  CT_AI_Report: editableData.CT_AI_Report || [],
                  CT_AI_Report_Status: editableData.CT_AI_Report_Status || []
                },
                section3:{CXR_AI_Report :editableData.CXR_AI_Report || [],
                  CXR_AI_Report_Status: editableData.CXR_AI_Report_Status || []
                },
                section4: editableData.key_impression || "",
                section5: sections || "",
                patientID: editableData.id ? editableData.id.$oid : '',
              }
            }
          }
        };
    
        const endPoint = active === 1 ? consultingRadiologistReport : radiologistReport;
    
        const res = await axios.post(endPoint, data);
        console.log(res);
        if (res.status === 200) {
          toast.success("Form submitted");
          reload();
        }
      } catch (error) {
        console.log(error);
        toast.error("Form not submitted");
      }
    }
    const processText = (text) => {
      return text.replace(/\.(\s|$)/g, '.<br>$1');
    };
    // const formattedText = processText(data.CT_AI_Report);

    // const safeHtml = DOMPurify.sanitize(formattedText);

  
    const dateValue = editableData.date && editableData.date.$date ? dayjs(editableData.date.$date) : null;
const patient = Array.isArray(data.consultedgPatientData) ? 
    data.consultedgPatientData.find(p => p._id && p._id.$oid === editableData.id.$oid) : 
    undefined;
    function handleDateChange(date, dateString) {
      setEditableData(prevData => ({
        ...prevData,
        date: {
          $date: dateString
        }
      }));
      console.log("feff",editableData.date.$date)
    }
    
    const loader = (
      <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
    );

    return (
      <>
{active === 1 ? (
       <div
       className={`${showModal
         ? " z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg "
         : "hidden"
         } bg-black bg-opacity-50`}
     >
       <div className="modal-container-outer">
         <div className="bg-[#FFFFFFE0] relative flex justify-start w-[75rem] h-[660px] p-6 rounded-[40px] gap-x-3 modal-container-inner">
           <RxCross2
             size={30}
             onClick={() => setShowModal(false)}
             className=" cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 left-[70rem]"
           />
            {!preview ? (

             <div className=" flex flex-col justify-start h-full w-full gap-y-2">
             <div className="flex justify-center ">
               <h1 className="text-[20px] font-medium">
                 Patient UID : {editableData.UID_backup}
               </h1>
             </div>
             <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
             <div>
    <p><strong>Name:</strong>  <span className="ms-1">{editableData.Name}</span></p>
    <p><strong>Age: </strong>  <span className="ms-1">{editableData.Age}</span></p>
    <p><strong>Sex: </strong>  <span className="ms-1">{editableData.Sex}</span></p>

    <p><strong>Height: </strong> <span className="ms-1"> {editableData.Height} cm</span></p>
    <p><strong>Weight: </strong> <span className="ms-1"> {editableData.Weight} kg</span></p>
    <p><strong>SPO2: </strong> <span className="ms-1"> {editableData.SPO2} %</span></p>
    <p><strong>Blood Pressure: </strong> <span className="ms-1"> {editableData.SBP}/{editableData.DBP}</span></p>
    <p><strong>Temperature: </strong> <span className="ms-1"> {editableData.Temp} °C</span></p>
    <p><strong>Habits:</strong> <span className="ms-1"> {editableData.Habits}</span></p>
    
    <p><strong>Symptoms:</strong> <span className="ms-1">{(editableData.Symptoms || []).join(', ')}</span></p>
  
  </div>
  <div>
  <p><strong>Date:</strong><span className="ms-1"> <DatePicker
    className="bg-[#efedede0] p-1 rounded-md w-auto bg-white"
    style={{ color: 'black', fontSize: 'inherit' }} // Set text color to black and inherit font size
    onChange={handleDateChange}
    value={dateValue}
    format="YYYY-MM-DD"
    disabled
/></span></p>
  <p><strong>Diagnosing Radiologist: </strong><span className="ms-1">  {Array.isArray(editableData?.Radiologist_Name)
                          ? editableData.Radiologist_Name.join(", ")
                          : editableData.Radiologist_Name}</span></p>
  <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{editableData.Assigned_Radiologist_Name}</span></p>
  <p><strong>Patient Status:</strong> <span className="ms-1">{editableData.AI_Disease_Diagnosis_Status}</span></p>
    <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{editableData.AI_Disease_Label}</strong> with probability of <strong> {editableData.AI_Disease_confidence}</strong></span></p>
    {('MedicalHistory' in editableData) && (
      <p><strong>Medical History:</strong> <span className="ms-1"> {editableData.MedicalHistory}</span></p>
    )}
      <p className="text-sm"><strong>Tests:</strong>  <span className="ms-1">{testsArray.join(', ')}</span></p>

  </div>
</div>
             <div className="flex flex-col text-sm gap-y-1">
              
              
              
             
 
              
              
          
         
             </div>
             <hr className="border-[#36AE78]" />
             <div className="flex flex-col gap-y-5">
               <div className="space-y-2">
               {('CT_AI_Report_Status' in data) && (
               <CTReportPagination
   editableData={editableData}
   handleEditChange1={handleEditChange1}
 />                 )}</div>
                   <div className="space-y-2">

  {('CXR_AI_Report_Status' in data) && (
        <DXReportPagination
   editableData={editableData}
   handleEditChange1={handleEditChange1}
 />          )}</div>
    {/* {('CT_AI_Report_Status' in data) && (
<> <p><strong>CT AI Report :</strong> {editableData.CT_AI_Report_Status}</p>
 <ReactQuill
                   theme="snow"
                   value={editableData.CT_AI_Report}
                   onChange={(content) =>
                     handleEditorChange("CT_AI_Report", content)
                   }
                   name="CT_AI_Report"
                   style={{ backgroundColor: "#ffffff" }}
                 /></>

)}
         {('CXR_AI_Report_Status' in data) && (
<> <p><strong>CXR AI Report :</strong> {editableData.CXR_AI_Report_Status}</p>
 <ReactQuill
                   theme="snow"
                   value={editableData.CXR_AI_Report}
                   onChange={(content) =>
                     handleEditorChange("CXR_AI_Report", content)
                   }
                   name="CXR_AI_Report"
                   style={{ backgroundColor: "#ffffff" }}
                 /></>

)}      */}


 {/* <textarea
                   className="h-fit w-full min-h-[3rem] text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3 overflow-hidden"
                   value={editableData.AI_Report}
                   onChange={handleEditChange}
                   name="CT_AI_Report"
                 /> */}
              
                 {/* {renderHTML(editableData.AI_Report)} */}
                 <div className="space-y-2">
                 <h3 className="text-[16px] font-semibold">
                   Key impressions:
                 </h3>
                 {/* <textarea
                   className="h-fit w-full text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3 no-scrollbar outline-none"
                   onChange={handleEditChange}
                   value={editableData.key_impression}
                   name="key_impression"
                 /> */}

                 <ReactQuill
                   theme="snow"
                   value={editableData.key_impression}
                   onChange={(content) =>
                     handleEditorChange("key_impression", content)
                   }
                   name="key_impression"
                   style={{ backgroundColor: "#ffffff" }}
                 />
                 {/* {renderHTML(editableData.key_impression)} */}
               </div>
             </div>
             {sections !== null && (
               <div>
                 {/* Render sections */}
                 {sections.map((section, index) => (
                   <div key={index} className="space-y-2 ">
                     <div className="flex items-center">
                       <input
                         type="text"
                         value={section.title}
                         onChange={(e) =>
                           handleSectionChange(
                             index,
                             "title",
                             e.target.value
                           )
                         }
                         className=" text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl px-3 py-1 no-scrollbar outline-none"
                         placeholder="Section Heading"
                       />
                       {/* Button to remove section */}

                       <button
                         onClick={() => removeSection(index)}
                         className="text-[#ec767e] p-2"
                       >
                         <FaMinusCircle size={22} />
                       </button>
                     </div>

                     <ReactQuill
                       theme="snow"
                       value={section.content}
                       onChange={(content) =>
                         handleQuillChange2(index, content)
                       }
                       placeholder="Section Content"
                       style={{ backgroundColor: "#ffffff" }}
                     />
                   </div>
                 ))}
               </div>
             )}
             {/* "Add Section" button rendered below the sections */}
             <div className="flex justify-end gap-x-3">
               <button
                 onClick={addSection}
                 className="flex items-center gap-x-1 bg-[#36AE78] text-white px-4 py-1 rounded-md"
               >
                 <FaPlus size={12} />
                 Add
               </button>
             </div>
             <div className="flex justify-center gap-x-3">
               <button
                 disabled={currentUserRights == "View"}
                 onClick={() => setPreview(true)}
                 className={`bg-[#36AE78] flex items-center justify-center gap-x-2 text-white px-8 py-1 rounded-md w-fit ${currentUserRights === "View"
                   ? "opacity-50 cursor-not-allowed"
                   : ""
                   }`}
               >
                 Preview
               </button>
               <button
                 onClick={handleSubmission}
                 className="bg-[#36AE78] text-white px-8 py-1 rounded-md w-fit"
               >
                 Submit
               </button>
             </div>
             <div>
               <RadioReport editableData={editableData}/>
           
             </div>
             </div>
               ) : ( <>
                <MdOutlineKeyboardArrowLeft
                  size={40}
                  onClick={() => setPreview(false)}
                  className=" cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-[69rem]"
                />
                <div className="space-y-4 p-9 w-full">
                  <div className="bg-white flex items-center p-8 border-b-2 border-[#6EC59D]">
                    <img
                      className="mr-4"
                      src={`data:image/jpeg;base64,${logo64Image}`}
                      width={100}
                      height={50}
                      alt="Binary Image"
                    />
                    <p className="text-2xl font-medium flex-grow text-center">
                      {editableData.Hospital.split("_")[0]}
                    </p>
                  </div>

                  <div className="flex justify-center ">
                    <h1 className="text-[20px] font-medium">
                      Patient UID : {editableData.UID_backup}
                    </h1>
                  </div>
                  <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
  <div>
    <p><strong>Name:</strong>  <span className="ms-1">{editableData.Name}</span></p>
    <p><strong>Age: </strong>  <span className="ms-1">{editableData.Age}</span></p>
    <p><strong>Sex: </strong>  <span className="ms-1">{editableData.Sex}</span></p>

    <p><strong>Height: </strong> <span className="ms-1"> {editableData.Height} cm</span></p>
    <p><strong>Weight: </strong> <span className="ms-1"> {editableData.Weight} kg</span></p>
    <p><strong>SPO2: </strong> <span className="ms-1"> {editableData.SPO2} %</span></p>
    <p><strong>Blood Pressure: </strong> <span className="ms-1"> {editableData.SBP}/{editableData.DBP}</span></p>
    <p><strong>Temperature: </strong> <span className="ms-1"> {editableData.Temp} °C</span></p>
    <p><strong>Habits:</strong> <span className="ms-1"> {editableData.Habits}</span></p>
  
    <p><strong>Symptoms:</strong> <span className="ms-1">{(editableData.Symptoms || []).join(', ')}</span></p>
  
  </div>
  <div>
  <p><strong>Date:</strong><span className="ms-1"> <DatePicker
    className="bg-[#efedede0] p-1 rounded-md w-auto bg-white"
    style={{ color: 'black', fontSize: 'inherit' }} // Set text color to black and inherit font size
    onChange={handleDateChange}
    value={dateValue}
    format="YYYY-MM-DD"
    disabled
/></span></p>
  <p><strong>Diagnosing Radiologist: </strong><span className="ms-1">  {Array.isArray(editableData?.Radiologist_Name)
                          ? editableData.Radiologist_Name.join(", ")
                          : editableData.Radiologist_Name}</span></p>
  <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{editableData.Assigned_Radiologist_Name}</span></p>
  <p><strong>Patient Status:</strong> <span className="ms-1">{editableData.AI_Disease_Diagnosis_Status}</span></p>
    <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{editableData.AI_Disease_Label}</strong> with probability of <strong> {editableData.AI_Disease_confidence}</strong></span></p>
    {('MedicalHistory' in editableData) && (
      <p><strong>Medical History:</strong> <span className="ms-1"> {editableData.MedicalHistory}</span></p>
    )}
      <p className="text-sm"><strong>Tests:</strong>  <span className="ms-1">{testsArray.join(', ')}</span></p>

  </div>
</div>
                
                  <hr className=" border-[#6EC59D]" />
                  <div className="space-y-4">
                    <div className="space-y-2">
                    {('CT_AI_Report_Status' in data) && (

                      <CTPreview editableData={editableData}/>
            )}
             
                     
                    </div>
                    <div className="space-y-2">

{('CXR_AI_Report_Status' in data) && (
<DXPreview editableData={editableData}/>

)} </div>
                    <div className="space-y-2">
                      <p className="font-semibold text-[15px]">
                        Key impressions:{" "}
                      </p>
                      {renderHTML(editableData.key_impression)}
                      {/* <p className="text-sm font-normal">
                        {editableData.key_impression}
                      </p> */}
                    </div>
                    {sections &&
                      sections.map((section, index) => (
                        <div className="space-y-2" key={index}>
                          <p className="font-semibold text-[15px]">
                            {section.title}:
                          </p>
                          <p className="text-sm font-normal">
                            {renderHTML(section.content)}
                          </p>
                        </div>
                      ))}
                  </div>
                  <div className="flex flex-col items-end justify-end p-6">
                    <img
                      src={`data:image/jpeg;base64,${base64Image}`}
                      width={200}
                      height={50}
                      alt="Binary Image"
                    />
                    <p className="semi-bold  mt-2">
                      {Array.isArray(editableData?.Radiologist_Name)
                        ? editableData.Radiologist_Name.join(", ")
                        : editableData.Radiologist_Name}
                    </p>
                  </div>
                </div>
              </>
            )}
             </div>
             </div>

           
           </div>
        
      ) : null}

      {active=== 0  ? (

      <div
        className={`${showModal
          ? " z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg "
          : "hidden"
          } bg-black bg-opacity-50`}
      >
        <div className="modal-container-outer">
          <div className="bg-[#FFFFFFE0] relative flex justify-start w-[75rem] h-[660px] p-6 rounded-[40px] gap-x-3 modal-container-inner">
            <RxCross2
              size={30}
              onClick={() => setShowModal(false)}
              className=" cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 left-[70rem]"
            />

            {!preview ? (
              <div className=" flex flex-col justify-start h-full w-full gap-y-2">
                <div className="flex justify-center ">
                  <h1 className="text-[20px] font-medium">
                    Patient UID : {editableData.UID_backup}
                  </h1>
                </div>
                <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
  <div>
    <p><strong>Name:</strong>  <span className="ms-1"><input
                      className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto p-2 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={editableData.Name}
                      name="Name"
                    /></span></p>
    <p><strong>Age: </strong>  <span className="ms-1">   <input
                      className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={editableData.Age}
                      name="Age"
                    /></span></p>
                     <p><strong>Sex: </strong>  <span className="ms-1">   <input
                      className="bg-[#efedede0] border border-grey-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={editableData.Sex}
                      name="Sex"
                      readOnly
                    /></span></p>
    <p><strong>Height: </strong> <span className="ms-1"> <input
                      className="bg-[#7b7f8329] border border-grey-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={editableData.Height}
                      name="Height"
                      readOnly
                    /> cm</span></p>
    <p><strong>Weight: </strong> <span className="ms-1"> <input
                      className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={editableData.Weight}
                      name="Weight"
                    /> kg</span></p>
    <p><strong>SPO2: </strong> <span className="ms-1">  <input
                      className="bg-[#7b7f8329] border border-grey-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={editableData.SPO2}
                      name="SPO2"
                      readOnly
                    /> %</span></p>
    <p><strong>Blood Pressure: </strong> <span className="ms-1"> <input
                      className="bg-[#7b7f8329] border border-grey-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={editableData.SBP}
                      name="SBP"
                      readOnly
                    />/<input
                    className="bg-[#7b7f8329] border border-grey-500 p-1 rounded-md w-auto bg-white"
                    onChange={handleEditChange}
                    type="text"
                    value={editableData.DBP}
                    name="DBP"
                    readOnly
                  /></span></p>
    <p><strong>Temperature: </strong> <span className="ms-1"> <input
                    className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto bg-white"
                    onChange={handleEditChange}
                    type="text"
                    value={editableData.Temp}
                    name="Temp"
                  /> °C</span></p>
    <p><strong>Habits:</strong> <span className="ms-1"><input
                    className="bg-[#7b7f8329] border border-grey-500 p-1 rounded-md w-auto bg-white"
                    onChange={handleEditChange}
                    type="text"
                    value={editableData.Habits}
                    name="Habits"
                    readOnly
                  /> </span></p>
    
    <p><strong>Symptoms:</strong> <span className="ms-1">   <textarea
        className="bg-white border border-green-500 p-2 rounded-md w-[700px] h-[100px] overflow-x-auto placeholder-gray-500 focus:outline-none focus:border-green-600 resize-none"
        type="text"
        value={symptoms}
        onChange={handleInputChange}
        name="AI_Disease_Diagnosis_Status"
      /></span></p>
  
   
  </div>
  <div>
  <p><strong>Date:</strong><span className="ms-1">  <DatePicker
          className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto bg-white"
          onChange={handleDateChange}
          value={dateValue}
          format="YYYY-MM-DD"
        /></span></p>
  <p><strong>Diagnosing Radiologist: </strong><span className="ms-1">  <input
                      className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={
                        Array.isArray(editableData?.Radiologist_Name)
                          ? editableData.Radiologist_Name.join(", ")
                          : editableData.Radiologist_Name
                      }
                      name="Radiologist_Name"
                    /></span></p>
  <p><strong>Assigned Radiologist:</strong> <span className="ms-1">  <input
                      className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={editableData.Assigned_Radiologist_Name}
                      name="Assigned_Radiologist_Name"
                    /></span></p>
  <p><strong>Patient Status:</strong> <span className="ms-1">  <input
                      className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={editableData.AI_Disease_Diagnosis_Status}
                      name="AI_Disease_Diagnosis_Status"
                    /></span></p>
    <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong><input
                      className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={editableData.AI_Disease_Label}
                      name="AI_Disease_Label"
                    /></strong> with probability of <strong> <input
                    className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto bg-white"
                    onChange={handleEditChange}
                    type="text"
                    value={editableData.AI_Disease_confidence}
                    name="AI_Disease_confidence"
                  /></strong></span></p>
   
   {('MedicalHistory' in data) && (
      <p><strong>Medical History:</strong> <span className="ms-1"> <input
      className="bg-[#7b7f8329] border border-grey-500 p-1 rounded-md w-auto bg-white"
      onChange={handleEditChange}
      type="text"
      value={editableData.MedicalHistory}
      name="MedicalHistory"
      readOnly
    /></span></p>
    )}
     <p className="text-sm"><strong>Tests:</strong>  <span className="ms-1"> <input
                      className="bg-[#efedede0] border border-green-500 p-1 rounded-md w-auto bg-white"
                      onChange={handleEditChange}
                      type="text"
                      value={testsArray.join(', ')}                      
                      name="AI_Tests"
                    /></span></p>
  </div>
</div>
                <div className="flex flex-col text-sm gap-y-1">
                 
                 
                 
                
    
                 
                 
             
            
                </div>
                <hr className="border-[#36AE78]" />
                <div className="flex flex-col gap-y-5">
                  <div className="space-y-2">
                  {('CT_AI_Report_Status' in data) && (
                  <CTReportPagination
      editableData={editableData}
      handleEditChange1={handleEditChange1}
    />                 )}</div>
                      <div className="space-y-2">

     {('CXR_AI_Report_Status' in data) && (
           <DXReportPagination
      editableData={editableData}
      handleEditChange1={handleEditChange1}
    />          )}</div>
       {/* {('CT_AI_Report_Status' in data) && (
 <> <p><strong>CT AI Report :</strong> {editableData.CT_AI_Report_Status}</p>
    <ReactQuill
                      theme="snow"
                      value={editableData.CT_AI_Report}
                      onChange={(content) =>
                        handleEditorChange("CT_AI_Report", content)
                      }
                      name="CT_AI_Report"
                      style={{ backgroundColor: "#ffffff" }}
                    /></>
  
)}
            {('CXR_AI_Report_Status' in data) && (
 <> <p><strong>CXR AI Report :</strong> {editableData.CXR_AI_Report_Status}</p>
    <ReactQuill
                      theme="snow"
                      value={editableData.CXR_AI_Report}
                      onChange={(content) =>
                        handleEditorChange("CXR_AI_Report", content)
                      }
                      name="CXR_AI_Report"
                      style={{ backgroundColor: "#ffffff" }}
                    /></>
  
)}      */}


    {/* <textarea
                      className="h-fit w-full min-h-[3rem] text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3 overflow-hidden"
                      value={editableData.AI_Report}
                      onChange={handleEditChange}
                      name="CT_AI_Report"
                    /> */}
                 
                    {/* {renderHTML(editableData.AI_Report)} */}
                    <div className="space-y-2">
                    <h3 className="text-[16px] font-semibold">
                      Key impressions:
                    </h3>
                    {/* <textarea
                      className="h-fit w-full text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3 no-scrollbar outline-none"
                      onChange={handleEditChange}
                      value={editableData.key_impression}
                      name="key_impression"
                    /> */}

                    <ReactQuill
                      theme="snow"
                      value={editableData.key_impression}
                      onChange={(content) =>
                        handleEditorChange("key_impression", content)
                      }
                      name="key_impression"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                    {/* {renderHTML(editableData.key_impression)} */}
                  </div>
                </div>
                {sections !== null && (
                  <div>
                    {/* Render sections */}
                    {sections.map((section, index) => (
                      <div key={index} className="space-y-2 ">
                        <div className="flex items-center">
                          <input
                            type="text"
                            value={section.title}
                            onChange={(e) =>
                              handleSectionChange(
                                index,
                                "title",
                                e.target.value
                              )
                            }
                            className=" text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl px-3 py-1 no-scrollbar outline-none"
                            placeholder="Section Heading"
                          />
                          {/* Button to remove section */}

                          <button
                            onClick={() => removeSection(index)}
                            className="text-[#ec767e] p-2"
                          >
                            <FaMinusCircle size={22} />
                          </button>
                        </div>

                        <ReactQuill
                          theme="snow"
                          value={section.content}
                          onChange={(content) =>
                            handleQuillChange2(index, content)
                          }
                          placeholder="Section Content"
                          style={{ backgroundColor: "#ffffff" }}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {/* "Add Section" button rendered below the sections */}
                <div className="flex justify-end gap-x-3">
                  <button
                    onClick={addSection}
                    className="flex items-center gap-x-1 bg-[#36AE78] text-white px-4 py-1 rounded-md"
                  >
                    <FaPlus size={12} />
                    Add
                  </button>
                </div>
                <div className="flex justify-center gap-x-3">
                  <button
                    disabled={currentUserRights == "View"}
                    onClick={() => setPreview(true)}
                    className={`bg-[#36AE78] flex items-center justify-center gap-x-2 text-white px-8 py-1 rounded-md w-fit ${currentUserRights === "View"
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                      }`}
                  >
                    Preview
                  </button>
                  <button
                    onClick={handleSubmission}
                    className="bg-[#36AE78] text-white px-8 py-1 rounded-md w-fit"
                  >
                    Submit
                  </button>
                </div>
                <div>
                  <RadioReport editableData={editableData}/>
              
                </div>

                {active != 2 ? (
                  <>
                    <h3 className="text-[16px] font-semibold flex">
                      Radiologist Reports:{" "}
                    </h3>
                    <div
                      className={`w-full h-[20rem] border-2 ${isDragging ? "border-[#36AE78]" : "border-slate-400"
                        } rounded-lg p-2 border-dashed flex flex-col justify-center items-center`}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                    >
                      <input
                        name="Report"
                        type="file"
                        className="hidden"
                        id="file-input"
                        onChange={handleFileInputChange}
                      />
                      <PiCloudArrowDown
                        size={30}
                        className={`text-5xl font-normal ${isDragging ? "text-[#36AE78]" : "text-slate-400"
                          }`}
                      />
                      <span className="text-slate-400 text-[15px] text-center">
                        Drag & Drop files here <br />
                        or{" "}
                      </span>{" "}
                      <label
                        className="text-blue-600 text-[15px] hover:underline cursor-pointer"
                        htmlFor="file-input"
                      >
                        {" "}
                        Browse
                      </label>
                    </div>
                    <div className="w-full">
                      <ul className="w-full py-[3px] flex gap-x-3 px-2 overflow-x-scroll no-scrollbar">
                        {formData.Report.map((file, index) => (
                          <li
                            className="border border-[#36AE78] text-[#36AE78] bg-[#c1f6de] bg-opacity-15 rounded-full w-fit pl-2 pr-1 whitespace-nowrap flex items-center gap-x-1"
                            key={index}
                          >
                            {file.name.length > 15
                              ? file.name.substring(0, 14) + "..."
                              : file.name}
                            <RxCross2
                              className="cursor-pointer ml-2"
                              onClick={() => handleRemoveFile(index)}
                            />
                          </li>
                        ))}
                      </ul>
                      <p className="text-left text-slate-500 text-sm pl-3 pt-2">
                        {formData.Report.length > 0
                          ? "Files: " + formData.Report.length
                          : ""}
                      </p>
                    </div>

                    <button
                      disabled={currentUserRights == "View"}
                      onClick={handleFileSubmit}
                      className={`bg-[#36AE78] flex items-center justify-center gap-x-2 text-white px-8 py-1 rounded-md w-fit mx-auto ${currentUserRights === "View"
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                        }`}
                    >
                      <IoCloudUploadOutline size={20} />
                      {btnLoading ? loader : "Submit"}
                    </button>

                    <div className="flex flex-col items-end justify-end p-6">
                      <img
                        src={`data:image/jpeg;base64,${base64Image}`}
                        width={200}
                        height={50}
                        alt="Binary Image"
                      />
                      <p className="semi-bold me-4 mt-2">
                        {Array.isArray(editableData?.Radiologist_Name)
                          ? editableData.Radiologist_Name.join(", ")
                          : editableData.Radiologist_Name}
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                <MdOutlineKeyboardArrowLeft
                  size={40}
                  onClick={() => setPreview(false)}
                  className=" cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-[69rem]"
                />
                <div className="space-y-4 p-9 w-full">
                  <div className="bg-white flex items-center p-8 border-b-2 border-[#6EC59D]">
                    <img
                      className="mr-4"
                      src={`data:image/jpeg;base64,${logo64Image}`}
                      width={100}
                      height={50}
                      alt="Binary Image"
                    />
                    <p className="text-2xl font-medium flex-grow text-center">
                      {editableData.Hospital.split("_")[0]}
                    </p>
                  </div>

                  <div className="flex justify-center ">
                    <h1 className="text-[20px] font-medium">
                      Patient UID : {editableData.UID_backup}
                    </h1>
                  </div>
                  <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
  <div>
    <p><strong>Name:</strong>  <span className="ms-1">{editableData.Name}</span></p>
    <p><strong>Age: </strong>  <span className="ms-1">{editableData.Age}</span></p>
    <p><strong>Height: </strong> <span className="ms-1"> {editableData.Height} cm</span></p>
    <p><strong>Weight: </strong> <span className="ms-1"> {editableData.Weight} kg</span></p>
    <p><strong>SPO2: </strong> <span className="ms-1"> {editableData.SPO2} %</span></p>
    <p><strong>Blood Pressure: </strong> <span className="ms-1"> {editableData.SBP}/{editableData.DBP}</span></p>
    <p><strong>Temperature: </strong> <span className="ms-1"> {editableData.Temp} °C</span></p>
    <p><strong>Habits:</strong> <span className="ms-1"> {editableData.Habits}</span></p>
 
    <p><strong>Symptoms:</strong> <span className="ms-1">{(editableData.Symptoms || []).join(', ')}</span></p>
  
  </div>
  <div>
  <p><strong>Date:</strong><span className="ms-1"> <DatePicker
    className="bg-[#efedede0] p-1 rounded-md w-auto bg-white"
    style={{ color: 'black', fontSize: 'inherit' }} // Set text color to black and inherit font size
    onChange={handleDateChange}
    value={dateValue}
    format="YYYY-MM-DD"
    disabled
/></span></p>
  <p><strong>Diagnosing Radiologist: </strong><span className="ms-1">  {Array.isArray(editableData?.Radiologist_Name)
                          ? editableData.Radiologist_Name.join(", ")
                          : editableData.Radiologist_Name}</span></p>
  <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{editableData.Assigned_Radiologist_Name}</span></p>
  <p><strong>Patient Status:</strong> <span className="ms-1">{editableData.AI_Disease_Diagnosis_Status}</span></p>
    <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{editableData.AI_Disease_Label}</strong> with probability of <strong> {editableData.AI_Disease_confidence}</strong></span></p>
    {('MedicalHistory' in editableData) && (
      <p><strong>Medical History:</strong> <span className="ms-1"> {editableData.MedicalHistory}</span></p>
    )}
      <p className="text-sm"><strong>Tests:</strong>  <span className="ms-1">{testsArray.join(', ')}</span></p>

  </div>
</div>
                
                  <hr className=" border-[#6EC59D]" />
                  <div className="space-y-4">
                    <div className="space-y-2">
                    {('CT_AI_Report_Status' in data) && (

                      <CTPreview editableData={editableData}/>
            )}
             
                     
                    </div>
                    <div className="space-y-2">

{('CXR_AI_Report_Status' in data) && (
<DXPreview editableData={editableData}/>

)} </div>
                    <div className="space-y-2">
                      <p className="font-semibold text-[15px]">
                        Key impressions:{" "}
                      </p>
                      {renderHTML(editableData.key_impression)}
                      {/* <p className="text-sm font-normal">
                        {editableData.key_impression}
                      </p> */}
                    </div>
                    {sections &&
                      sections.map((section, index) => (
                        <div className="space-y-2" key={index}>
                          <p className="font-semibold text-[15px]">
                            {section.title}:
                          </p>
                          <p className="text-sm font-normal">
                            {renderHTML(section.content)}
                          </p>
                        </div>
                      ))}
                  </div>
                  <div className="flex flex-col items-end justify-end p-6">
                    <img
                      src={`data:image/jpeg;base64,${base64Image}`}
                      width={200}
                      height={50}
                      alt="Binary Image"
                    />
                    <p className="semi-bold  mt-2">
                      {Array.isArray(editableData?.Radiologist_Name)
                        ? editableData.Radiologist_Name.join(", ")
                        : editableData.Radiologist_Name}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>) :null}
      {active ===2 ?(<div
        className={`${showModal
          ? " z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg "
          : "hidden"
          } bg-black bg-opacity-50`}
      >
        <div className="modal-container-outer">
          <div className="bg-[#FFFFFFE0] relative flex justify-start w-[75rem] h-[660px] p-6 rounded-[40px] gap-x-3 modal-container-inner">
            <RxCross2
              size={30}
              onClick={() => setShowModal(false)}
              className=" cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 left-[70rem]"
            />

{!preview ? (

              <div className=" flex flex-col justify-start h-full w-full gap-y-2">
                <div className="flex justify-center ">
                  <h1 className="text-[20px] font-medium">
                    Patient UID : {editableData.UID_backup}
                  </h1>
                </div>
                <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
  <div>
    <p><strong>Name:</strong>  <span className="ms-1">{editableData.Name}</span></p>
    <p><strong>Age: </strong>  <span className="ms-1">{editableData.Age}</span></p>
    <p><strong>Sex: </strong>  <span className="ms-1">{editableData.Sex}</span></p>

    <p><strong>Height: </strong> <span className="ms-1"> {editableData.Height} cm</span></p>
    <p><strong>Weight: </strong> <span className="ms-1"> {editableData.Weight} kg</span></p>
    <p><strong>SPO2: </strong> <span className="ms-1"> {editableData.SPO2} %</span></p>
    <p><strong>Blood Pressure: </strong> <span className="ms-1"> {editableData.SBP}/{editableData.DBP}</span></p>
    <p><strong>Temperature: </strong> <span className="ms-1"> {editableData.Temp} °C</span></p>
    <p><strong>Habits:</strong> <span className="ms-1"> {editableData.Habits}</span></p>
   
<p><strong>Symptoms:</strong> <span className="ms-1">{(editableData.Symptoms || []).join(', ')}</span></p>
  
  </div>
  <div>
  <p><strong>Date:</strong><span className="ms-1"> <DatePicker
    className="bg-[#efedede0] p-1 rounded-md w-auto bg-white"
    style={{ color: 'black', fontSize: 'inherit' }} // Set text color to black and inherit font size
    onChange={handleDateChange}
    value={dateValue}
    format="YYYY-MM-DD"
    disabled
/></span></p>
  <p><strong>Diagnosing Radiologist: </strong><span className="ms-1">  {Array.isArray(editableData?.Radiologist_Name)
                          ? editableData.Radiologist_Name.join(", ")
                          : editableData.Radiologist_Name}</span></p>
  <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{editableData.Assigned_Radiologist_Name}</span></p>
  <p><strong>Patient Status:</strong> <span className="ms-1">{editableData.AI_Disease_Diagnosis_Status}</span></p>
    <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{editableData.AI_Disease_Label}</strong> with probability of <strong> {editableData.AI_Disease_confidence}</strong></span></p>
    {('MedicalHistory' in editableData) && (
      <p><strong>Medical History:</strong> <span className="ms-1"> {editableData.MedicalHistory}</span></p>
    )}
      <p className="text-sm"><strong>Tests:</strong>  <span className="ms-1">{testsArray.join(', ')}</span></p>

  </div>
</div>
                <hr className="border-[#36AE78]" />
                <div className="flex flex-col gap-y-5">
                  <div className="space-y-2">
                  <div className="space-y-2">
                    {('CT_AI_Report_Status' in data) && (

                      <CTReport editableData={editableData}/>
            )}
             
                     
                    </div>
                    <div className="space-y-2">

{('CXR_AI_Report_Status' in data) && (
                  <DXReport editableData={editableData} />

)} </div>
                 
                  <RadioReport editableData={editableData} />
                    {/* <textarea
                      className="h-fit w-full text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3 no-scrollbar outline-none"
                      onChange={handleEditChange}
                      value={editableData.key_impression}
                      name="key_impression"
                    /> */}

                  
                    {/* {renderHTML(editableData.key_impression)} */}
                  </div>
                </div>
                {sections !== null && (
                  <div>
                    {/* Render sections */}
                    {sections.map((section, index) => (
                      <div key={index} className="space-y-2 ">
                        <div className="flex items-center">
                          <input
                            type="text"
                            value={section.title}
                            onChange={(e) =>
                              handleSectionChange(
                                index,
                                "title",
                                e.target.value
                              )
                            }
                            className=" text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl px-3 py-1 no-scrollbar outline-none"
                            placeholder="Section Heading"
                          />
                          {/* Button to remove section */}

                          <button
                            onClick={() => removeSection(index)}
                            className="text-[#ec767e] p-2"
                          >
                            <FaMinusCircle size={22} />
                          </button>
                        </div>

                        <ReactQuill
                          theme="snow"
                          value={section.content}
                          onChange={(content) =>
                            handleQuillChange2(index, content)
                          }
                          placeholder="Section Content"
                          style={{ backgroundColor: "#ffffff" }}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {/* "Add Section" button rendered below the sections */}
            
            
             
            
              </div>
                      ) : (
                        <>
                        <MdOutlineKeyboardArrowLeft
                          size={40}
                          onClick={() => setPreview(false)}
                          className=" cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-[69rem]"
                        />
                        <div className="space-y-4 p-9 w-full">
                          <div className="bg-white flex items-center p-8 border-b-2 border-[#6EC59D]">
                            <img
                              className="mr-4"
                              src={`data:image/jpeg;base64,${logo64Image}`}
                              width={100}
                              height={50}
                              alt="Binary Image"
                            />
                            <p className="text-2xl font-medium flex-grow text-center">
                              {editableData.Hospital.split("_")[0]}
                            </p>
                          </div>
        
                          <div className="flex justify-center ">
                            <h1 className="text-[20px] font-medium">
                              Patient UID : {editableData.UID_backup}
                            </h1>
                          </div>
                          <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
          <div>
            <p><strong>Name:</strong>  <span className="ms-1">{editableData.Name}</span></p>
            <p><strong>Age: </strong>  <span className="ms-1">{editableData.Age}</span></p>
            <p><strong>Sex: </strong>  <span className="ms-1">{editableData.Sex}</span></p>

            <p><strong>Height: </strong> <span className="ms-1"> {editableData.Height} cm</span></p>
            <p><strong>Weight: </strong> <span className="ms-1"> {editableData.Weight} kg</span></p>
            <p><strong>SPO2: </strong> <span className="ms-1"> {editableData.SPO2} %</span></p>
            <p><strong>Blood Pressure: </strong> <span className="ms-1"> {editableData.SBP}/{editableData.DBP}</span></p>
            <p><strong>Temperature: </strong> <span className="ms-1"> {editableData.Temp} °C</span></p>
            <p><strong>Habits:</strong> <span className="ms-1"> {editableData.Habits}</span></p>
         
            <p><strong>Symptoms:</strong> <span className="ms-1">{(editableData.Symptoms || []).join(', ')}</span></p>
          
          </div>
          <div>
          <p><strong>Date:</strong><span className="ms-1"> <DatePicker
            className="bg-[#efedede0] p-1 rounded-md w-auto bg-white"
            style={{ color: 'black', fontSize: 'inherit' }} // Set text color to black and inherit font size
            onChange={handleDateChange}
            value={dateValue}
            format="YYYY-MM-DD"
            disabled
        /></span></p>
          <p><strong>Diagnosing Radiologist: </strong><span className="ms-1">  {Array.isArray(editableData?.Radiologist_Name)
                                  ? editableData.Radiologist_Name.join(", ")
                                  : editableData.Radiologist_Name}</span></p>
          <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{editableData.Assigned_Radiologist_Name}</span></p>
          <p><strong>Patient Status:</strong> <span className="ms-1">{editableData.AI_Disease_Diagnosis_Status}</span></p>
            <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{editableData.AI_Disease_Label}</strong> with probability of <strong> {editableData.AI_Disease_confidence}</strong></span></p>
            {('MedicalHistory' in editableData) && (
              <p><strong>Medical History:</strong> <span className="ms-1"> {editableData.MedicalHistory}</span></p>
            )}
                      <p className="text-sm"><strong>Tests:</strong>  <span className="ms-1">{testsArray.join(', ')}</span></p>

          </div>
        </div>
                        
                          <hr className=" border-[#6EC59D]" />
                          <div className="space-y-4">
                            <div className="space-y-2">
                            {('CT_AI_Report_Status' in data) && (
        
                              <CTPreview editableData={editableData}/>
                    )}
                     
                             
                            </div>
                            <div className="space-y-2">
        
        {('CXR_AI_Report_Status' in data) && (
        <DXPreview editableData={editableData}/>
        
        )} </div>
                            <div className="space-y-2">
                              <p className="font-semibold text-[15px]">
                                Key impressions:{" "}
                              </p>
                              {renderHTML(editableData.key_impression)}
                              {/* <p className="text-sm font-normal">
                                {editableData.key_impression}
                              </p> */}
                            </div>
                            {sections &&
                              sections.map((section, index) => (
                                <div className="space-y-2" key={index}>
                                  <p className="font-semibold text-[15px]">
                                    {section.title}:
                                  </p>
                                  <p className="text-sm font-normal">
                                    {renderHTML(section.content)}
                                  </p>
                                </div>
                              ))}
                          </div>
                          <div className="flex flex-col items-end justify-end p-6">
                            <img
                              src={`data:image/jpeg;base64,${base64Image}`}
                              width={200}
                              height={50}
                              alt="Binary Image"
                            />
                            <p className="semi-bold  mt-2">
                              {Array.isArray(editableData?.Radiologist_Name)
                                ? editableData.Radiologist_Name.join(", ")
                                : editableData.Radiologist_Name}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
          </div>
        </div>
      </div>):null}
      </>
    );
  };

  return (
    <>
    <Aireportbox
     showModal={showModal}
     recordData={recordData}
     onConfirm={() => setShowModal(false)}
     onCancel={() => setShowModal(false)}
     data={confirmationModalData}
     setShowModal={setShowModal}
     setShowModal2={setShowModal2}
     showModal2={showModal2}
     formData={formData}
     modelview={modelview}
     setConfirmationModalData={setConfirmationModalData}
     setFormData={setFormData}
     setModelview={setModelview}
     active={active}
     reloadTable={reloadTable}
     radiologistData={radiologistData}
     setShowModalh={setShowModalh}
     showModalh={showModalh}
    />
      {/* <ConfirmationModal
        showModal={showModal}
        onConfirm={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        data={confirmationModalData}
        active={active}

      /> */}
      {loading ? (
        <div className="flex justify-center h-full items-center">
          <Rings height="160" width="160" color="#8e75d4" />
        </div>
      ) : (
        <div className=" overflow-y-scroll no-scrollbar w-full overflow-hidden rounded-xl overflow-x-auto mt-4 sticky top-0">
          <Datatable
            data={datatableData}
            handleModelView={handleModelView}
            modelview={modelview}
            consultingradiologistData={consultingradiologistData}
            handleDownload={handleDownload}
            setConfirmationModalData={setConfirmationModalData}
            setShowModal={setShowModal}
            handleView={handleView}
            formData={formData}
            setFormData={setFormData}
            flag={flag}
            active={active}
            radiologistData={radiologistData}
            setModelview={setModelview}
            setShowModal2={setShowModal2}
            showModal2={showModal2}
            setShowModalh={setShowModalh}
            showModalh={showModalh}
          />
        </div>
      )}
    </>
  );
}

const Table = ({ active, radiologistData, consultedradiologistData, consultingradiologistData, reloadTable, coconsultRadiologistList,formData  }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [isPatient, setisPatient] = useState(false);
  const [base, setbase] = useState("");
  const [logo, setlogo] = useState("");

  useEffect(() => {
    console.log("diptish");
    setlogo(radiologistData.Logo.$binary.base64);
    setbase(radiologistData.Signature.$binary.base64);

    //   const radiologist = coconsultRadiologistList.coconsultationRadiologist.find(r => r._id.$oid === currentUserID);
    //   if (radiologist) {


    // } else {
    //     console.log('Radiologist not found');
    // }

    if (active === 0) {
      setFilteredData(radiologistData.patientsData);
      // setbase(consultedradiologistData.Consulted_RADIO)
      setisPatient(true);
    } else if (active === 1) {
      setFilteredData(consultingradiologistData.consultingPatientData);
      setisPatient(false);
    } else {
      setFilteredData(consultedradiologistData.consultedgPatientData);
      setisPatient(false);
    }
  }, [active, radiologistData, consultingradiologistData]);

  return (
    <div className="flex flex-wrap justify-center gap-x-5 gap-y-4 mt-[22px]">
      <TableComponent logo={logo} base={base} consultingradiologistData={consultingradiologistData} radiologistData={radiologistData}  active={active} items={filteredData} flag={!isPatient} reloadTable={reloadTable} />
    </div>
  );
};

export default Table;
