import React, { useEffect, useState } from "react";
import Dropdown from "../HospitalAdmin/Dropdown";


const Tabs = ({active, setActive, noHospital, noRadiologist, noTechnician, noReports, setSidebarPage, setSidebarOpen, doctorspeData,reloadTable}) => {
const tabs = [
  { name: "Hospitals", no: noHospital },
  { name: "Radiologists", no: noRadiologist },
  { name: "Technicians", no: noTechnician },
  { name: "Reports", no: noReports },
];
  function handleSidebar(i){
    setSidebarPage(i);
    setSidebarOpen(true);
  }
  const items = [
    {
      value:"Add Radiologist",
    },
    {
      value:"Add Technician",
    },
    {
      value:"Update Hospital Details",
    },
    {
      value:"Add Hospital",
    }
  ]
  const reload = () => {
    console.log("meow")
    reloadTable()
 
     };
  return (
    <div className="mt-2 flex items-end justify-between border rounded-2xl h-[84px] w-full shadow bg-[#FFFFFF8A]">
      <div className="flex gap-2 ml-9">
        {tabs?.map((tab, index) => {
          return (
            <button
              key={index}
              onClick={() => {
                setActive(index);
              }}
              style={{ boxShadow: " 0px 0px 12px 0px rgba(0, 0, 0, 0.10)" }}
              className={`flex justify-center items-center px-10 h-9 cursor-pointer rounded-tr-lg rounded-tl-lg shadow font-semibold text-sm ${
                active == index ? "bg-[#8e75d4] text-white font-semibold scale-95 transform transition-transform duration-500 focus:outline-none ease-in-out" : "bg-[#E1DBFE] hover:bg-[#a698ecbd]"
              }`}
            >
              {tab.name} ({tab.no})
            </button>
          );
        })}
              <button onClick={reload} className={`w-[40px] h-[35px]  h-9  text-[12px] font-medium xx1 p-1 flex justify-center items-center gap-x-1 me-2`} ><svg class="h-6 w-6  font-semibold text-[16px]"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M19.95 11a8 8 0 1 0 -.5 4m.5 5v-5h-5" /></svg> </button>

      </div>

      <div className="flex mr-5 mb-3">

        {/* <div className="flex justify-center items-center bg-[#36AE78] rounded-xl w-44 h-9 gap-3">
          <img src="\assets\icons\Vector.png" alt="" />
          <p className="text-[white] font-semibold text-[16px] leading-6">
            Add hospital
          </p>
        </div> */}

        <Dropdown items={items} handleSidebar={handleSidebar} doctorspeData={doctorspeData} />
      </div>
    </div>
  );
};

export default Tabs;