import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';

const InReportDropdownButton = ({
  active,
  setModelview,
  setShowModal,
  setConfirmationModalData,
  tableData,
  setTableData,
  record,
  modelview,
  recordData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [aiReportCount, setAiReportCount] = useState(0);
  const [aicoldCount, setAicoldCount] = useState(0);
  const [aifollowCount, setAifollowCount] = useState(0);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (record) {
      setAiReportCount(Array.isArray(record.CT_AI_Report) ? record.CT_AI_Report.length : 0);
      setAicoldCount(Array.isArray(record.ColdReports) ? record.ColdReports.length : 0);
      setAifollowCount(Array.isArray(record.FollowUpReports) ? record.FollowUpReports.length : 0);
    }
  }, [record]);

  const handleOptionClick = (modality) => {
    setModelview(modality);
    setSelectedOption(modality);
    setShowModal(true);
    setConfirmationModalData({ record, modality });
    setTableData({
      ...tableData,
      Patient_uid: record._id?.$oid,
    });
    setIsOpen(false);
  };

  const options = ['Add Details', 'Cold Report', 'FollowUp Report'];
  const filteredOptions = active === 2 ? options.filter(option => option !== 'Write Report') : options;

  const getDropdownPosition = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownWidth = 180; // Width of the dropdown menu
      const dropdownHeight = filteredOptions.length * 36 + 16; // Height of the dropdown menu

      const rightSpace = window.innerWidth - buttonRect.right;
      const bottomSpace = window.innerHeight - buttonRect.bottom;

      return {
        top: bottomSpace < dropdownHeight ? -dropdownHeight : buttonRect.height + 8, // Adjust top position if not enough space at the bottom
        left: rightSpace < dropdownWidth ? -dropdownWidth : 0 // Adjust left position if not enough space on the right
      };
    }
    return { top: 0, left: 0 }; // Default positioning
  };

  const position = getDropdownPosition();

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className={`bg-[#ffffffe8] text-[#36AE78] border border-green-500 w-6 h-6 rounded-lg flex items-center justify-center ${isOpen ? 'border-blue-500' : ''}`}
      >
        <FaChevronDown size={12} />
      </button>

      {isOpen && (
        <div
          className="fixed mt-2 w-[180px] bg-[#ffffffe8] border border-green-500 rounded-lg shadow-lg z-[10000]"
          style={{
            top: `calc(${buttonRef.current.getBoundingClientRect().top + position.top}px)`,
            left: `calc(${buttonRef.current.getBoundingClientRect().left + position.left}px)`
          }}
        >
          {filteredOptions.map((modality) => (
            <button
              key={modality}
              onClick={() => handleOptionClick(modality)}
              className={`w-full px-2 py-1 text-left text-[#36AE78] hover:bg-[#F1F1F1] ${selectedOption === modality ? 'bg-blue-100' : ''}`}
            >
              {modality}
              {modality === 'Add Details' && aiReportCount > 0 && ` (${aiReportCount})`}
              {modality === 'Cold Report' && aicoldCount > 0 && ` (${aicoldCount})`}
              {modality === 'FollowUp Report' && aifollowCount > 0 && ` (${aifollowCount})`}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default InReportDropdownButton;
