import React, { useEffect, useState } from "react";
// import Reports from "../Patient.json";
import CardReport from "./CardReport";
import { MdDeleteForever } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import Settings from "../../Utils/Settings1";
import { currentUserID } from "../../Utils/USER";
import Details from "./Details";
import { FaExclamation } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
const { userRights, accountStatus } = Settings;


const CardComponent = ({ item, key, openDetails, setOpenDetails, reload }) => {
  const [showModal, setShowModal] = useState({ status: false, message: <></> });
  // const [status, setStatus] = useState("active");

  const handleConfirmation = () => {
    setShowModal({ status: false, message: <></> });
    if (item.STATUS == "Active") {
      terminate();
    }
    else if (item.STATUS == "Terminated") {
      activate();
    }
  }
  function viewDetails() {
    setOpenDetails({
      status: true,
      data: item
    })
  }
  async function terminate() {
    console.log(item._id.$oid)
    const payload =
    {
      "RequestId": uuidv4(),
      "Requestor": "Username",
      "Messages": {
        "MessageId": uuidv4(),
        "MessageType": accountStatus.split('/').pop(),
        "Timestamp": new Date().toISOString(),
        "Body": {
          "data": {
            "UserID": item._id.$oid,
            "Status": false
          }
        },
        "Parameters": {
          "UserId": currentUserID,
          "UserAccessToken": "",
          "APIVersion": ""
        },
        "Headers": {}
      }
    }
    const res = await axios.post(
      accountStatus, payload
    );
    console.log(res);
    if (res.status == 200) {
      toast.error("Terminated")
      setTimeout(function () {
        reload();
      }, 1000);
    }
  }
  async function activate() {
    console.log(item._id.$oid)
    const payload =
    {
      "RequestId": uuidv4(),
      "Requestor": "Username",
      "Messages": {
        "MessageId": uuidv4(),
        "MessageType": accountStatus.split('/').pop(),
        "Timestamp": new Date().toISOString(),
        "Body": {
          "data": {
            "UserID": item._id.$oid,
            "Status": true
          }
        },
        "Parameters": {
          "UserId": currentUserID,
          "UserAccessToken": "",
          "APIVersion": ""
        },
        "Headers": {}
      }
    }

    console.log(item._id.$oid);
    const res = await axios.post(
      accountStatus, payload
    );
    console.log(res);
    if (res.status == 200) {
      toast.success("Activated")
      setTimeout(function () {
        reload();
      }, 1000);
    }

  }
  async function rights() {
    setShowModal({ status: false, message: <></> });
    const payload = {
      "RequestId": uuidv4(),
      "Requestor": "Username",
      "Messages": {
        "MessageId": uuidv4(),
        "MessageType": userRights.split('/').pop(),
        "Timestamp": new Date().toISOString(),
        "Body": {
          "data": {
            "UserID": item._id.$oid
          }
        },
        "Parameters": {
          "UserId": currentUserID,
          "UserAccessToken": "",
          "APIVersion": ""
        },
        "Headers": {}
      }
    }

    const res = await axios.post(
      userRights, payload
    );
    console.log(res);
    if (res.status == 200) {
      toast.success("Rights changed")
      setTimeout(function () {
        reload();
      }, 1000);
    }
  }
  useEffect(() => {
    console.log(showModal)
  }, [showModal])

  return (
    <>
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showModal}
        onConfirm={handleConfirmation}
        rights={rights}
        onCancel={() => setShowModal({ status: false, message: <></> })}
        item={item}
        key={key}
      />
      <div key={key} onClick={() => console.log(item._id)} className={`w-[368px] h-fit space-y-3 rounded-[20px] ${item.STATUS == "Terminated" ? "bg-slate-100" : "bg-[#FFFFFFB8]"} p-4`}>
        <div className="flex gap-x-4 ">
          <img src="../assets/img/Hospitalimg.png" alt="hospital-img" />
          <div className="flex flex-col">
            <p className="text-sm font-semibold">{item.Name}</p>
            <span className="text-xs font-medium text-[#8F69FA]">
              {item.Address ? item.Address + ", " + item.City : item.Hospital}
            </span>
          </div>
        </div>
        <div className="text-[10px] text-[#303030] font-medium flex gap-x-6">
          <p>
            P : <span className="text-[#6F6F6F]">{item.Contact_Number}</span>
          </p>
          <p>
            E :{" "}
            <span className="text-[#6F6F6F]">
              {item.Email?.length > 21
                ? item.Email.substring(0, 21) + "..."
                : item.Email}
            </span>
          </p>
        </div>
        <hr className=" border-[#7B7B7B]" />
        <div className="flex justify-evenly items-center gap-x-4">
          {console.log(item, "666")}
          {

            item.STATUS !== "Terminated" ? (
              <div className="flex gap-x-2">
                <button
                  onClick={() =>
                    setShowModal({
                      status: true,
                      message: (
                        <>
                          <p className="text-xl font-semibold">
                            Are you sure you want to
                          </p>
                          <p className="text-3xl font-semibold">
                            Terminate Account?
                          </p>
                          <p className="text-xs font-medium py-4">
                            You Will Be Able To Re-Activate This Account After
                            Termination
                          </p>
                        </>
                      ),
                    })
                  }
                  className="text-xs font-medium rounded-xl w-[78px] h-[22px] text-[#36AE78] bg-[#ECF8F4] flex justify-center items-center"
                  data-toggle="modal"
                  data-target="#confirmationModal"
                >
                  Active
                </button>
                {item.Rights === "View" ? (
                  <button
                    onClick={() => {
                      setShowModal({
                        status: true,
                        message: (
                          <>
                            <p className="text-xl font-semibold">
                              Do you want to change
                            </p>
                            <p className="text-3xl font-semibold">
                              View Access to Full Access?
                            </p>
                          </>
                        ),
                        type: "rights",
                      });
                    }}
                    className="text-xs font-medium rounded-xl h-[22px]  text-[#b29440] bg-[#ffc40032] px-5 flex justify-center items-center"
                  >
                    View Access
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setShowModal({
                        status: true,
                        message: (
                          <>
                            <p className="text-xl font-semibold">
                              Do you want to change
                            </p>
                            <p className="text-3xl font-semibold">
                              Full Access to View Access?
                            </p>
                          </>
                        ),
                        type: "rights",
                      });
                    }}
                    className="text-xs font-medium rounded-xl h-[22px]  text-[#36AE78] bg-[#ECF8F4] px-6 flex justify-center items-center"
                  >
                    Full Access
                  </button>
                )}
              </div>
            ) : (
              <div>
                <button
                  className="text-xs font-medium rounded-xl px-[18px] h-[22px] text-[#A76569] bg-[#FDE0E2] flex justify-center items-center"
                  onClick={() =>
                    setShowModal({
                      status: true,
                      message: (
                        <>
                          <p className="text-xl font-semibold">
                            Are you sure you want to
                          </p>
                          <p className="text-3xl font-semibold">
                            Activate Account?
                          </p>
                          <p className="text-xs font-medium py-4">
                            You Will Be Able To de-Activate This Account After
                            Activation
                          </p>
                        </>
                      ),
                    })
                  }
                >
                  Deactivated
                </button>
              </div>
            )}
          <button
            onClick={viewDetails}
            className="text-sm font-medium rounded-lg text-white px-6 py-1 bg-[#4A4A4A]"
          >
            View Logs
          </button>
        </div>


      </div>
    </>
  );
};
const ConfirmationModal = ({
  showModal,
  onConfirm,
  rights,
  onCancel,
  item,
  hospitalAdminData,
}) => {
  return (
    <div
      className={`${showModal.status
        ? "fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
        : "hidden"
        } bg-black bg-opacity-50`}
    >
      {item.STATUS === "Active" ? (
        <div className="bg-[#FFFFFFE0] w-[640px] h-[460px] p-4 rounded-[40px] flex flex-col justify-center items-center gap-y-6">
          {showModal.type ? (
            item.Rights == "View" ?
              <div className="w-[120px] h-[120px] bg-[#36AE78] rounded-full flex justify-center items-center">
                <FaExclamation size={70} color="#FFFFFF" />
              </div> :
              <div className="w-[120px] h-[120px] bg-[#e4ca75] rounded-full flex justify-center items-center">
                <FaExclamation size={70} color="#FFFFFF" />
              </div>
          )

            :
            <div className="w-[120px] h-[120px] bg-[#E475A0] rounded-full flex justify-center items-center">
              <MdDeleteForever size={70} color="#FFFFFF" />
            </div>

          }
          <div className="text-center">
            {showModal.message}

            {showModal.type ? (
              <>
                {item.Rights == "View" ? (
                  <div className="flex justify-center mt-6 gap-x-6">
                    <button
                      className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#36AE78] border border-[#36AE78] rounded-lg bg-[#FFFFFFE0]"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#FFFFFF] rounded-lg bg-[#36AE78]"
                      onClick={rights}
                    >
                      Full Access
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center mt-6 gap-x-6">
                    <button
                      className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#e4ca75] border border-[#e4ca75] rounded-lg bg-[#FFFFFFE0]"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#FFFFFF] rounded-lg bg-[#e4ca75]"
                      onClick={rights}
                    >
                      View Access
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="flex justify-center mt-6 gap-x-6">
                <button
                  className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#E475A0] border border-[#E475A0] rounded-lg bg-[#FFFFFFE0]"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#FFFFFF] rounded-lg bg-[#E475A0]"
                  onClick={onConfirm}
                >
                  Terminate
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="bg-[#FFFFFFE0] w-[640px] h-[460px] p-4 rounded-[40px] flex flex-col justify-center items-center gap-y-6">
          <div className="w-[120px] h-[120px] bg-[#36AE78] rounded-full flex justify-center items-center">
            <FaCheck size={70} color="#FFFFFF" />
          </div>

          <div className="text-center">
            <p className="text-xl font-semibold">Are you sure you want to</p>
            <p className="text-3xl font-semibold">Activate Account?</p>
            <p className="text-xs font-medium py-4">
              You Will Be Able To De-Activate This Account After Activation
            </p>

            <div className="flex justify-center mt-6 gap-x-6">
              <button
                className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#36AE78] border border-[#36AE78] rounded-lg bg-[#FFFFFFE0]"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#FFFFFF] rounded-lg bg-[#36AE78]"
                onClick={onConfirm}
              >
                Activate
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Cards = ({
  active,
  searchQuery,
  setNoHospital,
  setNoRadiologist,
  setNoReports,
  setNoTechnician,
  filterOption,
  start,
  end,
  superAdminData,
  reloadTable,
}) => {
  const [jsonData, setJsonData] = useState(superAdminData.HOSPITAL);
  const [filteredData, setFilteredData] = useState([]);
  const [openDetails, setOpenDetails] = useState({
    status: false,
    data: {}
  })

  useEffect(() => {
    console.log(openDetails)
  }, [openDetails])

  useEffect(() => {
    if (active == 0) {
      setJsonData(superAdminData.HOSPITAL);
    } else if (active == 1) {
      setJsonData(superAdminData.RADIOLOGISTS);
    } else if (active == 2) {
      setJsonData(superAdminData.TECHNICIAN);
    }
    setNoHospital(superAdminData.HOSPITAL?.length);
    setNoRadiologist(superAdminData.RADIOLOGISTS?.length);
    setNoTechnician(superAdminData.TECHNICIAN?.length);
    setNoReports(superAdminData.REPORT_patients?.length);
  }, [active]);

  useEffect(() => {
    console.log(searchQuery, "searchhhhhhh")
    const filtered =
      filterOption?.length > 0
        ? jsonData
          ?.filter((item) =>
            item.Name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          ?.filter(
            (item) =>
              filterOption.includes(item.Name) ||
              filterOption.includes(item.City) ||
              filterOption.includes(item?.Hospital?.split("_")[0])
          )
        : jsonData?.filter((item) =>
          item.Name.toLowerCase().includes(searchQuery.toLowerCase())
        );

    setFilteredData(filtered);
  }, [searchQuery, jsonData, filterOption]);

  return (
    <div className="flex flex-wrap gap-x-5 gap-y-4 mt-[22px]">
      {openDetails.status &&
        <Details openDetails={openDetails} setOpenDetails={setOpenDetails} />
      }
      {active != 3 ? (
        filteredData
          ?.slice(start, end + 1)
          ?.map((item, index) => {
            // Normalize the STATUS property
            const normalizedItem = {
              ...item,
              STATUS: item.STATUS === true ? "Active" : item.STATUS === false ? "Terminated" : item.STATUS
            };

            return (
              <CardComponent
                key={index}
                item={normalizedItem}
                openDetails={openDetails}
                setOpenDetails={setOpenDetails}
                reload={reloadTable}
              />
            );
          })

      ) : (
        <CardReport
          filterOption={filterOption}
          superAdminData={superAdminData}
        />
      )}
    </div>
  );
};
export default Cards;
