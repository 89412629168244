import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker } from "react-leaflet";


const MapComponent = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const initializeMap = () => {
      
      const initialLat = 18.5204;
      const initialLng = 73.8567;
      const position = [initialLat, initialLng]
    
      mapRef.current = L.map("map", {
        center: position,
        zoom: 14,
        zoomControl: false, 
        scrollWheelZoom: false,
      });
    
      const customIcon = L.icon({
        iconUrl: 'https://static.vecteezy.com/system/resources/previews/014/585/763/original/red-map-pin-icon-png.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(mapRef.current);


      L.marker(position, { icon: customIcon })
  .addTo(mapRef.current)

    };

    initializeMap();

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, []);

  return <div id="map" className="w-full h-[8rem] rounded-lg shadow-md mt-2"></div>;
};

export default MapComponent;
