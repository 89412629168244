import React, { useEffect, useState, useRef } from "react";
import { TiUserAdd } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import { PiCloudArrowDown } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import link from "../../Utils/Settings";
import { currentUserID, currentUserRights } from "../../Utils/USER";
import { toast } from "react-hot-toast";
import { useCheck } from "./CheckContext";
import { BiChevronLeft } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";
import { useFilterContext } from "./FilterContext";
import Settings from "../../Utils/Settings1";
import { v4 as uuidv4 } from "uuid";
import AddPatient from "./Addpatient";
import { RxCross1 } from "react-icons/rx";

const { assign } = Settings;

const Tabs = ({
  technicianData,
  setPatientAdded,
  setIsAssigned,
  reloadTable,
  setSidebarOpen,
  sidebarOpen,
  apiData,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [showAssginBox, setshowAssignBox] = useState(false);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [selectedName, setselectedName] = useState("Select Radiologist's Name");
  const [selectedId, setselectedId] = useState("");
  const { selectedItems } = useCheck();
  const [loading, setLoading] = useState(false);
  // const [isContentVisible, setIsContentVisible] = useState(false);
  const { clearAllFilters, isFilterActive } = useFilterContext();

  const [formData, setFormData] = useState({
    user_id: currentUserID,
    Scans: [],
  });
  const modalRef = useRef();
  function reset() {
    setFormData({
      user_id: currentUserID,
      Scans: [],
    });
    setShowConfirmationModal(false);
    setIsDragging(false);
  }

  async function handleSubmit() {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", currentUserID);

    if (!formData.Scans || formData.Scans.length === 0) {
      toast.error("No files selected");
      return;
    }

    for (let i = 0; i < formData.Scans.length; i++) {
      const file = formData.Scans[i];
      const fileName = file.name.toLowerCase();
      const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);

      if (fileExtension !== "dcm" && fileExtension !== "dicom") {
        toast.error("Please select only DICOM files.");
        return;
      }
      formDataToSend.append("Scans", file);
    }

    try {
      setLoading(true);
      const promise = axios.post(
        `${link}/Upload_Files_Processors`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const res = await promise;

      if (res.status === 200 || res.status === 201) {
        toast.promise(
          promise,
          {
            loading: "Submitting...",
            success: "Form submitted successfully!",
            error: "Unable to submit",
          },
          {
            position: "top-right",
            style: {
              minWidth: "250px",
            },
          }
        );
        reset();
        setPatientAdded(true);
        reloadTable();
      } else {
        toast.error("Submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("Error submitting form.");
    } finally {
      setLoading(false);
      setShowConfirmationModal(false);
    }
  }

  const handleRemoveFile = (index) => {
    const updatedScans = [...formData.Scans];
    updatedScans.splice(index, 1);
    setFormData({ ...formData, Scans: updatedScans });
  };

  console.log(selectedItems);
  const data = technicianData;
  const handleAddPatientClick = () => {
    setSidebarOpen(true);
    setShowConfirmationModal(true);
  };

  const handleConfirm = () => {
    setShowConfirmationModal(false);
    // Add logic for handling confirmation here
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const items = e.dataTransfer.items;
    const scannedFiles = [];

    const traverseFilesAndDirectories = async (item) => {
      if (item.isFile) {
        const file = await new Promise((resolve) => item.file(resolve));
        scannedFiles.push(file);
      } else if (item.isDirectory) {
        const directoryReader = item.createReader();
        const directoryItems = await new Promise((resolve) =>
          directoryReader.readEntries(resolve)
        );
        for (const directoryItem of directoryItems) {
          await traverseFilesAndDirectories(directoryItem);
        }
      }
    };

    const scanDroppedItems = async () => {
      for (const item of items) {
        await traverseFilesAndDirectories(item.webkitGetAsEntry());
      }
      setFormData({ ...formData, Scans: [...formData.Scans, ...scannedFiles] });
      setIsDragging(true);
    };

    scanDroppedItems();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFormData({ ...formData, Scans: [...formData.Scans, ...selectedFiles] });
    // setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  //Assign functionality
  const handleAssign = () => {
    // Show the confirmation modal
    setshowAssignBox(true);
  };
  //Assign functionality
  const reload = () => {
    console.log("meow");
    reloadTable();
  };
  const handleAssignClose = () => {
    setshowAssignBox(false);
  };

  const handleDropdownToggle = () => {
    setisDropdownOpen((prevOpen) => !prevOpen);
  };
  const handleRadiologistSelect = (selectedRadiologist, id) => {
    // Add your logic for handling the selected radiologist
    setselectedName(selectedRadiologist);
    setselectedId(id);

    setisDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const handleAssignConfirm = async () => {
    // Prepare the data to be sent to the backend
    const requestData = {
      radiologist: selectedId,
      patients: selectedItems.map((patient) => ({
        id: patient?.id?.$oid,
        name: patient.name,
      })),
    };

    // New payload structure
    const payload = {
      RequestId: uuidv4(),
      Requestor: "Username",
      Messages: {
        MessageId: uuidv4(),
        MessageType: "assign",
        Requesttimestamp: new Date().toISOString(),
        Body: {
          data: {
            Radiologist: selectedId,
            Patients: selectedItems.map((patient) => patient?.id?.$oid),
          },
        },
        Parameters: {
          UserId: currentUserID,
          UserAccessToken: "",
          APIVersion: "",
        },
        Headers: {},
      },
    };

    try {
      setLoading(true);
      // Make a POST request to the backend server using Axios
      const response = await axios.post(assign, payload);

      // Check if the request was successful (status code 2xx)
      if (response.status === 200) {
        toast.success("Assigned");
        reloadTable();
        // Implement logic for handling a successful response
        console.log("Assign request successful");
        setIsAssigned(true);
      } else {
        // Implement logic for handling an unsuccessful response
        console.error(
          "Assign request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      // Handle any network or Axios-related errors
      console.error("Error during Assign request:", error);
    } finally {
      setLoading(false);
    }

    // Close the assign modal after handling the confirmation
    setshowAssignBox(false);
  };

  useEffect(() => {
    console.log("formdata", formData);
  }, [formData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowConfirmationModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (formData.Scans.length > 0) {
      setIsDragging(true);
    }
  }, [formData]);

  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );
  return (
    <div className="mt-2 flex items-end justify-between border rounded-2xl h-[6rem] w-full shadow bg-[#FFFFFF8A]">
      <div className="p-4 flex justify-center items-center gap-2">
        <button
          onClick={clearAllFilters}
          disabled={!isFilterActive}
          className="w-[85px] h-[32px] border border-[#303030] rounded-lg text-[12px] font-medium bg-[#FFFFFFE0] p-1 flex justify-center items-center gap-x-1"
          style={isFilterActive ? {} : { opacity: "0.5" }}
        >
          Clear all
        </button>
        <button
          disabled={currentUserRights === "View"}
          onClick={handleAssign}
          className={`w-[85px] h-[32px] border border-[#303030] rounded-lg text-[12px] font-medium bg-[#FFFFFFE0] p-1 flex justify-center items-center gap-x-1 ${
            currentUserRights === "View" ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <TiUserAdd size={15} />
          Assign
        </button>
        {showAssginBox && (
          <div className="z-40 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(0,0,0,0.6)]">
            <div className="z-50 bg-[#FFFFFFE0] w-[520px] h-[514px] rounded-3xl flex flex-col items-center justify-center">
              <RxCross2
                size={30}
                onClick={handleAssignClose}
                className="ml-[25rem] cursor-pointer hover:bg-gray-400 rounded-full p-1"
              />

              <p className="text-4xl font-semibold text-center">
                Assign Radiologist
              </p>
              <div className="w-[450px] h-[250px]  mt-9 flex flex-col  items-center gap-y-2">
                <p className="font-semibold mr-[220px] ">Radiologist's Name</p>
                <div className="w-[90%]  relative">
                  <div className="relative  text-left">
                    <button
                      onClick={handleDropdownToggle}
                      type="button"
                      className={`inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-green-500 rounded-md hover:bg-gray-50 focus:outline-none focus:border-green-300 focus:ring focus:ring-green-200 active:bg-gray-200 `}
                      id="dropdown-button"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      {selectedName}
                      <svg
                        className="-mr-1 ml-2 h-5 w-5 transition-transform duration-300 transform ${isDropdownOpen ? 'rotate-180' : ''}"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 12a1 1 0 01-.7-.29l-4-4a1 1 0 111.41-1.42L10 9.59l3.29-3.3a1 1 0 111.42 1.42l-4 4a1 1 0 01-.71.29z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>

                    {isDropdownOpen && (
                      <ul
                        className="origin-top-right absolute right-0 mt-2 w-[100%] rounded-md bg-white border-2 border-green-600 ring-opacity-5 overflow-y-auto max-h-40 scroll scrollbar-padding-[10px]"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="dropdown-button"
                      >
                        {console.log(data, "dataaaaa")}
                        {Array.isArray(data.RADIOLOGISTS) &&
                          data.RADIOLOGISTS.filter(
                            (e) => e.STATUS != "Terminated"
                          ).map((ele) => (
                            <li
                              key={ele["_id"]}
                              onClick={() =>
                                handleRadiologistSelect(ele["Name"], ele["_id"])
                              }
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                              role="menuitem"
                            >
                              {ele["Name"]}
                            </li>
                          ))}
                      </ul>
                    )}

                    <div className="mt-4  w-[100%]">
                      <ul
                        className="origin-top-right  w-[100%] rounded-md  bg-white border-2 border-green-600  ring-opacity-5 overflow-y-auto max-h-40 scroll scrollbar-padding-[10px]"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="dropdown-button"
                      >
                        {selectedItems.map((ele) => (
                          <li
                            className="flex justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                            role="menuitem"
                            key={ele.uid}
                          >
                            <span className="mr-3">{ele.uid}</span>
                            <span>{ele.name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <button
                onClick={handleAssignConfirm}
                className="bg-[#36AE78] text-white font-semibold px-8 py-2 rounded-xl w-[450px] mt-6 pointer-cursor"
              >
                {loading ? loader : "Continue"}
              </button>
            </div>
          </div>
        )}
        <button
          onClick={reload}
          className={`w-[40px] h-[35px]  h-9  text-[12px] font-medium xx1 p-1 flex justify-center items-center gap-x-1 me-2`}
        >
          <svg
            class="h-6 w-6  font-semibold text-[16px]"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            {" "}
            <path stroke="none" d="M0 0h24v24H0z" />{" "}
            <path d="M19.95 11a8 8 0 1 0 -.5 4m.5 5v-5h-5" />
          </svg>{" "}
        </button>
      </div>

      {/* <div className="flex items-center py-4">
        <div
          className={`flex items-center justify-center ${
            isContentVisible ? "w-[60rem] relative right-0 transition-all duration-1000" : "w-[0rem] relative right-[-5rem] overflow-hidden"
          } gap-x-4`}
        >
          {filterData.map((item) => {
            return (
              <button className="flex bg-[#FFFFFFE0] text-[14px] px-3 py-[7px] border rounded-lg">
                {item}
                <BiChevronDown size={18} />
              </button>
            );
          })}
        </div>
        <button
          onClick={toggleContentVisibility}
          className="flex border rounded-lg bg-[#FFFFFFE0] text-[14px] px-3 py-[7px]"
        >
          
          {isContentVisible ? <BiChevronRight size={18} /> :  <><BiChevronLeft size={18} />Filters</>}
        </button>
      </div> */}

      <button
        disabled={currentUserRights === "View"}
        onClick={handleAddPatientClick}
        className={`flex justify-center items-center bg-[#303030] rounded-xl h-9 m-4 px-6 gap-1 active:scale-95 active:transform transition-transform duration-300 focus:outline-none ${
          currentUserRights === "View" ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <p className="text-[white] font-semibold text-[16px] leading-6 flex items-center gap-x-2">
          <TiUserAdd size={15} />
          Add patient details
        </p>
      </button>
      {showConfirmationModal && (
  <div className="fixed top-0 inset-0 flex items-center justify-center h-full bg-black bg-opacity-50 z-40 backdrop-blur-lg">
    <div className={`z-50 fixed top-0 left-0 h-full pt-5 px-2 bg-white w-[60%] shadow-lg ${!sidebarOpen ? "-translate-x-full" : ""} transition-all duration-700 ease-in-out`}>
      <div onClick={() => { setSidebarOpen(false); setShowConfirmationModal(false); }} className="cursor-pointer bg-slate-200 p-2 w-fit rounded-full absolute right-3">
        <RxCross1 />
      </div>
      <AddPatient setSidebarOpen={setSidebarOpen} setShowConfirmationModal={setShowConfirmationModal} showConfirmationModal={showConfirmationModal} technicianData={technicianData} reloadTable={reloadTable} apiData={apiData} />
    </div>
  </div>
)}

    </div> 

  );
};
export default Tabs;
