import React, { useState, useMemo, useEffect } from "react";
import { Space, Table, Tag, Checkbox, DatePicker, Slider, Button } from "antd";
import { MdOutlineFileDownload, MdOutlineRemoveRedEye } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { AiFillFilter } from "react-icons/ai";
import { useFilterContext } from "./FilterContext";
import FilterDropdown from "./FilterDropdown";
import "./CustomDatePicker.css"
import DropdownButton from "./Dropdown1";
import { useCheck } from "./CheckContext";
import DownloadDropdownButton from "./Downloaddrop";
import ReportDropdownButton from "./ReportDrop";
import { Rings } from "react-loader-spinner";

import { formatInTimeZone } from 'date-fns-tz';

const Datatable = ({
  loading1,
  data,
  handleDownload,
  setShowModal,
  setConfirmationModalData,
  handleView,
  tableData,
  technicianData,
  handleModelView, 
  setTableData,
  datatableData,
}) => {

  const { dateRange, setDateRange, aiFilter, setAiFilter, ageRange, setAgeRange, sexFilter, setSexFilter, bodyPartFilter, setBodyPartFilter, modalityFilter, setModalityFilter, reportStatusFilter, setReportStatusFilter, radiologistFilter, setRadiologistFilter, aiSelected, setAiSelected, sexSelected, setSexSelected, bodyPartSelected, setBodyPartSelected, modalitySelected, setModalitySelected, reportStatusSelected, setReportStatusSelected, radiologistSelected, selectedAgeKeys, setSelectedAgeKeys, setRadiologistSelected, } = useFilterContext();

  const { selectedItems, handleCheckboxChange, setSelectedItems } = useCheck();
  const [selectAll, setSelectAll] = useState(false);
  const parentModalities = ['MRI', 'CT', 'US', 'DX']; // Define modalities here

  useEffect(() => {
    console.log("CT_Scan_ID:", datatableData);
    if (selectAll) {
      setSelectedItems([...tableData]);
    } else {
      setSelectedItems([]);
    }
  }, [selectAll]);
  const extractPatientScanIds = (patients) => {
  const patientScanIds = {};

  patients.forEach((patient) => {
    const { Name, MRI_Scan_ID, CT_Scan_ID, US_Scan_ID, DX_Scan_ID } = patient;
    patientScanIds[Name] = {
      MRI: MRI_Scan_ID || [],
      CT: CT_Scan_ID || [],
      US: US_Scan_ID || [],
      DX: DX_Scan_ID || [],
    };
  });

  return patientScanIds;
};
const extractPatientreport = (patients) => {
  const patientreport = {};

  patients.forEach((patient) => {
    const { Name, Radiologist_Report_Name } = patient;
    patientreport[Name] = {
      Radiologist_Report_Name: Radiologist_Report_Name || [],
    };
  });

  return patientreport;
};



const formatDate1 = (date) => {
  try {
    // Use 'UTC' time zone for consistent formatting
    return formatInTimeZone(new Date(date), 'UTC', 'dd-MM-yyyy');
  } catch {
    return "Invalid Date";
  }
};
const formatDate = (date) => {
  try {
    // Use 'UTC' time zone for consistent formatting
    return formatInTimeZone(new Date(date), 'UTC', 'dd-MM-yyyy');
  } catch {
    return "Invalid Date";
  }
};
  // const handleCheckboxChange = (record, checked) => {
  //   if (checked) {
  //     setSelectedRows([...selectedRows, record]);
  //   } else {
  //     setSelectedRows(selectedRows.filter(row => row !== record));
  //   }
  // };

  const handleAiChange = (ai) => {
    setAiFilter(ai);
  };
  const handlesexChange = (sex) => {
    setAiFilter(sex);
  };
  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };
  const handleAgeChange = (range) => {
    setAgeRange(range);
    setSelectedAgeKeys(range);
  }
  const filteredData = useMemo(() => {
    console.log(data);
    let filtered = [...data];
    if (dateRange) {
      filtered = filtered.filter((record) => {
        const recordDate = new Date(formatDate(record.date));
        const [startDate, endDate] = dateRange;
        return recordDate >= startDate && recordDate <= endDate;
      });
    }
    if (aiFilter && aiFilter.length > 0) {
      filtered = filtered.filter((record) => {
        // Check if the record's `ai` status matches any of the filters
        return aiFilter.includes(record.AI_Disease_Diagnosis_Status);
      });
    }
    if (ageRange && ageRange.length > 0) {
      filtered = filtered.filter((record) => {
        const age = parseInt(record.age);
        const [minAge, maxAge] = ageRange;
        return age >= parseInt(minAge) && age <= parseInt(maxAge);
      });
    }
    if (sexFilter && sexFilter.length > 0) {
      filtered = filtered.filter(record => sexFilter.includes(record.Sex));
    }
    if (bodyPartFilter && bodyPartFilter.length > 0) {
      filtered = filtered.filter(record => bodyPartFilter.includes(record.bodyPart));
    }
    if (modalityFilter && modalityFilter.length > 0) {
      filtered = filtered.filter(record => modalityFilter.includes(record.modality));
    }
    if (reportStatusFilter && reportStatusFilter.length > 0) {
      filtered = filtered.filter(record => reportStatusFilter.includes(record.reportStatus));
    }
    if (radiologistFilter && radiologistFilter.length > 0) {

      filtered = filtered.filter(record => {
        for (const element of radiologistFilter) {
          if (element === "Done" && record.radiologist.length > 0) {

            return true;
          }
          else if (element === "Pending" && record.radiologist.length === 0) {

            return true;
          }
          else {
            return false;
          }
        }

      });
    }

    return filtered;
  }, [data, ageRange, dateRange, aiFilter, sexFilter, bodyPartFilter, modalityFilter, radiologistFilter, reportStatusFilter]);
  const patientScanIds = extractPatientScanIds(datatableData || []);
  const patienreport = extractPatientreport(datatableData || []);

  const aiOptions = [
    { value: "Abnormal", label: "Abnormal" },
    { value: "Normal", label: "Normal" }
  ];

  const sexOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" }
  ];

  const bodyPartOptions = [
    { value: "CHEST", label: "Chest" },
    { value: "HEAD", label: "Head" },
    { value: "ARMS", label: "Arms" },
    { value: "LEGS", label: "Legs" }
  ];

  const modalityOptions = [
    { value: "CT", label: "CT" },
    { value: "DX", label: "DX" },
    { value: "MRI", label: "MRI" },
    { value: "USG", label: "USG" }
  ];

  const radiologistOptions = [
    { value: "Done", label: "Done" },
    { value: "Pending", label: "Pending" }
  ];

  const reportStatusOptions = [
    { value: "Done", label: "Done" },
    { value: "Pending", label: "Pending" }
  ]




  const columns = [
    {
      title: (
        <Checkbox
          checked={selectAll}
          onChange={(e) => setSelectAll(e.target.checked)}
        />
      ),
      dataIndex: 'select',
      render: (_, record) => (
        <Checkbox
          // Determine checked state based on selectedRows
          onChange={() => handleCheckboxChange(record.uid, record.name, record.id)}
          checked={selectedItems.some((row) => row.id.$oid === record.id.$oid)}
        />
      ),
    },
    {
      title: "AI",
      dataIndex: "AI_Disease_Diagnosis_Status",
      key: "AI_Disease_Diagnosis_Status",
      align: 'center',
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDropdown
          setSelectedKeys={setAiSelected}
          selectedKeys={aiSelected}
          confirm={confirm}
          clearFilters={clearFilters}
          options={aiOptions}
          onChange={handleAiChange}
          setFilter={setAiFilter}
        />
      ),
      filterIcon: () => {

        return <AiFillFilter style={{ color: aiFilter?.length > 0 ? "#36ae78" : "inherit" }} />
      },
      render: (status) => {
        return status === "Abnormal" ? (
          <div className="h-[24px] w-[24px] text-[#FFFFFF] text-xs flex items-center justify-center rounded-full bg-[#E475A0]">
            A
          </div>
        ) : (
          <div className="h-[24px] w-[24px] text-[#FFFFFF] text-xs flex items-center justify-center rounded-full bg-[#36AE78]">
            N
          </div>
        );
      }
    },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
      align: 'center',
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: 'center',

    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      align: "center",
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <Slider
            range
            defaultValue={[0, 100]}
            min={0}
            max={100}
            onChange={handleAgeChange}
            value={selectedAgeKeys}
          />
          <Space>
            <Button
              type="button"
              onClick={() => {
                confirm(); // Confirm immediately to trigger filter update
              }}
            >
              OK
            </Button>
            <Button
              type="button"
              onClick={() => {
                setSelectedAgeKeys([0, 100]); // Reset the slider range
                setAgeRange([]); // Reset the slider range
                confirm(); // Confirm the reset
              }}
            >
              Reset
            </Button>

          </Space>
        </div>
      ),
      filterIcon: () => (
        <span>
          <AiFillFilter size={14} style={{ color: ageRange?.length > 0 ? "#36ae78" : "inherit" }} />
        </span>
      ),
      render: (age) => `${age}`,
    },

    {
      title: "Sex",
      dataIndex: "sex",
      key: "sex",
      align: "center",
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDropdown
          setSelectedKeys={setSexSelected}
          selectedKeys={sexSelected}
          confirm={confirm}
          clearFilters={clearFilters}
          options={sexOptions}
          setFilter={setSexFilter}
          onChange={handlesexChange}


        />
      ),
      filterIcon: () => (
        <span>
          <AiFillFilter size={14} style={{ color: sexFilter?.length > 0 ? "#36ae78" : "inherit" }} />
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "$date",
      align: "center",
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <DatePicker.RangePicker
            onChange={handleDateRangeChange}
            value={dateRange}
            style={{ width: 200, marginBottom: 8, display: "block" }}
            popupStyle={{ transition: 'none' }} // Disable sliding animation
            transitionName="" // Disable hover effect
          />
        </div>
      ),
      filterIcon: () => (
        <span>
          <AiFillFilter size={14} style={{ color: dateRange ? "#36ae78" : "inherit" }} />
        </span>
      ),
      // Hide default filter icon
      render: (text) => {
        // Access the $date field from the nested structure
        return text ? formatDate1(text.$date) : 'N/A';
      },

    },
 
    {
      title: "Radiologist",
      dataIndex: "radiologist",
      key: "radiologist",
      align: "center",
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDropdown
          setSelectedKeys={setRadiologistSelected}
          selectedKeys={radiologistSelected}
          confirm={confirm}
          clearFilters={clearFilters}
          options={radiologistOptions}
          setFilter={setRadiologistFilter}


        />
      ),
      filterIcon: () => (
        <span>
          <AiFillFilter size={14} style={{ color: radiologistFilter?.length > 0 ? "#36ae78" : "inherit" }} />
        </span>
      ),
      render: (_, { radiologist }) => {
        return !radiologist ? "Pending" : radiologist;
      },

    },
    {
      title: "Report Status",
      dataIndex: "reportStatus",
      key: "reportStatus",
      align: "center",
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDropdown
          setSelectedKeys={setReportStatusSelected}
          selectedKeys={reportStatusSelected}
          confirm={confirm}
          clearFilters={clearFilters}
          options={reportStatusOptions}
          setFilter={setReportStatusFilter}


        />
      ),
      filterIcon: () => (
        <span>
          <AiFillFilter size={14} style={{ color: reportStatusFilter?.length > 0 ? "#36ae78" : "inherit" }} />
        </span>
      ),
      render: (_, record) => {
        return record.reportStatus == "Done" ? (
          <div className="flex justify-center">
Done           
          </div>
        ) : (
          <button className="w-[86px] h-[24px]">Pending</button>
        );
      },
    },
    {
      title: "Final Report",
      dataIndex: "finalReport",
      key: "finalReport",
      align: 'center',

      render: (_, record) => {
        return (
          <div className="flex justify-center gap-x-2">
            {/* <button
              onClick={() => {
                setShowModal(true);
                setConfirmationModalData(record);
              }}
              className="bg-[#FFFFFF8A] text-[#36AE78] border border-green-500 w-[24px] h-[24px] rounded-lg flex items-center justify-center"
            >
              <FaPen size={12} />

            </button> */}
            <ReportDropdownButton data={data}  setShowModal={setShowModal} tableData={tableData} setTableData={setTableData} setConfirmationModalData={setConfirmationModalData}   record={record}   modalities={parentModalities} handleModelView={handleModelView}/>

            <DropdownButton  patientScanIds={patientScanIds} handleView={handleView}       modalities={parentModalities} // Pass modalities as prop
 record={record} />

<div className="flex justify-center">
            <DownloadDropdownButton size={12} handleDownload={handleDownload}  record={record} modalities={parentModalities} patienreport={patienreport}/>
           
          </div>
            <span>
        </span>


            {/* <button
              onClick={() => handleView(record)}
              className="bg-[#FFFFFF8A] text-[#36AE78] border border-green-500 w-[24px] h-[24px] rounded-lg flex items-center justify-center"
            >
              <MdOutlineRemoveRedEye size={18} />
            </button> */}
          </div>
        );
      },
    },
  ];

  return( <>
    {loading1 ? (
      <div className="flex justify-center items-center h-full">
        <Rings height="160" width="160" color="#8e75d4" />
      </div>
    ) : (
      <>
      <Table className="overflow-scroll no-scrollbar w-full bg-white" columns={columns} dataSource={filteredData} pagination={false} />;
</>
    )}
  </>)
};
export default Datatable;

