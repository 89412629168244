import React, { useState } from 'react';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { FaSearch } from 'react-icons/fa';
import Logout from "../../pages/Common/Logout"; // Ensure this is the correct path
import { RxCross2 } from "react-icons/rx";

export default function SearchBar({ handleSearch ,setQuery1,setPageNumber}) {
  const [query, setQuery] = useState("");

  const handleLogout = () => {
    console.log('Logout successful in SearchBar component');
    // Add actual logout logic here if needed
  };

  const handleChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    setPageNumber(1);

    console.log("Current query:", newQuery);
  };

  const clearQuery = () => {
    setQuery("");
    handleSearch("");
    setQuery1("");
    setPageNumber(1);
  };

  return (
    <div className="flex justify-end w-full gap-x-6 items-center">
      <div className="relative ml-4 flex items-center gap-x-4">
        <input
          value={query}
          onChange={handleChange}
          className="px-4 py-1 w-[20rem] rounded-lg border border-gray-300 pr-10" // Added pr-10 for padding-right
          type="text"
          placeholder="Search"
          aria-label="Search input"
        />
        {query && (
          <RxCross2
            size={20}
            onClick={clearQuery}
            className="absolute right-3  top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
            aria-label="Clear search"
          />
        )}
       
      </div>
      <button
          onClick={() => handleSearch(query)}
          className="text-black rounded-lg"
          aria-label="Search button"
        >
          <FaSearch />
        </button>
      <button
        onClick={handleLogout}
        className="text-black rounded-lg"
        aria-label="Logout button"
      >
        <Logout />
      </button>
    </div>
  );
}
