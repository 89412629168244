import React, { useEffect, useState } from "react";
import Header from "../../components/SuperAdmin/Header";
import Tabs from "../../components/SuperAdmin/Tabs";
import Filters from "../../components/SuperAdmin/Filters";
import SearchBar from "../../components/SuperAdmin/SearchBar";
import Cards from "../../components/SuperAdmin/Cards";
import Pagination from "../../components/SuperAdmin/Pagination";
import Sidebar from "../../components/SuperAdmin/Sidebar/Sidebar";
import axios from "axios";
import { Rings } from "react-loader-spinner";
import Settings from "../../Utils/Settings1";
import { currentUserID } from "../../Utils/USER";
import { v4 as uuidv4 } from "uuid";
const { superAdminDashboard ,doctorSpecialization} = Settings;

const SuperAdminDashboard = ({ children }) => {
  const [active, setActive] = useState(0);
  const [noHospital, setNoHospital] = useState(0);
  const [noRadiologist, setNoRadiologist] = useState(0);
  const [noTechnician, setNoTechnician] = useState(0);
  const [noReports, setNoReports] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  // Sidebar
  const [sidebarPage, setSidebarPage] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [filterOption, setFilterOption] = useState([]);

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(8);
  const size = 9;
  const noElements = noRadiologist;
  const [clicks, setClicks] = useState(0);
  const [autoClickRate, setAutoClickRate] = useState(1); // clicks per second
  const [autoClickStarted, setAutoClickStarted] = useState(false);
  const [superAdminData, setSuperAdminData] = useState({});
  const [doctorspeData, setDoctorspeData] = useState({});

  const [loading, setLoading] = useState(true);

  // New state to control the usage of the unload confirmation hook
  const [shouldConfirmUnload, setShouldConfirmUnload] = useState(true);

 

  const fetchdoctorspec = async () => {
    try {
      const requestPayload = {
        "RequestId": uuidv4(),
        "Requestor": "Username",
        "Messages": {
          "MessageId": uuidv4(),
          "MessageType": doctorSpecialization.split('/').pop(),
          "Timestamp": new Date().toISOString(),
          "Body": {

          },
          "Parameters": {
            "UserId": currentUserID,
            "UserAccessToken": "",
            "APIVersion": ""
          },
          "Headers": {}
        }
      }
      const res4 = await axios.post(doctorSpecialization, requestPayload);
      setDoctorspeData(res4.data.Responses[0].Body.data);
    } catch (error) {
      console.error("Error", error);
    } finally {
    }
  };
  const reloadTableData = () => {
    console.log("Reloading data...",doctorspeData);
    fetchdoctorspec();
    fetchSuperAdminData()

  };
  const fetchSuperAdminData = async () => {
    try {
      setLoading(true);
      const requestPayload = {
        "RequestId": uuidv4(),
        "Requestor": "Username",
        "Messages": {
          "MessageId": uuidv4(),
          "MessageType": superAdminDashboard.split('/').pop(),
          "Timestamp": new Date().toISOString(),
          "Body": {

          },
          "Parameters": {
            "UserId": currentUserID,
            "UserAccessToken": "",
            "APIVersion": ""
          },
          "Headers": {}
        }
      }
      const res = await axios.post(superAdminDashboard, requestPayload);
      setSuperAdminData(res.data.Responses[0].Body.data);
    } catch (error) {
      console.error("Error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
   
    fetchdoctorspec()
    fetchSuperAdminData();
    if (!autoClickStarted) {
      setAutoClickStarted(true);
      setClicks(1); console.log("wddddweeds")

    }
  }, [autoClickStarted]);

  const handleAutoClickRateChange = (e) => {
    const rate = parseInt(e.target.value);
    if (!isNaN(rate) && rate >= 0) {
      setAutoClickRate(rate);
    }
  };
  // Use the unload confirmation hook conditionally




  return (
    <div className="h-full px-14 pb-5 overflow-x-hidden bg-gradient-to-r from-blue-100 via-purple-100 to-red-100">
      <Sidebar
        sidebarOpen={sidebarOpen}
        sidebarPage={sidebarPage}
        setSidebarOpen={setSidebarOpen}
        superAdminData={superAdminData}
        reloadTable1={reloadTableData}
        doctorspeData={doctorspeData}
      />
      <Header />
      <Tabs
        setSidebarOpen={setSidebarOpen}
        setSidebarPage={setSidebarPage}
        active={active}
        setActive={setActive}
        noHospital={noHospital}
        noRadiologist={noRadiologist}
        noTechnician={noTechnician}
        noReports={noReports}
        reloadTable={reloadTableData}
        doctorspeData={doctorspeData}
      />
      <div className="flex w-full">
        <Filters
          setFilterOption={setFilterOption}
          filterOption={filterOption}
          superAdminData={superAdminData}
        />
        <div className="w-full ml-5 flex flex-col justify-between ">
          <div>
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            {loading ? (
              <div className="flex justify-center h-full items-center">
                <Rings height="160" width="160" color="#8e75d4" />
              </div>
            ) : (
              <Cards
                active={active}
                searchQuery={searchQuery}
                setNoHospital={setNoHospital}
                setNoRadiologist={setNoRadiologist}
                setNoTechnician={setNoTechnician}
                setNoReports={setNoReports}
                filterOption={filterOption}
                start={start}
                end={end}
                superAdminData={superAdminData}
                reloadTable={reloadTableData}
              />
            )}
          </div>
          <Pagination
            start={start}
            setStart={setStart}
            end={end}
            setEnd={setEnd}
            size={size}
            noElements={noElements}
          />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
