import React, { createContext, useContext, useState } from "react";

const CheckContext = createContext();

export const CheckProvider = ({ children }) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (uid, name, id) => {
        const isSelected = selectedItems.some((item) => item.id.$oid === id.$oid);
        console.log("*", id);
        if (isSelected) {
            // If already selected, remove it from the array
            setSelectedItems((prevItems) =>
                prevItems.filter((item) => item.id.$oid !== id.$oid)
            );
        } else {
            // If not selected, add it to the array
            setSelectedItems((prevItems) => [...prevItems, { uid, name, id }]);
        }
    };

    return (
        <CheckContext.Provider value={{ selectedItems, handleCheckboxChange, setSelectedItems }}>
            {children}
        </CheckContext.Provider>
    );
};

export const useCheck = () => {
    const context = useContext(CheckContext);
    if (!context) {
        throw new Error("useSelection must be used within a SelectionProvider");
    }
    return context;
};
