import { useState } from 'react';
import React from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

const Pagination = ({ start, setStart, end, setEnd, size, noElements }) => {

  /*
    start - for start position of array of page 1
    end - for end position of array of page 1
    size - how many elements you want to render
    noElements - total length of the array


    page 1,
      start - 0
      end - 8
    page 2
      start - 9
      end - 11   
  */

  // const totalPages = Math.ceil(noElements / size);

  const handleNextClick = () => {
    setStart(prev=>prev+size);
    setEnd((prev)=>noElements< prev+size? noElements-1 : prev+size);
  };

  const handlePrevClick = () => {
             // 18                                             18-9
    setStart((prev)=> 0>= prev-size ?  0 : prev-size)
        // 11           11 - 9 = 0 - 2
        // 11           11 - (11-9) = 
    setEnd(prev=>prev - (prev-9) -1)
  };
  return (
    <div className='flex justify-center'>
      <div className='flex gap-x-10 w-fit'>
        <div className='flex text-sm justify-center items-center' onClick={handlePrevClick} style={{ cursor: start > 1 ? 'pointer' : 'not-allowed' }}>
          <BiChevronLeft className='text-lg' /> Prev
        </div>
        <div className='text-sm'>Page {Math.ceil(end / size)}</div>
        <div className='flex text-sm justify-center items-center' onClick={handleNextClick} style={{ cursor: end < noElements ? 'pointer' : 'not-allowed' }}>
          Next <BiChevronRight className='text-lg' />
        </div>
      </div>
    </div>
  );
}

export default Pagination;
