import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import ReactQuill from "react-quill";
import { PiCloudArrowDown } from "react-icons/pi";
import { FaPlusCircle, FaPlus, FaMinusCircle } from "react-icons/fa";
import { RxCross2 } from 'react-icons/rx';
import { v4 as uuidv4 } from 'uuid';
import './CustomDatePicker.css';

const Addnewreport = ({
  btnid,
  setFormData1,
  formData1,
  activePreviewId,
  setSidebarOpen,
  reloadTable,
  apiData,
  setShowConfirmationModal,
  showConfirmationModal,
  selectedTitle,
  setActivePreviewId
}) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal1, setShowConfirmationModal1] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (selectedTitle && btnid !== undefined) {
      setFormData1(prevState => ({
        ...prevState,
        section3: prevState.section3.map((section, i) =>
          i === btnid ? { ...section, Title: selectedTitle } : section
        )
      }));
    }
  }, [selectedTitle, btnid]);
  

  const addSection = (type) => {
    setFormData1(prevState => ({
      ...prevState,
      section3: prevState.section3.map((section, i) =>
        i === btnid
          ? {
              ...section,
              sections: [
                ...(section.sections || []),
                { id: Date.now(), type, title: '', content: '' }
              ]
            }
          : section
      )
    }));
  };
  

  const handleSectionChange = (id, field, value) => {
    setFormData1(prevState => ({
      ...prevState,
      section3: prevState.section3.map((section, index) =>
        index === btnid
          ? {
              ...section,
              sections: section.sections.map(sec =>
                sec.id === id
                  ? { ...sec, [field]: value }
                  : sec
              )
            }
          : section
      )
    }));
  };
  
  const removeSection = (id) => {
    setFormData1(prevState => ({
      ...prevState,
      section3: prevState.section3.map((section, index) =>
        index === btnid
          ? {
              ...section,
              sections: section.sections.filter(sec => sec.id !== id)
            }
          : section
      )
    }));
  };
  

  const handleQuillChange2 = (id, content) => {
    setFormData1(prevState => ({
      ...prevState,
      section3: prevState.section3.map((section, index) =>
        index === btnid
          ? {
              ...section,
              sections: section.sections.map(sec =>
                sec.id === id
                  ? { ...sec, content }
                  : sec
              )
            }
          : section
      )
    }));
  };
  
  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setFormData1(prevState => ({
      ...prevState,
      section3: prevState.section3.map((section, i) =>
        i === btnid ? { ...section, Title: newTitle } : section
      )
    }));
  };
  
  
  const handleDescriptionChange = (newDescription) => {
    setFormData1(prevState => ({
      ...prevState,
      section3: prevState.section3.map((section, i) =>
        i === btnid ? { ...section, Descriptions: newDescription } : section
      )
    }));
  };
  
  
  

  const fileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve({ base64: reader.result, name: file.name });
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

  const validFileTypes = ['image/png', 'image/jpeg'];

  const isValidFileType = (file) => validFileTypes.includes(file.type);

  const convertImagesToBase64 = async (files) => {
    const base64Promises = files.map(fileToBase64);
    return Promise.all(base64Promises);
  };

  const validateAndSetFiles = async (files) => {
    const validFiles = files.filter(isValidFileType);
    const invalidFiles = files.filter(file => !isValidFileType(file));

    if (invalidFiles.length > 0) {
      setError('Please upload only PNG or JPEG images.');
    } else {
      setError(null);
    }

    // Convert valid files to base64
    const base64Files = await convertImagesToBase64(validFiles);

    setFormData1(prevState => ({
      ...prevState,
      section3: prevState.section3.map((section, index) =>
        index === btnid
          ? {
              ...section,
              Snapshots: [
                ...(section.Snapshots || []),
                ...base64Files,
              ]
            }
          : section
      )
    }));
  };

  const handleFileInputChange = async (e) => {
    const files = Array.from(e.target.files);
    await validateAndSetFiles(files);
  };
  const handleRemoveFile = (index) => {
    setFormData1(prevState => ({
      ...prevState,
      section3: prevState.section3.map((section, i) =>
        i === btnid
          ? {
              ...section,
              Snapshots: section.Snapshots.filter((_, idx) => idx !== index)
            }
          : section
      )
    }));
  };

  // Function to handle file drop events
  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files);
    
    try {
      await validateAndSetFiles(files);
    } catch (err) {
      setError('Failed to process files');
      console.error(err);
    }
  };

  // Function to handle drag over events
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  // Function to handle drag leave events
  const handleDragLeave = () => {
    setIsDragging(false);
  };


  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );

  return (
    <div className="flex flex-col ms-4 my-4 overflow-y-auto pb-32 h-full scroller">
      <div className="absolute right-3 mt-5 me-5">  </div>

      <div className="w-full mt-3">
        <h3 className="text-[16px] font-semibold">Heading:</h3>
        <input
  className="bg-[rgb(0 0 0 / 6%)] border border-green-500 p-2 rounded-md w-auto bg-white"
  type="text"
  value={formData1.section3[btnid]?.Title || ''} // Access section3 with btnid index
  onChange={handleTitleChange}
  name="Title"
/>

      </div>

      <div className="space-y-2 mt-4">
        <h3 className="text-[16px] font-semibold">Description:</h3>
        {formData1.section3.map((section, index) => (
        <div key={index}>
          {/* Render ReactQuill for the section identified by btnid */}
          {index === btnid && (
            <ReactQuill
              theme="snow"
              value={section.Descriptions}
              onChange={handleDescriptionChange}
              style={{ backgroundColor: "#ffffff" }}
            />
          )}
        </div>
      ))}
      </div>

      {formData1.section3[btnid]?.sections?.length > 0 && (
  <div>
    {formData1.section3[btnid].sections.map((section, index) => (
      <div key={section.id} className="space-y-2">
        <div className="flex items-center">
          <input
            type="text"
            value={section.title}
            onChange={(e) =>
              handleSectionChange(
                section.id,
                'title',
                e.target.value
              )
            }
            className="text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl px-3 py-1 no-scrollbar outline-none"
            placeholder="Section Heading"
          />
          <button
            onClick={() => removeSection(section.id)}
            className="text-[#ec767e] p-2"
          >
            <FaMinusCircle size={22} />
          </button>
        </div>
        <ReactQuill
          theme="snow"
          value={section.content}
          onChange={(content) =>
            handleQuillChange2(section.id, content)
          }
          placeholder="Section Content"
          style={{ backgroundColor: "#ffffff" }}
        />
      </div>
    ))}
  </div>
)}


<div className="flex justify-end gap-x-3 mt-3">
  <button
    onClick={() => addSection('Description')}
    className="flex items-center gap-x-1 bg-[#36AE78] text-white px-4 py-1 rounded-md"
  >
    <FaPlus size={12} /> ADD
  </button>
</div>


<div className="space-y-2 mt-4">
      <h3 className="text-[16px] font-semibold">Report Snapshots:</h3>
      <div
        className={`w-[200px] h-[80px] border-2 ${isDragging ? "border-[#36AE78]" : "border-slate-400"} rounded-lg p-2 border-dashed flex flex-col justify-center items-center`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnter={() => setIsDragging(true)}
      >
        <input
          name="Report"
          type="file"
          className="hidden"
          id="file-input"
          multiple
          accept=".png, .jpg, .jpeg"
          onChange={handleFileInputChange}
        />
        <PiCloudArrowDown
          size={30}
          className={`text-5xl font-normal ${isDragging ? "text-[#36AE78]" : "text-slate-400"}`}
        />
        <span className="text-slate-400 text-[15px] text-center">
          Drag & Drop files here <br /> or{" "}
        </span>{" "}
        <label
          className="text-blue-600 text-[15px] hover:underline cursor-pointer"
          htmlFor="file-input"
        >
          Browse
        </label>
        {error && (
          <div className="text-red-500 text-xs mt-2">
            {error}
          </div>
        )}
      </div>

      <div className="w-full">
        <ul className="w-full py-[3px] flex gap-x-3 px-2 overflow-x-scroll no-scrollbar">
          {formData1.section3[btnid]?.Snapshots?.length > 0 && (
            <ul>
              {formData1.section3[btnid].Snapshots.map((snapshot, index) => (
                <li
                  key={index}
                  className="border border-[#36AE78] text-[#36AE78] bg-[#c1f6de] bg-opacity-15 rounded-full w-fit pl-2 pr-1 whitespace-nowrap flex items-center gap-x-1"
                >
                  {snapshot.name ? (
                    snapshot.name.length > 15
                      ? snapshot.name.substring(0, 14) + "..."
                      : snapshot.name
                  ) : (
                    "No name"
                  )}
                  <RxCross2
                    className="cursor-pointer ml-2"
                    onClick={() => handleRemoveFile(index)}
                  />
                </li>
              ))}
            </ul>
          )}
        </ul>
        <p className="text-left text-slate-500 text-sm pl-3 pt-2">
          {formData1.section3[btnid]?.Snapshots?.length > 0
            ? `Files: ${formData1.section3[btnid].Snapshots.length}`
            : ""}
        </p>
      </div>

      <div className="mt-5 flex justify-center gap-x-3">
        <button
          className={`bg-[#36AE78] flex items-center justify-center gap-x-2 text-white px-8 py-1 rounded-md w-fit ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
          onClick={() => {
            setActivePreviewId(true);
            setSidebarOpen(false);
            setShowConfirmationModal(false);
            console.log("Form Data:", formData1);
          }}
        >
          Preview
        </button>
      </div>
    </div>

    </div>
  );
};

export default Addnewreport;
