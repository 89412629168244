import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import Settings from "../../Utils/Settings1";
import { GoEye, GoEyeClosed } from "react-icons/go";

const { tokenValidator, setPassword } = Settings;

export default function ChangePasswordLink() {
  const { userId, resetToken } = useParams();
  const [loading, setLoading] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const [formData, setFormData] = useState({
    Password: "",
    Cpassword: "",
  });

  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
  const [showConfirmPasswordChecklist, setShowConfirmPasswordChecklist] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // State to track password conditions
  const [passwordConditions, setPasswordConditions] = useState({
    minLength: false,
    specialChar: false,
    number: false,
    capital: false,
    lowercase: false,
  });

  const generateUniqueId = () => {
    // Function to generate a unique identifier
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (Math.random() * 16)) >> (c / 4) & 15
    ).toString(16);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const payload = {
        "RequestId": generateUniqueId(),
        "Requestor": "Username",
        "Messages": {
          "MessageId": generateUniqueId(),
          "MessageType": tokenValidator.split('/').pop(),
          "Timestamp": new Date().toISOString(),
          "Body": {
            "data": {
              "UserId": userId
            }
          },
          "Parameters": {
            "UserId": "",
            "UserAccessToken": "",
            "APIVersion": ""
          },
          "Headers": {}
        }
      };

      try {
        const response = await axios.post(tokenValidator, payload);



        setIsValidToken(response.data.Responses[0].Body.data.data.reset_token === resetToken);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId, resetToken]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "Password") {
      setPasswordError(false);
      // Check password conditions
      const conditions = {
        minLength: value.length >= 8,
        specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value),
        number: /[0-9]+/.test(value),
        capital: /[A-Z]+/.test(value),
        lowercase: /[a-z]+/.test(value),
      };
      setPasswordConditions(conditions);
    }
  };

  const handlePasswordChange = async () => {
    setLoading(true);
    const payload = {
      "RequestId": generateUniqueId(),
      "Requestor": "Username",
      "Messages": {
        "MessageId": generateUniqueId(),
        "MessageType": tokenValidator.split('/').pop(),
        "Timestamp": new Date().toISOString(),
        "Body": {
          "data": {
            "UserId": userId,
            "userResetToken": resetToken,
            "newPassword": formData.Password,
            "reenterPassword": formData.Cpassword,
          }
        },
        "Parameters": {
          "UserId": "",
          "UserAccessToken": "",
          "APIVersion": ""
        },
        "Headers": {}
      }
    };

    try {
      if (formData.Password !== formData.Cpassword) {
        toast.error("Passwords do not match");
        setLoading(false);
        setPasswordError(true);
        return;
      }

      // Check if all password conditions are met
      const allConditionsMet = Object.values(passwordConditions).every(
        (condition) => condition === true
      );

      if (!allConditionsMet) {
        toast.error("Password does not meet all requirements");
        setLoading(false);
        setPasswordError(true);
        return;
      }

      const response = await axios.post(setPassword, payload);

      if (response.status === 200) {
        toast.success("Password successfully reset!");
        const loginPageUrl = "/";

        window.history.replaceState({}, document.title, loginPageUrl);

        setTimeout(() => {
          window.location.href = loginPageUrl;
        }, 3000);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "An error occurred");
    } finally {
      // Set loading to false regardless of success or failure
      setLoading(false);
    }
  };

  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );

  return (
    <div>
      {isValidToken ? (
        <div className="bg-gradient-to-r from-blue-100 via-purple-100 to-red-100 h-screen flex justify-center items-center">
          {/* Reset Password */}
          <div className="h-[604px] w-[682px] bg-[#FFFFFFB8] flex flex-col justify-center items-center gap-y-8">
            <h1 className="text-[35px] font-semibold align-middle">Reset Password</h1>
            <div>
              <div className="flex flex-col gap-y-3 mt-5">
                <label className="text-[14px] font-semibold" htmlFor="password">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="Password"
                    value={formData.Password}
                    onChange={handleInputChange}
                    className={`w-[400px] h-[40px] border rounded-xl p-2 ${passwordError ? "border-red-500" : "border-[#36AE78]"
                      }`}
                    placeholder="Enter new password"
                    onFocus={() => setShowPasswordChecklist(true)}
                    onBlur={() => setShowPasswordChecklist(false)}
                  />
                  {/* Password Checklist Tooltip */}
                  {showPasswordChecklist && (
                    <div className="absolute top-[46px] left-0 z-50 w-[400px] bg-white shadow-lg border border-gray-200 rounded p-4">
                      <ul className="list-disc pl-5 text-xs">
                        <li style={{ color: passwordConditions.minLength ? "#36AE78" : "#FF5B5B" }}>
                          Password must be at least 8 characters long
                        </li>
                        <li style={{ color: passwordConditions.specialChar ? "#36AE78" : "#FF5B5B" }}>
                          Password must contain a special character
                        </li>
                        <li style={{ color: passwordConditions.number ? "#36AE78" : "#FF5B5B" }}>
                          Password must contain a number
                        </li>
                        <li style={{ color: passwordConditions.capital ? "#36AE78" : "#FF5B5B" }}>
                          Password must contain a capital letter
                        </li>
                        <li style={{ color: passwordConditions.lowercase ? "#36AE78" : "#FF5B5B" }}>
                          Password must contain a lowercase letter
                        </li>
                      </ul>
                    </div>
                  )}
                  <button
                    type="button"
                    className="absolute top-1/2 right-2 transform -translate-y-1/2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <GoEyeClosed /> : <GoEye />}
                  </button>
                </div>
              </div>

              {/* Confirm Password */}
              <div className="flex flex-col gap-y-3 mt-6">
                <label className="text-[14px] font-semibold" htmlFor="cpassword">
                  Confirm Password
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="Cpassword"
                    value={formData.Cpassword}
                    onChange={handleInputChange}
                    className={`w-[400px] h-[40px] border rounded-xl p-2 ${passwordError ? "border-red-500" : "border-[#36AE78]"
                      }`}
                    placeholder="Confirm new password"
                    onFocus={() => setShowConfirmPasswordChecklist(true)}
                    onBlur={() => setShowConfirmPasswordChecklist(false)}
                  />

                  {/* Password Checklist Tooltip */}
                  {showConfirmPasswordChecklist && (
                    <div className="absolute top-[46px] left-0 z-50 w-[400px] bg-white shadow-lg border border-gray-200 rounded p-4">
                      <ul className="list-disc pl-5 text-xs">
                        <li style={{ color: passwordConditions.minLength ? "#36AE78" : "#FF5B5B" }}>
                          Password must be at least 8 characters long
                        </li>
                        <li style={{ color: passwordConditions.specialChar ? "#36AE78" : "#FF5B5B" }}>
                          Password must contain a special character
                        </li>
                        <li style={{ color: passwordConditions.number ? "#36AE78" : "#FF5B5B" }}>
                          Password must contain a number
                        </li>
                        <li style={{ color: passwordConditions.capital ? "#36AE78" : "#FF5B5B" }}>
                          Password must contain a capital letter
                        </li>
                        <li style={{ color: passwordConditions.lowercase ? "#36AE78" : "#FF5B5B" }}>
                          Password must contain a lowercase letter
                        </li>
                      </ul>
                    </div>
                  )}

                  <button
                    type="button"
                    className="absolute top-1/2 right-2 transform -translate-y-1/2"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <GoEyeClosed /> : <GoEye />}
                  </button>
                </div>
              </div>
              <button
                className="bg-[#000000e6] text-white p-2 mt-7 rounded-xl w-[400px]"
                onClick={handlePasswordChange}
                disabled={loading}
              >
                {loading ? loader : "Reset"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <div className="text-center">404 Page not found</div>
        </div>
      )}
    </div>
  );
}
