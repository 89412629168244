import React, { useState, useEffect } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { MdOutlineFileUpload } from "react-icons/md";
import Pagination from "./Pagination"; 

const CardReport = ({ filterOption, superAdminData }) => {
  const parsedData = superAdminData.REPORT_patients;
  const dummyData = "\"{'\\\"[]\\\"'}\""
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filtered = filterOption?.length > 0 ?
   parsedData?.filter(
      (item) =>
        filterOption?.includes(item.AI_Report_Status) ||
        filterOption?.includes(item.Report_Status)
    ) :parsedData;

    setFilteredData(filtered);
  }, [filterOption]);

  return (
    <>
      <div className=" w-[93%] overflow-hidden rounded-xl overflow-x-auto">
        <table className="mx-auto w-full table-auto">
          <thead className=" bg-[#FFFFFF8A] shadow-lg text-[#626262] text-sm">
            <th className="px-4 "></th>
            <th className="py-3 px-6 text-left ">AI</th> 
            <th className="py-3 px-7 text-left">UID</th>
            <th className="py-3 px-7 text-left">Name</th>
            <th className="py-3 px-6 text-left">Age</th>
            <th className="py-3 px-4 text-left">Sex</th>
            <th className="py-3 px-6 text-left">Date</th>
            <th className="py-3 px-4 text-left">Body Part</th>
            <th className="py-3 px-4 text-left">Modality</th>
            <th className="py-3 px-4 text-left">Past Reports</th>
            <th className="py-3 px-4 text-left">Action</th>
            <th className="py-3 px-4 text-left">Report Status</th>
            <th className="py-3 px-2 text-left">Final Report</th>
          </thead>

          <tbody>
            {filteredData && filteredData?.slice(0, 8)?.map((item) => {
              return (
                <tr className="bg-[#FFFFFF8A] text-sm">
                  <td className="text-center px-5">
                    <input type="checkbox" name="check" id="check" />
                  </td>
                  <td className="py-4 px-2 flex items-center gap-x-2">
                    {item.AI_Report_Status == "Abnormal" ? (
                      <div className="h-[24px] w-[24px] flex items-center justify-center rounded-full bg-[#E475A0]">
                        A
                      </div>
                    ) : (
                      <div className="h-[24px] w-[24px] flex items-center justify-center rounded-full bg-[#36AE78]">
                        N
                      </div>
                    )}
                    <button className="bg-[#FFFFFF8A] text-[#36AE78] border border-green-500 w-[24px] h-[24px] rounded-lg flex items-center justify-center">
                      <MdOutlineRemoveRedEye size={18} />
                    </button>
                  </td>

                  <td className="py-4 px-5">{item.UID_backup}</td>
                  <td className="py-4 px-4">{item.Name}</td>
                  <td className="py-4 px-6">{item.Age}</td>
                  <td className="py-4 px-6">{item.Sex}</td>
                  <td className="py-4 px-4">{item.Study_date}</td>
                  <td className="py-4 px-10">{item.Body_part}</td>
                  <td className="py-4 px-7">{item.Modality}</td>
                  <td className="py-4 px-9">
                    <button className="w-[56px] h-[24px] rounded-lg text-[#36AE78] bg-[#FFFFFFB8] border border-green-500 flex items-center justify-center">
                      <MdOutlineFileUpload size={22} />
                    </button>
                  </td>
                  <td className="py-4 px-7">
                    <button className="bg-[#FFFFFF8A] text-[#36AE78] border border-green-500 w-[24px] h-[24px] rounded-lg flex items-center justify-center">
                      <FaPen />
                    </button>
                  </td>
                  <td className="py-4 px-7">
                    {item.Report_Status == "Done" ? (
                      <button className="w-[86px] h-[24px] bg-[#6EC59D] rounded-[12px] text-white text-xs">
                        Approved
                      </button>
                    ) : (
                      <button className="w-[86px] h-[24px] bg-[#E475A0] rounded-[12px] text-white text-xs">
                        Pending
                      </button>
                    )}
                  </td>
                  <td className="py-4 px-7">
                    <div className="flex gap-x-2">
                      <button className="bg-[#FFFFFF8A] text-[#36AE78] border border-green-500 w-[24px] h-[24px] rounded-lg flex items-center justify-center">
                        <MdOutlineFileDownload size={18} />
                      </button>
                      <button className="bg-[#FFFFFF8A] text-[#36AE78] border border-green-500 w-[24px] h-[24px] rounded-lg flex items-center justify-center">
                        <MdOutlineRemoveRedEye size={18} />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default CardReport;
