import React, { useState, useEffect } from "react";
import InputDropdown from "./InputDropdown";
import axios from "axios";
import link from "../../../Utils/Settings";
import { currentUserID } from "../../../Utils/USER";
import { toast } from "react-hot-toast";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { v4 as uuidv4 } from 'uuid';


const Technician = ({ setSidebarOpen ,reloadTable}) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const reload = () => {
    console.log("meow")
    reloadTable()
 
     };
  const [formData, setFormData] = useState({
    Name: "",
    Adhaar_id: "",
    Contact_Number: "",
    Email: "",
    Password: "",
  });

  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
  const [passwordConditions, setPasswordConditions] = useState({
    minLength: false,
    specialChar: false,
    number: false,
    capital: false,
    lowercase: false,
  });

  // state variables for errors in fields
  const [formErrors, setFormErrors] = useState({
    Name: "",
    Email: "",
    Adhaar_id: "",
    Contact_Number: "",
    Password: "",
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  function handleInputChange(event) {
    const { name, value } = event.target;
    let error = "";
    setFormData({ ...formData, [name]: value });

    if (name === "Password") {
      // Update password conditions
      const conditions = {
        minLength: value.length >= 8,
        specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value),
        number: /[0-9]+/.test(value),
        capital: /[A-Z]+/.test(value),
        lowercase: /[a-z]+/.test(value),
      };
      setPasswordConditions(conditions);
    }

    switch (name) {
      case "Email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        error = !value.match(emailRegex) ? "Invalid email address" : "";
        break;

      case "Adhaar_id":
        error = !/^\d{12}$/.test(value) ? "Invalid Aadhaar ID" : "";
        break;

      case "Contact_Number":
        const phoneRegex = /^[0-9]{10}$/; // Assuming a 10-digit phone number
        error = !value.match(phoneRegex) ? "Invalid phone number" : "";
        break;

      default:
        break;
    }

    setFormErrors({ ...formErrors, [name]: error });
    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = async () => {

    const allConditionsMet = Object.values(passwordConditions).every(
      (condition) => condition === true
    );

    if (!allConditionsMet) {
      // Display error message if any password condition is not met
      toast.error("Password does not meet all requirements");
      return;
    }

    if (checkFields()) {
      setShowConfirmationModal(true);
    } else {
      const firstErrorInput = document.querySelector(".border-red-500");
      if (firstErrorInput) {
        firstErrorInput.focus();
      }

      toast.error("Please fix the errors in the form");
    }
  };

  function checkFields() {
    const { Name, Email, Password, Contact_Number, Adhaar_id } = formData;
    const obj = {
      Name,
      Email,
      Password,
      Adhaar_id,
      Contact_Number,
    };
    const errors = {};
    Object.keys(obj).forEach((key) => {
      switch (key) {
        case "Employment_Type":
          errors[key] = obj[key] ? "" : "Please select a Employment type";
          break;
        case "Email":
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          errors[key] = !obj[key].match(emailRegex)
            ? "Invalid email address"
            : "";
          break;
        // Add other cases if needed
        default:
          errors[key] = obj[key] ? "" : "This field is required";
          break;
      }
    });

    setFormErrors(errors);
    return Object.values(obj).every((e) => e.length > 0);
  }

  function getCurrentTimestamp() {
    const now = new Date();
    const offset = 5.5 * 60 * 60 * 1000; // IST offset from UTC in milliseconds
    const istTime = new Date(now.getTime() + offset);
    return istTime.toISOString(); 
  }

  const handleConfirmation = async () => {
    setShowConfirmationModal(false);
    try {
      setLoading(true);

      const payload = {
        RequestId: uuidv4(),
        // Requestor: "Username",
        Messages: {
          MessageId: uuidv4(),
          MessageType: "hospitalTechnicianForm",
          Requesttimestamp: getCurrentTimestamp(),
          Body: {
            data: {
              Name: formData.Name,
              Password: formData.Password,
              Email: formData.Email,
              Adhaar_id: formData.Adhaar_id,
              Contact_Number: formData.Contact_Number,
            },
          },
          Parameters: {
            UserId: currentUserID,
            UserAccessToken: "",
            APIVersion: "",
          },
          Headers: {},
        },
      };

      const res = await axios.post(
        `${link}/Scanalyst/hospitalTechnicianForm`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res);

      if (res.status === 200) {
        toast.success("Submitted");
        console.log("Form submitted successfully");
        setTimeout(reload, 1000);
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Error submitting form");
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
      setSidebarOpen(false);
    }
  };

  const loader = (
      <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
    );
    useEffect(() => {
      console.log(formData);
    }, [formData]);

  return (
    <div className=" flex flex-col mx-4 my-4 overflow-y-scroll pb-[130px] h-full no-scrollbar">
      <h1 className="text-2xl text-center mt-6 mb-6 font-bold p-4">
        Add Technician
      </h1>
      <div className="flex flex-col  gap-y-6">
        <div className="flex flex-col  gap-y-2">
          <label htmlFor="Name">Name</label>
          <input
            className={`px-2 py-2 border rounded-md focus:outline-none ${
              formErrors.Name && "border-red-500"
            }`}
            type="text"
            name="Name"
            id="name"
            value={formData.Name}
            onChange={handleInputChange}
          />
          {formErrors.Name && (
            <span className="text-red-500 text-sm">{formErrors.Name}</span>
          )}
        </div>
        <div className="flex flex-col  gap-y-2">
          <label htmlFor="Adhaar_id">Aadhar ID</label>
          <input
            className={`px-2 py-2 border rounded-md focus:outline-none ${
              formErrors.Adhaar_id && "border-red-500"
            }`}
            type="text"
            name="Adhaar_id"
            id="aadhar"
            value={formData.Adhaar_id}
            onChange={handleInputChange}
          />
          {formErrors.Adhaar_id && (
            <span className="text-red-500 text-sm">{formErrors.Adhaar_id}</span>
          )}
        </div>
        <div className="flex flex-col  gap-y-2">
          <label htmlFor="Contact_Number">Phone</label>
          <input
            className={`px-2 py-2 border rounded-md focus:outline-none ${
              formErrors.Contact_Number && "border-red-500"
            }`}
            type="tel"
            name="Contact_Number"
            id="phone"
            value={formData.Contact_Number}
            onChange={handleInputChange}
          />
          {formErrors.Contact_Number && (
            <span className="text-red-500 text-sm">
              {formErrors.Contact_Number}
            </span>
          )}
        </div>
        <div className="flex flex-col  gap-y-2">
          <label htmlFor="Email">Email</label>
          <input
            className={`px-2 py-2 border rounded-md focus:outline-none ${
              formErrors.Email && "border-red-500"
            }`}
            type="text"
            name="Email"
            id="email"
            value={formData.Email}
            onChange={handleInputChange}
          />
          {formErrors.Email && (
            <span className="text-red-500 text-sm">{formErrors.Email}</span>
          )}
        </div>
        <div className="flex flex-col  gap-y-2 relative">
          <label htmlFor="Password">Password</label>
          <input
            className={`px-2 py-2 border rounded-md focus:outline-none ${
              formErrors.Password && "border-red-500"
            }`}
            type={showPassword ? "text" : "password"}
            name="Password"
            id="pass"
            value={formData.Password}
            onChange={handleInputChange}
            onFocus={() => setShowPasswordChecklist(true)} 
            onBlur={() => setShowPasswordChecklist(false)}
          />
          {formErrors.Password && (
            <span className="text-red-500 text-sm">{formErrors.Password}</span>
          )}
          {showPasswordChecklist && (
            <div className="absolute top-[75px] left-0 z-50 w-[352px] bg-white shadow-lg border border-gray-200 rounded p-4">
              <ul className="list-disc pl-0.1 text-sm text-gray-800">
                <ul className="list-disc pl-5 text-xs">
                  <li style={{ color: passwordConditions.minLength ? "#36AE78" : "#FF5B5B" }}>
                    Password must be at least 8 characters long
                  </li>
                  <li style={{ color: passwordConditions.specialChar ? "#36AE78" : "#FF5B5B" }}>
                    Password must contain a special character
                  </li>
                  <li style={{ color: passwordConditions.number ? "#36AE78" : "#FF5B5B" }}>
                    Password must contain a number
                  </li>
                  <li style={{ color: passwordConditions.capital ? "#36AE78" : "#FF5B5B" }}>
                    Password must contain a capital letter
                  </li>
                  <li style={{ color: passwordConditions.lowercase ? "#36AE78" : "#FF5B5B" }}>
                    Password must contain a lowercase letter
                  </li>
                </ul>
              </ul>
            </div>
          )}
          <button
                    type="button"
                    className="absolute top-[50px] right-1 transform -translate-y-1/2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <GoEyeClosed /> : <GoEye />}
                  </button>
        </div>
      </div>
      <button
        className="text-sm w-full font-medium rounded-lg text-white mt-8 px-6 py-2 bg-[#4A4A4A] active:scale-95 active:transform transition-transform duration-300 focus:outline-none"
        onClick={handleSubmit}
      >
        {loading ? loader : "Sign up"}
      </button>
      
    {/* Confirmation Modal */}
    {showConfirmationModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <div className="bg-[#ededed] p-8 w-[640px] h-[14rem] rounded-3xl items-center m-[20px]">
            <p className="text-xl font-semibold mb-1 text-center">Are you sure you want to </p>
            <p className="text-2xl font-semibold mb-4 text-center">Submit the form?</p>
            <div className="flex justify-center">
              <button
                className="rounded-lg px-4 py-2 mr-7 w-[180px] mt-[20px] border border-red-500 bg-white text-red-500"
                onClick={() => setShowConfirmationModal(false)}
              >
                No
              </button>
              <button
                className="text-white bg-[#36AE78] rounded-lg px-4 py-2 w-[180px] mt-[20px]"
                onClick={handleConfirmation}
              >
                {loading ? loader : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Technician;
