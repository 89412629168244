import React from "react";
import { IoSearchOutline } from "react-icons/io5";

export default function SearchBar({ searchQuery, setSearchQuery }) {
  return (
    <div className="mt-6 ">
      <div className="flex items-center shadow w-[93%] h-[64px] rounded-[20px] bg-[#FFFFFF8A]">
        <div className="relative ml-4 flex justify-start items-center">
          <IoSearchOutline className="absolute text-2xl ml-3 text-[#7B7B7B]" />
          <input
            value={searchQuery}
            onChange={(e)=>setSearchQuery(e.target.value)}
            className="shadow pl-12 font-[500px] w-[400px] h-[36px] text-[12px] text-[#7B7B7B] rounded-[20px] focus:outline-none px-4 py-2"
            type="text"
            placeholder="Search for hospitals..."
          />
        </div>
      </div>
    </div>
  );
}
