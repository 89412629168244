import React, { useState, useRef, useEffect } from 'react';
import { FaPen } from 'react-icons/fa';

const ReportDropdownButton = ({
  data,
  setShowModal,
  setShowModalh,
  setConfirmationModalData,
  formData,
  setFormData,
  record,
  handleModelView,
  active,
  setShowModal2
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [aiReportCount, setAiReportCount] = useState(null);
  const [aicoldCount, setAicoldCount] = useState(null);
  const [aifollowCount, setAifollowCount] = useState(null);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (record && Array.isArray(record.ColdReports)) {
      setAicoldCount(record.ColdReports.length);

    }
    if (record && Array.isArray(record.FollowUpReports)) {
      setAifollowCount(record.FollowUpReports.length)

    }

    if (record && Array.isArray(record.CT_AI_Report)) {
      setAiReportCount(record.CT_AI_Report.length);
    
    }
  }, [record]);

  const handleOptionClick = (modality) => {
    handleModelView(modality, record);

    setSelectedOption(modality);
    setShowModal(true);
    setShowModal2(true);
    setShowModalh(true);

    setConfirmationModalData({ record, modality });
    setFormData({
      ...formData,
      Patient_uid: record._id.$oid,
    });
    setIsOpen(false);
  };

  const options = ['AI Report', 'Cold Report', 'Write Report', 'FollowUp Report','Analysis'];
  const filteredOptions = active === 2 ? options.filter(option => option !== 'Write Report') : options;

  const getDropdownPosition = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownWidth = 150; // Width of the dropdown menu
      const dropdownHeight = filteredOptions.length * 36 + 16; // Height of the dropdown menu

      const rightSpace = window.innerWidth - buttonRect.right;
      const bottomSpace = window.innerHeight - buttonRect.bottom;

      return {
        top: bottomSpace < dropdownHeight ? -dropdownHeight : buttonRect.height, // Adjust top position if not enough space at the bottom
        left: rightSpace < dropdownWidth ? -dropdownWidth : 0 // Adjust left position if not enough space on the right
      };
    }
    return { top: 0, left: 0 }; // Default positioning
  };

  const position = getDropdownPosition();

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className={`bg-[#ffffffe8] text-[#36AE78] border border-green-500 w-6 h-6 rounded-lg flex items-center justify-center ${isOpen ? 'border-blue-500' : ''}`}
      >
        <FaPen size={12} />
      </button>

      {isOpen && (
        <div
          className="fixed mt-2 w-[150px] bg-[#ffffffe8] border border-green-500 rounded-lg shadow-lg z-[10000]"
          style={{
            top: `calc(${buttonRef.current.getBoundingClientRect().top}px + ${position.top}px)`,
            left: `calc(${buttonRef.current.getBoundingClientRect().right}px + ${position.left}px)`
          }}
        >
          {filteredOptions.map((modality) => (
            <button
              key={modality}
              onClick={() => handleOptionClick(modality)}
              className={`w-full px-2 py-1 text-left text-[#36AE78] hover:bg-[#F1F1F1] ${selectedOption === modality ? 'bg-blue-100' : ''}`}
            >
              {modality}
              {modality === 'AI Report' && aiReportCount != null && ` (${aiReportCount})`}
              {modality === 'Cold Report' && aicoldCount != null && ` (${aicoldCount})`}
              {modality === 'FollowUp Report' && aifollowCount != null && ` (${aifollowCount})`}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReportDropdownButton;
