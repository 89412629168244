import React, { useState, useRef, useEffect } from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

const DropdownButton = ({ handleView, record, patientScanIds }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subDropdown, setSubDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSubDropdown(null); // Reset sub-dropdown when opening the main dropdown
    }
  };

  const handleOptionClick = (modality) => {
    setSubDropdown(subDropdown === modality ? null : modality); // Toggle sub-dropdown
  };

  const handleSubOptionClick = (modality, id) => {
    const idPart = id.split('_')[1]; // Get the part after the underscore

    if (['MRI', 'CT', 'US', 'DX'].includes(modality) && idPart) {
      handleView(record, modality, id); // Send modality and extracted ID part
    } else {
      console.error("Invalid modality or ID");
    }
    setIsOpen(false); // Close dropdown after selection
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if click is outside of it
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const patientName = record.name;

  // Calculate dropdown position
  const getDropdownPosition = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownWidth = 80; // Width of the dropdown menu
      const dropdownHeight = 36 * 4 + 16; // Height of the dropdown menu (4 options * 36px each + padding)

      const rightSpace = window.innerWidth - buttonRect.right;
      const bottomSpace = window.innerHeight - buttonRect.bottom;

      return {
        top: bottomSpace < dropdownHeight ? -dropdownHeight : buttonRect.height + 8,
        left: rightSpace < dropdownWidth ? -dropdownWidth : 0
      };
    }
    return { top: 0, left: 0 }; // Default positioning
  };

  const position = getDropdownPosition();

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className={`bg-[#ffffffe8] text-[#36AE78] border border-green-500 w-6 h-6 rounded-lg flex items-center justify-center ${isOpen ? 'border-blue-500' : ''}`}
      >
        <MdOutlineRemoveRedEye size={18} />
      </button>

      {isOpen && (
        <div
          className="fixed mt-2 w-[80px] bg-[#ffffffe8] border border-green-500 rounded-lg shadow-lg z-[10000]"
          style={{
            top: `calc(${buttonRef.current.getBoundingClientRect().top}px + ${position.top}px)`,
            left: `calc(${buttonRef.current.getBoundingClientRect().left}px + ${position.left}px)`
          }}
        >
          {['MRI', 'CT', 'US', 'DX'].map((modality) => (
            <div key={modality} className="relative group">
              <button
                onClick={() => handleOptionClick(modality)}
                className={`w-full px-2 py-1 text-left text-[#36AE78] hover:bg-[#F1F1F1] flex items-center justify-center ${subDropdown === modality ? 'bg-blue-100' : ''}`}
              >
                {modality} {patientScanIds[patientName]?.[modality]?.length > 0 ? `(${(patientScanIds[patientName][modality] || []).length})` : "(0)"}
              </button>
              {subDropdown === modality && patientScanIds[patientName]?.[modality] && (
                <div className="absolute left-[100%] top-0 mt-2 w-[80px] max-h-60 overflow-y-auto bg-[#ffffffe8] border border-green-500 rounded-lg shadow-lg z-100">
                  {patientScanIds[patientName][modality]?.map((id) => (
                    <button
                      key={id}
                      onClick={() => handleSubOptionClick(modality, id)}
                      className="w-full px-2 py-1 text-left text-[#36AE78] hover:bg-[#F1F1F1] flex items-center justify-center"
                    >
                      {id.split('_')[1] || "No ID part"}
                    </button>
                  )) || (
                    <div className="w-full px-2 py-1 text-left text-[#36AE78] flex items-center justify-center">
                      No IDs
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
