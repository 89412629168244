import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { PiCloudArrowDown } from "react-icons/pi";
import ColdReport from "./ColdReport";
import CTReportPagination from "./CT_Reportpages";
import DXReportPagination from "./DX_Reportpages";
import logo1 from "../../assets/logo.png";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import FollowUps from "./Followupsec";
import InReportDropdownButton from "./insidedropdown";
import ReactQuill from "react-quill";
import { TbPencilExclamation } from "react-icons/tb";
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { formatInTimeZone } from 'date-fns-tz';

import { FaPlus } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import { currentUserID, currentUserRights } from "../../Utils/USER";
import { Rings } from "react-loader-spinner";
import { toast } from "react-hot-toast";
import axios from "axios";
import Settings from "../../Utils/Settings1";
import FollowReport from "./FollowReport";
import Plot from 'react-plotly.js'; // or wherever your Plot component is coming from
import HealthForecastChart from "./Charts/HealthChart";
import WeightForecastChart from "./Charts/WeigthChart";
import TempForecastChart from "./Charts/TempChart";
import BPForecastChart from "./Charts/BPChart";
import Spo2ForecastChart from "./Charts/Spo2Chart";
import { RxCross1 } from "react-icons/rx";
import Addnewreport from "./Addnewreport";

const { link, consultedRadiologistDashboard, consultingRadiologistReport, radiologistReport ,download,analysislink} = Settings;

const Aireportbox = ({setShowModalh,showModalh,showModal2,setShowModal2,  radiologistData,  reloadTable, recordData,    active,setModelview, showModal, data, onConfirm, onCancel, setShowModal, formData, setConfirmationModalData, setFormData, modelview }) => {
    const [next, setNext] = useState(false);
    const [sections, setSections] = useState(null);
    const [activePreviewId, setActivePreviewId] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [btnid, setbtnid] = useState(0);

    const [error, setError] = useState('');
    const fileToURL = (file) => {
      if (file instanceof Blob || file instanceof File) {
        return URL.createObjectURL(file);
      }
      console.error('Invalid type for createObjectURL. Expected Blob or File.');
      return '';
    };
        const [AnalysisData, setAnalysisData] = useState({});
    const [wshowModal, setwShowModal] = useState(false);
    const [tshowModal, settShowModal] = useState(false);
    const [bpshowModal, setbpShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [hshowModal, sethShowModal] = useState(true);
    const [spo2showModal, setspo2ShowModal] = useState(false);
    const [buttons, setButtons] = useState([{ id: 1, title: 'Observation Report' }]);
    const [editing, setEditing] = useState(null);
    const [newTitle, setNewTitle] = useState('');
  
    // Add a new button with an empty title
    const addSection1 = () => {
      // Add a new button
      setButtons(prevButtons => [...prevButtons, { id: prevButtons.length + 1, title: '' }]);
      
      // Add a new section to section3 in formData1
      setFormData1(prevFormData => ({
        ...prevFormData,
        section3: [
          ...prevFormData.section3, // Spread existing sections
          { Title: '', Descriptions: '', sections: [], Snapshots: [] } // Add new section
        ]
      }));
    };
    
    
  
    // Handle title change
    const handleTitleChange1 = (id, title) => {
      setButtons(buttons.map(button =>
        button.id === id ? { ...button, title } : button
      ));
    };
  
    // Toggle editing mode
    const handleEditClick1 = (id) => {
      setEditing(id);
      const buttonToEdit = buttons.find(button => button.id === id);
      if (buttonToEdit) {
        setNewTitle(buttonToEdit.title);
      }
    };
  
    // Save edited title
    const saveTitle = (id) => {
      handleTitleChange1(id, newTitle);
      setEditing(null);
    };
    const generateUniqueId = () => {
      // Function to generate a unique identifier
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (Math.random() * 16)) >> (c / 4) & 15
      ).toString(16);
    }
 const [base, setbase] = useState("");
  const [logo, setlogo] = useState("");
  const [pdf, setpdf] = useState("");
  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );
  async function FetchAnalysis() {
    try {
      const apayload=
      {
        "RequestId": generateUniqueId(),
        "Messages": {
            "MessageId": generateUniqueId(),
            "MessageType": analysislink.split('/').pop(),
            "Requesttimestamp":new Date().toISOString(),
            "Body": {
                "patientID": record.id.$oid,
     
            },
            "Parameters": {
                "UserId": currentUserID,
                "UserAccessToken": "",
                "APIVersion": ""
            },
            "Headers": {}
        }
    }
   
 
      
      const res = await axios.post(analysislink,apayload);
      

      const responseData = res.data;
      setAnalysisData(res.data.Responses[0].Body.data)
   


      console.log("Body.data",AnalysisData)

       } catch (error) {
      console.error("Error", error);
    } finally {
    }
  }
    const [data1, setdata1] = useState([]);
    const [formData1, setFormData1] = useState({section3: [{ Title: '', Descriptions: '', sections: [], Snapshots: [] },]});
      const reload = () => {
        console.log("meow")
        reloadTable()
    
      };
      const fileToBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
    
    // Helper function to convert all files to base64
    const convertImagesToBase64 = async (files) => {
        const base64Promises = files.map(file => fileToBase64(file));
        return Promise.all(base64Promises);
    };
    
      const validFileTypes = ['image/png', 'image/jpeg'];

      const isValidFileType = (file) => validFileTypes.includes(file.type);
    
      const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = Array.from(e.dataTransfer.files);
    
        validateAndSetFiles(files);
      };
    
      const handleFileInputChange = (e) => {
        const files = Array.from(e.target.files);
    
        validateAndSetFiles(files);
      };
    
      const validateAndSetFiles = (files) => {
        const validFiles = files.filter(isValidFileType);
        const invalidFiles = files.filter(file => !isValidFileType(file));
    
        if (invalidFiles.length > 0) {
          setError('Please upload only PNG or JPEG images.');
        } else {
          setError(null);
        }
    
        setFormData1((prevState) => ({
          ...prevState,
          reportimgs: [...prevState.reportimgs, ...validFiles]
        }));
      };
    
      const handleRemoveFile = (index) => {
        setFormData1((prevState) => ({
          ...prevState,
          reportimgs: prevState.reportimgs.filter((_, i) => i !== index)
        }));
      };
    
      const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
      };
    
      const handleDragLeave = () => {
        setIsDragging(false);
      };
    
     // function to render and display styling from quill
  function renderHTML(htmlString) {
    if (!htmlString || typeof htmlString !== 'string') {
      return null;
    }

    const tagsToReplace = {
      '<li>': '<li class="ml-2">',
      '<h1>': '<h1 class="text-2xl font-bold">',
      '<h2>': '<h2 class="text-xl font-semibold">',
      '<h3>': '<h3 class="text-lg font-semibold">',
      '<h4>': '<h4 class="text-base font-semibold">',
      '<h5>': '<h5 class="text-sm font-semibold">',
      '<h6>': '<h6 class="text-xs font-semibold">',
      '<ul>': '<ul class="list-disc list-inside">',
      '<ol>': '<ol class="list-decimal list-inside">',
    };

    const replacedHTML = htmlString.replace(
      /(<li>|<h1>|<h2>|<h3>|<h4>|<h5>|<h6>|<ul>|<ol>)/g,
      (tag) => tagsToReplace[tag]
    );

    return <div dangerouslySetInnerHTML={{ __html: replacedHTML }} />;
  }
  const removeSection1 = (id) => {
    // Prevent removal of the first button
    if (id === 1) {
      return;
    }
  
    // Remove button by id
    setButtons(prevButtons => prevButtons.filter(button => button.id !== id));
  
    // Remove corresponding form data entry
    setFormData1(prevFormData => ({
      ...prevFormData,
      section3: prevFormData.section3.filter((_, index) => index !== id - 1)
    }));
  };
  

    // Safely check if data and data.record exist
    const record = data?.record || {};
    const habitsArray = record.Habits
        ? (typeof record.Habits === 'string'
            ? record.Habits.split(',').map(item => item.trim())
            : record.Habits)
        : [];
        const addSection = () => {
            if (sections === null) {
              setSections([{ title: "", content: "" }]);
            } else {
              setSections([...sections, { title: "", content: "" }]);
            }
          };
         const removeSection = (index) => {
            const updatedSections = [...sections];
            updatedSections.splice(index, 1);
            setSections(updatedSections);
          };
          // Function to handle changes in section heading or content
          const handleSectionChange = (index, key, value) => {
            const updatedSections = [...sections];
            updatedSections[index][key] = value;
            setSections(updatedSections);
          };
          const handleQuillChange2 = (index, content) => {
            const updatedSections = [...sections];
            updatedSections[index].content = content;
            setSections(updatedSections);
          };
          const follow = Array.isArray(record.followUps) ? record.followUps : [];
          const followupCount = follow.length-1;
          // Handler for ReactQuill change
          const handleDescriptionChange = (content) => {
            console.log("foo",followupCount)
            setFormData1({
              ...formData1,
              reportDescription: content,
            });
          };
          const base64Image = base;
          const logo64Image = logo;
          const pdf64Image = pdf;

          const formatDate = (date) => {
            try {
              // Use 'UTC' time zone for consistent formatting
              return formatInTimeZone(new Date(date), 'UTC', 'dd-MM-yyyy');
            } catch {
              return "Invalid Date";
            }
          };
    async function handleSubmit() {
      setLoading(true);
      
      try {
        // Determine followUpID based on followupCount
        const followUpID = followupCount === "-1" ? null : record.followUps?.[followupCount]?.followUpID || null;
    
        // Determine visitID based on followUpID
        const visitID = followUpID ? null : (record.visitID || '');
    
        // Convert images to base64 if they exist
        const imgsBase64 = formData1.reportimgs ? await convertImagesToBase64(formData1.reportimgs) : [];
    
        // Prepare the data object for submission
        const data = {
          RequestId: uuidv4(),
          Messages: {
            MessageId: uuidv4(),
            MessageType: "ConsultingRadiologistReport",
            Parameters: {
              UserId: currentUserID,
            },
            Body: {
              data: {
                section1: {
                  UID_backup: record.UID_backup || '',
                  Name: record.Name || '',
                  Age: record.Age || '',
                  Sex: record.Sex || '',
                  Height: record.Height || '',
                  Weight: record.Weight || '',
                  SPO2: record.SPO2 || '',
                  SBP: record.SBP || '',
                  DBP: record.DBP || '',
                  Temp: record.Temp || '',
                  MedicalHistory: record.MedicalHistory || '',
                  Radiologist_Name: record.Radiologist_Name || '',
                  Assigned_Radiologist_Name: record.Assigned_Radiologist_Name || '',
                  Date: record.date?.$date || '',
                  Symptoms: record.Symptoms || '',
                  AI_Disease_Diagnosis_Status: record.AI_Disease_Diagnosis_Status || '',
                  AI_Disease_Label: record.AI_Disease_Label || '',
                  AI_Disease_confidence: record.AI_Disease_confidence || '',
                  AI_Tests: record.AI_Tests || [],
                },
                section2: {
                  Followup: record.followUps || [],
                },
                section3: formData1.section3 || [], // Ensure `section3` is the correct key
                patientID: record.id?.$oid || '',
                followUpID: followUpID,
                visitID: visitID,
              }
            }
          }
        };
    
        // Determine the endpoint based on the active state
        const endPoint = active === 1 ? consultingRadiologistReport : radiologistReport;
    
        // Make the POST request
        const res = await axios.post(endPoint, data);
    
        // Check the response status and handle success
        if (res.status === 200) {
          toast.success("Form submitted successfully");
          setTimeout(() => reload(), 1000);
        } else {
          throw new Error('Failed to submit form');
        }
      } catch (error) {
        console.error('Submission error:', error);
        toast.error("Form not submitted");
      } finally {
        setLoading(false);
      }
    }

  
  const [activeButton, setActiveButton] = useState('health');
  const handleButtonClick = (button) => {
    // Update active button state
    setActiveButton(button);
    
    // Reset all modals
    setwShowModal(false);
    sethShowModal(false)
    settShowModal(false);
    setbpShowModal(false);
    setspo2ShowModal(false)
    setShowModalh(false)
    
    // Show the selected modal
    if (button === 'weight') {
      setwShowModal(true);
    } else if (button === 'temp') {
      settShowModal(true);
    } else if (button === 'bp') {
      setbpShowModal(true);
    }
   else if (button === 'spo2') {
    setspo2ShowModal(true);
  }else if (button === 'health') {
    sethShowModal(true);
    setShowModalh(true);
  }
  };
  const overallHealthData = AnalysisData["Overall Health"];
  const overallWeightData = AnalysisData["Weight"];
  const overallTemperatureData = AnalysisData["Temperature"];
  const overallDBPData = AnalysisData["DBP"];
  const overallSBPData = AnalysisData["SBP"];
  const overallSPO2Data = AnalysisData["SPO2"];
  const formatDate1 = (date) => {
    try {
      // Use 'UTC' time zone for consistent formatting
      return formatInTimeZone(new Date(date), 'UTC', 'dd-MM-yyyy');
    } catch {
      return "Invalid Date";
    }
  };
  const handleAddReportClick = (title,id) => {
    setSelectedTitle(title); // Set the selected title

    setSidebarOpen(true);
    setShowConfirmationModal(true);
    const i=id-1;
    setbtnid(i);
    console.log("id btn id=",btnid )
    console.log("id btn id=",formData1 )

    // Log the updated button ID
  };
  const handleAddReportClick1 = (id) => {
    setActivePreviewId(id)
    const i=id-1;
    setbtnid(i);
    console.log("id btn meow=",id )


    // Log the updated button ID
  };
  useEffect(() => {
    return () => {
      // Clean up URLs when component unmounts
      formData1[btnid]?.Snapshots?.forEach(file => {
        if (file instanceof Blob || file instanceof File) {
          URL.revokeObjectURL(fileToURL(file));
        }
      });
    };
  }, [formData1, btnid]);
  
  const xDataspo2 = overallSPO2Data && overallSPO2Data.xCoordinate
  ? overallSPO2Data.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yDataspo2 = overallSPO2Data && overallSPO2Data.yCoordinate
  ? overallSPO2Data.yCoordinate.map(Number)
  : [];
  
  const xDatadb = overallDBPData && overallDBPData.xCoordinate
  ? overallDBPData.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yDatadb = overallDBPData && overallDBPData.yCoordinate
  ? overallDBPData.yCoordinate.map(Number)
  : [];
  
  const xDatasb = overallSBPData && overallSBPData.xCoordinate
  ? overallSBPData.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yDatasb = overallSBPData && overallSBPData.yCoordinate
  ? overallSBPData.yCoordinate.map(Number)
  : [];
  const xDatah = overallTemperatureData && overallTemperatureData.xCoordinate
  ? overallTemperatureData.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];
  const handleClickp = () => {
    setActivePreviewId(prevactivePreviewId => !prevactivePreviewId);
  };

const yDatah = overallTemperatureData && overallTemperatureData.yCoordinate
  ? overallTemperatureData.yCoordinate.map(Number)
  : [];
const templen=yDatah.length;

  const xDataw = overallWeightData && overallWeightData.xCoordinate
  ? overallWeightData.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yDataw = overallWeightData && overallWeightData.yCoordinate
  ? overallWeightData.yCoordinate.map(Number)
  : [];

// Convert xCoordinate (dates) and yCoordinate (numbers)v
const xData = overallHealthData && overallHealthData.xCoordinate
  ? overallHealthData.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yData = overallHealthData && overallHealthData.yCoordinate
  ? overallHealthData.yCoordinate.map(Number)
  : [];
    
    const testsArray = record.AI_Tests
        ? JSON.parse(record.AI_Tests.replace(/'/g, '"'))
        : [];

    useEffect(() => {
      FetchAnalysis();
      if (record && record.FollowUpReports && record.FollowUpReports.length > 0) {
        const base64String = record.FollowUpReports[0].HashedPatientUID.$binary.base64;
        console.log("datapdf", base64String);
        setpdf(base64String)
    } else {
        console.log("FollowUpReports is undefined or empty");
    }
            setdata1(data.record);
        setlogo(radiologistData.Logo.$binary.base64);
        setbase(radiologistData.Signature.$binary.base64);
        // setpdf(record.FollowUpReports[0].HashedPatientUID.$binary.base64)
        console.log("record", data1);

    }, [data]);
    const handleTitleChange = (event) => {
        console.log('Title change event:', event.target.value);
        setFormData1((prevState) => ({
          ...prevState,
          reportTitle: event.target.value,
        }));
      };
    
    if (modelview === 'AI Report') {
        return (
            <div className={`${
                showModal
                    ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
                    : "hidden"
            } bg-black bg-opacity-50`}>
                <div className="modal-container-outer">
                    <div className="bg-[#FFFFFFE0] relative flex flex-col justify-start w-[81rem] h-[45rem] p-6 rounded-[40px] gap-x-3 modal-container-inner">
                        <div className="absolute top-6 left-6 flex items-center space-x-4">
                            <h2 className="text-xl font-bold">AI Reports</h2>
                            <InReportDropdownButton 
                                setShowModal={setShowModal} 
                                formData={formData} 
                                setConfirmationModalData={setConfirmationModalData} 
                                setFormData={setFormData}  
                                record={record} 
                                modelview={modelview}
                                setModelview={setModelview}
                                active={active}
                                recordData={recordData}

                            />
                        </div>

                        <RxCross2
                            size={30}
                            onClick={() => {
                                onCancel();
                                setNext(false);
                                FetchAnalysis();
                                setActivePreviewId(false)
                                setShowModal(false)

                            }}
                            className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
                        />

                        <div className="flex flex-wrap justify-center gap-x-5 gap-y-4 mt-[50px]">
                            <div className="flex flex-col justify-start h-full gap-y-4 w-full">
                            <div className="flex justify-center ">
                              <h1 className="text-[20px] font-medium">
                                Patient UID : {record.UID_backup}
                              </h1>
                            </div>
                                <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
                                    <div>
                                        <p><strong>Name:</strong> <span className="ms-1">{record.Name || 'N/A'}</span></p>
                                        <p><strong>Age: </strong> <span className="ms-1">{record.Age || 'N/A'}</span></p>
                                        <p><strong>Sex: </strong> <span className="ms-1">{record.Sex || 'N/A'}</span></p>
                                        <p><strong>Height: </strong> <span className="ms-1"> {record.Height || 'N/A'} cm</span></p>
                                        <p><strong>Weight: </strong> <span className="ms-1"> {record.Weight || 'N/A'} kg</span></p>
                                        <p><strong>SPO2: </strong> <span className="ms-1"> {record.SPO2 || 'N/A'} %</span></p>
                                        <p><strong>Blood Pressure: </strong> <span className="ms-1"> {record.SBP || 'N/A'}/{record.DBP || 'N/A'}</span></p>
                                        <p><strong>Temperature: </strong> <span className="ms-1"> {record.Temp || 'N/A'} °C</span></p>
                                        <p><strong>Habits:</strong> <span className="ms-1"> {habitsArray.length > 0 ? habitsArray.join(', ') : 'N/A'}</span></p>
                                        <p><strong>Symptoms:</strong> <span className="ms-1">{record.Symptoms?.length > 0 ? record.Symptoms.join(', ') : 'N/A'}</span></p>
                                    </div>
                                    <div>
                                        <p><strong>Date:</strong><span className="ms-1"> {record.date?.$date ? formatDate(new Date(record.date.$date)) : 'N/A'}</span></p>
                                        <p><strong>Diagnosing Radiologist: </strong>
  <span className="ms-1">
    {Array.isArray(record?.Radiologist_Name)
      ? [...new Set(record.Radiologist_Name)].join(", ")
      : record.Radiologist_Name}
  </span>
</p>                                        <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{record.Assigned_Radiologist_Name || 'N/A'}</span></p>
                                        <p><strong>Patient Status:</strong> <span className="ms-1">{record.AI_Disease_Diagnosis_Status || 'N/A'}</span></p>
                                        <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{record.AI_Disease_Label || 'N/A'}</strong> with probability of <strong>{record.AI_Disease_confidence || 'N/A'}</strong></span></p>
                                        {('MedicalHistory' in record) && (
                                            <p><strong>Medical History:</strong> <span className="ms-1"> {record.MedicalHistory || 'N/A'}</span></p>
                                        )}
                                        <p className="text-sm"><strong>Tests:</strong> <span className="ms-1">{testsArray.length > 0 ? testsArray.join(', ') : 'N/A'}</span></p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between"></div>
                                {('followUps' in record) && (
                                    <FollowUps data1={data1} />
                                )}
                                 <div className="flex justify-end">
  <button
    onClick={() => {
setShowModal1(true)   
sethShowModal(true)
handleButtonClick('health') }}
    className="bg-[#36AE78] w-[100px] text-white font-semibold px-4 py-2 rounded-xl active:scale-95 active:transform transition-transform duration-300 focus:outline-none"
  >
    Analysis
  </button>{showModal1 && (
  <div className={`${
    showModal
      ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
      : "hidden"
  } bg-black bg-opacity-50`}>
    <div className="modal-container-outer">
      <div className="bg-[#FFFFFFE0] relative flex flex-col w-[86rem] h-[45rem] p-6 rounded-[15px] gap-x-3 modal-container-inner">
        <div className="flex justify-center">
          <h1 className="text-[20px] font-medium"><strong>Analysis</strong></h1>
        </div>

        <RxCross2
          size={30}
          onClick={() => {
            setShowModal1(false);
            setwShowModal(false);
            sethShowModal(false);
            setActiveButton('health');
            setbpShowModal(false);
            settShowModal(false);
            setspo2ShowModal(false)
            setActivePreviewId(false)

          }}
          className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
        />

        <div className="flex flex-1">
          <div className="flex flex-col flex-basis-1/5 p-4">
          <button
              onClick={() => handleButtonClick('health')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'health'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              Health Forecast
            </button>
            <button
              onClick={() => handleButtonClick('weight')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'weight'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              Weight Forecast
            </button>
            <button
              onClick={() => handleButtonClick('temp')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'temp'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              Temp Forecast
            </button>
            <button
              onClick={() => handleButtonClick('bp')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'bp'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              BP Forecast
            </button>
            <button
              onClick={() => handleButtonClick('spo2')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'spo2'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              SPO2 Forecast
            </button>
          </div>

          <div className="flex-1 flex flex-wrap justify-left p-4 w-[1048px]">
          {hshowModal && (
             <HealthForecastChart xData={xData} yData={yData} />
          //    <Plot
          //    data={[
          //      {
          //        x: xData,
          //        y: yData,
          //        type: 'scatter',
          //        mode: 'lines+markers',
          //        name: 'red',
          //        line: { color: 'red' },
          //        marker: { color: 'red' },
          //        hovertemplate: 'Percentage: %{y:.2f}<extra></extra>', // Customize hover text here
          //      },
          //    ]}
          //    layout={{
          //      width: '1100',
          //      height: '620',
          //      title: 'Overall Health Forecast',
          //      xaxis: {
          //        title: 'Date',
          //        tickvals: xData,
          //        ticktext: xData,
          //      },
          //      yaxis: {
          //        title: 'Health Index',
          //        tickvals: [0, 1, 2, 3,4,5],
          //        ticktext: ['0', '1', '2', '3','4','5'],
          //        range: [0, 5],
          //      },
          //      legend: {
          //        x: 0.5,
          //        y: -0.2,
          //        orientation: 'h',
         
           
           
            )}
            {wshowModal && (
          <WeightForecastChart xDataw={xDataw} yDataw={yDataw} />
          
            )}
            {tshowModal && (
            <TempForecastChart xDatah={xDatah} yDatah={yDatah} />
           
            )}
            {bpshowModal && (

<BPForecastChart xDatadb={xDatadb} yDatadb={yDatadb} xDatasb={xDatasb} yDatasb={yDatasb} />

    
          
            )}
             {spo2showModal && (
     
          <Spo2ForecastChart xDataspo2={xDataspo2} yDataspo2={yDataspo2} />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)}</div>
                                <hr className="border-[#36AE78]" />
                                {('CT_AI_Report_Status' in record) && (
                                    <CTReportPagination data={data.record} />
                                )}
                                <div className="flex flex-col gap-y-5">
                                    <div className="space-y-2">
                                        {('CXR_AI_Report' in record) && (
                                            <DXReportPagination data={data.record} />
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col items-end justify-end p-6">
                                    <img
                                        src={logo1}
                                        alt="logo"
                                        className="w-[7rem] h-[6rem] object-contain"
                                    />
                                </div>
                                <div className="flex justify-end items-end p-1">
                                    <GrPrevious
                                        size={40}
                                        onClick={() => setNext(false)}
                                        className="cursor-pointer hover:bg-gray-400 rounded-full p-2 right-[45rem]"
                                    />
                                    <GrNext
                                        size={40}
                                        onClick={() => setNext(true)}
                                        className="cursor-pointer hover:bg-gray-400 rounded-full p-2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (modelview === 'Cold Report') {
        return (
            <div className={`${
                showModal
                    ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
                    : "hidden"
            } bg-black bg-opacity-50`}>
                <div className="modal-container-outer">
                    <div className="bg-[#FFFFFFE0] relative flex flex-col justify-start w-[81rem] h-[45rem] p-6 rounded-[40px] gap-x-3 modal-container-inner">
                        <div className="absolute top-6 left-6 flex items-center space-x-4">
                            <h2 className="text-xl font-bold">Cold Reports</h2>
                            <InReportDropdownButton 
                                setShowModal={setShowModal} 
                                formData={formData} 
                                setConfirmationModalData={setConfirmationModalData} 
                                setFormData={setFormData}  
                                record={record} 
                                modelview={modelview}
                                setModelview={setModelview}
                                active={active}
                                recordData={recordData}


                            />
                        </div>

                        <RxCross2
                            size={30}
                            onClick={() => {
                                onCancel();
                                setNext(false);
                                FetchAnalysis();
                                setActivePreviewId(false)

                            }}
                            className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
                        />
                        <div className="mt-5">
                           
                            <div className="space-y-4 p-9 w-full">
                              <div className="bg-white flex items-center p-8 border-b-2 border-[#6EC59D]">
                                <img
                                  className="mr-4"
                                  src={`data:image/jpeg;base64,${logo64Image}`}
                                  width={100}
                                  height={50}
                                  alt="Binary Image"
                                />
                                <p className="text-2xl font-medium flex-grow text-center">
                                  {record.Hospital.split("_")[0]}
                                </p>
                              </div>
            
                              <div className="flex justify-center ">
                                <h1 className="text-[20px] font-medium">
                                  Patient UID : {record.UID_backup}
                                </h1>
                              </div>
                              <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
                                    <div>
                                        <p><strong>Name:</strong> <span className="ms-1">{record.Name || 'N/A'}</span></p>
                                        <p><strong>Age: </strong> <span className="ms-1">{record.Age || 'N/A'}</span></p>
                                        <p><strong>Sex: </strong> <span className="ms-1">{record.Sex || 'N/A'}</span></p>
                                        <p><strong>Height: </strong> <span className="ms-1"> {record.Height || 'N/A'} cm</span></p>
                                        <p><strong>Weight: </strong> <span className="ms-1"> {record.Weight || 'N/A'} kg</span></p>
                                        <p><strong>SPO2: </strong> <span className="ms-1"> {record.SPO2 || 'N/A'} %</span></p>
                                        <p><strong>Blood Pressure: </strong> <span className="ms-1"> {record.SBP || 'N/A'}/{record.DBP || 'N/A'}</span></p>
                                        <p><strong>Temperature: </strong> <span className="ms-1"> {record.Temp || 'N/A'} °C</span></p>
                                        <p><strong>Habits:</strong> <span className="ms-1"> {habitsArray.length > 0 ? habitsArray.join(', ') : 'N/A'}</span></p>
                                        <p><strong>Symptoms:</strong> <span className="ms-1">{record.Symptoms?.length > 0 ? record.Symptoms.join(', ') : 'N/A'}</span></p>
                                    </div>
                                    <div>
                                        <p><strong>Date:</strong><span className="ms-1"> {record.date?.$date ? formatDate(new Date(record.date.$date)) : 'N/A'}</span></p>
                                        <p><strong>Diagnosing Radiologist: </strong>
  <span className="ms-1">
    {Array.isArray(record?.Radiologist_Name)
      ? [...new Set(record.Radiologist_Name)].join(", ")
      : record.Radiologist_Name}
  </span>
</p>                                        <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{record.Assigned_Radiologist_Name || 'N/A'}</span></p>
                                        <p><strong>Patient Status:</strong> <span className="ms-1">{record.AI_Disease_Diagnosis_Status || 'N/A'}</span></p>
                                        <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{record.AI_Disease_Label || 'N/A'}</strong> with probability of <strong>{record.AI_Disease_confidence || 'N/A'}</strong></span></p>
                                        {('MedicalHistory' in record) && (
                                            <p><strong>Medical History:</strong> <span className="ms-1"> {record.MedicalHistory || 'N/A'}</span></p>
                                        )}
                                        <p className="text-sm"><strong>Tests:</strong> <span className="ms-1">{testsArray.length > 0 ? testsArray.join(', ') : 'N/A'}</span></p>
                                    </div>
                                </div>
       
                              <hr className=" border-[#6EC59D]" />
                           
                             <ColdReport data={data.record} AnalysisData={AnalysisData}/>

                              
                            </div>
                          </div>

                        {/* Cold Report specific content here */}

                    </div>
                </div>
            </div>
        );
    } else if (modelview === 'Write Report') {
        return (
            <div className={`${
                showModal
                    ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
                    : "hidden"
            } bg-black bg-opacity-50`}>
                <div className="modal-container-outer">
                    <div className="bg-[#FFFFFFE0] relative flex flex-col justify-start w-[81rem] h-[45rem] p-6 rounded-[40px] gap-x-3 modal-container-inner">
                        <div className="absolute top-6 left-6 flex items-center space-x-4">
                            <h2 className="text-xl font-bold">Write Report</h2>
                            <InReportDropdownButton 
                                setShowModal={setShowModal} 
                                formData={formData} 
                                setConfirmationModalData={setConfirmationModalData} 
                                setFormData={setFormData}  
                                record={record} 
                                modelview={modelview}
                                setModelview={setModelview}
                                recordData={recordData}

                                active={active}

                            />
                        </div>

                        <RxCross2
                            size={30}
                            onClick={() => {
                                onCancel();
                                setNext(false);
                                FetchAnalysis();
                                setActivePreviewId(false)


                            }}
                            className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
                        />
                                

    <div className="flex flex-wrap justify-center gap-x-5 gap-y-4 mt-[50px]">
                            <div className="flex flex-col justify-start h-full gap-y-4 w-full">
                            <div className="flex justify-center ">
               <h1 className="text-[20px] font-medium">
                 Patient UID : {record.UID_backup}
               </h1>
             </div>
                                <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
                                    <div>
                                        <p><strong>Name:</strong> <span className="ms-1">{record.Name || 'N/A'}</span></p>
                                        <p><strong>Age: </strong> <span className="ms-1">{record.Age || 'N/A'}</span></p>
                                        <p><strong>Sex: </strong> <span className="ms-1">{record.Sex || 'N/A'}</span></p>
                                        <p><strong>Height: </strong> <span className="ms-1"> {record.Height || 'N/A'} cm</span></p>
                                        <p><strong>Weight: </strong> <span className="ms-1"> {record.Weight || 'N/A'} kg</span></p>
                                        <p><strong>SPO2: </strong> <span className="ms-1"> {record.SPO2 || 'N/A'} %</span></p>
                                        <p><strong>Blood Pressure: </strong> <span className="ms-1"> {record.SBP || 'N/A'}/{record.DBP || 'N/A'}</span></p>
                                        <p><strong>Temperature: </strong> <span className="ms-1"> {record.Temp || 'N/A'} °C</span></p>
                                        <p><strong>Habits:</strong> <span className="ms-1"> {habitsArray.length > 0 ? habitsArray.join(', ') : 'N/A'}</span></p>
                                        <p><strong>Symptoms:</strong> <span className="ms-1">{record.Symptoms?.length > 0 ? record.Symptoms.join(', ') : 'N/A'}</span></p>
                                    </div>
                                    <div>
                                        <p><strong>Date:</strong><span className="ms-1"> {record.date?.$date ? formatDate(new Date(record.date.$date)) : 'N/A'}</span></p>
                                        <p><strong>Diagnosing Radiologist: </strong>
  <span className="ms-1">
    {Array.isArray(record?.Radiologist_Name)
      ? [...new Set(record.Radiologist_Name)].join(", ")
      : record.Radiologist_Name}
  </span>
</p>                                        <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{record.Assigned_Radiologist_Name || 'N/A'}</span></p>
                                        <p><strong>Patient Status:</strong> <span className="ms-1">{record.AI_Disease_Diagnosis_Status || 'N/A'}</span></p>
                                        <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{record.AI_Disease_Label || 'N/A'}</strong> with probability of <strong>{record.AI_Disease_confidence || 'N/A'}</strong></span></p>
                                        {('MedicalHistory' in record) && (
                                            <p><strong>Medical History:</strong> <span className="ms-1"> {record.MedicalHistory || 'N/A'}</span></p>
                                        )}
                                        <p className="text-sm"><strong>Tests:</strong> <span className="ms-1">{testsArray.length > 0 ? testsArray.join(', ') : 'N/A'}</span></p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between"></div>
                                {('followUps' in record) && (
                                    <FollowUps data1={data1} />
                                )}
                                  <div className="flex justify-end">
  <button
    onClick={() => {
setShowModal1(true)   
sethShowModal(true)
handleButtonClick('health') }}
    className="bg-[#36AE78] w-[100px] text-white font-semibold px-4 py-2 rounded-xl active:scale-95 active:transform transition-transform duration-300 focus:outline-none"
  >
    Analysis
  </button>
  {showModal1 && (
  <div className={`${
    showModal1
      ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
      : "hidden"
  } bg-black bg-opacity-50`}>
    <div className="modal-container-outer">
      <div className="bg-[#FFFFFFE0] relative flex flex-col w-[86rem] h-[45rem] p-6 rounded-[15px] gap-x-3 modal-container-inner">
        <div className="flex justify-center">
          <h1 className="text-[20px] font-medium"><strong>Analysis</strong></h1>
        </div>

        <RxCross2
          size={30}
          onClick={() => {
            setShowModal1(false);
            setwShowModal(false);
            sethShowModal(false);
            setActiveButton('health');
            setbpShowModal(false);
            settShowModal(false);
            setspo2ShowModal(false)
            setActivePreviewId(false)
          }}
          className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
        />

        <div className="flex flex-1">
          <div className="flex flex-col flex-basis-1/5 p-4">
          <button
              onClick={() => handleButtonClick('health')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'health'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              Health Forecast
            </button>
            <button
              onClick={() => handleButtonClick('weight')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'weight'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              Weight Forecast
            </button>
            <button
              onClick={() => handleButtonClick('temp')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'temp'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              Temp Forecast
            </button>
            <button
              onClick={() => handleButtonClick('bp')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'bp'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              BP Forecast
            </button>
            <button
              onClick={() => handleButtonClick('spo2')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'spo2'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              SPO2 Forecast
            </button>
          </div>

          <div className="flex-1 flex flex-wrap justify-left p-4 w-[1048px]">
            {hshowModal && (
             <HealthForecastChart xData={xData} yData={yData} />
          //    <Plot
          //    data={[
          //      {
          //        x: xData,
          //        y: yData,
          //        type: 'scatter',
          //        mode: 'lines+markers',
          //        name: 'red',
          //        line: { color: 'red' },
          //        marker: { color: 'red' },
          //        hovertemplate: 'Percentage: %{y:.2f}<extra></extra>', // Customize hover text here
          //      },
          //    ]}
          //    layout={{
          //      width: '1100',
          //      height: '620',
          //      title: 'Overall Health Forecast',
          //      xaxis: {
          //        title: 'Date',
          //        tickvals: xData,
          //        ticktext: xData,
          //      },
          //      yaxis: {
          //        title: 'Health Index',
          //        tickvals: [0, 1, 2, 3,4,5],
          //        ticktext: ['0', '1', '2', '3','4','5'],
          //        range: [0, 5],
          //      },
          //      legend: {
          //        x: 0.5,
          //        y: -0.2,
          //        orientation: 'h',
          //        xanchor: 'center',
          //      },
          //    }}
          //  />
           
           
            )}
            {wshowModal && (
          <WeightForecastChart xDataw={xDataw} yDataw={yDataw} />
          
            )}
            {tshowModal && (
            <TempForecastChart xDatah={xDatah} yDatah={yDatah} />
           
            )}
            {bpshowModal && (

<BPForecastChart xDatadb={xDatadb} yDatadb={yDatadb} xDatasb={xDatasb} yDatasb={yDatasb} />

    
          
            )}
             {spo2showModal && (
     
          <Spo2ForecastChart xDataspo2={xDataspo2} yDataspo2={yDataspo2} />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)}

</div>
                                <hr className="border-[#36AE78]" />
                                <div className="space-y-2">
                                <div className="flex">
      {/* Left Side - Buttons */}
      <div className="flex flex-col items-start w-100 p-4 border-r border-gray-300">
  {buttons.map((button) => {
    console.log(`Rendering button with id: ${button.id}`); // Add logging to check the button id

    return (
      <div key={button.id} className="flex items-center mb-2 w-full">
        {editing === button.id ? (
          <input
            type="text"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            onBlur={() => saveTitle(button.id)}
            className="flex-grow text-black font-semibold px-4 py-2 rounded-xl border border-black"
          />
        ) : (
          <>
            <button
              onClick={() => handleEditClick1(button.id)}
              className={`flex-grow text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                button.title === 'Observation Report'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              {button.title || 'Untitled1'}
            </button>

            <button
              onClick={() => {
                handleAddReportClick(button.title, button.id);
                console.log("id btn", button.id - 1);
                console.log("id btn", formData1);
                setActivePreviewId(null); // Reset activePreviewId when adding report
              }}
              className="ml-2 flex items-center justify-center text-[#36AE78]"
            >
              <TbPencilExclamation size={22} />
            </button>

            <button
              onClick={() => {
                handleAddReportClick1(button.id);
              }}
              className="text-[#36AE78] p-2 ml-2"
            >
              <MdOutlineRemoveRedEye size={22} />
            </button>

            {/* Conditionally render FaMinusCircle based on button.id */}
            {button.id !== 1 && (
              <button
                onClick={() => removeSection1(button.id)}
                className="text-[#ec767e] p-2 ml-2"
              >
                <FaMinusCircle size={22} />
              </button>
            )}
          </>
        )}
      </div>
    );
  })}

  <div className="flex justify-start gap-x-3 mt-4">
    <button
      onClick={addSection1}
      className="flex items-center gap-x-1 bg-[#36AE78] text-white px-4 py-1 rounded-md"
    >
      <FaPlus size={12} />
      ADD
    </button>
  </div>

  <button
    className="text-sm w-full font-medium rounded-lg text-white mt-8 px-6 py-2 bg-[#303030] active:scale-95 transition-transform duration-300 focus:outline-none"
    onClick={handleSubmit}
  >
    {loading ? loader : "Submit"}
  </button>
</div>


      {/* Right Side - Detailed Information */}
      {activePreviewId && (
        <div className="flex-1 p-4 h-[600px] w-[600px] overflow-auto me-5 border border-[#36AE78]">
          <RxCross2
            size={30}
            onClick={() => setActivePreviewId(null)}
            className="cursor-pointer hover:bg-gray-400 rounded-full p-1"
          />
          <div className="bg-white p-8 border-b-2 border-[#6EC59D]">
            <img
              className="mr-4"
              src={`data:image/jpeg;base64,${logo64Image}`}
              width={100}
              height={50}
              alt="Binary Image"
            />
            <p className="text-2xl font-medium text-center">
              {record.Hospital.split("_")[0]}
            </p>
          </div>

          <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1 mt-4">
            <div>
              <p><strong>Name:</strong> <span className="ms-1">{record.Name}</span></p>
              <p><strong>Age: </strong> <span className="ms-1">{record.Age}</span></p>
              <p><strong>Sex: </strong> <span className="ms-1">{record.Sex}</span></p>
              <p><strong>Habits:</strong> <span className="ms-1">{record.Habits}</span></p>
              <p><strong>Symptoms:</strong> <span className="ms-1">{(record.Symptoms || []).join(', ')}</span></p>
            </div>
            <div>
              <p><strong>Date:</strong> <span className="ms-1">{record.date?.$date ? formatDate(new Date(record.date.$date)) : 'N/A'}</span></p>
              <p><strong>Weight: </strong> <span className="ms-1">{record.Weight} kg</span></p>
              <p><strong>SPO2: </strong> <span className="ms-1">{record.SPO2} %</span></p>
              <p><strong>Blood Pressure: </strong> <span className="ms-1">{record.SBP}/{record.DBP}</span></p>
              <p><strong>Temperature: </strong> <span className="ms-1">{record.Temp} °C</span></p>
            </div>
          </div>

          <hr className="border-[#6EC59D] my-4" />

          <div className="space-y-4">
      {/* Title */}
      <p><strong>{formData1.section3[btnid]?.Title || 'No Title'}</strong></p>

      {/* Description */}
      <p>{ renderHTML(formData1.section3[btnid]?.Descriptions || '') }</p>

      {/* Sections */}
      {formData1.section3[btnid]?.sections && formData1.section3[btnid].sections.length > 0 ? (
        formData1.section3[btnid].sections.map((section, index) => (
          <div className="space-y-2" key={index}>
            <p className="font-semibold text-[15px]">{section.title}</p>
            <p className="text-sm font-normal">{renderHTML(section.content)}</p>
          </div>
        ))
      ) : (
        <p>No Sections Available</p>
      )}

      {/* Snapshots */}
      <div className="space-y-2">
        <div className="flex flex-wrap gap-2">
          {formData1.section3[btnid]?.Snapshots && formData1.section3[btnid].Snapshots.length > 0 ? (
            formData1.section3[btnid].Snapshots.map((snapshot, index) => (
              <div key={index} className="relative">
                <img
                  src={snapshot.base64} // Use base64 string directly
                  alt={`Snapshot ${index}`}
                  className="w-[300px] h-[300px] object-cover"
                />
                <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white text-xs p-1">
                  {snapshot.name || 'No name'}
                </div>
              </div>
            ))
          ) : (
            <p>No Snapshots Available</p>
          )}
        </div>
      </div>
    </div>

          <div className="flex flex-col items-end justify-end p-6">
            <img
              src={`data:image/jpeg;base64,${base64Image}`}
              width={200}
              height={50}
              alt="Binary Image"
            />
            <p className="semi-bold mt-2">
              {Array.isArray(record?.Radiologist_Name)
                ? record.Radiologist_Name.join(", ")
                : record.Radiologist_Name}
            </p>
          </div>
        </div>
      )}
  

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed top-0 inset-0 flex items-center justify-center h-full bg-black bg-opacity-50 z-40 backdrop-blur-lg">
          <div className={`z-50 fixed top-0 left-0 h-full pt-5 px-2 bg-white w-[50%] shadow-lg ${!sidebarOpen ? "-translate-x-full" : ""} transition-all duration-700 ease-in-out`}>
            <div onClick={() => { setSidebarOpen(false); setShowConfirmationModal(false); }} className="cursor-pointer bg-slate-200 p-2 w-fit rounded-full absolute right-3">
              <RxCross1 />
            </div>
            <Addnewreport
            setActivePreviewId={setActivePreviewId}
            setFormData1={setFormData1}
            formData1={formData1}
            activePreviewId={activePreviewId}
            btnid={btnid}
              setSidebarOpen={setSidebarOpen}
              setShowConfirmationModal={setShowConfirmationModal}
              showConfirmationModal={showConfirmationModal}
              reloadTable={reloadTable}
              selectedTitle={selectedTitle} // Pass the selected title
              // Pass other props if needed
            />
          </div>
        </div>)}

    
    </div>
{/* 
                 <h3 className="text-[16px] font-semibold">
                Report Title:                 </h3>
               

                <input
        className="bg-[#efedede0] border border-green-500 p-2 rounded-md w-auto bg-white"
        type="text"
        value={formData1.reportTitle}
        onChange={handleTitleChange}
        name="reportTitle"
      />
               </div>
                                <div className="space-y-2">
                 <h3 className="text-[16px] font-semibold">
                 Report Description:                 </h3>

                 <ReactQuill
                   theme="snow"
                   value={formData1.reportDescription}
                   onChange={
         handleDescriptionChange          }
                   name="reportDescription"
                   style={{ backgroundColor: "#ffffff" }}
                 /> */}
                   {/* {sections !== null && (
               <div>
                
                 {sections.map((section, index) => (
                   <div key={index} className="space-y-2 ">
                     <div className="flex items-center">
                       <input
                         type="text"
                         value={section.title}
                         onChange={(e) =>
                           handleSectionChange(
                             index,
                             "title",
                             e.target.value
                           )
                         }
                         className=" text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl px-3 py-1 no-scrollbar outline-none"
                         placeholder="Section Heading"
                       />

                       <button
                         onClick={() => removeSection(index)}
                         className="text-[#ec767e] p-2"
                       >
                         <FaMinusCircle size={22} />
                       </button>
                     </div>

                     <ReactQuill
                       theme="snow"
                       value={section.content}
                       onChange={(content) =>
                         handleQuillChange2(index, content)
                       }
                       placeholder="Section Content"
                       style={{ backgroundColor: "#ffffff" }}
                     />
                   </div>
                 ))}
               </div>
             )}
                   <div className="flex justify-end gap-x-3">
               <button
                 onClick={addSection}
                 className="flex items-center gap-x-1 bg-[#36AE78] text-white px-4 py-1 rounded-md"
               >
                 <FaPlus size={12} />
ADD                 </button>
             
             </div> */}
             {/* <div className="space-y-2">
                 <h3 className="text-[16px] font-semibold">
                 Report Image:                 </h3>
                 <div
      className={`w-[200px] h-[80px] border-2 ${isDragging ? "border-[#36AE78]" : "border-slate-400"
        } rounded-lg p-2 border-dashed flex flex-col justify-center items-center`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnter={() => setIsDragging(true)}
    >
      <input
        name="Report"
        type="file"
        className="hidden"
        id="file-input"
        multiple
        accept=".png, .jpg, .jpeg"
        onChange={handleFileInputChange}
      />
      <PiCloudArrowDown
        size={30}
        className={`text-5xl font-normal ${isDragging ? "text-[#36AE78]" : "text-slate-400"
          }`}
      />
      <span className="text-slate-400 text-[15px] text-center">
        Drag & Drop files here <br />
        or{" "}
      </span>{" "}
      <label
        className="text-blue-600 text-[15px] hover:underline cursor-pointer"
        htmlFor="file-input"
      >
        {" "}
        Browse
      </label>
      {error && (
        <div className="text-red-500 text-xs mt-2">
          {error}
        </div>
      )}
    </div>
    <div className="w-full">
      <ul className="w-full py-[3px] flex gap-x-3 px-2 overflow-x-scroll no-scrollbar">
        {formData1.reportimgs.map((file, index) => (
          <li
            className="border border-[#36AE78] text-[#36AE78] bg-[#c1f6de] bg-opacity-15 rounded-full w-fit pl-2 pr-1 whitespace-nowrap flex items-center gap-x-1"
            key={index}
          >
            {file.name ? (
              file.name.length > 15
                ? file.name.substring(0, 14) + "..."
                : file.name
            ) : (
              "No name"
            )}
            <RxCross2
              className="cursor-pointer ml-2"
              onClick={() => handleRemoveFile(index)}
            />
          </li>
        ))}
      </ul>
      <p className="text-left text-slate-500 text-sm pl-3 pt-2">
        {formData1.reportimgs.length > 0
          ? "Files: " + formData1.reportimgs.length
          : ""}
      </p>
    </div>
                      <div className=" mt-5 flex justify-center gap-x-3">
               <button
                 className={`bg-[#36AE78] flex items-center justify-center gap-x-2 text-white px-8 py-1 rounded-md w-fit 
                   ? "opacity-50 cursor-not-allowed"
                   : ""
                   }`}
                   onClick={() => setActivePreviewId(true)}

               >
                 activePreviewId
               </button>
               <button
                 className="bg-[#36AE78] text-white px-8 py-1 rounded-md w-fit"
                 onClick={handleSubmit}
               >
              
              {loading ? loader : "Submit"}
              </button>
             </div>         
                 </div> */}

                 {/* {renderHTML(data1.key_impression)} */}
               </div>
                                <div className="flex flex-col items-end justify-end p-6">
                                    <img
                                        src={logo1}
                                        alt="logo"
                                        className="w-[7rem] h-[6rem] object-contain"
                                    />
                                </div>
                                <div className="flex justify-end items-end p-1">
                                    <GrPrevious
                                        size={40}
                                        onClick={() => setNext(false)}
                                        className="cursor-pointer hover:bg-gray-400 rounded-full p-2 right-[45rem]"
                                    />
                                    <GrNext
                                        size={40}
                                        onClick={() => setNext(true)}
                                        className="cursor-pointer hover:bg-gray-400 rounded-full p-2"
                                    />
                                </div>
                            </div>
                        </div>
                          <></>
                        
                        {/* Report 3 specific content here */}

                    </div>
                </div>
            </div>
        );
    } else if (modelview === 'FollowUp Report') {
        return (
            <div className={`${
                showModal
                    ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
                    : "hidden"
            } bg-black bg-opacity-50`}>
                <div className="modal-container-outer">
                    <div className="bg-[#FFFFFFE0] relative flex flex-col justify-start w-[81rem] h-[45rem] p-6 rounded-[40px] gap-x-3 modal-container-inner">
                        <div className="absolute top-6 left-6 flex items-center space-x-4">
                            <h2 className="text-xl font-bold">Followup Report</h2>
                            <InReportDropdownButton 
                                setShowModal={setShowModal} 
                                formData={formData} 
                                setConfirmationModalData={setConfirmationModalData} 
                                setFormData={setFormData}  
                                record={record} 
                                modelview={modelview}
                                setModelview={setModelview}
                                active={active}
                                recordData={recordData}


                            />
                        </div>

                        <RxCross2
                            size={30}
                            onClick={() => {
                                onCancel();
                                setNext(false);
                                FetchAnalysis();
                                setActivePreviewId(false);


                            }}
                            className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
                        />
 <div className="mt-5">
                           
                            <div className="space-y-4 p-9 w-full">
                              <div className="bg-white flex items-center p-8 border-b-2 border-[#6EC59D]">
                                <img
                                  className="mr-4"
                                  src={`data:image/jpeg;base64,${logo64Image}`}
                                  width={100}
                                  height={50}
                                  alt="Binary Image"
                                />
                                <p className="text-2xl font-medium flex-grow text-center">
                                  {record.Hospital.split("_")[0]}
                                </p>
                              </div>
            
                              <div className="flex justify-center ">
                                <h1 className="text-[20px] font-medium">
                                  Patient UID : {record.UID_backup}
                                </h1>
                              </div>
                              <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
              <div>
                <p><strong>Name:</strong>  <span className="ms-1">{record.Name}</span></p>
                <p><strong>Age: </strong>  <span className="ms-1">{record.Age}</span></p>
                <p><strong>Sex: </strong>  <span className="ms-1">{record.Sex}</span></p>
                <p><strong>Height: </strong> <span className="ms-1"> {record.Height} cm</span></p>
               
              </div>
              <div>
              <p><strong>Date:</strong><span className="ms-1"> {record.date?.$date ? formatDate(new Date(record.date.$date)) : 'N/A'}</span></p>

              <p><strong>Diagnosing Radiologist: </strong>
  <span className="ms-1">
    {Array.isArray(record?.Radiologist_Name)
      ? [...new Set(record.Radiologist_Name)].join(", ")
      : record.Radiologist_Name}
  </span>
</p>

              </div>
              
            </div>

            {('followUps' in record) && (
                                    <FollowUps data1={data1} />
                                )}
                           
                             <FollowReport data={data.record} AnalysisData={AnalysisData }/>

                            </div>
                          </div>
                        {/* Report 4 specific content here */}

                    </div>
                </div>
            </div>
        );
    }   else if (modelview === 'Analysis') {
      return (
        <>
        {showModal2 && (
          <div className={`${
            showModal2
              ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
              : "hidden"
          } bg-black bg-opacity-50`}>
            <div className="modal-container-outer">
              <div className="bg-[#FFFFFFE0] relative flex flex-col w-[86rem] h-[45rem] p-6 rounded-[15px] gap-x-3 modal-container-inner">
                <div className="flex justify-center">
                  <h1 className="text-[20px] font-medium"><strong>Analysis</strong></h1>
                </div>
        
                <RxCross2
                  size={30}
                  onClick={() => {
                    setShowModal2(false);
                    setwShowModal(false);
                    sethShowModal(false);
                    setActiveButton('health');
                    setbpShowModal(false);
                    settShowModal(false);
                    setspo2ShowModal(false)
                    setShowModalh(false)
                  }}
                  className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
                />
        
                <div className="flex flex-1">
                  <div className="flex flex-col flex-basis-1/5 p-4">
                  <button
                      onClick={() => handleButtonClick('health')}
                      className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                        activeButton === 'health'
                          ? 'bg-[#36AE78] text-white'
                          : 'bg-white text-black border border-black'
                      }`}
                    >
                      Health Forecast
                    </button>
                    <button
                      onClick={() => handleButtonClick('weight')}
                      className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                        activeButton === 'weight'
                          ? 'bg-[#36AE78] text-white'
                          : 'bg-white text-black border border-black'
                      }`}
                    >
                      Weight Forecast
                    </button>
                    <button
                      onClick={() => handleButtonClick('temp')}
                      className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                        activeButton === 'temp'
                          ? 'bg-[#36AE78] text-white'
                          : 'bg-white text-black border border-black'
                      }`}
                    >
                      Temp Forecast
                    </button>
                    <button
                      onClick={() => handleButtonClick('bp')}
                      className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                        activeButton === 'bp'
                          ? 'bg-[#36AE78] text-white'
                          : 'bg-white text-black border border-black'
                      }`}
                    >
                      BP Forecast
                    </button>
                    <button
                      onClick={() => handleButtonClick('spo2')}
                      className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                        activeButton === 'spo2'
                          ? 'bg-[#36AE78] text-white'
                          : 'bg-white text-black border border-black'
                      }`}
                    >
                      SPO2 Forecast
                    </button>
                  </div>
        
                  <div className="flex-1 flex flex-wrap justify-left p-4 w-[1048px]">
                  {hshowModal && (
             <HealthForecastChart xData={xData} yData={yData} />
         
           
            )}
            {wshowModal && (
          <WeightForecastChart xDataw={xDataw} yDataw={yDataw} />
          
            )}
            {tshowModal && (
            <TempForecastChart xDatah={xDatah} yDatah={yDatah} />
           
            )}
            {bpshowModal && (

<BPForecastChart xDatadb={xDatadb} yDatadb={yDatadb} xDatasb={xDatasb} yDatasb={yDatasb} />

    
          
            )}
             {spo2showModal && (
     
          <Spo2ForecastChart xDataspo2={xDataspo2} yDataspo2={yDataspo2} />
            )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}</>
      )
  } else {
        return null; // Default case if no modelview matches
    }
};

export default Aireportbox;
