import React, { useState, useEffect } from "react";
import { Checkbox, Button } from "antd";

const FilterDropdown = ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters, options, setFilter }) => {


    return (
        <div style={{ padding: 10, display: "flex", flexDirection: "column" }}>
            {options.map(option => (
                <Checkbox
                    key={option.value}
                    onChange={() => {
                        const newSelectedKeys = selectedKeys.includes(option.value)
                            ? selectedKeys.filter(key => key !== option.value)
                            : [...selectedKeys, option.value];
                        setSelectedKeys(newSelectedKeys);
                        setFilter(newSelectedKeys.length === 0 ? null : newSelectedKeys);

                    }}
                    checked={selectedKeys.includes(option.value)}
                >
                    {option.label}
                </Checkbox>
            ))}
            <div style={{ marginTop: 8 }}>
                <Button type="primary" onClick={() => { confirm(); }} size="small" style={{ marginRight: 8 }}>
                    OK
                </Button>
                <Button onClick={() => { clearFilters(); setSelectedKeys([]); setFilter([]); }} size="small">
                    Reset
                </Button>
            </div>
        </div>
    );
};

export default FilterDropdown;
