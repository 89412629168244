import React, { useEffect } from "react";
import { currentUserID } from './USER';

import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('user_id')) {
      window.location.href = '/';
    }
  }, [currentUserID, navigate]);

  // return children;
  return currentUserID ? children : null;
};

export default ProtectedRoute;
