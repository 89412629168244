import React from 'react';

const ServiceDropdown = ({ options, selected, setSelected, handleInputChange, name}) => {
  const handleSelect = (event) => {
    setSelected(event.target.value);
  };

  return (
    <select className='px-2 py-2 border border-slate-200 rounded-md focus:outline-none' name={name} value={selected} onChange={(e) => {
       handleInputChange(e);
      handleSelect(e);
    }} >
        {options.map((option) => (
        <option  key={option} value={option=='Select'? '' : option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default ServiceDropdown;