import React, { useState,useEffect } from 'react';
import { GrPrevious, GrNext } from 'react-icons/gr';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill CSS for styling
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { format } from 'date-fns'; // For better date formatting
import Plot from 'react-plotly.js'; // or wherever your Plot component is coming from
import { RxCross2 } from "react-icons/rx";
import HealthForecastChart from "./Charts/HealthChart";
import WeightForecastChart from "./Charts/WeigthChart";
import TempForecastChart from "./Charts/TempChart";
import BPForecastChart from "./Charts/BPChart";
import Spo2ForecastChart from "./Charts/Spo2Chart";
const FollowReport = ({ data = { FollowUpReports: [],followUps:[]},AnalysisData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [wshowModal, setwShowModal] = useState(false);
  const [tshowModal, settShowModal] = useState(false);
  const [bpshowModal, setbpShowModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hshowModal, sethShowModal] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [spo2showModal, setspo2ShowModal] = useState(false);

  const handleButtonClick = (button) => {
    // Update active button state
    setActiveButton(button);
    
    // Reset all modals
    setwShowModal(false);
    sethShowModal(false)
    settShowModal(false);
    setbpShowModal(false);
    setspo2ShowModal(false)
    
    // Show the selected modal
    if (button === 'weight') {
      setwShowModal(true);
    } else if (button === 'temp') {
      settShowModal(true);
    } else if (button === 'bp') {
      setbpShowModal(true);
    }
   else if (button === 'spo2') {
    setspo2ShowModal(true);
  }else if (button === 'health') {
    sethShowModal(true);
  }
  };
  // Ensure the data arrays are valid
  const reports = Array.isArray(data.FollowUpReports) ? data.FollowUpReports : [];
  
  const totalReports = reports.length;

  // Handler for Previous button
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  useEffect(() => {
    console.log("data1", AnalysisData);
}, [data]);
  // Handler for Next button
  const handleNext = () => {
    if (currentIndex < totalReports - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Handler for status input change
  const handleStatusChange = (event) => {

  };
  const follow = Array.isArray(data.followUps) ? data.followUps : [];
  const followupCount = follow.length;

  
  
  useEffect(() => {
    console.log("Current Follow-Up Data: ", AnalysisData);

  }, []);
  // Handler for Quill editor change
  const handleQuillChange = (content) => {
    const updatedReports = [...reports];
    updatedReports[currentIndex] = content;
  };
  const currentFollowUp = follow[currentIndex] || {};

  // Handle AI_Tests
  const aiTestsString = currentFollowUp.AI_Tests || '[]';
  let testsArray = [];
  try {
    testsArray = JSON.parse(aiTestsString.replace(/'/g, '"'));
  } catch (error) {
    console.error('Failed to parse AI_Tests:', error);
  }
  const formatDate = (date) => {
    try {
      return format(new Date(date), 'dd-MM-yyyy');
    } catch {
      return "Invalid Date";
    }
  };
  const overallHealthData = AnalysisData["Overall Health"];
  const overallWeightData = AnalysisData["Weight"];
  const overallTemperatureData = AnalysisData["Temperature"];
  const overallDBPData = AnalysisData["DBP"];
  const overallSBPData = AnalysisData["SBP"];
  const overallSPO2Data = AnalysisData["SPO2"];
  const formatDate1 = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const xDataspo2 = overallSPO2Data && overallSPO2Data.xCoordinate
  ? overallSPO2Data.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yDataspo2 = overallSPO2Data && overallSPO2Data.yCoordinate
  ? overallSPO2Data.yCoordinate.map(Number)
  : [];
  
  const xDatadb = overallDBPData && overallDBPData.xCoordinate
  ? overallDBPData.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yDatadb = overallDBPData && overallDBPData.yCoordinate
  ? overallDBPData.yCoordinate.map(Number)
  : [];
  
  const xDatasb = overallSBPData && overallSBPData.xCoordinate
  ? overallSBPData.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yDatasb = overallSBPData && overallSBPData.yCoordinate
  ? overallSBPData.yCoordinate.map(Number)
  : [];
  const xDatah = overallTemperatureData && overallTemperatureData.xCoordinate
  ? overallTemperatureData.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yDatah = overallTemperatureData && overallTemperatureData.yCoordinate
  ? overallTemperatureData.yCoordinate.map(Number)
  : [];
const templen=yDatah.length;

  const xDataw = overallWeightData && overallWeightData.xCoordinate
  ? overallWeightData.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yDataw = overallWeightData && overallWeightData.yCoordinate
  ? overallWeightData.yCoordinate.map(Number)
  : [];

// Convert xCoordinate (dates) and yCoordinate (numbers)v
const xData = overallHealthData && overallHealthData.xCoordinate
  ? overallHealthData.xCoordinate.map(dateString => {
      const date = new Date(dateString);
      return formatDate1(date);
    })
  : [];

const yData = overallHealthData && overallHealthData.yCoordinate
  ? overallHealthData.yCoordinate.map(Number)
  : [];

  // Handle Habits
  let habitsArray = [];
  const habitsString = currentFollowUp.Habits || '[]';
  try {
    habitsArray = JSON.parse(habitsString.replace(/'/g, '"'));
  } catch (error) {
    console.error("Error parsing Habits: ", error);
  }
  // const isEditable = currentIndex === totalReports - 1;
  const isEditable = false;

  return (<>  <div className="flex flex-col gap-y-5">
   
    {/* Pagination buttons */}
    <div className="flex justify-end">
  <button
    onClick={() => {
setShowModal(true)   
sethShowModal(true)
handleButtonClick('health') }}
    className="bg-[#36AE78] w-[100px] text-white font-semibold px-4 py-2 rounded-xl active:scale-95 active:transform transition-transform duration-300 focus:outline-none"
  >
    Analysis
  </button>
  {showModal && (
  <div className={`${
    showModal
      ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
      : "hidden"
  } bg-black bg-opacity-50`}>
    <div className="modal-container-outer">
      <div className="bg-[#FFFFFFE0] relative flex flex-col w-[86rem] h-[45rem] p-6 rounded-[15px] gap-x-3 modal-container-inner">
        <div className="flex justify-center">
          <h1 className="text-[20px] font-medium"><strong>Analysis</strong></h1>
        </div>

        <RxCross2
          size={30}
          onClick={() => {
            setShowModal(false);
            setwShowModal(false);
            sethShowModal(false);
            setActiveButton(null);
            setbpShowModal(false);
            settShowModal(false);
            setspo2ShowModal(false)
          }}
          className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
        />

        <div className="flex flex-1">
          <div className="flex flex-col flex-basis-1/5 p-4">
          <button
              onClick={() => handleButtonClick('health')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'health'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              Health Forecast
            </button>
            <button
              onClick={() => handleButtonClick('weight')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'weight'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              Weight Forecast
            </button>
            <button
              onClick={() => handleButtonClick('temp')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'temp'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              Temp Forecast
            </button>
            <button
              onClick={() => handleButtonClick('bp')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'bp'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              BP Forecast
            </button>
            <button
              onClick={() => handleButtonClick('spo2')}
              className={`w-full mb-4 text-black font-semibold px-4 py-2 rounded-xl transition-transform duration-300 focus:outline-none ${
                activeButton === 'spo2'
                  ? 'bg-[#36AE78] text-white'
                  : 'bg-white text-black border border-black'
              }`}
            >
              SPO2 Forecast
            </button>
          </div>

          <div className="flex-1 flex flex-wrap justify-left p-4 w-[1048px]">
          {hshowModal && (
             <HealthForecastChart xData={xData} yData={yData} />
          //    <Plot
          //    data={[
          //      {
          //        x: xData,
          //        y: yData,
          //        type: 'scatter',
          //        mode: 'lines+markers',
          //        name: 'red',
          //        line: { color: 'red' },
          //        marker: { color: 'red' },
          //        hovertemplate: 'Percentage: %{y:.2f}<extra></extra>', // Customize hover text here
          //      },
          //    ]}
          //    layout={{
          //      width: '1100',
          //      height: '620',
          //      title: 'Overall Health Forecast',
          //      xaxis: {
          //        title: 'Date',
          //        tickvals: xData,
          //        ticktext: xData,
          //      },
          //      yaxis: {
          //        title: 'Health Index',
          //        tickvals: [0, 1, 2, 3,4,5],
          //        ticktext: ['0', '1', '2', '3','4','5'],
          //        range: [0, 5],
          //      },
          //      legend: {
          //        x: 0.5,
          //        y: -0.2,
          //        orientation: 'h',
          //        xanchor: 'center',
          //      },
          //    }}
          //  />
           
           
            )}
            {wshowModal && (
          <WeightForecastChart xDataw={xDataw} yDataw={yDataw} />
          
            )}
            {tshowModal && (
            <TempForecastChart xDatah={xDatah} yDatah={yDatah} />
           
            )}
            {bpshowModal && (

<BPForecastChart xDatadb={xDatadb} yDatadb={yDatadb} xDatasb={xDatasb} yDatasb={yDatasb} />

    
          
            )}
             {spo2showModal && (
     
          <Spo2ForecastChart xDataspo2={xDataspo2} yDataspo2={yDataspo2} />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)}

</div>
  </div>

  <hr className=" border-[#6EC59D]" />

    <div className="flex flex-col gap-y-5">

      {/* Report display */}
      {totalReports > 0 && (
        <div className="space-y-2">
    <p><strong>Follow Up ID :</strong> {reports[currentIndex].followUpID}</p>

          <p><strong> {reports[currentIndex].ObservationReportTitle}</strong></p>
        
          <div className={`h-auto w-full rounded-xl p-3 ${isEditable ? 'bg-white border border-[#36AE78]' : 'bg-gray-100 border-gray-500'}`}>
          <div className="h-auto w-full text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3">
            {parse(DOMPurify.sanitize(reports[currentIndex].ObservationReport || ''))}
            
           </div >
          </div>
          <div className="space-y-2">
          {Array.isArray(reports[currentIndex]?.section4) && reports[currentIndex].section4.map((item, index) => (
  <div key={index} className="space-y-2">
    <p><strong>{item.title}</strong></p>
    <div
      className={`h-auto w-full rounded-xl p-3 ${isEditable ? 'bg-white border border-[#36AE78]' : 'bg-gray-100 border-gray-500'}`}
    >
      <div className="h-auto w-full text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3">
        {parse(DOMPurify.sanitize(item.content || ''))}
      </div>
    </div>
  </div>
))}

    </div>
          <div className="space-y-2">
  <h3 className="text-[16px] font-semibold">
    Report Snapshots:
  </h3>           
  <div className="flex flex-wrap gap-2">
    {reports[currentIndex].snapshots.map((snapshot, index) => (
      <div key={index} className="relative">
        <img
        src={snapshot.base64 ? snapshot.base64 : snapshot} // Conditional src assignment
        alt={`Preview ${index}`}
          className="w-[300px] h-[300px] object-cover"
        />
      </div>
    ))}
  </div>
</div>

                 
          <div className="flex flex-col items-end justify-end p-6">
                              
                                <p className="semi-bold  mt-2">
                                 {reports[currentIndex].reportingRadiologistName}
                                </p>
                              </div>
        </div>
      )}

      {/* Pagination buttons */}
      <div className="flex justify-start items-center">
        <GrPrevious
          size={30}
          onClick={handlePrevious}
          className={`cursor-pointer hover:bg-gray-400 rounded-full p-1 ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          style={{ pointerEvents: currentIndex === 0 ? 'none' : 'auto' }}
        />
        <span className="ml-2 mr-2 text-sm">{totalReports > 0 ? `${currentIndex + 1} / ${totalReports}` : 'No reports'}</span>
        <GrNext
          size={30}
          onClick={handleNext}
          className={`cursor-pointer hover:bg-gray-400 rounded-full p-1 ${currentIndex === totalReports - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          style={{ pointerEvents: currentIndex === totalReports - 1 ? 'none' : 'auto' }}
        />
      </div>
    </div></>
  );
};

export default FollowReport;
