import React, { useState, useEffect, useRef } from 'react'
import { BiChevronDown } from 'react-icons/bi';

const Dropdown = ({items, handleSidebar,doctorspeData}) => {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef();
    
    useEffect(() => {
      const handler = (e) =>{
         if((!dropdownRef.current.contains(e.target))){
        setOpen(false);
      }
      };

      document.addEventListener("mousedown", handler)

      return() =>{
        document.removeEventListener("mousedown", handler)
      }
    },)
    
    function handleOpen(){
        setOpen(prev=>!prev);
    }

  return (
    <div className='relative'>
          <button onClick={handleOpen} className="flex justify-center items-center bg-[#36AE78] rounded-xl h-9  px-2 gap-1 active:scale-95 active:transform transition-transform duration-300 focus:outline-none">
          <p className="text-[white] font-semibold text-[16px] leading-6 ">
            + Add / Update User 
          </p>
          <BiChevronDown className='text-xl text-white '/>
        </button>
        <ul style={!open ?{ transform:'scaleY(0)'}:{}} className='absolute -left-8 my-2 bg-white  rounded-lg' ref={dropdownRef}>
            {
                items.map((item, i)=>{
                    return <li key={i} onClick={()=>handleSidebar(i)} className='cursor-pointer hover:bg-slate-100 bg-white p-2 m-1 rounded-md whitespace-nowrap'>
                        {item.value}
                    </li>
                })
            }
        </ul>
    </div>
  )
}

export default Dropdown;