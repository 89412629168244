// HealthForecastChart.js
import React, { useEffect } from 'react';
import Plot from 'react-plotly.js'; // or wherever your Plot component is coming from


const Spo2ForecastChart = ({ xDataspo2, yDataspo2 }) => {
  // Use useEffect to print xData when it changes
  useEffect(() => {
    console.log('xData:', xDataspo2);
  }, [xDataspo2]); // Dependency array includes xData, so this runs whenever xData changes



  return (
    <Plot
    data={[
      {
        x: xDataspo2,
        y: yDataspo2,
        type: 'scatter',
        mode: 'lines+markers',
        name: 'SPO2',
        line: { color: 'Red' },
        marker: { color: 'Red' },
        hovertemplate: 'Percentage: %{y:.2f}%<extra></extra>', // Custom hover template showing only y-value
      },
    ]}
    layout={{
      width: 1100,
      height: 620,
      title: 'SPO2 Forecast',
      xaxis: {
        title: 'Date',
        tickvals: xDataspo2,
        ticktext: xDataspo2,
      },
      yaxis: {
        title: 'Percentage',
        tickvals: [0, 50, 100, 150, 200, 250],
        ticktext: ['0%', '50%', '100%', '150%', '200%', '250%'],
        range: [0, 250],
      },
      legend: {
        x: 0.5,
        y: -0.2,
        orientation: 'h',
        xanchor: 'center',
      },
    }}
  />
  
  );
};

export default Spo2ForecastChart;
