// HealthForecastChart.js
import React, { useEffect } from 'react';
import Plot from 'react-plotly.js'; // or wherever your Plot component is coming from


const WeightForecastChart = ({ xDataw, yDataw }) => {
  // Use useEffect to print xData when it changes
  useEffect(() => {
    console.log('xData:', xDataw);
  }, [xDataw]); // Dependency array includes xData, so this runs whenever xData changes



  return (
    <Plot
    data={[
      {
        x: xDataw,
        y: yDataw,
        type: 'scatter',
        mode: 'lines+markers',
        name: 'Health Score',
        line: { color: 'red' }, // Red color for the line
        marker: { color: 'red' }, // Red color for the markers
        hovertemplate: 'Weight: %{y:.2f} kgs<extra></extra>', // Customize hover text here
      },
    ]}
    layout={{
      width: 1100,
      height: 620,
      title: 'Weight Forecast',
      xaxis: {
        title: 'Date',
        tickmode: 'array',
        tickvals: xDataw,
        ticktext: xDataw,
        showgrid: true, // Ensure the grid is shown for better visibility
        zeroline: false, // Hide zero line if it's not needed
      },
      yaxis: {
        title: 'Weight',
        tickmode: 'array',
        tickvals: [50, 70, 90, 110, 130, 150],
        ticktext: ['50 kgs', '70 kgs', '90 kgs', '110 kgs', '130 kgs', '150 kgs'],
        // Ensure range is set to fit your data
        range: [40, 160], // Adjust range based on your data to ensure proper scaling
        showgrid: true, // Ensure the grid is shown for better visibility
        zeroline: false, // Hide zero line if it's not needed
      },
      legend: {
        x: 0.5,
        y: -0.2,
        orientation: 'h',
        xanchor: 'center',
      },
    }}
  />
  
  );
};

export default WeightForecastChart;
