import React, { useState,useEffect } from 'react';
import { GrPrevious, GrNext } from 'react-icons/gr';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill CSS for styling

const CTReportPagination = ({ data = { CT_AI_Report: [], CT_AI_Report_Status: [], CT_Scan_ID: [] } }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Ensure the data arrays are valid
  const reports = Array.isArray(data.CT_AI_Report) ? data.CT_AI_Report : [];
  const statuses = Array.isArray(data.CT_AI_Report_Status) ? data.CT_AI_Report_Status : [];
  const scanIds = Array.isArray(data.CT_Scan_ID) ? data.CT_Scan_ID : [];
  const totalReports = reports.length;

  // Handler for Previous button
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  useEffect(() => {
    console.log("data1", data);
}, [data]);
  // Handler for Next button
  const handleNext = () => {
    if (currentIndex < totalReports - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Handler for status input change
  const handleStatusChange = (event) => {
    const updatedStatuses = [...statuses];
    updatedStatuses[currentIndex] = event.target.value;
  };

  // Handler for Quill editor change
  const handleQuillChange = (content) => {
    const updatedReports = [...reports];
    updatedReports[currentIndex] = content;
  };

  // const isEditable = currentIndex === totalReports - 1;
  const isEditable = false;

  return (
    <div className="flex flex-col gap-y-5">
      {/* Report display */}
      {totalReports > 0 && (
        <div className="space-y-2">
          <p><strong>CT Scan ID:</strong> {scanIds[currentIndex].split('_')[1] || 'N/A'}</p>
          <p><strong>CT AI Report Status:</strong>
            <input
              className={`p-1 rounded-md w-auto ${isEditable ? 'bg-white border border-[#36AE78]' : 'bg-gray-100 border-gray-500'}`}
              onChange={handleStatusChange}
              type="text"
              value={statuses[currentIndex] || ''}
              readOnly={!isEditable}
            />
          </p>
          <div className={`h-auto w-full rounded-xl p-3 ${isEditable ? 'bg-white border border-[#36AE78]' : 'bg-gray-100 border-gray-500'}`}>
            <ReactQuill
              value={reports[currentIndex] || ''}
              onChange={handleQuillChange}
              theme="snow"
              className="react-quill-editor"
              readOnly={!isEditable}
            />
          </div>
        </div>
      )}

      {/* Pagination buttons */}
      <div className="flex justify-start items-center">
        <GrPrevious
          size={30}
          onClick={handlePrevious}
          className={`cursor-pointer hover:bg-gray-400 rounded-full p-1 ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          style={{ pointerEvents: currentIndex === 0 ? 'none' : 'auto' }}
        />
        <span className="ml-2 mr-2 text-sm">{totalReports > 0 ? `${currentIndex + 1} / ${totalReports}` : 'No reports'}</span>
        <GrNext
          size={30}
          onClick={handleNext}
          className={`cursor-pointer hover:bg-gray-400 rounded-full p-1 ${currentIndex === totalReports - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          style={{ pointerEvents: currentIndex === totalReports - 1 ? 'none' : 'auto' }}
        />
      </div>
    </div>
  );
};

export default CTReportPagination;
