import React from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { LiaHospital } from "react-icons/lia";
import { LiaHashtagSolid } from "react-icons/lia";
import { MdOutlineMail } from "react-icons/md";
import { GiSmartphone } from "react-icons/gi";
import { BiChevronLeft } from "react-icons/bi";
import MapComponent from "./MapComponent";

const Details = ({ openDetails, setOpenDetails }) => {
  const data = openDetails.data;
  console.log(data);

  function handleOnclick() {
    setOpenDetails({ status: false, data: {} });
  }
  const position = [51.505, -0.09]
  return (
    <>
      {openDetails.status && (
        <div
          className="fixed top-0 left-0 h-screen w-full flex justify-center items-center backdrop-blur-lg"
          onClick={() => setOpenDetails({ status: false, data: {} })}
        ></div>
      )}

      <div className="w-[640px] h-[680px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[56px] bg-[#FFFFFFE0] flex justify-center items-center">
        <div>
          <BiChevronLeft size={30} onClick={handleOnclick} className="" />
          <h1 className="text-[44px] font-semibold text-center">
            User details
          </h1>
          <div>
            <div className="flex flex-col gap-y-4 mt-9 px-[90px] ">
              <div className="flex flex-col gap-y-1 relative">
                <label className="text-[14px] font-semibold">
                  Hospital Name
                </label>
                <LiaHospital
                  size={23}
                  color="#000"
                  className="absolute bottom-2 right-3 "
                />
                <input
                  type="text"
                  value={data.Name}
                  disabled
                  className="w-[400px] h-[40px] border border-[#36AE78] rounded-xl text-[#303030] font-medium p-2 disabled:bg-white text-[14px] "
                />
              </div>
              <div className="flex flex-col gap-y-1 relative">
                <label className="text-[14px] font-semibold" htmlFor="email">
                  Registration Number
                </label>
                <LiaHashtagSolid
                  size={23}
                  color="#000"
                  className="absolute bottom-2 right-3 "
                />
                <input
                  type="text"
                  value={data.Registration_no}
                  disabled
                  className="w-[400px] h-[40px] border border-[#36AE78] rounded-xl text-[#303030] font-medium p-2 disabled:bg-white text-[14px] "
                />
              </div>

              <div className="flex flex-col gap-y-1 relative">
                <label className="text-[14px] font-semibold " htmlFor="email">
                  Email ID
                </label>
                <MdOutlineMail
                  size={23}
                  color="#000"
                  className="absolute bottom-2 right-3 "
                />
                <input
                  type="text"
                  value={data.Email}
                  disabled
                  className="w-[400px] h-[40px] border border-[#36AE78] rounded-xl text-[#303030] font-medium p-2 disabled:bg-white text-[14px] "
                />
              </div>

              <div className="flex flex-col gap-y-1 relative">
                <label className="text-[14px] font-semibold" htmlFor="email">
                  Phone Number
                </label>
                <GiSmartphone
                  size={23}
                  color="#000"
                  className="absolute bottom-2 right-3 "
                />
                <input
                  type="text"
                  value={data.Contact_Number}
                  disabled
                  className="w-[400px] h-[40px] border border-[#36AE78] rounded-xl text-[#303030] font-medium p-2 disabled:bg-white text-[14px]"
                />
              </div>              
              <MapComponent/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
