// Dropdown component (Dropdown.js)
import { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

const Dropdown = ({ title, options }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    // Handle option click here if needed
    console.log("Selected option:", option);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        className="flex items-center h-[3rem] justify-center"
        onClick={toggleDropdown}
      >
        {title} {isOpen ? <BiChevronUp size={18} /> : <BiChevronDown size={18} />}
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 mt-1 bg-white shadow-lg rounded-md z-10">
          {options.map((option, index) => (
            <div
              key={index}
              className="py-2 px-4 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
