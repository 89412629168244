import React, { useEffect, useState } from "react";
import { BiTransferAlt } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { BiChevronDown } from "react-icons/bi";
import axios from "axios";
import toast from "react-hot-toast";
import { currentUserID } from "../../Utils/USER";
import Settings from "../../Utils/Settings1";
import { v4 as uuidv4 } from "uuid";
const { transfer } = Settings;

export default function Transfer({ radiologist, reloadTable }) {
  const [showModal, setShowModal] = useState(false);
  const [fromOption, setFromOption] = useState("");
  const [toOption, setToOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [fromDropdownOpen, setFromDropdownOpen] = useState(false);
  const [toDropdownOpen, setToDropdownOpen] = useState(false);

  async function handleTransfer(item) {
    const reload = () => {
      console.log("meow");
      reloadTable();
    };
    const data = {
      USERID: currentUserID,
      FROM: fromOption,
      TO: toOption,
    };

    // New payload structure
    const payload = {
      RequestId: uuidv4(),
      Requestor: "Username",
      Messages: {
        MessageId: uuidv4(),
        MessageType: "accountStatus",
        Requesttimestamp: new Date().toISOString(),
        Body: {
          data: {
            fromRadiologist: fromOption,
            toRadiologist: toOption,
          },
        },
        Parameters: {
          UserId: currentUserID,
          UserAccessToken: "",
          APIVersion: "",
        },
        Headers: {},
      },
    };

    try {
      setLoading(true);
      const res = await axios.post(transfer, payload);
      console.log("Transfer from:", fromOption, "to:", toOption);
      setShowModal(false);
      toast.success("Transferred successfully");
      setTimeout(function () {
        // window.location.reload();
        reload();
      }, 1000);
    } catch (error) {
      toast.error("Error transferring");
      console.error("Error", error);
    } finally {
      setLoading(false); // Ensure loading is set to false regardless of success or error
    }
  }
  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="flex justify-center items-center bg-[#36AE78] rounded-xl h-9  px-2 gap-1 active:scale-95 active:transform transition-transform duration-300 focus:outline-none"
      >
        <p className="text-[white] font-semibold text-[16px] leading-6 ">
          Transfer
        </p>
        <BiTransferAlt color="white" size={20} />
      </button>
      {showModal && (
        <div className="z-40 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(0,0,0,0.6)]">
          <div className="z-50 bg-[#FFFFFFE0] p-4 w-[30rem] h-[514px] rounded-3xl flex flex-col items-center justify-center">
            <RxCross2
              onClick={() => setShowModal(false)}
              size={30}
              className="ml-[25rem] cursor-pointer hover:bg-gray-400 rounded-full p-1"
            />

            <p className="text-4xl font-semibold text-center">Transfer</p>
            <div className="w-full h-[15rem] flex flex-col items-center justify-center gap-y-[3.0rem]">
              <p className="font-medium text-sm w-full z-10">
                <div className="relative">
                  <button
                    onClick={() => setFromDropdownOpen(!fromDropdownOpen)}
                    type="button"
                    className="inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-green-500 rounded-md hover:bg-gray-50 focus:outline-none focus:border-green-300 focus:ring focus:ring-green-200 active:bg-gray-200 z-10"
                  >
                    {fromOption ? radiologist.find(r => r._id.$oid === fromOption)?.Name : "Select From"}
                    <BiChevronDown className="ml-2 h-5 w-5" />
                  </button>
                  {fromDropdownOpen && (
                    <ul
                      className="origin-top-right absolute right-0 mt-2 w-full rounded-md bg-white border-2 border-green-600 ring-opacity-5 overflow-y-auto max-h-40 z-60"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="dropdown-button"
                    >
                      {radiologist
                        ?.filter((e) => e.STATUS === false)
                        ?.map((data) => (
                          <li
                            key={data._id.$oid}
                            onClick={() => {
                              setFromOption(data._id.$oid);
                              setFromDropdownOpen(false);
                            }}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                            role="menuitem"
                          >
                            {data.Name}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </p>

              <p className="font-medium text-sm w-full z-0">
                <div className="relative">
                  <button
                    onClick={() => setToDropdownOpen(!toDropdownOpen)}
                    type="button"
                    className="inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-green-500 rounded-md hover:bg-gray-50 focus:outline-none focus:border-green-300 focus:ring focus:ring-green-200 active:bg-gray-200"
                  >
                    {toOption ? radiologist.find(r => r._id.$oid === toOption)?.Name : "Select To"}
                    <BiChevronDown className="ml-2 h-5 w-5" />
                  </button>
                  {toDropdownOpen && (
                    <ul
                      className="origin-top-right absolute right-0 mt-2 w-full rounded-md bg-white border-2 border-green-600 ring-opacity-5 overflow-y-auto max-h-40 z-60"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="dropdown-button"
                    >
                      {radiologist
                        ?.filter((e) => e.STATUS === true)
                        ?.map((data) => (
                          <li
                            key={data._id.$oid}
                            onClick={() => {
                              setToOption(data._id.$oid);
                              setToDropdownOpen(false);
                            }}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                            role="menuitem"
                          >
                            {data.Name}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </p>
            </div>

            <button
              onClick={handleTransfer}
              className="bg-[#36AE78] text-white font-semibold px-8 py-2 rounded-xl w-[450px] mt-6 pointer-cursor"
            >
              {loading ? loader : "Transfer"}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
