import React, { useEffect, useState } from 'react';
import { GrPrevious, GrNext } from 'react-icons/gr';
import { formatInTimeZone } from 'date-fns-tz';

const FollowUps = ({ data1 = { followUps: [] } }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Ensure the data arrays are valid
  const follow = Array.isArray(data1.followUps) ? data1.followUps : [];
  const followupCount = follow.length;

  useEffect(() => {
    console.log("Current Follow-Up Data: ", formatDate(currentFollowUp.date?.$date));
    
  }, [currentIndex, data1.followUps]);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < followupCount - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Format date
  const formatDate = (date) => {
    try {
      // Use 'UTC' time zone for consistent formatting
      return formatInTimeZone(new Date(date), 'UTC', 'dd-MM-yyyy');
    } catch {
      return "Invalid Date";
    }
  };

  const currentFollowUp = follow[currentIndex] || {};

  // Handle AI_Tests
  const aiTestsString = currentFollowUp.AI_Tests || '[]';
  let testsArray = [];
  try {
    testsArray = JSON.parse(aiTestsString.replace(/'/g, '"'));
  } catch (error) {
    console.error('Failed to parse AI_Tests:', error);
  }


  

  return (
    <div className="flex flex-col gap-y-5">
      <h4><strong>Follow-Up Patient Details</strong></h4>

      {/* Report display */}
      {followupCount > 0 && (
        <>
              <h3><strong>Follow Up ID :</strong> {currentFollowUp.followUpID}</h3>

        <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
          <div>
            <p><strong>Age: </strong><span className="ms-1">{currentFollowUp.Age}</span></p>
            <p><strong>Weight: </strong><span className="ms-1">{currentFollowUp.Weight} kg</span></p>
            <p><strong>SPO2: </strong><span className="ms-1">{currentFollowUp.SPO2} %</span></p>
            <p><strong>Temperature: </strong><span className="ms-1">{currentFollowUp.Temp} °C</span></p>
            <p><strong>Blood Pressure: </strong> <span className="ms-1"> {currentFollowUp.SBP || 'N/A'}/{currentFollowUp.DBP || 'N/A'}</span></p>

            <p><strong>Habits:</strong><span className="ms-1">{Array.isArray(currentFollowUp.Habits) ? currentFollowUp.Habits.join(', ') : 'No Habits available'}</span></p>
          
          </div>
          <div>
            <p><strong>Date:</strong><span className="ms-1">{formatDate(currentFollowUp.date?.$date)}</span></p>
            <p><strong>Patient Status:</strong><span className="ms-1">{currentFollowUp.AI_Disease_Diagnosis_Status}</span></p>
            <p><strong>Other Disease:</strong><span className="ms-1"> Most likely <strong>{currentFollowUp.AI_Disease_Label}</strong> with probability of <strong>{currentFollowUp.AI_Disease_confidence}</strong></span></p>
            <p className="text-sm"><strong>Tests:</strong> <span className="ms-1">{testsArray.join(', ')}</span></p>
            <p className="text-sm"><strong>Symptoms:</strong><span className="ms-1">{Array.isArray(currentFollowUp.Symptoms) ? currentFollowUp.Symptoms.join(', ') : 'No symptoms available'}</span></p>

          </div>
        </div></>
      )}
      {/* Pagination buttons */}
      {followupCount > 0 && (
        <div className="flex justify-start items-center">
          <GrPrevious
            size={30}
            onClick={handlePrevious}
            className={`cursor-pointer hover:bg-gray-400 rounded-full p-1 ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{ pointerEvents: currentIndex === 0 ? 'none' : 'auto' }}
            aria-label="Previous Follow-Up"
          />
          <span className="ml-2 mr-2 text-sm">{followupCount > 0 ? `${currentIndex + 1} / ${followupCount}` : 'No reports'}</span>
          <GrNext
            size={30}
            onClick={handleNext}
            className={`cursor-pointer hover:bg-gray-400 rounded-full p-1 ${currentIndex === followupCount - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{ pointerEvents: currentIndex === followupCount - 1 ? 'none' : 'auto' }}
            aria-label="Next Follow-Up"
          />
        </div>
      )}
    </div>
  );
};

export default FollowUps;
