import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Settings from '../../Utils/Settings1';
import { currentUserID } from '../../Utils/USER';

const { hospitalAdminDashboard } = Settings;

const useHospitalAdminData = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getCurrentTimestamp = () => {
    const now = new Date();
    const offset = 5.5 * 60 * 60 * 1000; // IST offset from UTC in milliseconds
    const istTime = new Date(now.getTime() + offset);
    return istTime.toISOString();
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        RequestId: uuidv4(),
        Messages: {
          MessageId: uuidv4(),
          MessageType: hospitalAdminDashboard.split('/').pop(),
          Requesttimestamp: getCurrentTimestamp(),
          Body: {},
          Parameters: {
            UserId: currentUserID,
            UserAccessToken: "",
            APIVersion: "",
          },
          Headers: {},
        },
      };

      const res = await axios.post(hospitalAdminDashboard, payload);
      setData(res.data.Responses[0].Body.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, reload: fetchData };
};

export default useHospitalAdminData;
