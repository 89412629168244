const link = process.env.REACT_APP_BACKEND_URL;
const login = `${link}/Scanalyst/Login`;
const userlogout = `${link}/Scanalyst/Logout`;
const resetPassword = `${link}/Scanalyst/resetPassword`;
const tokenValidator = `${link}/Scanalyst/tokenValidator`;
const setPassword = `${link}/Scanalyst/setPassword`;

const hospitalAdminDashboard = `${link}/Scanalyst/hospitalAdminDashboard`;
const technicianDashboard = `${link}/Scanalyst/technicianDashboard`;
const radiologistDashboard = `${link}/Scanalyst/radiologistDashboard`;
const consultedRadiologistDashboard = `${link}/Scanalyst/consultedRadiologistDashboard`;
const consultingRadiologistDashboard = `${link}/Scanalyst/consultingRadiologistDashboard`;
const coConsultationAssign = `${link}/Scanalyst/coConsultationAssign`;

const radiologistReport = `${link}/Scanalyst/radiologistReport`;
const consultingRadiologistReport = `${link}/Scanalyst/consultingRadiologistReport`;

const accountStatus = `${link}/Scanalyst/accountStatus`;
const userRights = `${link}/Scanalyst/userRights`;
const download = `${link}/Scanalyst/Download`;

const assign = `${link}/Scanalyst/assign`;
const RadiologistList = `${link}/Scanalyst/coconsultRadiologistList`;
const transfer = `${link}/Scanalyst/transfer`;
const analysislink = `${link}/Scanalyst/patientAnalysis`;

const superAdminDashboard = `${link}/Scanalyst/superAdminDashboard`;
const superHospitalForm = `${link}/Scanalyst/superHospitalForm`;
const superHospitalUpdateForm = `${link}/Scanalyst/superHospitalUpdateForm`;
const superTechnicianForm = `${link}/Scanalyst/superTechncianForm`;
const superDoctorForm = `${link}/Scanalyst/superDoctorForm`;
const doctorSpecialization = `${link}/Scanalyst/doctorSpecialization`;
const followup = `${link}/Scanalyst/patientFollowUps`;


export default {
  link,
  login,
  setPassword,
  hospitalAdminDashboard,
  technicianDashboard,
  radiologistDashboard,
  consultedRadiologistDashboard,
  consultingRadiologistDashboard,
  radiologistReport,
  consultingRadiologistReport,
  coConsultationAssign,
  accountStatus,
  userRights,
  resetPassword,
  tokenValidator,
  download,
  userlogout,
  assign,
  RadiologistList,
  transfer,
  superAdminDashboard,
  superHospitalForm,
  superHospitalUpdateForm,
  superTechnicianForm,
  superDoctorForm,
  doctorSpecialization,
  followup,
  analysislink
};
