import React, { useEffect } from "react";
import { BiChevronDown } from "react-icons/bi";
import { DatePicker, Space } from "antd";
import { RxCross2 } from "react-icons/rx";

const Logs = ({ openDetails, setOpenDetails }) => {
  const data = openDetails.data;

  const date = data.LOGIN_DATE_TIME ? data.LOGIN_DATE_TIME.map((e) => e.split(" ")[0]) : [];
  const login = data.LOGIN_DATE_TIME ? data.LOGIN_DATE_TIME.map((e) => e.split(" ")[1]) : [];
  const logout = data.LOGOUT_DATE_TIME ? data.LOGOUT_DATE_TIME.map((e) => e.split(" ")[1]) : [];

  console.log(date, login, logout);

  function handleOnclick() {
    setOpenDetails({ status: false, data: {} });
  }

  return (
    <>
      {openDetails.status && (
        <div
          className="fixed top-0 left-0 h-screen w-full flex justify-center items-center backdrop-blur-lg"
          onClick={() => setOpenDetails({ status: false, data: {} })}
        ></div>
      )}

      <div className="w-[640px] h-[680px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[56px] bg-[#FFFFFFE0] flex justify-center items-center">
        <div>
        <RxCross2 size={30} onClick={handleOnclick} className="relative left-[35rem] cursor-pointer hover:bg-gray-100 rounded-full p-1" />
          <h1 className="text-[44px] font-semibold text-center">View Logs</h1>
          <div>
            <div className="flex flex-col gap-y-4 mt-9 px-[90px] ">
              <div className="flex flex-col gap-y-1 relative">
                <label className="text-[14px] font-semibold text-[#626262]">
                  Views logs for:
                </label>
                <input
                  type="text"
                  value={data.Name}
                  disabled
                  className="w-full h-[40px] border border-[#36AE78] rounded-xl text-[#303030] font-medium p-2 disabled:bg-white text-[14px] "
                />
              </div>

              <div className="flex flex-col gap-y-1 relative">
                <Space direction="vertical" size={12}>
                  <DatePicker.RangePicker
                    showTime
                    className="border border-[#36AE78] w-full p-2 text-[#303030]"
                  />
                </Space>
              </div>
              <div className="overflow-y-scroll  no-scrollbar h-[20rem] rounded-lg shadow-sm border border-slate-300">
                <table className="mx-auto w-full table-auto ">
                  <thead className=" bg-[#d4ebe1] text-[#36AE78] text-sm sticky top-0">
                    <th className="py-3 px-6 text-left ">Date</th>
                    <th className="py-3 px-6 text-left ">Login</th>
                    <th className="py-3 px-6 text-left ">Logout</th>
                    <th className="py-3 px-6 text-left ">Hours</th>
                  </thead>

                  <tbody className="divide-y">
                    {date.map((dateItem, index) => (
                      <tr key={index}>
                        <td className="py-4 px-5">{dateItem}</td>
                        <td className="py-4 px-5">{login[index]}</td>
                        <td className="py-4 px-5">{logout[index]}</td>
                        <td className="py-4 px-5"></td>
                      </tr>
                    ))}
                    {/* <tr>
                      {date.map((e) => {
                       return  <td className="py-4 px-5">{e}</td>;
                      })}
                      {login.map((e) => {
                       return  <td className="py-4 px-5">{e}</td>;
                      })}
                      {logout.map((e) => {
                       return  <td className="py-4 px-5">{e}</td>;
                      })}
                      <td className="py-4 px-5"></td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logs;
