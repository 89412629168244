import React, { useState,useEffect } from 'react';
import { GrPrevious, GrNext } from 'react-icons/gr';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill CSS for styling
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
const ColdReport = ({ data = { ColdReports: []} }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Ensure the data arrays are valid
  const reports = Array.isArray(data.ColdReports) ? data.ColdReports : [];
  
  const totalReports = reports.length;

  // Handler for Previous button
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  useEffect(() => {
    console.log("data1", data);
}, [data]);
  // Handler for Next button
  const handleNext = () => {
    if (currentIndex < totalReports - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Handler for status input change
  const handleStatusChange = (event) => {

  };

  // Handler for Quill editor change
  const handleQuillChange = (content) => {
    const updatedReports = [...reports];
    updatedReports[currentIndex] = content;
  };

  // const isEditable = currentIndex === totalReports - 1;
  const isEditable = false;

  return (
    <div className="flex flex-col gap-y-5">
      {/* Report display */}
      {totalReports > 0 && (
        <div className="space-y-2">
          <p><strong> {reports[currentIndex].ObservationReportTitle}</strong></p>
        
          <div className={`h-auto w-full rounded-xl p-3 ${isEditable ? 'bg-white border border-[#36AE78]' : 'bg-gray-100 border-gray-500'}`}>
          <div className="h-auto w-full text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3">
            {parse(DOMPurify.sanitize(reports[currentIndex].ObservationReport || ''))}
            
           </div >
          </div>
          <div className="space-y-2">
          {Array.isArray(reports[currentIndex]?.section4) && reports[currentIndex].section4.map((item, index) => (
  <div key={index} className="space-y-2">
    <p><strong>{item.title}</strong></p>
    <div
      className={`h-auto w-full rounded-xl p-3 ${isEditable ? 'bg-white border border-[#36AE78]' : 'bg-gray-100 border-gray-500'}`}
    >
      <div className="h-auto w-full text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3">
        {parse(DOMPurify.sanitize(item.content || ''))}
      </div>
    </div>
  </div>
))}

    </div>
          <div className="space-y-2">
  <h3 className="text-[16px] font-semibold">
    Report Snapshots:
  </h3>           
  <div className="flex flex-wrap gap-2">
    {reports[currentIndex].snapshots.map((snapshot, index) => (
      <div key={index} className="relative">
        <img
        src={snapshot.base64 ? snapshot.base64 : snapshot} // Conditional src assignment
        alt={`Preview ${index}`}
          className="w-[300px] h-[300px] object-cover"
        />
      </div>
    ))}
  </div>
</div>

                 
          <div className="flex flex-col items-end justify-end p-6">
                              
                                <p className="semi-bold  mt-2">
                                 {reports[currentIndex].reportingRadiologistName}
                                </p>
                              </div>
        </div>
      )}

      {/* Pagination buttons */}
      <div className="flex justify-start items-center">
        <GrPrevious
          size={30}
          onClick={handlePrevious}
          className={`cursor-pointer hover:bg-gray-400 rounded-full p-1 ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          style={{ pointerEvents: currentIndex === 0 ? 'none' : 'auto' }}
        />
        <span className="ml-2 mr-2 text-sm">{totalReports > 0 ? `${currentIndex + 1} / ${totalReports}` : 'No reports'}</span>
        <GrNext
          size={30}
          onClick={handleNext}
          className={`cursor-pointer hover:bg-gray-400 rounded-full p-1 ${currentIndex === totalReports - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          style={{ pointerEvents: currentIndex === totalReports - 1 ? 'none' : 'auto' }}
        />
      </div>
    </div>
  );
};

export default ColdReport;
