import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Tabs from '../../components/HospitalAdmin/Tabs';
import Sidebar from '../../components/HospitalAdmin/Sidebar/Sidebar';
import Cards from '../../components/HospitalAdmin/Cards';
import Header from '../../components/HospitalAdmin/Header';
import SearchBar from '../../components/HospitalAdmin/SearchBar';
import { Rings } from 'react-loader-spinner';
import Settings from '../../Utils/Settings1';
import { currentUserID } from '../../Utils/USER';
import { v4 as uuidv4 } from 'uuid';
import useHospitalAdminData from './useHospitalAdminData';

const { doctorSpecialization } = Settings;

const HospitalAdmin = () => {
  const [active, setActive] = useState(0);
  const [sidebarPage, setSidebarPage] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterOption, setFilterOption] = useState([]);
  const [doctorspeData, setDoctorspeData] = useState({});

  const { data: hospitalAdminData, loading, reload: reloadTableData } = useHospitalAdminData();

  const fetchdoctorspec = async () => {
    try {
      const requestPayload = {
        RequestId: uuidv4(),
        Requestor: 'Username',
        Messages: {
          MessageId: uuidv4(),
          MessageType: doctorSpecialization.split('/').pop(),
          Timestamp: new Date().toISOString(),
          Body: {},
          Parameters: {
            UserId: currentUserID,
            UserAccessToken: '',
            APIVersion: '',
          },
          Headers: {},
        },
      };

      const res = await axios.post(doctorSpecialization, requestPayload);
      setDoctorspeData(res.data.Responses[0].Body.data);
    } catch (error) {
      console.error('Error fetching doctor specialization data:', error);
    }
  };

  useEffect(() => {
    fetchdoctorspec();
  }, []);

  return (
    <div className="h-screen px-14 pb-5 overflow-x-hidden bg-gradient-to-r from-blue-100 via-purple-100 to-red-100">
      <div className="flex">
        <Header />
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </div>
      <Sidebar
        sidebarOpen={sidebarOpen}
        sidebarPage={sidebarPage}
        setSidebarOpen={setSidebarOpen}
        hospitalAdminData={hospitalAdminData}
        reloadTable={reloadTableData}
        doctorspeData={doctorspeData}
      />
      <Tabs
        setSidebarOpen={setSidebarOpen}
        setSidebarPage={setSidebarPage}
        active={active}
        setActive={setActive}
        reloadTable={reloadTableData}
        hospitalAdminData={hospitalAdminData}
      />
      <Cards
        active={active}
        hospitalAdminData={hospitalAdminData}
        loading={loading}
        filterOption={filterOption}
        searchQuery={searchQuery}
        reloadTable={reloadTableData}
      />
    </div>
  );
};

export default HospitalAdmin;
