import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from "react-icons/fa";

const InReportDropdownButton = ({
  active,
  setModelview,
  setShowModal,
  setConfirmationModalData,
  formData,
  setFormData,
  record,
  modelview,
  recordData  ,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [aiReportCount, setAiReportCount] = useState(0); // State to track AI report count
  const dropdownRef = useRef(null);
  const [aicoldCount, setAicoldCount] = useState(null);
  const [aifollowCount, setAifollowCount] = useState(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (record && Array.isArray(record.ColdReports)) {
      setAicoldCount(record.ColdReports.length);

    }
    if (record && Array.isArray(record.FollowUpReports)) {
      setAifollowCount(record.FollowUpReports.length)

    }

    if (record && Array.isArray(record.CT_AI_Report)) {
      setAiReportCount(record.CT_AI_Report.length);
    
    }
    if (!(record && Array.isArray(record.CT_AI_Report))) {
      setAiReportCount(null);

    }
  }, [record]);

  const handleOptionClick = (modality) => {
    setModelview(modality);

    setSelectedOption(modality);
    setShowModal(true);
    setConfirmationModalData({ record, modality });
    setFormData({
      ...formData,
      Patient_uid: record._id.$oid,
    });
    setIsOpen(false);
  };

  const options = ['AI Report', 'Cold Report', 'Write Report', 'FollowUp Report'];
  const filteredOptions = active === 2 ? options.filter(option => option !== 'Write Report') : options;

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={`bg-[#ffffffe8] text-[#36AE78] border border-green-500 w-6 h-6 rounded-lg flex items-center justify-center ${isOpen ? 'border-blue-500' : ''}`}
      >
        <FaChevronDown size={12} />
      </button>

      {isOpen && (
        <div className="fixed top-192 right-30 me-5 mt-2 w-[180px] bg-[#ffffffe8] border border-green-500 rounded-lg shadow-lg z-[10000]">
          {filteredOptions.map((modality) => (
            <button
              key={modality}
              onClick={() => handleOptionClick(modality)}
              className={`w-full px-2 py-1 text-left text-[#36AE78] hover:bg-[#F1F1F1] ${selectedOption === modality ? 'bg-blue-100' : ''}`}
            >
              {modality}
              {modality === 'AI Report' && aiReportCount != null && ` (${aiReportCount})`}

              {modality === 'Cold Report' && aicoldCount != null && ` (${aicoldCount})`}
              {modality === 'FollowUp Report' && aifollowCount != null && ` (${aifollowCount})`}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default InReportDropdownButton;
