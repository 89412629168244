import React from 'react';

const SpecDropdown = ({ options, selected1, setSelected1, handleInputChange, name }) => {
  const handleChange = (event) => {
    handleInputChange(event);
    setSelected1(event.target.value);
  };

  return (
    <select
      className="px-2 py-2 border border-slate-200 rounded-md focus:outline-none"
      name={name}
      value={selected1}
      onChange={handleChange}
    >
      {options.map((option) => (
        <option key={option} value={option === 'Select' ? '' : option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default SpecDropdown;
