import React, { useState } from "react";
import { Menu, Dropdown, Button, Input } from "antd";
import './CustomDatePicker.css'
const DropdownCheckbox = ({ label, name, value, onChange, options, error }) => {
  const [visible, setVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation(); // Prevent dropdown from closing
  };

  const handleCheckboxChange = (e) => {
    onChange(e);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const menu = (
    <Menu onClick={handleMenuClick} className="max-h-60 overflow-y-auto scroller">
      <Menu.Item key="search" className="px-3 py-2">
        <Input
          placeholder="Search symptoms"
          value={searchQuery}
          onChange={handleSearch}
          className="w-full"
        />
      </Menu.Item>
      {filteredOptions.length > 0 ? (
        filteredOptions.map((option) => (
          <Menu.Item key={option}>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name={name}
                value={option}
                checked={value.includes(option)}
                onChange={handleCheckboxChange}
                className="form-checkbox custom-checkbox "  // Add custom-checkbox class here
              />
              <span className="ml-2">{option}</span>
            </label>
          </Menu.Item>
        ))
      ) : (
        <Menu.Item disabled>No symptoms found</Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className="flex flex-col gap-y-2">
      <label>{label}</label>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={visible}
        onVisibleChange={(flag) => setVisible(flag)}
      >
        <Button className="px-3 py-2 border rounded-md focus:outline-none text-left bg-white">
          Select Symptoms
        </Button>
      </Dropdown>
      {error && (
        <span className="text-red-500 text-sm">{error}</span>
      )}
    </div>
  );
};

export default DropdownCheckbox;
