import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Radiologist from "./Radiologist";
import Technician from "./Technician";
import UpdateUser from "./UpdateUser";

const Sidebar = ({
  sidebarPage,
  setSidebarOpen,
  sidebarOpen,
  hospitalAdminData,
  reloadTable,
  doctorspeData,
}) => {
  const [userData, setUserData] = useState([]);
  const reloadTableData = () => {
    console.log("hhh")
   reloadTable()
  };
  useEffect(() => {

    if(hospitalAdminData.RADIOLOGIST){
    const newArr =  [...hospitalAdminData.RADIOLOGIST,...hospitalAdminData.TECHNICIAN];
    setUserData(newArr.map(e=>{
      return {
        name: e.Name,
        id: e._id.$oid,
      }
    })
    )
  }
  }, [hospitalAdminData]);
  const reload = () => {
    console.log("meow")
    reloadTable()
 
     };
  return (
    <>
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40 "
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
      <div
        className={`z-50 fixed top-0 left-0 h-full pt-5 px-2 bg-white w-[25rem] shadow-lg ${
          !sidebarOpen ? "-translate-x-full" : ""
        } transition-all duration-700 ease-in-out`}
      >
        <div
          onClick={() => setSidebarOpen(false)}
          className="cursor-pointer bg-slate-200 p-2 w-fit rounded-full absolute right-3"
        >
          <RxCross1 />
        </div>
        {sidebarPage == 0 ? (
          <Radiologist setSidebarOpen = {setSidebarOpen} doctorspeData={doctorspeData}  reloadTable={reloadTableData}/>
        ) : sidebarPage == 1 ? (
          <Technician setSidebarOpen = {setSidebarOpen} reloadTable={reloadTableData}/>
        ) : sidebarPage == 2 ? (
          <UpdateUser userData={userData} setSidebarOpen = {setSidebarOpen} reloadTable={reloadTableData}/>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Sidebar;
