import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import link from "../../Utils/Settings";
import { currentUserID, currentUserRights } from "../../Utils/USER";
import { toast } from "react-hot-toast";
import { useSelection } from "./selectionContext";
import { useRadioFilterContext } from "./RadioFilterContext";
import { MdDelete } from "react-icons/md";
import Settings from "../../Utils/Settings1";
import { v4 as uuidv4 } from "uuid";
const { coConsultationAssign } = Settings;

const Tabs = ({
  active,
  setActive,
  radiologistData,
  reloadTable,
  coconsultRadiologistList,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [showAssginBox, setshowAssignBox] = useState(false);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [selectedName, setselectedName] = useState("Select Radiologist's Name");
  const [selectedId, setselectedId] = useState("");
  const { selectedItems } = useSelection();
  const [loading, setLoading] = useState(false);
  const { clearAllFilters, isFilterActive } = useRadioFilterContext();
  const [data, setData] = useState({ RADIOLOGISTS: [] });

  console.log("Items in selectedItem", coconsultRadiologistList);
  const tabs = [
    { name: "Patients" },
    { name: "Consulting" },
    { name: "Consulted" },
  ];
  const reload = () => {
    reloadTable();
  };

  const handleAssign = () => {
    // Show the confirmation modal
    setshowAssignBox(true);
  };
  const handleAssignClose = () => {
    setshowAssignBox(false);
  };

  const handleDropdownToggle = () => {
    setisDropdownOpen((prevOpen) => !prevOpen);
  };
  const handleRadiologistSelect = (selectedRadiologist, id) => {
    // Add your logic for handling the selected radiologist
    setselectedName(selectedRadiologist);
    setselectedId(id);
    console.log("hii");
    console.log(selectedId);

    setisDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const handleAssignConfirm = async () => {
    // Prepare the data to be sent to the backend
    const requestData = {
      Radiologist_id: selectedId,
      user_id: currentUserID,
      items: selectedItems.map((patient) => ({
        id: patient?.id?.$oid,
        name: patient.name,
      })),
    };

    // New payload structure
    const payload = {
      RequestId: uuidv4(),
      Requestor: "Username",
      Messages: {
        MessageId: uuidv4(),
        MessageType: "coConsultationAssign",
        Requesttimestamp: new Date().toISOString(), // Automatically set the current timestamp
        Body: {
          data: {
            RadiologistID: selectedId,
            PatientsID: selectedItems.map((patient) => patient?.id?.$oid),
          },
        },
        Parameters: {
          UserId: currentUserID,
          UserAccessToken: "",
          APIVersion: "",
        },
        Headers: {},
      },
    };

    try {
      setLoading(true);
      // Make a POST request to the backend server using Axios
      const response = await axios.post(coConsultationAssign, payload);

      // Check if the request was successful (status code 2xx)
      if (response.status === 200) {
        toast.success("Assigned");
        reloadTable();

        // Implement logic for handling a successful response
        console.log("Assign request successful");
        setTimeout(function () {}, 1000);
      } else {
        console.error("Request failed:", response.status, response.statusText);
      }
    } catch (error) {
      // Handle any network or Axios-related errors
      console.error("Error during Assign request:", error);
    } finally {
      setLoading(false);
    }

    // Close the assign modal after handling the confirmation
    setshowAssignBox(false);
  };
  useEffect(() => {
    console.log("radi",coconsultRadiologistList)
    if (
      coconsultRadiologistList &&
      Array.isArray(coconsultRadiologistList.coconsultationRadiologist)
    ) {
      const radiologists =
        coconsultRadiologistList.coconsultationRadiologist.map(
          (radiologist) => ({
            ...radiologist,
            _id: radiologist._id.$oid, // Ensure _id is properly extracted
          })
        );
      setData({ RADIOLOGISTS: radiologists });
    }
  }, [coconsultRadiologistList]);

  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );
  return (
    <div className="mt-2 flex items-end justify-between border rounded-2xl h-[84px] w-full shadow bg-[#FFFFFF8A]">
      <div className="flex gap-2 ml-9">
        {tabs.map((tab, index) => {
          return (
            <button
              key={index}
              onClick={() => {
                setActive(index);
              }}
              style={{ boxShadow: " 0px 0px 12px 0px rgba(0, 0, 0, 0.10)" }}
              className={`flex justify-center items-center px-10 h-9 cursor-pointer rounded-tr-lg rounded-tl-lg shadow font-semibold text-sm ${
                active === index
                  ? "bg-[#8e75d4] text-white font-semibold scale-95 transform transition-transform duration-500 focus:outline-none ease-in-out"
                  : "bg-[#E1DBFE] hover:bg-[#a698ecbd]"
              }`}
            >
              {tab.name}
            </button>
          );
        })}
        <button
          onClick={reload}
          className={`w-[40px] h-[35px]  h-9  text-[12px] font-medium xx1 p-1 flex justify-center items-center gap-x-1 me-2`}
        >
          <svg
            class="h-6 w-6  font-semibold text-[16px]"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            {" "}
            <path stroke="none" d="M0 0h24v24H0z" />{" "}
            <path d="M19.95 11a8 8 0 1 0 -.5 4m.5 5v-5h-5" />
          </svg>{" "}
        </button>
      </div>
      <div className="flex items-center justify-center gap-x-3 mr-5 mb-3">
        <button
          onClick={clearAllFilters}
          disabled={!isFilterActive}
          className=" flex justify-center items-center gap-x-1"
          style={isFilterActive ? {} : { opacity: "0.5" }}
        >
          <MdDelete size={35} />
        </button>
        {/* <button onClick={reload} className={`w-[85px] h-[32px] border border-[#303030] rounded-lg text-[12px] font-medium bg-[#FFFFFFE0] p-1 flex justify-center items-center gap-x-1 `} ><svg class="h-6 w-6 text-gray-800"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M19.95 11a8 8 0 1 0 -.5 4m.5 5v-5h-5" /></svg> </button> */}

        <div className="flex justify-center items-center gap-3">
          <button
            disabled={currentUserRights === "View"}
            onClick={handleAssign}
            className={`text-[white] font-semibold text-[16px] leading-6 rounded-xl w-44 h-9 bg-[#36AE78] ${
              currentUserRights === "View"
                ? "opacity-50 bg-[] cursor-not-allowed"
                : ""
            }`}
          >
            Co-consulting
          </button>

          {showAssginBox && (
            <div className="z-40 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(0,0,0,0.6)]">
              <div className="z-50 bg-[#FFFFFFE0] w-[520px] h-[514px] rounded-3xl flex flex-col items-center justify-center">
                <RxCross2
                  size={30}
                  onClick={handleAssignClose}
                  className="ml-[25rem] cursor-pointer hover:bg-gray-400 rounded-full p-1"
                />

                <p className="text-4xl font-semibold text-center">
                  Assign Radiologist
                </p>
                <div className="w-[450px] h-[250px]  mt-9 flex flex-col  items-center gap-y-2">
                  <p className="font-semibold mr-[220px] ">
                    Radiologist's Name
                  </p>
                  <div className="w-[90%]  relative">
                    <div className="relative  text-left">
                      <button
                        onClick={handleDropdownToggle}
                        type="button"
                        className={`inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-green-500 rounded-md hover:bg-gray-50 focus:outline-none focus:border-green-300 focus:ring focus:ring-green-200 active:bg-gray-200 `}
                        id="dropdown-button"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        {selectedName}
                        <svg
                          className="-mr-1 ml-2 h-5 w-5 transition-transform duration-300 transform ${isDropdownOpen ? 'rotate-180' : ''}"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12a1 1 0 01-.7-.29l-4-4a1 1 0 111.41-1.42L10 9.59l3.29-3.3a1 1 0 111.42 1.42l-4 4a1 1 0 01-.71.29z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>

                      {isDropdownOpen && (
                        <ul
                          className="origin-top-right absolute right-0 mt-2 w-[100%] rounded-md bg-white border-2 border-green-600 ring-opacity-5 overflow-y-auto max-h-40 scroll scrollbar-padding-[10px]"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="dropdown-button"
                        >
                          {Array.isArray(coconsultRadiologistList.RADIOLOGISTS) && (
                            <ul>
                              {coconsultRadiologistList.RADIOLOGISTS.filter(
                                (e) =>
                                  e.STATUS !== false && e._id !== currentUserID
                              ).map((ele) => (
                                <li
                                  key={ele._id}
                                  onClick={() =>
                                    handleRadiologistSelect(ele.Name, ele._id)
                                  }
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                  role="menuitem"
                                >
                                  {ele.Name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </ul>
                      )}

                      <div className="mt-4  w-[100%]">
                        <ul
                          className="origin-top-right  w-[100%] rounded-md  bg-white border-2 border-green-600  ring-opacity-5 overflow-y-auto max-h-40 scroll scrollbar-padding-[10px]"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="dropdown-button"
                        >
                          {console.log("selected item", selectedItems)}
                          {selectedItems.map((ele) => (
                            <li
                              className="flex justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                              role="menuitem"
                              key={ele.uid}
                            >
                              <span className="mr-3">{ele.uid}</span>
                              <span>{ele.name}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  onClick={handleAssignConfirm}
                  className="bg-[#36AE78] text-white font-semibold px-8 py-2 rounded-xl w-[450px] mt-6 pointer-cursor"
                >
                  {loading ? loader : "Add"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
