import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Settings from '../../Utils/Settings1';
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { PiInfoLight } from "react-icons/pi";

// import PasswordChecklist from "react-password-checklist";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { currentUserID } from "../../Utils/USER";
const { link, login, resetPassword } = Settings;

export default function Login() {
  const [formData, setFormData] = useState({
    Email: "",
    Password: "",
  });
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true); // State to disable button
  const [timer, setTimer] = useState(30); // Timer in seconds
  const [starttimer, Setstarttimer] = useState(false)
  const onClose = () => {
    setShowForgetPassword(false); // Close the Forget Password popup
  };

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // State to track password conditions
  const [passwordConditions, setPasswordConditions] = useState({
    minLength: false,
    specialChar: false,
    number: false,
    capital: false,
    lowercase: false,
  });

  useEffect(() => {
    if (timer > 0 && disableButton) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer, disableButton]);
  const handleResetPassword = async () => {
    try {
      setLoading(true);
      setDisableButton(true);
      setTimer(30); // Reret the timer to its initial value

      const payload = {
        "RequestId": generateUniqueId(),
        "Requestor": "Username",
        "Messages": {
          "MessageId": generateUniqueId(),
          "MessageType": resetPassword.split('/').pop(),
          "Timestamp": new Date().toISOString(),
          "Body": {
            "data": {
              "Email": email
            }
          },
          "Parameters": {
            "UserId": "",
            "UserAccessToken": "",
            "APIVersion": ""
          },
          "Headers": {}
        }
      };

      const response = await axios.post(
        resetPassword,
        payload,
        {
          withCredentials: true // Ensure credentials are included in the request
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setTimer(true); // Start the timer when the reset process starts
        setMessage("Reset link successfully sent to your email. Please check your inbox.");
        setError("");
        startTimer(); // Start the timer after a successful request
      } else {
        setError("An error occurred while processing your request.");
      }
    } catch (error) {
      setError(error.response?.data?.error || "Invalid Email Address");
    } finally {
      setLoading(false);
      setTimer(false); // Reset start timer flag
      // Do not enable the button here; it should be enabled after the timer ends
    }
  };


  const startTimer = () => {
    setTimeout(() => {
      setDisableButton(false);
    }, 30000);
  };

  const handleResendLink = () => {
    handleResetPassword();
  };
  const [showForgetPassword, setShowForgetPassword] = useState(false);


  const toggleForgetPassword = () => {
    setShowForgetPassword(!showForgetPassword);
  };

  useEffect(() => {
    const userId = localStorage.getItem("user_id");

    const dashboard = localStorage.getItem("dashboard");
    console.log(userId)
    if (userId) {
      if (dashboard === "0") {
        window.location.href = '/dashboard';
      } else if (dashboard === "1") {
        window.location.href = '/dashboard/radiologist';
      } else if (dashboard === "2") {
        window.location.href = '/dashboard/technician';
      } else if (dashboard === "3") {
        window.location.href = '/dashboard/hospitaladmin';
      }
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "Email") {
      setEmailError(false);
    }
    if (name === "Password") {
      setPasswordError(false);
      // Check password conditions
      const conditions = {
        minLength: value.length >= 8,
        specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value),
        number: /[0-9]+/.test(value),
        capital: /[A-Z]+/.test(value),
        lowercase: /[a-z]+/.test(value),
      };
      setPasswordConditions(conditions);
    }
  };
  const generateUniqueId = () => {
    // Function to generate a unique identifier
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (Math.random() * 16)) >> (c / 4) & 15
    ).toString(16);
  }

  async function handleLogin() {
    try {
      setLoading(true);
      const RequestId = generateUniqueId();
      const messageId = generateUniqueId();
      const requestBody = {
        RequestId: RequestId,
        Requestor: "Username", // Replace with actual username
        Messages: {
          MessageId: messageId,
          MessageType: login.split('/').pop(),
          Requesttimestamp: new Date().toISOString(), // Use ISO 8601 format
          Body: {
            data: formData, // Include form data (Email and Password)
          },
          Parameters: {
            UserId: "",
            UserAccessToken: "",
            APIVersion: "",
          },
          Headers: {},
        },
      }
      const res = await axios.post(login, requestBody);

      console.log(res);

      if (res.status === 200) {
        const responseBody = res.data.Responses[0].Body.data;
        console.log(responseBody);
        const { Dashboard, Rights, user_id } = responseBody;

        toast.success("Login successful!");

        localStorage.setItem("user_id", user_id);
        localStorage.setItem("rights", Rights);
        localStorage.setItem("dashboard", Dashboard);

        // Cookies.set("user_id", user_id, { expires: 7 }); // Uncomment if you want to use cookies

        console.log("Form submitted successfully");
        console.log(res.data);

        switch (Dashboard) {
          case "0":
            window.location.href = '/dashboard';
            break;
          case "1":
            window.location.href = '/dashboard/radiologist';
            break;
          case "2":
            window.location.href = '/dashboard/technician';
            break;
          case "3":
            window.location.href = '/dashboard/hospitaladmin';
            break;
          default:
            console.error("Unknown dashboard type");
            break;
        }
      } else {
        console.error("Failed to submit form");
        setEmailError(true);
        setPasswordError(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.status === 401) {
        toast.error("Invalid credentials!");
        setEmailError(true);
        setPasswordError(true);
      } else {
        toast.error("Something went wrong!");
        setPasswordError(true);
      }
    } finally {
      setLoading(false);
    }
  }

  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <div className="bg-gradient-to-r from-blue-100 via-purple-100 to-red-100 h-screen flex justify-center items-center">
      <div className="h-[604px] w-[450px] bg-gradient-to-r from-sky-300 via-purple-500 to-red-400">
        <div className="flex flex-col gap-y-6">
          <p className="text-[48px] font-extrabold text-[#FFFFFF] p-[60px]">
            Welcome
            <br />
            Back!
          </p>
          <div className="flex flex-col gap-y-4 p-[60px]">
            <p className="text-[36px] font-extrabold text-[#FFFFFF] ">
              Scanalyst
            </p>
            <p className="text-[16px] font-bold text-[#FFFFFF]">
              Empowering Radiology.
              <br />
              Elevating Healthcare.
              <br />
              Redefining Productivity & Performance
            </p>
          </div>
        </div>
      </div>

      {/* Input E-mail Field */}
      <div className="h-[604px] w-[682px] bg-[#FFFFFFB8] flex flex-col justify-center items-center gap-y-8">
        <h1 className="text-[44px] font-semibold">Login</h1>
        <div>
          <div class="flex flex-col gap-y-3">
            <label class="text-[14px] font-semibold" htmlFor="email">
              Email ID
            </label>
            <div className="relative">
              <input
                type="email"
                name="Email"
                value={formData.Email}
                onChange={handleInputChange}
                className={`w-[400px] h-[40px] border rounded-xl p-2 ${emailError ? "border-red-500" : "border-[#36AE78]"
                  }`}
                placeholder="Enter your email address"
              />
              <div className="absolute top-2.5 right-2 cursor-pointer">
                <span>
                  {/* Tooltip wrapper */}
                  <Tooltip id="email-tooltip" place="top" effect="solid">
                    <div>
                      Enter your hospital email ID
                      <br />
                      <span className="text-[12px]">
                        Note: In case you forget your email ID, contact your
                        hospital admin
                      </span>
                    </div>
                  </Tooltip>
                  {/* Icon triggering the tooltip */}
                  <PiInfoLight
                    className="text-[#000000] cursor-pointer"
                    data-tooltip-id="email-tooltip"
                    style={{ fontSize: ["18px"] }}
                  />
                </span>
              </div>
            </div>
            <div />
            {/* Input Password Field */}
            <div className="flex flex-col gap-y-3 relative">
              <label className="text-[14px] font-semibold" htmlFor="pass">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="Password"
                  value={formData.Password}
                  onChange={handleInputChange}
                  className={`w-[400px] h-[40px] border rounded-xl p-2 ${passwordError ? "border-red-500" : "border-[#36AE78]"
                    }`}
                  placeholder="Enter your password"
                  onFocus={() => setShowPasswordChecklist(true)}
                  onBlur={() => setShowPasswordChecklist(false)}
                />
                {/* Password Checklist Tooltip */}
                {showPasswordChecklist && (
                  <div className="absolute top-[46px] left-0 z-50 w-[400px] bg-white shadow-lg border border-gray-200 rounded p-4">
                    <ul className="list-disc pl-0.1 text-sm text-gray-800">
                      <ul className="list-disc pl-5 text-xs">
                        <li
                          style={{
                            color: passwordConditions.minLength
                              ? "#36AE78"
                              : "#FF5B5B",
                          }}
                        >
                          Password must be at least 8 characters long
                        </li>
                        <li
                          style={{
                            color: passwordConditions.specialChar
                              ? "#36AE78"
                              : "#FF5B5B",
                          }}
                        >
                          Password must contain a special character
                        </li>
                        <li
                          style={{
                            color: passwordConditions.number
                              ? "#36AE78"
                              : "#FF5B5B",
                          }}
                        >
                          Password must contain a number
                        </li>
                        <li
                          style={{
                            color: passwordConditions.capital
                              ? "#36AE78"
                              : "#FF5B5B",
                          }}
                        >
                          Password must contain a capital letter
                        </li>
                        <li
                          style={{
                            color: passwordConditions.lowercase
                              ? "#36AE78"
                              : "#FF5B5B",
                          }}
                        >
                          Password must contain a lowercase letter
                        </li>
                      </ul>
                    </ul>
                  </div>
                )}
              </div>
              {/* Toggle button for showing/hiding password */}
              <button
                type="button"
                className="absolute top-[53px] right-2 transform -translate-y-1/2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <GoEyeClosed className="text-gray-700" />
                ) : (
                  <GoEye />
                )}
              </button>
            </div>
            <button
              class="bg-[#000000e6] text-white p-2 mt-4 rounded-xl"
              onClick={handleLogin}
            >
              {loading ? loader : "Login"}
            </button>
            <Link
              to="#"
              className="text-blue-700 text-sm hover:text-blue-900 hover:underline"
              onClick={toggleForgetPassword}
            >
              Forget Password?
            </Link>
            {/* Render Forget Password Popup */}
            {showForgetPassword && (
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg w-[520px] h-[380px] ">
                  <div className="relative">
                    <h2 className="text-xl font-semibold mb-4 text-center mt-5">
                      Trouble logging in?
                    </h2>
                    <button
                      className="absolute top-0 right-0  rounded mb-5"
                      onClick={onClose}
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="0"
                        viewBox="0 0 15 15"
                        className="cursor-pointer hover:bg-gray-400 rounded-full p-1"
                        height="30"
                        width="30"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <p className="mb-4 text-gray-600 text-sm text-center mt-5">
                    Enter your email and we'll send you a link to get back into
                    your account.
                  </p>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="h-fit w-full text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3 no-scrollbar outline-none"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setMessage("");
                      setError("");
                      setTimer(0);
                      setDisableButton(false);
                    }}
                    name="email"
                    required
                    disabled={starttimer && timer > 0} // Disable the input field only when the timer is running and not yet expired
                  />
                  <div className="flex justify-between">
                    <button
                      className={`bg-[#36AE78] text-white font-semibold px-8 py-2 rounded-xl w-[450px] mt-6 pointer-cursor ${disableButton && "opacity-50 pointer-events-none"
                        }`}
                      onClick={handleResetPassword}
                      disabled={disableButton}
                    >
                      {loading ? (
                        <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
                      ) : (
                        "Send login link"
                      )}
                    </button>
                  </div>
                  {starttimer && ( // conditionally render based on starttimer state
                    <p className="text-sm text-red-600 mt-6">
                      You can resend the link in {timer} seconds.
                    </p>
                  )}
                  {message && <p className="text-blue-500 mt-2">{message}</p>}
                  {error && <p className="text-red-500 mt-2">{error}</p>}
                </div>
              </div>
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
