import React, { useState } from "react";
import axios from "axios";
import link from "../../Utils/Settings";
import { currentUserID } from "../../Utils/USER";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import DropdownCheckbox from "./Dropdownchecj"; // Ensure this path is correct
import DropdownCheckboxh from "./DropdownCheckboxh"; // Ensure this path is correct

import { RxCross2 } from 'react-icons/rx';
import { DatePicker, Form, Input, Button } from 'antd';
import moment from 'moment';
import './CustomDatePicker.css';


// Reusable Input Component
const SelectInput = ({ label, name, value, onChange, options, error }) => (
  <div className="flex flex-col gap-y-2  mt-3">
    <label htmlFor={name}>{label}</label>
    <select
      className={`px-3 py-2 border rounded-md focus:outline-none ${error && "border-red-500"}`}
      name={name}
      id={name}
      value={value}
      onChange={onChange}
    >
      <option value="">Select {label.toLowerCase()}</option>
      {options.map((option) => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
    {error && (
      <span className="text-red-500 text-sm">{error}</span>
    )}
  </div>
);

const TextInput = ({ label, name, value, onChange, error, readonly, bgColor }) => (
  <div className="flex flex-col gap-y-2  mt-3">
    <label htmlFor={name}>{label}</label>
    <input
      className={`px-3 py-2 border rounded-md focus:outline-none ${bgColor} ${error && "border-red-500"}`}
      type="text"
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      readOnly={readonly}
    />
    {error && (
      <span className="text-red-500 text-sm">{error}</span>
    )}
  </div>
);

const NumInput = ({ label, name, value, onChange, error, className,unit }) => (
  <div className="flex flex-col  mt-3">
    <label className="mb-2">{label}</label>
    <div className="flex items-center">
      <input
        className={`flex-grow px-3 py-2 border rounded-md focus:outline-none ${error && "border-red-500"} `}
        type="text"
        name={name}
        value={value}
        onChange={onChange}

      />
      <span className="p-2 bg-gray-200 border border-gray-300 rounded-r">{unit}</span>
    </div>
    {error && <span className="text-red-500">{error}</span>}
  </div>
);

const NumInput1 = ({ label, name, value, onChange, error, className,placeholder }) => (
  <div className="flex flex-col  mt-3">
    <label className="mb-2">{label}</label>
    <div className="flex items-center">
      <input
        className={`w-24 flex-grow px-3 py-2 border rounded-md focus:outline-none ${error && "border-red-500"} `}
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}

      />
   
    </div>
    {error && <span className="text-red-500">{error}</span>}
  </div>
);
const NumInput2 = ({ label, name, value, onChange, error, className,placeholder }) => (
  <div className="flex flex-col mt-3 mt-3">
 
    <div className="flex items-center mt-5">
      <input
        className={`w-24 flex-grow px-3 py-2 border rounded-md focus:outline-none ${error && "border-red-500"} `}
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}

      />
   
    </div>
    {error && <span className="text-red-500">{error}</span>}
  </div>
);
const AddPatient = ({ setSidebarOpen, reloadTable, apiData ,setShowConfirmationModal,showConfirmationModal}) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal1, setShowConfirmationModal1] = useState(false);

  const [formData, setFormData] = useState({
    patientName: "",
    patientPhoneNo: "",
    patientEmail: "",
    patientAge: "",
    patientSex: "",
    patientDOB: "",
    patientHeight: "",
    patientWeight: "",
    patientSBP: "",
    patientDBP: "",
    patientSPO2: "",
    patientTemp: "",
    patientHabits: "",
    patientOccupation: "",
    patientMedicalHistory: "",
    patientSymptoms: [],
  });

  const reload = () => {
    console.log("meow");
    reloadTable();
  };

  const [formErrors, setFormErrors] = useState({});

  const calculateAge = (dob) => {
    return moment().diff(moment(dob, 'YYYY-MM-DD'), 'years');
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    if (/^\d*\.?\d*$/.test(value)) {  // Allow digits and a single decimal point
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const validateField = (name, value) => {
    let error = '';

    if (name === 'patientPhoneNo') {
      if (!/^\d{10}$/.test(value)) {
        error = 'Phone number must be exactly 10 digits';
      }
    }

    if (name === 'patientEmail') {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        error = 'Invalid email format';
      }
    }

    return error;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    const error = validateField(name, value);
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleChange2= (event) => {
    const { name, value } = event.target;
    // Ensure the new value is a number less than 100
    if (value === "" || (Number(value) <= 100 && !isNaN(value))) {
      // Update form data
      setFormData((prevData) => ({ ...prevData, [name]: value }));
  
      // Validate field and update errors
      const error = validateField(name, value);
      setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    }
  };
  
  const handleCheckboxChange = (event) => {
    if (!event.target) {
      console.error('Event target is undefined');
      return;
    }
    const { name, value, checked } = event.target;
    setFormData((prevData) => {
      const updatedSymptoms = checked
        ? [...prevData.patientSymptoms, value]
        : prevData.patientSymptoms.filter((symptom) => symptom !== value);
      return {
        ...prevData,
        [name]: updatedSymptoms,
      };
    });
  };
  
  const handleRemoveSymptom = (index) => {
    console.log("meow close")
    setFormData((prevData) => {
      const updatedSymptoms = [...prevData.patientSymptoms];
      updatedSymptoms.splice(index, 1);
      return {
        ...prevData,
        patientSymptoms: updatedSymptoms,
      };
    });
  };
  const handleRemoveSymptom1 = (index) => {
    console.log("meow close")
    setFormData((prevData) => {
      const updatedHabit = [...prevData.patientHabits];
      updatedHabit.splice(index, 1);
      return {
        ...prevData,
        patientHabits: updatedHabit,
      };
    });
  };

  const handleSubmit = async () => {
    if (checkFields()) {
      setShowConfirmationModal1(true);
    } else {
      const firstErrorInput = document.querySelector(".border-red-500");
      if (firstErrorInput) {
        firstErrorInput.focus();
      }
      toast.error("Please fix the errors in the form");
    }
  };
  const disableFutureDates = (currentDate) => {
    // Disable future dates
    return currentDate && currentDate > moment().endOf('day');
  };
  

  
  const handleDateChange = (date, dateString) => {
    setFormData(prevData => ({
      ...prevData,
      patientDOB: date,
      patientAge: calculateAge(dateString)
    }));
  };
  const checkFields = () => {
    const errors = {};
    const requiredFields = [
      "patientName", "patientPhoneNo", "patientEmail",
      "patientAge", "patientSex",
      "patientDOB", "patientHabits", "patientOccupation",
      "patientMedicalHistory", "patientSymptoms", "patientHeight",
      "patientWeight",
      "patientSBP",
      "patientDBP",
      "patientSPO2",
      "patientTemp",
    ];

    // Phone number validation
    if (!/^\d{10}$/.test(formData.patientPhoneNo)) {
      errors.patientPhoneNo = "Phone number must be exactly 10 digits";
    }

    // Email validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.patientEmail)) {
      errors.patientEmail = "Invalid email format";
    }

    requiredFields.forEach((field) => {
      if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
        errors[field] = "This field is required";
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const getCurrentTimestamp = () => {
    const now = new Date();
    const offset = 5.5 * 60 * 60 * 1000; // IST offset from UTC in milliseconds
    const istTime = new Date(now.getTime() + offset);
    return istTime.toISOString();
  };
  const handleCheckboxChange1 = (checkboxValue, checked) => {
    console.log(`Checkbox changed: ${checkboxValue}, Checked: ${checked}`);


    setFormData(prevState => {
      const newHabits = checked
        ? [...prevState.patientHabits, checkboxValue]
        : prevState.patientHabits.filter(habits => habits !== checkboxValue);
      return {
        ...prevState,
        patientHabits: newHabits
      };
    });
  };

  
  const handleConfirmation = async () => {

    try {
      setLoading(true);
      const payload = {
        RequestId: uuidv4(),
        Messages: {
          MessageId: uuidv4(),
          MessageType: "patientRegistration",
          Requesttimestamp: getCurrentTimestamp(),
          Body: {
            "patientName": formData.patientName,
            "patientPhoneNo": formData.patientPhoneNo,
            "patientEmail": formData.patientEmail,
            "patientAge": formData.patientAge,
            "patientSex": formData.patientSex,
            "patientDOB": formData.patientDOB,
            "patientHeight":formData.patientHeight,
            "patientWeight":formData.patientWeight,
             "patientSBP":formData.patientSBP,
             "patientDBP":formData.patientDBP,

             "patientSPO2":formData.patientSPO2,
              "patientTemp":formData.patientTemp,
            "patientHabits": formData.patientHabits,
            "patientOccupation": formData.patientOccupation,
            "patientMedicalHistory": formData.patientMedicalHistory,
            "patientSymptoms": formData.patientSymptoms
          },
          Parameters: {
            UserId: currentUserID,
            UserAccessToken: "",
            APIVersion: "",
          },
          Headers: {},
        },
      };

      console.log('Payload:', JSON.stringify(payload, null, 2));

      const res = await axios.post(
        `${link}/Scanalyst/patientRegistration`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      );

      console.log('Response:', res);

      if (res.status === 200) {

        setLoading(false)
        toast.success("Submitted successfully");
        setTimeout(() => reload(), 1000);
        setShowConfirmationModal(false);

      } else {
        toast.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Error submitting form");
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
      setSidebarOpen(false);
    }
  };

  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );
  return (
<div className="flex flex-col ms-4 my-4 overflow-y-auto  pb-32 h-full scroller ">
<h1 className="text-2xl text-center mt-6 mb-6 font-bold">Add Patient Details</h1>

  {/* Two-column Layout */}
  <div className="flex flex-wrap gap-6 mb-3">
    {/* Left Column */}
    <div className="w-full md:w-[45%] pr-4">
      <TextInput
        label="Name"
        name="patientName"
        value={formData.patientName}
        onChange={handleInputChange}
        error={formErrors.patientName}
      />
      <TextInput
        label="Phone Number"
        name="patientPhoneNo"
        value={formData.patientPhoneNo}
        onChange={handleInputChange}
        error={formErrors.patientPhoneNo}
      />
      <TextInput
        label="Email"
        name="patientEmail"
        value={formData.patientEmail}
        onChange={handleInputChange}
        error={formErrors.patientEmail}
      />
      <TextInput
        className="bg-red-500"
        label="Age"
        name="patientAge"
        value={formData.patientAge}
        onChange={handleInputChange} // Not needed as it's readonly
        error={formErrors.patientAge}
        bgColor="bg-[#cdcdcdad]" // Custom background color for the Age input
        readonly
      />
    <Form layout="vertical">
      <Form.Item
        label="Date of Birth"
        validateStatus={formErrors.patientDOB ? 'error' : ''}
        help={formErrors.patientDOB}
      >
      <DatePicker
  onChange={handleDateChange}
  value={formData.patientDOB }
  className="w-full"
  popupStyle={{ transition: 'none' }} // Disable sliding animation
  transitionName=""
  disabledDate={disableFutureDates}
  // Disable hover effect
/>
      </Form.Item>
    </Form>
      <SelectInput
        label="Sex"
        name="patientSex"
        value={formData.patientSex}
        onChange={handleInputChange}
        options={["Male", "Female"]}
        error={formErrors.patientSex}
      />
      <SelectInput
        label="Occupation"
        name="patientOccupation"
        value={formData.patientOccupation}
        onChange={handleInputChange}
        options={apiData.Occupation}
        error={formErrors.patientOccupation}
      />
    </div>

    {/* Right Column */}
    <div className="w-full md:w-[45%] pl-4">

    <DropdownCheckboxh
    label="Habits"
    name="patientHabits"
    value={formData.patientHabits}
    onChange={handleCheckboxChange1}
    options={apiData.Habits}
    error={formErrors.patientHabits}
  />
   
   <div className="w-full mt-3">
        <ul className="w-full py-[3px] flex gap-x-3 px-2 overflow-x-scroll no-scrollbar">
          {(Array.isArray(formData.patientHabits) ? formData.patientHabits : []).map((habit, index) => (
            <li
              className="border border-[#36AE78] text-[#36AE78] bg-[#c1f6de] bg-opacity-15 rounded-full w-fit pl-2 pr-1 whitespace-nowrap flex items-center gap-x-1"
              key={index}
            >
              {habit.length > 15 ? habit.substring(0, 14) + '...' : habit}
              <RxCross2
                className="cursor-pointer ml-2"
                onClick={() => {
                  handleRemoveSymptom1(index);
                }}
              />
            </li>
          ))}
        </ul>
      </div>



      <SelectInput
        label="Medical History"
        name="patientMedicalHistory"
        value={formData.patientMedicalHistory}
        onChange={handleInputChange}
        options={apiData.MedicalHistory}
        error={formErrors.patientMedicalHistory}
      />

      <NumInput
        label="Height"
        name="patientHeight"
        value={formData.patientHeight}
        onChange={handleChange1} // Not needed as it's readonly
        error={formErrors.patientHeight}
        unit="cm"
      />
      <NumInput
        label="Weight"
        name="patientWeight"
        value={formData.patientWeight}
        onChange={handleChange1} // Not needed as it's readonly
        error={formErrors.patientWeight}
        unit="kg"
      />
      <div className="flex items-center space-x-2">
        <NumInput1
          label="Blood Pressure"
          name="patientSBP"
          value={formData.patientSBP}
          onChange={handleChange1} // Not needed as it's readonly
          error={formErrors.patientSBP}
          placeholder="systole"
        />
        <span className="mt-5 py-2">/</span>
        <NumInput2
          name="patientDBP"
          value={formData.patientDBP}
          onChange={handleChange1} // Not needed as it's readonly
          error={formErrors.patientDBP}
          placeholder="diastole"
        />
      </div>
      <NumInput
        label="SPO2"
        name="patientSPO2"
        value={formData.patientSPO2}
        onChange={handleChange2} // Not needed as it's readonly
        error={formErrors.patientSPO2}
        unit="%"
      />
      <NumInput
        label="Temperature"
        name="patientTemp"
        value={formData.patientTemp}
        onChange={handleChange1} // Not needed as it's readonly
        error={formErrors.patientTemp}
        unit="°C"
      />
    
      
    </div>
  </div>


  {/* Dropdown Checkbox */}
  <DropdownCheckbox
    label="Symptoms"
    name="patientSymptoms"
    value={formData.patientSymptoms}
    onChange={handleCheckboxChange}
    options={apiData.Symptoms}
    error={formErrors.patientSymptoms}
  />



      <div className="w-full mt-3">
        <ul className="w-full py-[3px] flex gap-x-3 px-2 overflow-x-scroll no-scrollbar">
          {formData.patientSymptoms.map((symptom, index) => (
            <li
              className="border border-[#36AE78] text-[#36AE78] bg-[#c1f6de] bg-opacity-15 rounded-full w-fit pl-2 pr-1 whitespace-nowrap flex items-center gap-x-1"
              key={index}
            >
              {symptom.length > 15 ? symptom.substring(0, 14) + '...' : symptom}
              <RxCross2
                className="cursor-pointer ml-2"
                onClick={() => {handleRemoveSymptom(index);
}}
              />
            </li>
          ))}
        </ul>
      </div>
      <button
        className="text-sm w-full font-medium rounded-lg text-white mt-8 px-6 py-2 bg-[#36AE78] active:scale-95 transition-transform duration-300 focus:outline-none"
        onClick={handleSubmit}
      >
        {loading ? loader : "Submit"}
      </button>
      {/* Confirmation Modal */}
      {showConfirmationModal1 && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 w-[400px] rounded-3xl">
            <p className="text-xl font-semibold mb-2 text-center">Are you sure you want to submit the form?</p>
            <div className="flex justify-center gap-4 mt-6">
              <button
                className="rounded-lg px-4 py-2 border border-red-500 text-red-500"
                onClick={() => setShowConfirmationModal(false)}
              >
                No
              </button>
              <button
                className="rounded-lg px-4 py-2 bg-[#36AE78] text-white"
                onClick={handleConfirmation}
              >
                {loading ? loader : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPatient;
