import React from 'react'
import logo from '../../assets/scan_logo.png'


const Header = () => {
  return (
    <div className='flex justify-between p-2'>
        <div className=''>
        <img src={logo} alt="logo" className="w-[12rem] h-[4rem] object-contain" />

        </div>
    </div>
    
  )
}

export default Header