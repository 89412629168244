import React, { useState, useRef, useEffect } from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';

const DownloadDropdownButton = ({ handleDownload, record, patienreport }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null); // Ref for the button

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if click is outside of it
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const patientName = record.name;
  const reports = patienreport[patientName]?.Radiologist_Report_Name || [];

  const getDropdownPosition = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownWidth = 200; // Approximate width for dropdown calculations
      const dropdownHeight = 36 * reports.length + 16; // Approximate height for dropdown calculations
  
      const rightSpace = window.innerWidth - buttonRect.right;
      const bottomSpace = window.innerHeight - buttonRect.bottom;
  
      let top = buttonRect.bottom + 8; // Default to appear below the button
      let left = buttonRect.left - 100; // Shift left by 100px
  
      // Adjust left if there's not enough space on the right
      if (rightSpace < dropdownWidth + 100) {
        left = buttonRect.right - dropdownWidth - 300; // Adjust left position if not enough space on the right
      }
  
      // Adjust top if there's not enough space at the bottom
      if (bottomSpace < dropdownHeight) {
        top = buttonRect.top - dropdownHeight - 8; // Appear above the button
      }
  
      return { top, left }; // Move dropdown 100px to the left
    }
    return { top: 0, left: 0 }; // Default positioning
  };
  

  const position = getDropdownPosition();

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className={`bg-[#ffffffe8] text-[#36AE78] border border-green-500 w-12 h-6 rounded-lg flex items-center justify-center ${isOpen ? 'border-blue-500' : ''}`}
      >
        <MdOutlineFileDownload size={20} />
      </button>

      {isOpen && (
        <div
          className="fixed bg-[#ffffffe8] border border-green-500 rounded-lg shadow-lg z-[10000]"
          style={{
            top: `${position.top}px`,
            left: `${position.left}px`,
            maxHeight: '15vh', // Max height to handle long lists
            overflowY: 'auto' // Scroll if content overflows
          }}
        >
          {reports.length > 0 ? (
            reports.map((report, index) => (
              <div key={index} className="relative group">
                <button
                  onClick={() => handleDownload(record, report)}
                  className="w-full px-2 py-1 text-left text-[#36AE78] hover:bg-[#F1F1F1] flex items-center justify-center"
                >
                  {report}
                </button>
              </div>
            ))
          ) : (
            <div className="w-full px-2 py-1 text-left text-[#36AE78]">
              No reports available
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DownloadDropdownButton;
