import React, { createContext, useContext, useState } from "react";

const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (uid, name, id) => {
        console.log(id,"***")
        const isSelected = selectedItems.some((item) => item.id.$oid === id.$oid);

        if (isSelected) {
            // If already selected, remove it from the array
            setSelectedItems((prevItems) =>
                prevItems.filter((item) => item.id.$oid !== id.$oid)
            );
        } else {
            // If not selected, add it to the array
            setSelectedItems((prevItems) => [...selectedItems, { uid, name, id }]);
        }
    };

    return (
        <SelectionContext.Provider value={{ selectedItems, handleCheckboxChange }}>
            {children}
        </SelectionContext.Provider>
    );
};

export const useSelection = () => {
    const context = useContext(SelectionContext);
    if (!context) {
        throw new Error("useSelection must be used within a SelectionProvider");
    }
    return context;
};
