import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { BiChevronUp } from "react-icons/bi";


export default function Filters({ setFilterOption, filterOption, superAdminData }) {
  const [activeDropdown, setActiveDropdown] = useState(-1);
  const [checkboxes, setCheckboxes] = useState({});
  
  const filterData = [
    {
      name: "Hospital name",
      // data: superAdminData.HOSPITAL.map(HOSPITAL => HOSPITAL.Name),
      data: superAdminData && superAdminData.HOSPITAL
  ? superAdminData.HOSPITAL?.map(HOSPITAL => HOSPITAL.Name)
  : [],

    },
    {
      name: "City",
      data: ["Pune", "Bengaluru", "Mumbai"],
    },
    {
      name: "Report Status",
      data: ["Done", "Pending"],
    },
    {
      name: "AI Status",
      data: ["Normal", "Abnormal"],
    }
  ];

  const toggleDropdown = (indexP) => { 
    setActiveDropdown(activeDropdown === indexP ? -1 : indexP);
  };

  const closeDropdown = () => {
    setActiveDropdown(-1);
  };

  const handleCheckbox = (event) => {
    const {name, value, checked} = event.target; 
    setCheckboxes({...checkboxes, [name]:checked})
    if (!filterOption.includes(name)) {
      setFilterOption((prev) => [...prev, name]);
    } else {
      const index = filterOption.indexOf(name);
      const newArr = [...filterOption];
      newArr.splice(index, 1);
      setFilterOption(newArr);
    }
  };

  const clearFilters = () =>{
    setCheckboxes({});
    setFilterOption([]);
  }


  return (
    <div className="mt-5 border rounded-[20px] w-[214px] h-[75vh] shadow bg-[#FFFFFF8A] p-5">
      {/* heading */}
      <div className="flex justify-between">
        <p className="font-semibold text-[20px] leading-6">Filters</p>
        <p className="cursor-pointer font-[500] text-[14px] leading-[21px] underline" onClick={clearFilters}>
          Clear All
        </p> 
      </div>

      {/* Dropdown */}
      <div className="mt-6 flex flex-col items-center gap-2 ">
        {filterData?.map((e, indexP) => {
          return (
            <React.Fragment key={indexP}> 
              <div
              
                onClick={() => toggleDropdown(indexP)}
                className="w-[186px] h-[28px] font-medium border border-#DFF2E9 rounded-xl bg-white flex"
              >
                <div className=" w-full p-2 flex items-center text-[12px] leading-[14.4px] justify-between">
                  {e.name}
                  {activeDropdown != indexP ? (
                    <BiChevronDown size={20} />
                  ) : (
                    <BiChevronUp size={20} onClick={closeDropdown} />
                  )}
                </div>
              </div>
              {e.data?.length > 0 && (
                <div
                  className={`bg-white border border-[#DFF2E9] rounded-xl px-3 py-2 w-[186px] space-y-2 ${
                    activeDropdown != indexP ? "scale-y-0 hidden" : ""
                  }`}
                >
                  {e.data?.map((item, indexC) => {
                    return (
                      <div key={indexC} className="flex gap-x-2 items-center">
                        <input
                          type="checkbox"
                          name={item} 
                          value={item}
                          checked={!!checkboxes[item]} 
                          onChange={(e) => handleCheckbox(e)} 
                        />
                        <span className="text-[12px]">
                          {item?.length > 15
                            ? item.substring(0, 15) + "..."
                            : item}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
