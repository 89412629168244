import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/SuperAdmin/Header";
import Login from "./pages/Common/Login";
import Unauthorized from "./pages/Common/Unauthorized";
import RadiolostRole from "./pages/Radiologist/RadiologistRole";
import TechnicianRole from "./pages/Technician/TechnicianRole";
import HospitalAdmin from "./pages/HospitalAdmin/HospitalAdmin";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./Utils/ProtectedRoute";
import SuperAdminDashboard from "./pages/SuperAdmin/SuperAdminDashboard";
import { FilterProvider } from "./components/Technician/FilterContext";
import { RadioFilterProvider } from "./components/Radiologist/RadioFilterContext";
import ChangePasswordLink from "./pages/Common/ChangePasswordLink"; 

function App() {
  return (
    <>
      <Router>
        <Toaster />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <SuperAdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/radiologist"
            element={
              <ProtectedRoute>
                <RadioFilterProvider>
                  <RadiolostRole />
                </RadioFilterProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/technician"
            element={
              <ProtectedRoute>
                <FilterProvider>
                  <TechnicianRole />
                </FilterProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/hospitaladmin"
            element={
              <ProtectedRoute>
                <HospitalAdmin />
              </ProtectedRoute>
            }
          />

          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/reset-password/:userId/:resetToken" element={<ChangePasswordLink />} />

          <Route
            path="*"
            element={<div className="text-center">404 Page not found</div>}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
