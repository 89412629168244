import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { PiCloudArrowDown } from "react-icons/pi";
import ColdReport from "./ColdReport";
import CTReportPagination from "./CT_Reportpages";
import DXReportPagination from "./DxReportpages";
import logo1 from "../../assets/logo.png";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import FollowUps from "./Followupsec";
import InReportDropdownButton from "./Insidedropdown";
import ReactQuill from "react-quill";
import { FaPlus } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import { currentUserID, currentUserRights } from "../../Utils/USER";
import { Rings } from "react-loader-spinner";
import { toast } from "react-hot-toast";
import axios from "axios";
import Settings from "../../Utils/Settings1";
import FollowReport from "./FollowReport";
import { RxCross1 } from "react-icons/rx";
import Followup from "./Followup";
import { formatInTimeZone } from 'date-fns-tz';

const { link, consultedRadiologistDashboard, consultingRadiologistReport, radiologistReport ,download} = Settings;

const Aireportbox = ({  technicianData,  reloadTable, recordData,setModelview, showModal,apiData, data, onConfirm, onCancel, setShowModal, tableData, setConfirmationModalData, setTableData, modelview }) => {
    const [next, setNext] = useState(false);
    const [sections, setSections] = useState(null);
    const [preview, setPreview] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [error, setError] = useState('');
    const fileToURL = (file) => URL.createObjectURL(file);
    const [loading, setLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [btnshow, setBtnshow] = useState(false);

    const [formData, setFormData] = useState({
        user_id: currentUserID,
        Scans: [],
      });
      let dk=""
      const handleAddPatientClick = () => {
        setSidebarOpen(true);
        setShowConfirmationModal(true);
      };
    
//  const [base, setbase] = useState("");
//   const [logo, setlogo] = useState("");
  const [pdf, setpdf] = useState("");
  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );
    const [data1, setdata1] = useState([]);
    const [formData1, setFormData1] = useState({
        reportTitle: "",
        reportDescription: "",
        reportimgs:[]
      
      });
      const reload = () => {
        console.log("meow")
        reloadTable()
    
      };
  
    
 
    
      const validFileTypes = ['image/png', 'image/jpeg'];

      const isValidFileType = (file) => validFileTypes.includes(file.type);
    
      const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = Array.from(e.dataTransfer.files);
    
        validateAndSetFiles(files);
      };
  
    
      const validateAndSetFiles = (files) => {
        const validFiles = files.filter(isValidFileType);
        const invalidFiles = files.filter(file => !isValidFileType(file));
    
        if (invalidFiles.length > 0) {
          setError('Please upload only PNG or JPEG images.');
        } else {
          setError(null);
        }
    
        setFormData1((prevState) => ({
          ...prevState,
          reportimgs: [...prevState.reportimgs, ...validFiles]
        }));
      };
    
    
  
    
     // function to render and display styling from quill
  function renderHTML(htmlString) {
    if (!htmlString || typeof htmlString !== 'string') {
      return null;
    }

    const tagsToReplace = {
      '<li>': '<li class="ml-2">',
      '<h1>': '<h1 class="text-2xl font-bold">',
      '<h2>': '<h2 class="text-xl font-semibold">',
      '<h3>': '<h3 class="text-lg font-semibold">',
      '<h4>': '<h4 class="text-base font-semibold">',
      '<h5>': '<h5 class="text-sm font-semibold">',
      '<h6>': '<h6 class="text-xs font-semibold">',
      '<ul>': '<ul class="list-disc list-inside">',
      '<ol>': '<ol class="list-decimal list-inside">',
    };

    const replacedHTML = htmlString.replace(
      /(<li>|<h1>|<h2>|<h3>|<h4>|<h5>|<h6>|<ul>|<ol>)/g,
      (tag) => tagsToReplace[tag]
    );

    return <div dangerouslySetInnerHTML={{ __html: replacedHTML }} />;
  }
  
    // Safely check if data and data.record exist
    const record = data?.record || {};
    const habitsArray = record.Habits
        ? (typeof record.Habits === 'string'
            ? record.Habits.split(',').map(item => item.trim())
            : record.Habits)
        : [];
        const addSection = () => {
            if (sections === null) {
              setSections([{ title: "", content: "" }]);
            } else {
              setSections([...sections, { title: "", content: "" }]);
            }
          };
         const removeSection = (index) => {
            const updatedSections = [...sections];
            updatedSections.splice(index, 1);
            setSections(updatedSections);
          };
          // Function to handle changes in section heading or content
          const handleSectionChange = (index, key, value) => {
            const updatedSections = [...sections];
            updatedSections[index][key] = value;
            setSections(updatedSections);
          };
          const handleQuillChange2 = (index, content) => {
            const updatedSections = [...sections];
            updatedSections[index].content = content;
            setSections(updatedSections);
          };
          const follow = Array.isArray(record.followUps) ? record.followUps : [];
          const followupCount = follow.length-1;
          // Handler for ReactQuill change
          const handleDescriptionChange = (content) => {
            console.log("foo",followupCount)
            setFormData1({
              ...formData1,
              reportDescription: content,
            });
          };
      

          const formatDate = (date) => {
            try {
              // Use 'UTC' time zone for consistent formatting
              return formatInTimeZone(new Date(date), 'UTC', 'dd-MM-yyyy');
            } catch {
              return "Invalid Date";
            }
          };
    function reset() {
        setFormData({
          user_id: currentUserID,
          Scans: [],
        });}
    async function handleSubmit1() {
      setLoading(true)
        const formDataToSend = new FormData();
        formDataToSend.append("user_id", currentUserID);
        formDataToSend.append("patientRegUID", data.record._id.$oid);
      
        if (!formData.Scans || formData.Scans.length === 0) {
          toast.error("No files selected");
          return;
        }
      
        // Validate files
        for (let i = 0; i < formData.Scans.length; i++) {
          const file = formData.Scans[i];
          const fileName = file.name.toLowerCase();
          const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
      
          if (fileExtension !== "dcm" && fileExtension !== "dicom") {
            toast.error("Please select only DICOM files.");
            return;
          }
          formDataToSend.append("Scans", file);
        }
      
        const loadingToastId = toast.loading("Submitting...", { position: "top-center" });
      
        try {
          const response = await axios.post(
            `${link}/Scanalyst/Upload_Files_Processors`,
            formDataToSend,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
      
          // Log the response for debugging
          console.log("Response:", response);
      
          if (response.status === 200 || response.status === 201) {
            setLoading(false);
            toast.success("Form submitted successfully.", { id: loadingToastId });
            setTimeout(() => reload(), 1000);
            setShowModal(false);
//
            // reset();
          } else {
            toast.error("Submission failed. Please try again.", { id: loadingToastId });
          }
        } catch (error) {
          // Log error details
          console.error("Error occurred:", error.response || error);
          toast.error("Error submitting form.", { id: loadingToastId });
        } finally {
          setLoading(false);
        }
      }
      const handleRemoveAllFiles = () => {
        // Check if formData.Scans is an array before trying to modify it
        if (Array.isArray(formData.Scans)) {
            // Set Scans to an empty array
            setFormData({ ...formData, Scans: [] });
            setBtnshow(false); // Hide button when files are cleared

        } else {
            console.error('formData.Scans is not an array');
            // Optionally reset Scans to an empty array
            setFormData({ ...formData, Scans: [] });
        }
    };
    const handleRemoveFile1 = (index) => {
      if (index < 0) {
        console.error('Index must be greater than or equal to 0');
        return;
      }
  
      // Check if formData.Scans is an array before trying to modify it
      if (Array.isArray(formData.Scans)) {
        // Create a copy of the Scans array with the file removed
        const updatedScans = [...formData.Scans];
        updatedScans.splice(index, 1);
        setFormData(prevFormData => ({
          ...prevFormData,
          Scans: updatedScans
        }));
        // Update btnshow based on the updated number of files
        setBtnshow(updatedScans.length > 0);
      } else {
        console.error('formData.Scans is not an array');
        // Optionally reset Scans to an empty array
        setFormData(prevFormData => ({
          ...prevFormData,
          Scans: []
        }));
        setBtnshow(false); // Hide button if no files
      }
    };
    
    const handleFileInputChange = (event) => {
      const files = Array.from(event.target.files);
      setFormData(prevFormData => ({
        ...prevFormData,
        Scans: [...prevFormData.Scans, ...files]
      }));
      setBtnshow(files.length > 0);
      // Reset the file input value to allow re-uploading the same file
      event.target.value = null;
    };
    async function handleSubmit() {
      try {
        let followUpID;
        if(followupCount=="-1"){
          const followUpID =  null;

        }
        else{
          followUpID = record.followUps[followupCount]?.followUpID || null;

        }
          const visitID = followUpID ? null : record.visitID || '';
  
          // Convert images to base64
  
          const data = {
              RequestId: uuidv4(),
              Messages: {
                  MessageId: uuidv4(),
                  MessageType: "ConsultingRadiologistReport",
                  Parameters: {
                      UserId: currentUserID,
                  },
                  Body: {
                      data: {
                          section1: {
                              UID_backup: record.UID_backup,
                              Name: record.Name,
                              Age: record.Age,
                              Sex: record.Sex,
                              Height: record.Height,
                              Weight: record.Weight,
                              SPO2: record.SPO2,
                              SBP: record.SBP,
                              DBP: record.DBP,
                              Temp: record.Temp,
                              MedicalHistory: record.MedicalHistory,
                              Radiologist_Name: record.Radiologist_Name,
                              Assigned_Radiologist_Name: record.Assigned_Radiologist_Name,
                              Date: record.date ? record.date.$date : '',
                              Symptoms: record.Symptoms,
                              AI_Disease_Diagnosis_Status: record.AI_Disease_Diagnosis_Status,
                              AI_Disease_Label: record.AI_Disease_Label,
                              AI_Disease_confidence: record.AI_Disease_confidence,
                              AI_Tests: record.AI_Tests,
                          },
                          section2: {
                              Followup: record.followUps,
                          },
                          section3: {
                              Report_Title: formData1.reportTitle || [],
                              Report_Description: formData1.reportDescription || []
                          },
                          section4: sections || "",
                          patientID: record.id ? record.id.$oid : '',
                          followUpID: followUpID,
                          visitID: visitID,
                          section5: { 
                            //   snapshots: imgsBase64 || [], // Use base64-encoded images here
                          },
                      }
                  }
              }
          };
  
          const endPoint =  consultingRadiologistReport;
          const res = await axios.post(endPoint, data);
  
          if (res.status === 200) {
              toast.success("Form submitted");
              reload();
          }
      } catch (error) {
          console.log(error);
          toast.error("Form not submitted");
      }
  }
  
  
    
    const testsArray = record.AI_Tests
        ? JSON.parse(record.AI_Tests.replace(/'/g, '"'))
        : [];

    useEffect(() => {
        console.log("FollowUpReports is ",record);
        // console.log("FollowUpReports is undefined or empty",technicianData.Logo);

      if (record && record.FollowUpReports && record.FollowUpReports.length > 0) {
        const base64String = record.FollowUpReports[0].HashedPatientUID.$binary.base64;
        console.log("datapdf", base64String);
        setpdf(base64String)
    } else {
        console.log("FollowUpReports is undefined or empty");
    }
            setdata1(data.record);
        // setlogo(technicianData.Logo.$binary.base64);
        // setbase(technicianData.Signature.$binary.base64);
        // setpdf(record.FollowUpReports[0].HashedPatientUID.$binary.base64)
        console.log("record", data1);

    }, [data]);
    const handleTitleChange = (event) => {
        console.log('Title change event:', event.target.value);
        setFormData1((prevState) => ({
          ...prevState,
          reportTitle: event.target.value,
        }));
      };
    
    if (modelview === 'Add Details') {
        return (
            <div className={`${
                showModal
                    ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
                    : "hidden"
            } bg-black bg-opacity-50`}>
                <div className="modal-container-outer">
                    <div className="bg-[#FFFFFFE0] relative flex flex-col justify-start w-[81rem] h-[45rem] p-6 rounded-[15px] gap-x-3 modal-container-inner">
                        <div className="absolute top-6 left-6 flex items-center space-x-4">
                            <h2 className="text-xl font-bold">AI Reports</h2>
                            <InReportDropdownButton 
                                setShowModal={setShowModal} 
                                tableData={tableData} 
                                setConfirmationModalData={setConfirmationModalData} 
                                setTableData={setTableData}  
                                record={record} 
                                modelview={modelview}
                                setModelview={setModelview}
                                recordData={recordData}

                            />
                        </div>

                        <RxCross2
                            size={30}
                            onClick={() => {
                                onCancel();
                                setNext(false);
                            }}
                            className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
                        />

                        <div className="flex flex-wrap justify-center gap-x-5 gap-y-4 mt-[50px]">
                            <div className="flex flex-col justify-start h-full gap-y-4 w-full">
                            
            
                            <div className="flex justify-center ">
                              <h1 className="text-[20px] font-medium">
                                Patient UID : {record.UID_backup}
                              </h1>
                            </div>
                                <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
                                    <div>
                                        <p><strong>Name:</strong> <span className="ms-1">{record.Name || 'N/A'}</span></p>
                                        <p><strong>Age: </strong> <span className="ms-1">{record.Age || 'N/A'}</span></p>
                                        <p><strong>Sex: </strong> <span className="ms-1">{record.Sex || 'N/A'}</span></p>
                                        <p><strong>Height: </strong> <span className="ms-1"> {record.Height || 'N/A'} cm</span></p>
                                        <p><strong>Weight: </strong> <span className="ms-1"> {record.Weight || 'N/A'} kg</span></p>
                                        <p><strong>SPO2: </strong> <span className="ms-1"> {record.SPO2 || 'N/A'} %</span></p>
                                        <p><strong>Blood Pressure: </strong> <span className="ms-1"> {record.SBP || 'N/A'}/{record.DBP || 'N/A'}</span></p>
                                        <p><strong>Temperature: </strong> <span className="ms-1"> {record.Temp || 'N/A'} °C</span></p>
                                        <p><strong>Habits:</strong> <span className="ms-1"> {habitsArray.length > 0 ? habitsArray.join(', ') : 'N/A'}</span></p>
                                        <p><strong>Symptoms:</strong> <span className="ms-1">{record.Symptoms?.length > 0 ? record.Symptoms.join(', ') : 'N/A'}</span></p>
                                    </div>
                                    <div>
                                        <p><strong>Date:</strong><span className="ms-1"> {record.date?.$date ? formatDate(new Date(record.date.$date)) : 'N/A'}</span></p>
                                        <p><strong>Diagnosing Radiologist: </strong>
  <span className="ms-1">
    {Array.isArray(record?.Radiologist_Name)
      ? [...new Set(record.Radiologist_Name)].join(", ")
      : record.Radiologist_Name}
  </span>
</p>                                        <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{record.Assigned_Radiologist_Name || 'N/A'}</span></p>
                                        <p><strong>Patient Status:</strong> <span className="ms-1">{record.AI_Disease_Diagnosis_Status || 'N/A'}</span></p>
                                        <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{record.AI_Disease_Label || 'N/A'}</strong> with probability of <strong>{record.AI_Disease_confidence || 'N/A'}</strong></span></p>
                                        {('MedicalHistory' in record) && (
                                            <p><strong>Medical History:</strong> <span className="ms-1"> {record.MedicalHistory || 'N/A'}</span></p>
                                        )}
                                        <p className="text-sm"><strong>Tests:</strong> <span className="ms-1">{testsArray.length > 0 ? testsArray.join(', ') : 'N/A'}</span></p>
                                    </div>
                                </div>                               

                                <div className="flex items-center justify-between"></div>
                                <div className="flex items-center justify-between">
            <button
        onClick={handleAddPatientClick}
        className={`flex justify-center w-[130px] items-center bg-[#36AE78] rounded-xl h-9  px-6 gap-1 active:scale-95 active:transform transition-transform duration-300 focus:outline-none ${
          currentUserRights === "View" ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <p className="text-[white] font-semibold text-[16px] leading-6 flex items-center gap-x-2">
Follow up        </p>
      </button>
  
      {showConfirmationModal && (
  <div className="fixed top-0 inset-0 flex items-center justify-center h-full bg-black bg-opacity-50 z-40 backdrop-blur-lg">
    <div className={`z-50 fixed top-0 left-0 h-full pt-5 px-2 bg-white w-[50%] shadow-lg ${!sidebarOpen ? "-translate-x-full" : ""} transition-all duration-700 ease-in-out`}>
      <div onClick={() => { setSidebarOpen(false); setShowConfirmationModal(false); }} className="cursor-pointer bg-slate-200 p-2 w-fit rounded-full absolute right-3">
        <RxCross1 />
      </div>
      <Followup setSidebarOpen={setSidebarOpen} data={data} setShowConfirmationModal={setShowConfirmationModal} showConfirmationModal={showConfirmationModal}  technicianData={technicianData} reloadTable={reloadTable} apiData={apiData}  />
    </div>
  </div>
)}
  <p className="text-sm">{dk}</p>
  <div className="flex items-center gap-x-4 ml-auto">
    <div className="relative flex items-center border border-[#36AE78] border-dashed p-2 rounded-md">
      <input
        name="Scans"
        type="file"
        className="hidden"
        id="file-input"
        webkitdirectory=""
        directory=""
        multiple
        onChange={handleFileInputChange}
      />
      <label
        htmlFor="file-input"
        className="flex items-center justify-center text-blue-600 hover:underline cursor-pointer"
      >
        <PiCloudArrowDown
          className={`text-2xl font-normal ${
            isDragging ? "text-[#36AE78]" : "text-slate-400"
          }`}
        />
        <span className="text-slate-400 ml-2">Browse (Add Scan)</span>
      </label>
    </div> {btnshow && (
        <button 
          className="btn cursor-pointer ml-2 px-2 py-1 border border-black rounded-xl"
          onClick={handleRemoveAllFiles}
        >
          Clear all
        </button>
      )}
    <button
      onClick={handleSubmit1}
      className="bg-[#36AE78] text-white font-semibold px-4 py-2 rounded-xl active:scale-95 active:transform transition-transform duration-300 focus:outline-none"
    >
      {loading ? loader : "Add"}
    </button>
  </div>
</div>

          
            <div className="w-full">
              <ul className="w-full py-[3px] flex gap-x-3 px-2 overflow-x-scroll no-scrollbar">
                {formData.Scans.map((file, index) => (
                  <li
                    className="border border-[#36AE78] text-[#36AE78] bg-[#c1f6de] bg-opacity-15 rounded-full w-fit pl-2 pr-1 whitespace-nowrap flex items-center gap-x-1"
                    key={index}
                  >
                    {file.name.length > 15
                      ? file.name.substring(0, 14) + "..."
                      : file.name}
                     <RxCross2 
                      className="cursor-pointer ml-2"
                      onClick={()=> handleRemoveFile1(index)}
                    />
                  </li>
                ))}
              </ul>
              <p className="text-left text-slate-500 text-sm pl-3 pt-2">
                {formData.Scans.length > 0 ? "Files: " + formData.Scans.length : ""}
              </p>
            </div>
                                {('followUps' in record) && (
                                    <FollowUps data={data.record} />
                                )}
                                <hr className="border-[#36AE78]" />
                                {('CT_AI_Report_Status' in record) && (
                                    <CTReportPagination data={data.record} />
                                )}
                                <div className="flex flex-col gap-y-5">
                                    <div className="space-y-2">
                                        {('CXR_AI_Report' in record) && (
                                            <DXReportPagination data={data.record} />
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col items-end justify-end p-6">
                                    <img
                                        src={logo1}
                                        alt="logo"
                                        className="w-[7rem] h-[6rem] object-contain"
                                    />
                                </div>
                                <div className="flex justify-end items-end p-1">
                                    <GrPrevious
                                        size={40}
                                        onClick={() => setNext(false)}
                                        className="cursor-pointer hover:bg-gray-400 rounded-full p-2 right-[45rem]"
                                    />
                                    <GrNext
                                        size={40}
                                        onClick={() => setNext(true)}
                                        className="cursor-pointer hover:bg-gray-400 rounded-full p-2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (modelview === 'Cold Report') {
        return (
            <div className={`${
                showModal
                    ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
                    : "hidden"
            } bg-black bg-opacity-50`}>
                <div className="modal-container-outer">
                    <div className="bg-[#FFFFFFE0] relative flex flex-col justify-start w-[81rem] h-[45rem] p-6 rounded-[40px] gap-x-3 modal-container-inner">
                        <div className="absolute top-6 left-6 flex items-center space-x-4">
                            <h2 className="text-xl font-bold">Cold Reports</h2>
                            <InReportDropdownButton 
                                setShowModal={setShowModal} 
                                tableData={tableData} 
                                setConfirmationModalData={setConfirmationModalData} 
                                setTableData={setTableData}  
                                record={record} 
                                modelview={modelview}
                                setModelview={setModelview}
                                recordData={recordData}

                            />
                        </div>

                        <RxCross2
                            size={30}
                            onClick={() => {
                                onCancel();
                                setNext(false);
                            }}
                            className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
                        />
                        <div className="mt-5">
                           
                            <div className="space-y-4 p-9 w-full">
                            
            
                              <div className="flex justify-center ">
                                <h1 className="text-[20px] font-medium">
                                  Patient UID : {record.UID_backup}
                                </h1>
                              </div>
                              <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
                                    <div>
                                        <p><strong>Name:</strong> <span className="ms-1">{record.Name || 'N/A'}</span></p>
                                        <p><strong>Age: </strong> <span className="ms-1">{record.Age || 'N/A'}</span></p>
                                        <p><strong>Sex: </strong> <span className="ms-1">{record.Sex || 'N/A'}</span></p>
                                        <p><strong>Height: </strong> <span className="ms-1"> {record.Height || 'N/A'} cm</span></p>
                                        <p><strong>Weight: </strong> <span className="ms-1"> {record.Weight || 'N/A'} kg</span></p>
                                        <p><strong>SPO2: </strong> <span className="ms-1"> {record.SPO2 || 'N/A'} %</span></p>
                                        <p><strong>Blood Pressure: </strong> <span className="ms-1"> {record.SBP || 'N/A'}/{record.DBP || 'N/A'}</span></p>
                                        <p><strong>Temperature: </strong> <span className="ms-1"> {record.Temp || 'N/A'} °C</span></p>
                                        <p><strong>Habits:</strong> <span className="ms-1"> {habitsArray.length > 0 ? habitsArray.join(', ') : 'N/A'}</span></p>
                                        <p><strong>Symptoms:</strong> <span className="ms-1">{record.Symptoms?.length > 0 ? record.Symptoms.join(', ') : 'N/A'}</span></p>
                                    </div>
                                    <div>
                                        <p><strong>Date:</strong><span className="ms-1"> {record.date?.$date ? formatDate(new Date(record.date.$date)) : 'N/A'}</span></p>
                                        <p><strong>Diagnosing Radiologist: </strong>
  <span className="ms-1">
    {Array.isArray(record?.Radiologist_Name)
      ? [...new Set(record.Radiologist_Name)].join(", ")
      : record.Radiologist_Name}
  </span>
</p>                                        <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{record.Assigned_Radiologist_Name || 'N/A'}</span></p>
                                        <p><strong>Patient Status:</strong> <span className="ms-1">{record.AI_Disease_Diagnosis_Status || 'N/A'}</span></p>
                                        <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{record.AI_Disease_Label || 'N/A'}</strong> with probability of <strong>{record.AI_Disease_confidence || 'N/A'}</strong></span></p>
                                        {('MedicalHistory' in record) && (
                                            <p><strong>Medical History:</strong> <span className="ms-1"> {record.MedicalHistory || 'N/A'}</span></p>
                                        )}
                                        <p className="text-sm"><strong>Tests:</strong> <span className="ms-1">{testsArray.length > 0 ? testsArray.join(', ') : 'N/A'}</span></p>
                                    </div>
                                </div>
       
                              <hr className=" border-[#6EC59D]" />
                           
                             <ColdReport data={data.record} />

                              
                            </div>
                          </div>

                        {/* Cold Report specific content here */}

                    </div>
                </div>
            </div>
        );
    }else if (modelview === 'FollowUp Report') {
        return (
            <div className={`${
                showModal
                    ? "z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
                    : "hidden"
            } bg-black bg-opacity-50`}>
                <div className="modal-container-outer">
                    <div className="bg-[#FFFFFFE0] relative flex flex-col justify-start w-[81rem] h-[45rem] p-6 rounded-[40px] gap-x-3 modal-container-inner">
                        <div className="absolute top-6 left-6 flex items-center space-x-4">
                            <h2 className="text-xl font-bold">Followup Report</h2>
                            <InReportDropdownButton 
                                setShowModal={setShowModal} 
                                tableData={tableData} 
                                setConfirmationModalData={setConfirmationModalData} 
                                setTableData={setTableData}  
                                record={record} 
                                modelview={modelview}
                                setModelview={setModelview}
                                recordData={recordData}

                            />
                        </div>

                        <RxCross2
                            size={30}
                            onClick={() => {
                                onCancel();
                                setNext(false);
                            }}
                            className="cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 right-6 top-6"
                        />
 <div className="mt-5">
                           
                            <div className="space-y-4 p-9 w-full">
                             
            
                              <div className="flex justify-center ">
                                <h1 className="text-[20px] font-medium">
                                  Patient UID : {record.UID_backup}
                                </h1>
                              </div>
                              <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
              <div>
                <p><strong>Name:</strong>  <span className="ms-1">{record.Name}</span></p>
                <p><strong>Age: </strong>  <span className="ms-1">{record.Age}</span></p>
                <p><strong>Sex: </strong>  <span className="ms-1">{record.Sex}</span></p>
                <p><strong>Height: </strong> <span className="ms-1"> {record.Height} cm</span></p>
               
              </div>
              <div>
              <p><strong>Date:</strong><span className="ms-1"> {record.date?.$date ? formatDate(new Date(record.date.$date)) : 'N/A'}</span></p>

              <p><strong>Diagnosing Radiologist: </strong>
  <span className="ms-1">
    {Array.isArray(record?.Radiologist_Name)
      ? [...new Set(record.Radiologist_Name)].join(", ")
      : record.Radiologist_Name}
  </span>
</p>

             
              </div>
            
            </div>
            {('followUps' in record) && (
                                    <FollowUps data={data.record} />
                                )}

                             <FollowReport data={data.record} />
                            </div>
                          </div>
                        {/* Report 4 specific content here */}

                    </div>
                </div>
            </div>
        );
    } else {
        return null; // Default case if no modelview matches
    }
};

export default Aireportbox;
