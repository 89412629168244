// HealthForecastChart.js
import React, { useEffect } from 'react';
import Plot from 'react-plotly.js'; // or wherever your Plot component is coming from


const BPForecastChart = ({ xDatadb, yDatadb,xDatasb,yDatasb }) => {
  // Use useEffect to print xData when it changes
  useEffect(() => {
    console.log('xData:', xDatadb);
  }, [xDatadb]); // Dependency array includes xData, so this runs whenever xData changes



  return (
    <Plot
  data={[
    {
      x: xDatadb,
      y: yDatadb,
      type: 'scatter',
      mode: 'lines+markers',
      name: 'DBP',
      line: { color: 'Red' },
      marker: { color: 'Red' },
      hovertemplate: 'DBP: %{y:}<extra></extra>', // Custom hover for DBP
    },
    {
      x: xDatasb,
      y: yDatasb,
      type: 'scatter',
      mode: 'lines+markers',
      name: 'SBP',
      line: { color: 'Blue' },
      marker: { color: 'Blue' },
      hovertemplate: 'SBP: %{y:}<extra></extra>', // Custom hover for SBP
    },
  ]}
  layout={{
    width: 1100,
    height: 620,
    title: 'BP Forecast',
    xaxis: {
      title: 'Date',
      showgrid: true,
      zeroline: false,
    },
    yaxis: {
      title: 'Levels',
      tickvals: [0, 50, 100, 150, 200, 250],
      ticktext: ['0', '50', '100', '150', '200', '250'],
      range: [0, 250],
      showgrid: true,
      zeroline: false,
    },
    legend: {
      x: 0.5,
      y: -0.2,
      orientation: 'h',
      xanchor: 'center',
    },
    hovermode: 'x unified', // Highlights both traces at the same x-coordinate
  }}
  />
  );
};

export default BPForecastChart;
