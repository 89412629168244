import React, { useEffect, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import Settings from "../../Utils/Settings1";
import {currentUserID} from "../../Utils/USER";
import Logs from "./Logs";
import { Rings } from "react-loader-spinner";
import { FaExclamation } from "react-icons/fa";
import { toast } from "react-hot-toast";
const { userRights, accountStatus } = Settings;

function CardComponent({ item, key, setOpenDetails,reloadTable }) {
  const [showModal, setShowModal] = useState({
    status: false,
    message: <></>,
  });
  const [loading, setLoading] = useState(false);

  // const [status, setStatus] = useState("active");
  const reload = () => {
    console.log("meow")
    reloadTable()
 
     };
  async function terminate() {
    setLoading(true);
    const payload =
    {
      "RequestId": generateUniqueId(),
      "Requestor": "Username",
      "Messages": {
        "MessageId":  generateUniqueId(),
        "MessageType": accountStatus.split('/').pop(),
        "Timestamp":new Date().toISOString(),
        "Body": {
            "data": {
              "UserID":item._id.$oid,
              "Status": false
            }       
        },
        "Parameters": {
          "UserId": currentUserID,
          "UserAccessToken": "",                              
          "APIVersion": ""
        },
        "Headers": {}
      }
    }
    const res = await axios.post(
      accountStatus,payload
    );
    console.log(res);
    if(res.status == 200){
      setLoading(false)
      setShowModal(false)
      toast.error("Deactivated")
      setTimeout(function () {
reload()      }, 1000);
    }
  }
  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const random = Math.random().toString(36).substring(2, 10); 
    return `${timestamp}-${random}`;
  }
  
  async function activate() {
    setLoading(true)
    const payload =
    {
      "RequestId": generateUniqueId(),
      "Requestor": "Username",
      "Messages": {
        "MessageId":  generateUniqueId(),
        "MessageType": accountStatus.split('/').pop(),
        "Timestamp": new Date().toISOString(),
        "Body": {
            "data": {
              "UserID":item._id.$oid,
              "Status": true
            }       
        },
        "Parameters": {
          "UserId": currentUserID,
          "UserAccessToken": "",                              
          "APIVersion": ""
        },
        "Headers": {}
      }
    }

    console.log(item._id.$oid);
    const res = await axios.post(
      accountStatus,payload
    );
    console.log(res);
    if(res.status == 200){
      setLoading(false)
      setShowModal(false)
      toast.success("Activated")
      console.log(res)
      setTimeout(function () {
reload()      }, 1000);
    }
    
  }
  async function rights() {
    setLoading(true)
    const payload={
      "RequestId": generateUniqueId(),
      "Requestor": "Username",
      "Messages": {
        "MessageId": generateUniqueId(),
        "MessageType": userRights.split('/').pop(),
        "Timestamp":new Date().toISOString() ,
        "Body": {
            "data": {
                "UserID":item._id.$oid
            }       
        },
        "Parameters": {
          "UserId": currentUserID,
          "UserAccessToken": "",                              
          "APIVersion": ""
        },
        "Headers": {}
      }
    }
    
    const res = await axios.post(
      userRights,payload
    );
    console.log(res);
    if(res.status == 200){
      setLoading(false)
      setShowModal(false);

      toast.success("Rights changed")
      setTimeout(function () {
reload()      }, 1000);
    }
  }
  const handleConfirmation = () => {
    if (item.STATUS == true) {
      terminate();
    } else if (item.STATUS == false) {
      activate();
    }
  };
  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );
  function viewDetails() {
    setOpenDetails({
      status: true,
      data: item,
    });
  }
  useEffect(() => {
    console.log(showModal);
  }, [showModal]);
 
  return (
    <>
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showModal}
        onConfirm={handleConfirmation}
        rights={rights}
        onCancel={() => setShowModal({ status: false, message: <></> })}
        item={item}
        key={key}
        loading={loading}
        loader={loader}
      />
      <div
        className={`w-[25rem] h-fit space-y-3 rounded-[20px] m-2 ${
          item.STATUS == false ? "bg-slate-100" : "bg-[#FFFFFFB8]"
        } p-4`}
      >
        {console.log(item)}
        <div className="flex justify-between ">
          <div className="flex gap-x-4 ">
            <img src="../assets/img/Hospitalimg.png" alt="hospital-img" />
            <div className="flex flex-col">
              <p className="text-sm font-semibold">{item.Name}</p>
            </div>
          </div>

          <div
            className="bg-[#FDE0E2] text-[#A76569] rounded-full h-6 w-6 text-sm flex items-center justify-center cursor-pointer"
            title={`${
              item.Pending_Patients ? item.Pending_Patients?.length : 0
            } Pending Patients`}
          >
            {item.Pending_Patients ? item.Pending_Patients?.length : 0}
          </div>
        </div>
        <div className="text-[10px] text-[#303030] font-medium flex gap-x-6">
          <p>
            P : <span className="text-[#6F6F6F]">{item.Contact_Number}</span>
          </p>
          <p>
            E :{" "}
            <span className="text-[#6F6F6F]">
              {item.Email.length > 21
                ? item.Email.substring(0, 21) + "..."
                : item.Email}
            </span>
          </p>
        </div>
        <hr className=" border-[#7B7B7B]" />
        <div className="flex justify-evenly items-center gap-x-4">
          {item.STATUS !== false ? (
            <div className="flex gap-x-2">

              <button
                onClick={() =>
                  setShowModal({
                    status: true,
                    message: (
                      <>
                        <p className="text-xl font-semibold">
                          Are you sure you want to
                        </p>
                        <p className="text-3xl font-semibold">
                        Deactivate Account?
                        </p>
                        <p className="text-xs font-medium py-4">
                          You Will Be Able To Re-Activate This Account After
                          Termination
                        </p>
                      </>
                    ),
                  })
                }
                className="text-xs font-medium rounded-xl w-[78px] h-[22px] text-[#36AE78] bg-[#ECF8F4] flex justify-center items-center"
                data-toggle="modal"
                data-target="#confirmationModal"
              >
                     Active       
              </button>
              {item.Rights === "View" ? (
                <button
                  onClick={() => {
                    setShowModal({
                      status: true,
                      message: (
                        <>
                          <p className="text-xl font-semibold">
                            Do you want to change
                          </p>
                          <p className="text-3xl font-semibold">
                            View Access to Full Access?
                          </p>
                        </>
                      ),
                      type: "rights",
                    });
                  }}
                  className="text-xs font-medium rounded-xl h-[22px]  text-[#b29440] bg-[#ffc40032] px-7 flex justify-center items-center"
                >

                                View Access
                </button>
              ) : (
                <button
                  onClick={() => {
                    setShowModal({
                      status: true,
                      message: (
                        <>
                          <p className="text-xl font-semibold">
                            Do you want to change
                          </p>
                          <p className="text-3xl font-semibold">
                            Full Access to View Access?
                          </p>
                        </>
                      ),
                      type: "rights",
                    });
                  }}
                  className="text-xs font-medium rounded-xl h-[22px]  text-[#36AE78] bg-[#ECF8F4] px-7 flex justify-center items-center"
                >
                  
                 Full Access
                </button>
              )}
            </div>
          ) : (
            <div>
              <button
                className="text-xs font-medium rounded-xl px-[18px] h-[22px] text-[#A76569] bg-[#FDE0E2] flex justify-center items-center"
                onClick={() =>
                  setShowModal({
                    status: true,
                    message: (
                      <>
                        <p className="text-xl font-semibold">
                          Are you sure you want to
                        </p>
                        <p className="text-3xl font-semibold">
                          Activate Account?
                        </p>
                        <p className="text-xs font-medium py-4">
                          You Will Be Able To de-Activate This Account After
                          Activation
                        </p>
                      </>
                    ),
                  })
                }
              >
Deactivated              </button>
            </div>
          )}
          <button
            onClick={viewDetails}
            className="text-sm font-medium rounded-lg text-white px-6 py-1 bg-[#4A4A4A]"
          >
            View Logs
          </button>
        </div>
      </div>
    </>
  );
}

const ConfirmationModal = ({
  showModal,
  onConfirm,
  rights,
  onCancel,
  item,
  hospitalAdminData,
  loading,
  loader,
  
}) => {
  return (
    <div
      className={`${
        showModal.status
          ? "fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg"
          : "hidden"
      } bg-black bg-opacity-50`}
    >
      {item.STATUS === true ? (
        <div className="bg-[#FFFFFFE0] w-[640px] h-[460px] p-4 rounded-[40px] flex flex-col justify-center items-center gap-y-6">
          {showModal.type ?(
              item.Rights == "View" ?
              <div className="w-[120px] h-[120px] bg-[#36AE78] rounded-full flex justify-center items-center">
                <FaExclamation size={70} color="#FFFFFF" />
              </div> :
              <div className="w-[120px] h-[120px] bg-[#e4ca75] rounded-full flex justify-center items-center">
              <FaExclamation size={70} color="#FFFFFF" />
            </div> 
          )
               
            :
            <div className="w-[120px] h-[120px] bg-[#E475A0] rounded-full flex justify-center items-center">
              <MdDeleteForever size={70} color="#FFFFFF" />
            </div>

          }
          <div className="text-center">
            {showModal.message}

            {showModal.type ? (
              <>
                {item.Rights == "View" ? (
                  <div className="flex justify-center mt-6 gap-x-6">
                    <button
                      className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#36AE78] border border-[#36AE78] rounded-lg bg-[#FFFFFFE0]"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#FFFFFF] rounded-lg bg-[#36AE78]"
                      onClick={rights}
                    >
                              {loading ? loader : "  Full Access"}
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center mt-6 gap-x-6">
                    <button
                      className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#e4ca75] border border-[#e4ca75] rounded-lg bg-[#FFFFFFE0]"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#FFFFFF] rounded-lg bg-[#e4ca75]"
                      onClick={rights}
                    >
                                                {loading ? loader : "  View Access"}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="flex justify-center mt-6 gap-x-6">
                <button
                  className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#E475A0] border border-[#E475A0] rounded-lg bg-[#FFFFFFE0]"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#FFFFFF] rounded-lg bg-[#E475A0]"
                  onClick={onConfirm}
                >
              {loading ? loader : "Deactivate"}

                  
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="bg-[#FFFFFFE0] w-[640px] h-[460px] p-4 rounded-[40px] flex flex-col justify-center items-center gap-y-6">
          <div className="w-[120px] h-[120px] bg-[#36AE78] rounded-full flex justify-center items-center">
            <FaCheck size={70} color="#FFFFFF" />
          </div>

          <div className="text-center">
            <p className="text-xl font-semibold">Are you sure you want to</p>
            <p className="text-3xl font-semibold">Activate Account?</p>
            <p className="text-xs font-medium py-4">
              You Will Be Able To De-Activate This Account After Activation
            </p>

            <div className="flex justify-center mt-6 gap-x-6">
              <button
                className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#36AE78] border border-[#36AE78] rounded-lg bg-[#FFFFFFE0]"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#FFFFFF] rounded-lg bg-[#36AE78]"
                onClick={onConfirm}
              >
                              {loading ? loader : "Activate"}

                
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const Cards = ({
  active,
  hospitalAdminData,
  loading,
  filterOption,
  searchQuery,
  setSearchQuery,
  reloadTable
}) => {
  const [jsonData, setJsonData] = useState(hospitalAdminData);
  const [openDetails, setOpenDetails] = useState({
    status: false,
    data: {},
  });
  const [filteredData, setFilteredData] = useState([]);
  const reloadTableData = () => {
    console.log("hhh")
   reloadTable()
  };
  useEffect(() => {
    active == 0
      ? setJsonData(hospitalAdminData.RADIOLOGIST)
      : setJsonData(hospitalAdminData.TECHNICIAN);
  }, [active, hospitalAdminData]);

  useEffect(() => {
    const filtered =
      searchQuery?.length > 0
        ? jsonData.filter((item) =>
            item.Name.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : jsonData;
    setFilteredData(filtered);
  }, [searchQuery, jsonData]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center h-full items-center">
          <Rings height="160" width="160" color="#8e75d4" />
        </div>
      ) : (
        <div className="flex flex-wrap justify-center gap-x-5 gap-y-4 mt-[22px]">
          {openDetails.status && (
            <Logs openDetails={openDetails} setOpenDetails={setOpenDetails} />
          )}

          {filteredData &&
            filteredData?.map((item, index) => {
              return (
                <CardComponent
                  item={item}
                  key={index}
                  jsonData={jsonData}
                  openDetails={openDetails}
                  setOpenDetails={setOpenDetails}
                  reloadTable={reloadTableData}
                />
              );
            })}
        </div>
      )}
    </>
  );
};

export default Cards;
