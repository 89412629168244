import React, { useState } from "react";
import axios from "axios";
import link from "../../Utils/Settings";
import { currentUserID } from "../../Utils/USER";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import DropdownCheckbox from "./Dropdownchecj"// Ensure this path is correct
import DropdownCheckboxh from "./DropdownCheckboxh"; // Ensure this path is correct
import { RxCross2 } from 'react-icons/rx';
import { DatePicker, Form, Input, Button } from 'antd';
import moment from 'moment';
import './CustomDatePicker.css';

// Reusable Input Components
const SelectInput = ({ label, name, value, onChange, options, error }) => (
  <div className="flex flex-col gap-y-2 mt-3">
    <label htmlFor={name}>{label}</label>
    <select
      className={`px-3 py-2 border rounded-md focus:outline-none ${error && "border-red-500"}`}
      name={name}
      id={name}
      value={value}
      onChange={onChange}
    >
      <option value="">Select {label.toLowerCase()}</option>
      {options.map((option) => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
    {error && <span className="text-red-500 text-sm">{error}</span>}
  </div>
);

const TextInput = ({ label, name, value, onChange, error, readonly, bgColor }) => (
  <div className="flex flex-col gap-y-2 mt-3">
    <label htmlFor={name}>{label}</label>
    <input
      className={`px-3 py-2 border rounded-md focus:outline-none ${bgColor} ${error && "border-red-500"}`}
      type="text"
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      readOnly={readonly}
    />
    {error && <span className="text-red-500 text-sm">{error}</span>}
  </div>
);

const NumInput = ({ label, name, value, onChange, error, unit }) => (
  <div className="flex flex-col mt-3">
    <label className="mb-2">{label}</label>
    <div className="flex items-center">
      <input
        className={`flex-grow px-3 py-2 border rounded-md focus:outline-none ${error && "border-red-500"} `}
        type="text"
        name={name}
        value={value}
        onChange={onChange}
      />
      <span className="p-2 bg-gray-200 border border-gray-300 rounded-r">{unit}</span>
    </div>
    {error && <span className="text-red-500">{error}</span>}
  </div>
);

const NumInput1 = ({ label, name, value, onChange, error, placeholder }) => (
  <div className="flex flex-col mt-3">
    <label className="mb-2">{label}</label>
    <div className="flex items-center">
      <input
        className={`w-24 flex-grow px-3 py-2 border rounded-md focus:outline-none ${error && "border-red-500"} `}
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
    {error && <span className="text-red-500">{error}</span>}
  </div>
);

const NumInput2 = ({ label, name, value, onChange, error, placeholder }) => (
  <div className="flex flex-col mt-3">
    <div className="flex items-center mt-5">
      <input
        className={`w-24 flex-grow px-3 py-2 border rounded-md focus:outline-none ${error && "border-red-500"} `}
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
    {error && <span className="text-red-500">{error}</span>}
  </div>
);

const Followup = ({ setSidebarOpen, reloadTable, apiData, data, setShowConfirmationModal, showConfirmationModal }) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal1, setShowConfirmationModal1] = useState(false);
  const [formData, setFormData] = useState({
    patientWeight:  '',
    patientSBP:  '',
    patientDBP: '',
    patientSPO2:  '',
    patientTemp: '',
    patientHabits: [], // Ensure this is an array
    patientSymptoms:  [], // Ensure this is an array
  });
  
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Ensure the new value is a number less than 100 or empty
    if (value === "" || (Number(value) <= 100 && !isNaN(value))) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    if (/^\d*\.?\d*$/.test(value)) {  // Allow digits and a single decimal point
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prevData) => {
      const updatedSymptoms = checked
        ? [...prevData.patientSymptoms, value]
        : prevData.patientSymptoms.filter((symptom) => symptom !== value);
      return { ...prevData, [name]: updatedSymptoms };
    });
  };

  const handleRemoveSymptom = (index) => {
    setFormData((prevData) => {
      const updatedSymptoms = [...prevData.patientSymptoms];
      updatedSymptoms.splice(index, 1);
      return { ...prevData, patientSymptoms: updatedSymptoms };
    });
  };
  const handleRemoveHabit = (index) => {
    setFormData((prevData) => {
      const updatedHabits = [...prevData.patientHabits];
      updatedHabits.splice(index, 1);
      return { ...prevData, patientHabits: updatedHabits };
    });
  };
  
  const handleCheckboxChange1 = (checkboxValue, checked) => {
    console.log(`Checkbox changed: ${checkboxValue}, Checked: ${checked}`);


    setFormData(prevState => {
      const newHabits = checked
        ? [...prevState.patientHabits, checkboxValue]
        : prevState.patientHabits.filter(habits => habits !== checkboxValue);
      return {
        ...prevState,
        patientHabits: newHabits
      };
    });
  };
  
  const handleCheckboxChange2 = (checkboxValue, checked) => {
    console.log(`Checkbox changed: ${checkboxValue}, Checked: ${checked}`);


    setFormData(prevState => {
      const newSymptoms = checked
        ? [...prevState.patientSymptoms, checkboxValue]
        : prevState.patientSymptoms.filter(symptom => symptom !== checkboxValue);
      return {
        ...prevState,
        patientSymptoms: newSymptoms
      };
    });
  };

  const handleSubmit = async () => {
    if (checkFields()) {
      setShowConfirmationModal1(true);
    } else {
      const firstErrorInput = document.querySelector(".border-red-500");
      if (firstErrorInput) {
        firstErrorInput.focus();
      }
      toast.error("Please fix the errors in the form");
    }
  };

  const checkFields = () => {
    const errors = {};
    const requiredFields = [
      "patientWeight", "patientSBP", "patientDBP", "patientSPO2", "patientTemp",
      "patientHabits", "patientSymptoms",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
        errors[field] = "This field is required";
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const getCurrentTimestamp = () => {
    const now = new Date();
    const offset = 5.5 * 60 * 60 * 1000; // IST offset from UTC in milliseconds
    const istTime = new Date(now.getTime() + offset);
    return istTime.toISOString();
  };

  const handleConfirmation = async () => {
    try {
      console.log("Data object:", data); // Add this line to check the structure of data
      setLoading(true);
      const payload = {
        RequestId: uuidv4(),
        Messages: {
          MessageId: uuidv4(),
          MessageType: "patientRegistration",
          Requesttimestamp: getCurrentTimestamp(),
          Body: {
            patientUID: data.record._id.$oid, // Use optional chaining to avoid accessing properties of undefined
            patientWeight: formData.patientWeight,
            patientSBP: formData.patientSBP,
            patientDBP: formData.patientDBP,
            patientSPO2: formData.patientSPO2,
            patientTemp: formData.patientTemp,
            patientHabits: formData.patientHabits,
            patientSymptoms: formData.patientSymptoms
          },
          Parameters: {
            UserId: currentUserID,
            UserAccessToken: "",
            APIVersion: "",
          },
          Headers: {},
        },
      };
  
      const res = await axios.post(
        `${link}/Scanalyst/patientFollowUps`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      );
  
      if (res.status === 200) {
        toast.success("Submitted successfully");
        setTimeout(() => reloadTable(), 1000);
        setShowConfirmationModal(false);
      } else {
        toast.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Error submitting form");
      setShowConfirmationModal(false);
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
      setSidebarOpen(false);
    }
  };
  

  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );

  return (
    <div className="flex flex-col ms-4 my-4 overflow-y-auto pb-32 h-full scroller">
      <h1 className="text-2xl text-center mt-6 mb-6 font-bold">{data.Name}</h1>

      {/* Two-column Layout */}
      <div className="flex flex-wrap gap-6 mb-3">
        {/* Left Column */}
        <div className="w-full md:w-[45%] pr-4">
          <NumInput
            label="SPO2"
            name="patientSPO2"
            value={formData.patientSPO2}
            onChange={handleChange}
            error={formErrors.patientSPO2}
            unit="%"
          />
          <NumInput
            label="Temperature"
            name="patientTemp"
            value={formData.patientTemp}
            onChange={handleChange1}
            error={formErrors.patientTemp}
            unit="°C"
          />
        </div>

        {/* Right Column */}
        <div className="w-full md:w-[45%] pl-4">
          <div className="flex items-center space-x-2">
            <NumInput1
              label="Blood Pressure"
              name="patientSBP"
              value={formData.patientSBP}
              onChange={handleChange1}
              error={formErrors.patientSBP}
              placeholder="systole"
            />
            <span className="mt-5 py-2">/</span>
            <NumInput2
              name="patientDBP"
              value={formData.patientDBP}
              onChange={handleChange1}
              error={formErrors.patientDBP}
              placeholder="diastole"
            />
          </div>
          <NumInput
            label="Weight"
            name="patientWeight"
            value={formData.patientWeight}
            onChange={handleChange1}
            error={formErrors.patientWeight}
            unit="kg"
          />
        </div>
      </div>

      {/* Dropdown Checkbox */}
      <DropdownCheckbox
  label="Symptoms"
  name="patientSymptoms"
  value={formData.patientSymptoms}
  onChange={handleCheckboxChange}
  options={apiData?.Symptoms || []} // Default to empty array if apiData or Symptoms is undefined
  error={formErrors.patientSymptoms}
/>


      <div className="w-full mt-3">
        <ul className="w-full py-[3px] flex gap-x-3 px-2 overflow-x-scroll no-scrollbar">
          {formData.patientSymptoms.map((symptom, index) => (
            <li
              className="border border-[#36AE78] text-[#36AE78] bg-[#c1f6de] bg-opacity-15 rounded-full w-fit pl-2 pr-1 whitespace-nowrap flex items-center gap-x-1"
              key={index}
            >
              {symptom.length > 15 ? symptom.substring(0, 14) + '...' : symptom}
              <RxCross2
                className="cursor-pointer ml-2"
                onClick={() => {
                  handleRemoveSymptom(index);
                }}
              />
            </li>
          ))}
        </ul>
      </div>
      <DropdownCheckboxh
        label="Habits"
        name="patientHabits"
        value={formData.patientHabits}
        onChange={handleCheckboxChange1}
        options={apiData?.Habits || []}
        error={formErrors.patientHabits}
      />
<div className="w-full mt-3">
  <ul className="w-full py-[3px] flex gap-x-3 px-2 overflow-x-scroll no-scrollbar">
    {Array.isArray(formData.patientHabits) && formData.patientHabits.map((habit, index) => (
      <li
        className="border border-[#36AE78] text-[#36AE78] bg-[#c1f6de] bg-opacity-15 rounded-full w-fit pl-2 pr-1 whitespace-nowrap flex items-center gap-x-1"
        key={index}
      >
        {habit.length > 15 ? habit.substring(0, 14) + '...' : habit}
        <RxCross2
          className="cursor-pointer ml-2"
          onClick={() => {
            handleRemoveHabit(index); // Update handler for habits
          }}
        />
      </li>
    ))}
  </ul>
</div>


      <button
        className="text-sm w-full font-medium rounded-lg text-white mt-8 px-6 py-2 bg-[#36AE78] active:scale-95 transition-transform duration-300 focus:outline-none"
        onClick={handleSubmit}
      >
        {loading ? loader : "Submit"}
      </button>

      {/* Confirmation Modal */}
      {showConfirmationModal1 && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 w-[400px] rounded-3xl">
            <p className="text-xl font-semibold mb-2 text-center">Are you sure you want to submit the form?</p>
            <div className="flex justify-center gap-4 mt-6">
              <button
                className="rounded-lg px-4 py-2 border border-red-500 text-red-500"
                onClick={() => setShowConfirmationModal1(false)}
              >
                No
              </button>
              <button
                className="rounded-lg px-4 py-2 bg-[#36AE78] text-white"
                onClick={handleConfirmation}
              >
                {loading ? loader : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Followup;
