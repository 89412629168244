import React, { useState, useEffect } from "react";
import { useCheck } from "./CheckContext";
import { Rings } from "react-loader-spinner";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import Settings from "../../Utils/Settings1";
import { toast } from "react-hot-toast";
import { BiChevronDown } from "react-icons/bi";
import Dropdown from "./Dropdown";
import Datatable from "./Datatable";
import ConfirmationModal from "./ConfirmationModal";
import { dwvLink ,CT,X,US,M} from "../../Utils/dwv_settings";
import { currentUserID } from "../../Utils/USER";
import Aireportbox from "./AiReportbox";
const { download} = Settings;

const Table = ({ loading1,technicianData, loading, searchQuery ,reloadTable,setSidebarOpen,sidebarOpen,apiData,followupData,setDatatableData,datatableData, handlePage, fetchTechnicianData}) => {
  const [showModal, setShowModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  // const [recordlen, setRecordlen] = useState(null);
  const itemsPerPage = 10;
  // const { handleCheckboxChange, selectedItems } = useCheck();
  const [confirmationModalData, setConfirmationModalData] = useState({});



  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const random = Math.random().toString(36).substring(2, 10); 
    return `${timestamp}-${random}`;
  }
  async function handleDownload(item, report) {
    console.log("click", report);
    const id = item._id.$oid;
    const payload = {
      "RequestId": generateUniqueId(),
      "Requestor": "Username",
      "Messages": {
        "MessageId": generateUniqueId(),
        "MessageType": download.split('/').slice(-2).join('/'),
        "Timestamp": new Date().toISOString(),
        "Body": {
          "data": {
            "PatientID": id,
            "fileName": report
          }
        },
        "Parameters": {
          "UserId": currentUserID,
          "UserAccessToken": "",
          "APIVersion": ""
        },
        "Headers": {}
      }
    };
  
    try {
      const res = await axios.post(download, payload, {
        responseType: 'blob'  // Important for binary data
      });
  
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: 'application/pdf' });  // Adjust MIME type as needed
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = report;  // Use 'report' if it contains the filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toast.success("Downloaded");
      } else {
        console.log("Download failed");
        toast.error("Download failed");
      }
    } catch (error) {
      console.log("Error downloading", error);
      toast.error("Error downloading");
    }
  }
  const [modelview, setModelview] = useState("");

  const [recordData, setRecordData] = useState([]);

  
  const handleView = async (item, modality, id) => {
    try {
      const TechnicianID = currentUserID;
      const directory = `${item.UID_backup}_${item.Name}_${item.Age}_${item.Sex}`;
      let scanId = id;
  
      // Constructing the path for the data
      const data = `Hospitals/${item.Hospital}/Patients/${directory}/${modality}/${scanId}/DCM/`;
  
      // Logging the selected data for debugging
      console.log("Selected Modality:", modality);
      console.log("Selected ID:", id);
      console.log("Constructed Data Path:", data);
  
      // Encrypting query parameters
      const encryptedPatientID = encrypt(TechnicianID);
      const encryptedData = encrypt(data);
      const encryptedModality = encrypt(modality);
      const encryptedParams = {
        encryptedData: encryptedData,
        encryptedModality: encryptedModality
      };
  
      // Encoding the encrypted parameters as a query string
      const encryptedParamsString = encodeURIComponent(JSON.stringify(encryptedParams));
  
      // Constructing the URL with encrypted query parameters
      const url = `${dwvLink}?ID=${encryptedPatientID}&data=${encryptedParamsString}`;
  
      // Opening the URL in a new tab
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error in handleView:", error);
    }
  };
  
  


  // Encryption function (example)
  function encrypt(data) {
    // Implement your encryption algorithm here
    // For demonstration purposes, let's assume simple base64 encoding
    return btoa(data);
  }
  useEffect(() => {

    if (technicianData && technicianData.PATIENTS) {
      setFilteredData(technicianData.PATIENTS);
    }
  }, [technicianData]);



  useEffect(() => {
    setTableData(filteredData.map((item) => ({
      id: item._id,
      ai: item.AI_Report_Status,
      uid: item.UID_backup,
      name: item.Name,
      age: parseInt(String(item.Age).replace(/\D/g, ""), 10),
      sex: item.Sex,
      date: item.Study_date,
      bodyPart: item.Body_part,
      modality: item.Modality,
      radiologist: item.Assigned_Radiologist_Name || "",
      reportStatus: item.Report_Status,
      finalReport: "",
      ...item,
    })));
  }, [filteredData]);
  const handleModelView = async ( modality,record) => {
    setModelview(modality);
    setRecordData(record)
    console.log("Selected Model:", record);

  };
  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  
    // Function to call reloadTable if needed
   console.log("gggg")
  //  setRecordlen(technicianData.PATIENTS.length)
    setDatatableData(
      currentItems
        .filter((item) => {
          if (searchQuery) {
            const queryLower = searchQuery.toLowerCase();
            const uidBackupStr = (item.UID_backup || '').toString(); // Ensure UID_backup is a string
  
            return (
              item.Name.toLowerCase().includes(queryLower) ||
              uidBackupStr.includes(queryLower) ||
              (item?.Assigned_Radiologist_Name || '').toLowerCase().includes(queryLower)
            );
          }
          return true;
        })
        .map((item) => ({
          id: item._id,
          ai: item.AI_Report_Status,
          uid: item.UID_backup,
          name: item.Name,
          age: parseInt(String(item.Age).replace(/\D/g, ""), 10),
          sex: item.Sex,
          date: item.Study_date,
          bodyPart: item.Body_part,
          modality: item.Modality,
          radiologist: item.Assigned_Radiologist_Name || "",
          reportStatus: item.Report_Status,
          finalReport: "",
          ...item,
        }))
    );
  }, [currentPage, filteredData, searchQuery, setDatatableData]);

  //  setTotalPages(3);
  const test = technicianData?.totalPatientRecords ?? 'default value';
  // const test = technicianData?.PATIENTS?.totalPatientRecords || 18;
  // const test = 18;
  const totalPages = Math.ceil(test/ itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const recordlen = test;
  // setRecordlen(test);

  console.log(test);
  
  const handleNextPage = () => {
    const prevPage = currentPage + 1;
    setCurrentPage(prevPage);
    handlePage(prevPage ,  itemsPerPage);
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1;
    setCurrentPage(prevPage);
    handlePage(prevPage, itemsPerPage);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    handlePage(pageNumber, itemsPerPage);
  };

  return (
    <>
     <Aireportbox
     showModal={showModal}
     recordData={recordData}
     onConfirm={() => setShowModal(false)}
     onCancel={() => setShowModal(false)}
     data={confirmationModalData}
     setShowModal={setShowModal}
     tableData={tableData}
     modelview={modelview}
     setConfirmationModalData={setConfirmationModalData}
     setTableData={setTableData}
     setModelview={setModelview}
     reloadTable={reloadTable}
     technicianData={technicianData}
     apiData={apiData}
    />
      {/* {showModal && <ConfirmationModal
        showModal={showModal}
        onConfirm={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        data={confirmationModalData}
        reloadTable={reloadTable}
        followupData={followupData}
        setShowModal={setShowModal}
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        apiData={apiData}
      />} */}
      {loading ? (
        <div className="flex justify-center h-full items-center">
          <Rings height="160" width="160" color="#8e75d4" />
        </div>
      ) : (
        <div className=" overflow-y-scroll no-scrollbar w-full overflow-hidden rounded-xl overflow-x-auto mt-4 sticky top-0">
          <Datatable
            data={datatableData}
            datatableData={datatableData}
            loading1={loading1}
            handleDownload={handleDownload}
            setConfirmationModalData={setConfirmationModalData}
            setShowModal={setShowModal}
            handleView={handleView}
            tableData={tableData}
            technicianData={technicianData}
            handleModelView={handleModelView}
            setTableData={setTableData}

          />
          {/* <table className="mx-auto w-full table-auto ">
            <thead className=" bg-[#ffffff] shadow-sm text-[#626262] text-sm sticky top-0">
              <th className=""></th>
              <th className="flex items-center h-[3rem] justify-center">
            AI <Dropdown options={options} />
          </th>
              <th className="text-center">UID</th>
              <th className="text-center">Name</th>
              <th className="text-center">Age</th>
              <th className="text-center">Sex</th>
              <th className="text-center">Date</th>
              <th className="text-center">Body Part</th>
              <th className="text-center">Modality</th>
              <th className="text-center">Radiologist</th>
              <th className="text-center">Report Status</th>
              <th className="py-3 text-center">Final Report</th>
            </thead>

            <tbody>
              {currentItems &&
                currentItems.map((item) => {
                  return (
                    <tr className="bg-[#FFFFFF8A] text-sm">
                      <td className="text-center">
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            name="check"
                            id="check"
                            onChange={() =>
                              handleCheckboxChange(
                                item.UID_backup,
                                item.Name,
                                item["_id"]
                              )
                            }
                          />
                        </div>
                      </td>
                      <td className="py-4 flex items-center justify-center gap-x-2">
                        {item.AI_Report_Status == "Abnormal" ? (
                          <div className="h-[24px] w-[24px] text-[#FFFFFF] text-xs flex items-center justify-center rounded-full bg-[#E475A0]">
                            A
                          </div>
                        ) : (
                          <div className="h-[24px] w-[24px] text-[#FFFFFF] text-xs flex items-center justify-center rounded-full bg-[#36AE78]">
                            N
                          </div>
                        )}
                      </td>

                      <td className="text-center">{item.UID_backup}</td>
                      <td className="text-center">{item.Name}</td>
                      <td className="text-center">{parseInt(item.Age.replace(/\D/g, ""), 10)}</td>
                      <td className="text-center">{item.Sex}</td>
                      <td className="text-center">{formatDate(item.Study_date)}</td>
                      <td className="text-center">{item.Body_part}</td>
                      <td className="text-center">{item.Modality}</td>

                      <td className="text-center">
                        {!item.Assigned_Radiologist_Name
                          ? "Pending"
                          : item.Assigned_Radiologist_Name}
                      </td>

                      <td className="text-center">
                        {item.Report_Status == "Done" ? (
                          <div className="flex justify-center">
                            <button onClick={() => handleDownload(item)} className="bg-[#FFFFFF8A] text-[#36AE78] border border-green-500 w-[86px] h-[24px] rounded-lg flex items-center justify-center">
                              <MdOutlineFileDownload size={20} />
                            </button>
                          </div>
                        ) : (
                          <button className="w-[86px] h-[24px] ">
                            Pending
                          </button>
                        )}
                      </td>
                      <td className="">
                        <div className="flex justify-center gap-x-2 ">
                          <button
                            onClick={() => {
                              setShowModal(true);
                              setConfirmationModalData(item);
                            }}
                            className="bg-[#FFFFFF8A] text-[#36AE78] border border-green-500 w-[24px] h-[24px] rounded-lg flex items-center justify-center"
                          >
                            <FaPen size={12} />
                          </button>
                          <button onClick={() => handleView(item)} className="bg-[#FFFFFF8A] text-[#36AE78] border border-green-500 w-[24px] h-[24px] rounded-lg flex items-center justify-center">
                            <MdOutlineRemoveRedEye size={18} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table> */}
        </div>
      )}
      <div className="flex justify-center mt-4 gap-x-5 text-sm">
        <button
          className="flex items-center justify-center hover:text-[#36ae78]"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={`flex items-center justify-center hover:text-[#36ae78] ${currentPage === pageNumber ? "text-[#36ae78] text-500" : ""
              }`}
            onClick={() => handlePageClick(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}
        <button
          className="flex items-center justify-center hover:text-[#36ae78]"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </>
  );
};
export default Table;
