import React, { useEffect, useState } from 'react';
import Tabs from "../../components/Technician/Tabs";
import Header from '../../components/Technician/Header';
import Settings from '../../Utils/Settings1';
import axios from 'axios';
import SearchBar from '../../components/Technician/SearchBar';
import Table from '../../components/Technician/Table';
import { currentUserID } from '../../Utils/USER';
import link from '../../Utils/Settings';
import { Rings } from 'react-loader-spinner';

const { technicianDashboard,followup } = Settings;

function TechnicianRole() {
  const [technicianData, setTechnicianData] = useState({});
  const [followupData, setFollowupData] = useState({});
  const [datatableData, setDatatableData] = useState([]);

  const [pageNumber, setPageNumber] = useState(1); // Initialize to the first page
  const [pageSize, setPageSize] = useState(10); // Default page size

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [isPatientAdded, setPatientAdded] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [Query1, setQuery1] = useState("");


  // const [totalpage, setTotalPage] = useState(1);
 


  const reloadTableData = () => {
    fetchTechnicianData();
  };

  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const random = Math.random().toString(36).substring(2, 10); 
    return `${timestamp}-${random}`;
  }
  const handleSearch = async (query) => {
    setSearchQuery(query);
    setQuery1(query);
    console.log("query",query)
    setLoading(true); // Set loading to true before the API call
    try {
      const payload = {
        RequestId: generateUniqueId(),
        Requestor: "Username",
        Messages: {
          MessageId: generateUniqueId(),
          MessageType: technicianDashboard.split('/').pop(),
          Timestamp: new Date().toISOString(),
          Body: {
            data:{
            query: query,
            pageNumber: pageNumber,
                pageSize: pageSize,
          }
          },
          Parameters: {
            UserId: currentUserID,
            UserAccessToken: "yourAccessTokenHere",
            APIVersion: "yourAPIVersionHere",
          },
          Headers: {},
        }
      };

      const res = await axios.post(technicianDashboard, payload);
      const responseData = res.data;
      setTechnicianData(responseData?.Responses[0]?.Body?.data || {});
      setQuery1(query);
    } catch (error) {
      console.error("Error fetching technician data:", error);
    } finally {
setLoading(false)
    }  };

    const handlePage = async (pageNumber, pageSize) => {
      // Optional: Show loading state
      setLoading1(true);
      console.log('HandlePage trigger');
    
      try {
        const payload = {
          RequestId: generateUniqueId(),
          Requestor: "Username",
          Messages: {
            MessageId: generateUniqueId(),
            MessageType: technicianDashboard.split('/').pop(),
            Timestamp: new Date().toISOString(),
            Body: {
              data: {
                query: Query1, // Adjust this if needed
                pageNumber: pageNumber,
                pageSize: pageSize
              }
            },
            Parameters: {
              UserId: currentUserID,
              UserAccessToken: "yourAccessTokenHere",
              APIVersion: "yourAPIVersionHere",
            },
            Headers: {},
          }
        };
    
        const res = await axios.post(technicianDashboard, payload);
    
        const responseData = res.data;
        const patients = responseData?.Responses?.[0]?.Body?.data?.PATIENTS || [];
    setPageNumber(pageNumber);
        // Process and set data
        setDatatableData(
          patients
            .filter((item) => {
              // Apply additional filtering if needed
              return true;
            })
            .map((item) => ({
              id: item._id,
              ai: item.AI_Report_Status,
              uid: item.UID_backup,
              name: item.Name,
              age: parseInt(String(item.Age).replace(/\D/g, ""), 10),
              sex: item.Sex,
              date: item.Study_date,
              bodyPart: item.Body_part,
              modality: item.Modality,
              radiologist: item.Assigned_Radiologist_Name || "",
              reportStatus: item.Report_Status,
              finalReport: "",
              ...item,
            }))
        );

        // Log for debugging
        console.log("handle", responseData?.Responses?.[0]?.Body?.data);
    
      } catch (error) {
        console.error("Error fetching technician data:", error);
        // Optional: Show error message to user
      } finally {
        // Optional: Hide loading state
        setLoading1(false);
      }
    };
    



  async function getapi() {
    setLoading(true); // Set loading to true before the API call
    try {
      const payload = {
        RequestId: generateUniqueId(),
        Messages: {
          MessageId: generateUniqueId(),
          MessageType: "Formapi",
          Requesttimestamp: new Date().toISOString(),
          Body: {

          },
          Parameters: {
            UserId: currentUserID,
            UserAccessToken: "",
            APIVersion: "",
          },
          Headers: {},
        },
      };

      const res = await axios.post(`${link}/Scanalyst/formAPIs`, payload);
      console.log("API Data:", res.data?.Responses[0]?.Body?.data);
      setApiData(res.data?.Responses[0]?.Body?.data);
    } catch (error) {
      console.error("Error fetching API data:", error);
    } finally {
    }
  }

  
  async function followupl() {
    setLoading(true); // Set loading to true before the API call
    try {
        const payload = {
            RequestId: generateUniqueId(),
            Requestor: "Username",
            Messages: {
                MessageId: generateUniqueId(),
                MessageType: followup.split('/').pop(),
                Timestamp: new Date().toISOString(),
                Body: {
                    patientHabits: "example habits",
                    patientTemp: "example temperature",
                    patientSPO2: "example SPO2",
                    patientSymptoms: "example symptoms",
                    patientSBP: "example SBP",
                    patientDBP: "example DBP",
                    patientWeight: "example weight"
                },
                Parameters: {
                    UserId: currentUserID,
                    UserAccessToken: "yourAccessTokenHere",
                    APIVersion: "yourAPIVersionHere"
                },
                Headers: {}
            }
        };

        const res4 = await axios.post(followup, payload);
        const responseData = res4.data;
        setFollowupData(responseData?.patient_data || {});
        console.log("Response Data:", responseData);
        

    } catch (error) {
        console.error("Error fetching technician data:", error);
    } finally {
        setLoading(false);
    }
}
const [query, setQuery] = useState("");


  async function fetchTechnicianData() {
    setLoading(true); 
   
    try {
      const payload = {
        RequestId: generateUniqueId(),
        Requestor: "Username",
        Messages: {
          MessageId: generateUniqueId(),
          MessageType: technicianDashboard.split('/').pop(),
          Timestamp: new Date().toISOString(),
          Body: {
            data:{
             query: query,
             pageNumber: pageNumber,
             pageSize: pageSize }          
            },
          Parameters: {
            UserId: currentUserID,
            UserAccessToken: "yourAccessTokenHere",
            APIVersion: "yourAPIVersionHere",
          },
          Headers: {},
        }
      };

      const res = await axios.post(technicianDashboard, payload);
      const responseData = res.data;
      // setTechnicianData(responseData);
      setTechnicianData(responseData?.Responses[0]?.Body?.data || {});
      console.log("page 1",technicianData)

    } catch (error) {
      console.error("Error fetching technician data:", error);
    } finally {
        setLoading(false)
    }
  }


  useEffect(() => {
    getapi();
    fetchTechnicianData();
    setLoading(true); // Set loading to true before the API call
  }, []);

  useEffect(() => {
    if (isPatientAdded) {
      fetchTechnicianData();
      setPatientAdded(false);
    }
  }, [isPatientAdded]);

  useEffect(() => {
    if (isAssigned) {
      fetchTechnicianData();
      setIsAssigned(false);
    }
  }, [isAssigned]);

  return (
    <div className="h-screen px-14 pb-5 overflow-x-hidden bg-gradient-to-r from-blue-100 via-purple-100 to-red-100">
      <div className='flex'>
        <Header />
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
          setQuery1={setQuery1}
          setPageNumber={setPageNumber}
        />
      </div>
      <Tabs
        apiData={apiData}
        technicianData={technicianData}
        setPatientAdded={setPatientAdded}
        isAssigned={isAssigned}
        reloadTable={reloadTableData}
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        setIsAssigned={setIsAssigned}
        
      />
      {loading ?
        <div className="flex justify-center h-full items-center">
          <Rings height="160" width="160" color="#8e75d4" />
        </div> :
        <Table
        apiData={apiData}
        followupData={followupData}
          searchQuery={searchQuery}
          technicianData={technicianData}
          loading={loading}
          loading1={loading1}
          reloadTable={reloadTableData}
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          datatableData={datatableData}
          setDatatableData={setDatatableData}
          handlePage = {handlePage}
          
        />}
    </div>
  );
}

export default TechnicianRole;
