import React, { useState, useEffect } from "react";
import InputDropdown from "./InputDropdown";
import axios from "axios";
import { toast } from "react-hot-toast";
import { currentUserID } from "../../../Utils/USER";
import { v4 as uuidv4 } from "uuid";
import Settings from "../../../Utils/Settings1";

const { superHospitalUpdateForm } = Settings;

const UpdateHospital = ({ superAdminData, setSidebarOpen, reloadTable1 }) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const reload = () => {
    console.log("meow");
    reloadTable1();
  };
  const dropdownOptions =
    superAdminData && superAdminData.HOSPITAL
      ? [
          { name: "Select", id: "" },
          ...superAdminData.HOSPITAL.map((HOSPITAL) => ({
            name: HOSPITAL.Name,
            id: HOSPITAL._id.$oid,
          })),
        ]
      : [];

  const [formData, setFormData] = useState({
    UserId: currentUserID,
    HospitalID: "",
    Contact_no: "",
    certificate: "",
    RequestId: uuidv4(),
    MessageId: uuidv4(),
    MessageType: "superHospitalUpdateForm",
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [formErrors, setFormErrors] = useState({
    HospitalID: "",
    Contact_no: "",
    certificate: "",
  });

  function handleInputChange(event) {
    const { name, value, files } = event.target;
    let error = "";

    switch (name) {
      case "Contact_no":
        const phoneRegex = /^[0-9]{10}$/;
        error = !value.match(phoneRegex) ? "Invalid phone number" : "";
        break;
      default:
        break;
    }

    setFormErrors({ ...formErrors, [name]: error });

    if (name === "certificate") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  function checkFields() {
    const { HospitalID, Contact_no } = formData;
    const obj1 = {
      HospitalID,
      Contact_no,
    };
    const obj = {
      HospitalID,
    };
    const errors = {};
    Object.keys(obj1).forEach((key) => {
      switch (key) {
        case "HospitalID":
          errors[key] = obj1[key] ? "" : "Please select a hospital";
          break;
        case "Contact_no":
          errors[key] = obj1[key] ? "" : "This field is required";
          break;
        default:
          break;
      }
    });
    setFormErrors(errors);
    return Object.values(obj).every((e) => e?.length > 0);
  }

  function handleSubmit() {
    if (checkFields()) {
      setShowConfirmationModal(true);
    } else {
      const firstErrorInput = document.querySelector(".border-red-500");
      if (firstErrorInput) {
        firstErrorInput.focus();
      }

      toast.error("Please fix the errors in the form");
    }
  }

  async function confirmSubmission() {
    setShowConfirmationModal(false);

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    // Log the formDataToSend content for debugging
    for (let pair of formDataToSend.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      setLoading(true);
      const res = await axios.post(superHospitalUpdateForm, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res);
      if (res.status === 200) {
        toast.success("Submitted");
        console.log("Form submitted successfully");
        setTimeout(function () {
          reload();
        }, 1000);
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Error submitting form");
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
      setSidebarOpen(false);
    }
  }

  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <div className="p-4">
      <h1 className="text-2xl text-center mt-6 mb-6 font-bold">
        Update Hospital
      </h1>
      <div className="flex flex-col  gap-y-6">
        <div className="flex flex-col  gap-y-2">
          <label htmlFor="user">Select User</label>
          <InputDropdown
            options={dropdownOptions}
            selected={selected}
            setSelected={setSelected}
            handleInputChange={handleInputChange}
            name={"HospitalID"}
          ></InputDropdown>
          {formErrors.HospitalID && (
            <span className="text-red-500 text-sm">
              {formErrors.HospitalID}
            </span>
          )}
        </div>
        <div className="flex flex-col  gap-y-2">
          <label htmlFor="phone">Phone</label>
          <input
            className={`px-2 py-2 border rounded-md focus:outline-none ${
              formErrors.Contact_no && "border-red-500"
            }`}
            type="tel"
            name="Contact_no"
            id="phone"
            value={formData.Contact_no}
            onChange={handleInputChange}
          />
          {formErrors.Contact_no && (
            <span className="text-red-500 text-sm">
              {formErrors.Contact_no}
            </span>
          )}
        </div>

        <div className="flex flex-col  gap-y-2">
          <label htmlFor="certificate">Additional Documents</label>
          <input
            className={`px-2 py-2 border rounded-md focus:outline-none ${
              formErrors.certificate && "border-red-500"
            }`}
            type="file"
            name="certificate"
            id="certificate"
            onChange={handleInputChange}
          />
          {formErrors.certificate && (
            <span className="text-red-500 text-sm">
              {formErrors.certificate}
            </span>
          )}
        </div>
      </div>
      <button
        className="text-sm w-full font-medium rounded-lg text-white mt-8 px-6 py-2 bg-[#4A4A4A] active:scale-95 active:transform transition-transform duration-300 focus:outline-none"
        onClick={handleSubmit}
      >
        {loading ? loader : "Update"}
      </button>

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <div className="bg-[#ededed] p-8 w-[640px] h-[14rem] rounded-3xl items-center m-[20px]">
            <p className="text-xl font-semibold mb-1 text-center">
              Are you sure you want to{" "}
            </p>
            <p className="text-2xl font-semibold mb-4 text-center">
              Submit the form?
            </p>
            <div className="flex justify-center">
              <button
                className="font-semibold rounded-lg px-4 py-2 mr-7 w-[180px] mt-[20px] border border-red-500 bg-white text-red-500"
                onClick={() => setShowConfirmationModal(false)}
              >
                No
              </button>
              <button
                className="text-white bg-[#36AE78] rounded-lg px-4 py-2 w-[180px] mt-[20px] font-semibold"
                onClick={confirmSubmission}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateHospital;
