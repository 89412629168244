import React, { useState, useEffect } from "react";
import InputDropdown from "./InputDropdown";
import axios from "axios";
import link from "../../../Utils/Settings";
import { currentUserID } from "../../../Utils/USER";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';

const UpdateUser = ({ userData, setSidebarOpen, reloadTable }) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('');
  const dropdownOptions = ['Radiologist', 'Technician'];
  
  const reload = () => {
    console.log("meow");
    reloadTable();
  };
  
  const [formData, setFormData] = useState({
    UserID: currentUserID,
    receiverID: userData[0]?.id || "",
    Contact_Number: "",
    certificate: null,
  });

  const [formErrors, setFormErrors] = useState({
    Contact_Number: "",
  });

  function handleInputChange(event) {
    const { name, value, files } = event.target;
    let error = "";
    switch (name) {
      case "Contact_Number":
        const phoneRegex = /^[0-9]{10}$/; // Assuming a 10-digit phone number
        error = !value.match(phoneRegex) ? "Invalid phone number" : "";
        break;
      default:
        break;
    }

    setFormErrors({ ...formErrors, [name]: error });
    if (name === "certificate") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  function checkFields() {
    const { UserID, receiverID, Contact_Number } = formData;
    const obj = {
      UserID,
      receiverID,
      Contact_Number,
    };
    const errors = {};
    Object.keys(obj).forEach((key) => {
      errors[key] = obj[key] ? "" : "This field is required";
    });
    setFormErrors(errors);
    return Object.values(obj).every((e) => e && e.length > 0);
  }

  async function handleSubmit() {
    if (checkFields()) {
      const requestId = uuidv4();
      const messageId = uuidv4();
      const messageType = "hospitalDoctorForm";

      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      formDataToSend.append("RequestId", requestId);
      formDataToSend.append("MessageId", messageId);
      formDataToSend.append("MessageType", messageType);

      try {
        setLoading(true);
        const res = await axios.post(
          `${link}/Scanalyst/hospitalUpdateForm`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("res");

        console.log(  formData );

        if (res.status === 200) {
          toast.success("Submitted");
          console.log("Form submitted successfully");
          setTimeout(function () {
            reload();
          }, 1000);
        } else {
          console.error("Failed to submit form");
        }
      } catch (error) {
        toast.error("Error submitting form");
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
        setSidebarOpen(false);
      }
    } else {
      const firstErrorInput = document.querySelector(".border-red-500");
      if (firstErrorInput) {
        firstErrorInput.focus();
      }

      toast.error("Fill all the fields");
    }
  }

  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <div className="p-4">
      <h1 className="text-2xl text-center mt-6 mb-6 font-bold">Update User</h1>
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-2">
          <label htmlFor="user">
            Select User
          </label>
          <InputDropdown options={userData} selected={selected} setSelected={setSelected} handleInputChange={handleInputChange} name={"user_id"}></InputDropdown>
        </div>
        <div className="flex flex-col gap-y-2">
          <label htmlFor="phone">
            Phone
          </label>
          <input
            className={`px-2 py-2 border rounded-md focus:outline-none ${formErrors.Contact_Number && "border-red-500"}`}
            type="tel"
            name="Contact_Number"
            id="phone"
            value={formData.Contact_Number}
            onChange={handleInputChange}
          />
          {formErrors.Contact_Number && (
            <span className="text-red-500 text-sm">{formErrors.Contact_Number}</span>
          )}
        </div>
        <div className="flex flex-col gap-y-2">
          <label htmlFor="service">
            Additional Documents
          </label>
          <input
            className="px-2 py-2 border border-slate-200 rounded-md focus:outline-none"
            type="file"
            name="certificate"
            id="doc"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <button className="text-sm w-full font-medium rounded-lg text-white mt-8 px-6 py-2 bg-[#4A4A4A] active:scale-95 active:transform transition-transform duration-300 focus:outline-none" onClick={handleSubmit}>
        {loading ? loader : "Update"}
      </button>
    </div>
  );
};

export default UpdateUser;
