// HealthForecastChart.js
import React, { useEffect } from 'react';
import Plot from 'react-plotly.js'; // or wherever your Plot component is coming from


const HealthForecastChart = ({ xData, yData }) => {
  // Use useEffect to print xData when it changes
  useEffect(() => {
    console.log('xData:', xData);
  }, [xData]); // Dependency array includes xData, so this runs whenever xData changes



  return (
    <Plot
    data={[
      {
        x: xData,
        y: yData,
        type: 'scatter',
        mode: 'lines+markers',
        name: 'red',
        line: { color: 'red' },
        marker: { color: 'red' },
        hovertemplate: 'Health Index: %{y:.2f}<extra></extra>', // Customize hover text here
      },
    ]}
    layout={{
      width: '1100',
      height: '620',
      title: 'Overall Health Forecast',
      xaxis: {
        title: 'Date',
        tickvals: xData,
        ticktext: xData,
      },
      yaxis: {
       title: 'Health Index',
       tickvals: [0, 1, 2, 3,4,5],
       ticktext: ['0', '1', '2', '3','4','5'],
       range: [0, 5],
      },
      legend: {
        x: 0.5,
        y: -0.2,
        orientation: 'h',
        xanchor: 'center',
      },
    }}
  />
  
  );
};

export default HealthForecastChart;
