import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { currentUserID } from '../../Utils/USER';
import Settings from '../../Utils/Settings1';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
const { link, userlogout } = Settings;

const Logout = ({ onLogout }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  let timeout;


  const resetTimer = () => {
    clearTimeout(timeout);
    timeout = setTimeout(logout, 600000); // 10 minutes
  };


  const logout = async () => {
    setLoading(true);
    localStorage.clear();
    const requestId = generateUniqueId();
    const messageId = generateUniqueId();
    try {
      const requestBody = {
        RequestId: requestId, // Provide the actual RequestId here
        Messages: {
          Parameters: {
            UserId: currentUserID
          },
          MessageId: messageId, // Provide the actual MessageId here
          MessageType: "Logout" // Provide the actual MessageType here
        }
      };


      const response = await axios.post(userlogout, requestBody);

      if (response.status === 200) {
        setShowModal(false);
        toast.success('Logout successful!');
        navigate('/');
        onLogout();
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    resetTimer();

    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
    };
  }, []);

  const handleConfirmation = async () => {
    setLoading(true);
    clearTimeout(timeout);
    await handleLogout();
  };


  const generateUniqueId = () => {
    // Function to generate a unique identifier
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (Math.random() * 16)) >> (c / 4) & 15
    ).toString(16);
  }
  const handleLogout = async () => {
    localStorage.clear();


    try {
      const requestBody = {
        RequestId: generateUniqueId(), // Provide the actual RequestId here
        Messages: {
          Parameters: {
            UserId: currentUserID
          },
          MessageId: generateUniqueId(), // Provide the actual MessageId here
          MessageType: "Logout" // Provide the actual MessageType here
        }
      };

      const response = await axios.post(userlogout, requestBody);

      if (response.status === 200) {
        setShowModal(false);
        toast.success('Logout successful!');
        navigate('/');
        onLogout();
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleActivity1 = () => {
    console.log('User is active');

  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Prevent the default behavior
      event.preventDefault();
      event.returnValue = 'Are you sure you want to reload?';
      return 'Are you sure you want to reload?';
    };


    const handleUnload = async () => {
      // Assuming onConfirm is an asynchronous function
      handleLogout()
      const RequestId = generateUniqueId();
      const messageId = generateUniqueId();

      const requestBody = {
        RequestId: RequestId, // Provide the actual RequestId here
        Messages: {
          Parameters: {
            UserId: currentUserID
          },
          MessageId: messageId, // Provide the actual MessageId here
          MessageType: "Logout" // Provide the actual MessageType here
        }
      };

      // Convert the requestBody to a JSON string
      const jsonString = JSON.stringify(requestBody);

      try {
        const response = await fetch(`${link}/Scanalyst/Logout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: jsonString,
          keepalive: true // Important to keep the request alive for a beacon-like behavior
        });

        if (response.ok) {
          console.log('Logout request sent successfully.');
        } else {
          console.error('Failed to send logout request. Status:', response.status);
        }
      } catch (error) {
        console.error('Error during logout request:', error);
      }
    }

    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
      document.removeEventListener('mousemove', handleActivity1);
      document.removeEventListener('keydown', handleActivity1);
    };
  }, []);

  const ConfirmationModal = ({ showModal, onConfirm, onCancel }) => {
    return (
      <div
        className={`${showModal ? 'z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg' : 'hidden'
          } bg-black bg-opacity-50`}
      >
        <div className="bg-[#FFFFFFE0] w-[640px] h-[20rem] p-4 rounded-[40px] flex flex-col justify-center items-center">
          <div className="text-center space-y-10">
            <div>
              <p className="text-xl font-semibold">Are you sure you want to</p>
              <p className="text-3xl font-semibold">LOGOUT?</p>
            </div>
            <div className="flex justify-center mt-6 gap-x-6">
              <button
                className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#E475A0] border border-[#E475A0] rounded-lg bg-[#FFFFFFE0]"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className="w-[180px] h-[40px] font-semibold text-[16px] text-center text-[#FFFFFF] rounded-lg bg-[#E475A0]"
                onClick={onConfirm}
              >
                {loading ? loader : 'Logout'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red" />
  );

  return (
    <>
      <button onClick={() => setShowModal(true)}>
        <AiOutlinePoweroff size={30} className="hover:text-[red]" />
      </button>
      {showModal && (
        <ConfirmationModal
          showModal={showModal}
          onConfirm={handleConfirmation}
          onCancel={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default Logout;
