import React, { useState } from "react";
import { Menu, Dropdown, Button, Input } from "antd";
import './CustomDatePicker.css';

const DropdownCheckboxh = ({ label, name, value = [], onChange, options = [], error }) => {
  const [visible, setVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation(); // Prevent dropdown from closing
  };

  const handleCheckboxChange = (e) => {
    const { value: checkboxValue, checked } = e.target;
    console.log(`Checkbox value: ${checkboxValue}, Checked: ${checked}`);
    onChange(checkboxValue, checked);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Ensure value is an array and normalize it
  const normalizedValue = Array.isArray(value) ? value.map(v => v.trim().toLowerCase()) : [];
  const normalizedOptions = Array.isArray(options) ? options.map(o => o.trim().toLowerCase()) : [];

  const filteredOptions = normalizedOptions.filter((option) =>
    option.includes(searchQuery.toLowerCase())
  );

  const menu = (
    <Menu onClick={handleMenuClick} className="max-h-60 overflow-y-auto scroller">
      <Menu.Item key="search" className="px-3 py-2">
        <Input
          placeholder="Search habits"
          value={searchQuery}
          onChange={handleSearch}
          className="w-full"
        />
      </Menu.Item>
      {filteredOptions.length > 0 ? (
        filteredOptions.map((option) => {
          // Find the original option value from the normalized option
          const originalOption = options[normalizedOptions.indexOf(option)];
          return (
            <Menu.Item key={originalOption}>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name={name}
                  value={originalOption}
                  checked={normalizedValue.includes(option)}
                  onChange={handleCheckboxChange}
                  className="form-checkbox custom-checkbox"
                />
                <span className="ml-2">{originalOption}</span>
              </label>
            </Menu.Item>
          );
        })
      ) : (
        <Menu.Item disabled>No habits found</Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className="flex flex-col gap-y-2">
      <label>{label}</label>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={visible}
        onVisibleChange={(flag) => setVisible(flag)}
      >
        <Button className="px-3 py-2 border rounded-md focus:outline-none text-left bg-white">
       Select Habits
        </Button>
      </Dropdown>
      {error && (
        <span className="text-red-500 text-sm">{error}</span>
      )}
    </div>
  );
};

export default DropdownCheckboxh;
