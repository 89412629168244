// HealthForecastChart.js
import React, { useEffect } from 'react';
import Plot from 'react-plotly.js'; // or wherever your Plot component is coming from


const TempForecastChart = ({ xDatah, yDatah }) => {
  // Use useEffect to print xData when it changes
  useEffect(() => {
    console.log('xData:', xDatah);
  }, [xDatah]); // Dependency array includes xData, so this runs whenever xData changes



  return (
    <Plot
    data={[
      {
        x: xDatah,
        y: yDatah,
        type: 'scatter',
        mode: 'lines+markers',
        name: 'red',
        line: { color: 'red' },
        marker: { color: 'red' },
        hovertemplate: 'Temp: %{y:.2f} °F<extra></extra>', // Customize hover text to show only the y-value
      },
    ]}
    layout={{
      width: 1100,
      height: 620,
      title: 'Temp Forecast',
      xaxis: {
        title: 'Date',
        tickmode: 'array',
        tickvals: xDatah,
        ticktext: xDatah,
        showgrid: true, // Ensure the grid is shown for better visibility
        zeroline: false, // Hide zero line if it's not needed
      },
      yaxis: {
        title: 'Temp',
        tickmode: 'array',
        tickvals: [50, 70, 90, 110, 130],
        ticktext: ['50 °F', '70 °F', '90 °F', '110 °F', '130 °F'],
        range: [45, 135], // Adjust range based on your data
        showgrid: true, // Ensure the grid is shown for better visibility
        zeroline: false, // Hide zero line if it's not needed
      },
      legend: {
        x: 0.5,
        y: -0.2,
        orientation: 'h',
        xanchor: 'center',
      },
    }}
  />
  
  );
};

export default TempForecastChart;
