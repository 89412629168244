import React, { useEffect, useState } from 'react'
import Tabs from "../../components/Radiologist/Tabs";
import Header from '../../components/Radiologist/Header';
import Settings from '../../Utils/Settings1';
import axios from 'axios';
import {currentUserID} from "../../Utils/USER";
import SearchBar from '../../components/Radiologist/SearchBar';
import Table from '../../components/Radiologist/Table';
import { Rings } from 'react-loader-spinner';
const {  radiologistDashboard ,consultedRadiologistDashboard,consultingRadiologistDashboard,RadiologistList,analysislink} = Settings;

function RadiologistRole() {
  const [radiologistData, setRadiologistData] = useState({});
  const [consultedradiologistData, setConsultedRadiologistData] = useState({});
  const [consultingradiologistData, setConsultingRadiologistData] = useState({});
  const [coconsultRadiologistList, setcoconsultRadiologistList] = useState({});

  

  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [active, setActive] = useState(0);
  const reloadTableData = () => {
   
    fetchRadiologistData()
    console.log("hhh",radiologistData)

  };
 
  const generateUniqueId = () => {
    // Function to generate a unique identifier
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (Math.random() * 16)) >> (c / 4) & 15
    ).toString(16);
  }



  async function fetchRadiologistData() {
    try {
      setLoading(true);
      const dashboardpayload={
        "RequestId": generateUniqueId(),
        "Requestor": "Username",
        "Messages": {
          "MessageId": generateUniqueId(),
          "MessageType": radiologistDashboard.split('/').pop(),
          "Timestamp": new Date().toISOString(),
          "Body": {
                
          },
          "Parameters": {
            "UserId": currentUserID,
            "UserAccessToken": "",                              
            "APIVersion": ""
          },
          "Headers": {}
        }
      }
     const consultedpayload= {
        "RequestId":  generateUniqueId(),
        "Requestor": "Username",
        "Messages": {
          "MessageId":  generateUniqueId(),
          "MessageType": consultedRadiologistDashboard.split('/').pop(),
          "Timestamp":  new Date().toISOString(),
          "Body": {
                
          },
          "Parameters": {
            "UserId": currentUserID,
            "UserAccessToken": "",                              
            "APIVersion": ""
          },
          "Headers": {}
        }
      }
      const payload={
        "RequestId": generateUniqueId(),
        "Requestor": "Username",
        "Messages": {
          "MessageId": generateUniqueId(),
          "MessageType": consultingRadiologistDashboard.split('/').pop(),
          "Timestamp": new Date().toISOString(),
          "Body": {
                
          },
          "Parameters": {
            "UserId": currentUserID,
            "UserAccessToken": "",                              
            "APIVersion": ""
          },
          "Headers": {}
        }
      }
      const coconsultRadiologistListpayload={
        "RequestId": generateUniqueId(),
        "Requestor": "Username",
        "Messages": {
          "MessageId":generateUniqueId(),
          "MessageType": RadiologistList.split('/').pop(),
          "Timestamp": new Date().toISOString(),
          "Body": {
                
          },
          "Parameters": {
            "UserId": currentUserID,
            "UserAccessToken": "",                              
            "APIVersion": ""
          },
          "Headers": {}
        }
      }
      
      const res = await axios.post(radiologistDashboard,dashboardpayload);
      const res1 = await axios.post(consultedRadiologistDashboard,consultedpayload);
      const res2 = await axios.post(consultingRadiologistDashboard,payload);
      const res3 = await axios.post(RadiologistList,coconsultRadiologistListpayload);

      const responseData = res.data;
      setRadiologistData(res.data.Responses[0]?.Body?.data || {});
      setConsultedRadiologistData(res1.data.Responses[0].Body.data);
      setConsultingRadiologistData(res2.data.Responses[0].Body.data);
      setcoconsultRadiologistList(res3.data.Responses[0].Body.data);


      console.log("res1.data.Responses[0].Body.data")

       } catch (error) {
      console.error("Error", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchRadiologistData();
  }, []);

  return (
    <div className="h-screen px-14 pb-5 overflow-x-hidden bg-gradient-to-r from-blue-100 via-purple-100 to-red-100">
      <div className='flex'>
        <Header />
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>
      <Tabs
        active={active}
        setActive={setActive}
        radiologistData={radiologistData}
        reloadTable={reloadTableData} 
        coconsultRadiologistList={coconsultRadiologistList}
        // Pass the reload function to the Tabs component

      />
      {loading ?
        <div className="flex justify-center h-full items-center">
          <Rings height="160" width="160" color="#8e75d4" />
        </div> :
        <Table
          active={active}
          radiologistData={radiologistData}
          consultedradiologistData={consultedradiologistData}
          consultingradiologistData={consultingradiologistData}
          coconsultRadiologistList={coconsultRadiologistList}
       

          // setRadiologistData={setRadiologistData}
          reloadTable={reloadTableData} // Pass the reload function to the Tabs component
          />

      }

    </div>
  )

}
export default RadiologistRole;

