import React, { useState, useMemo, useEffect } from "react";
import { Space, Table, Tag, Checkbox, DatePicker, Slider, Button } from "antd";
import { MdOutlineFileDownload, MdOutlineRemoveRedEye } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { AiFillFilter } from "react-icons/ai";
import { useRadioFilterContext } from "./RadioFilterContext";
import FilterDropdown from "./FilterDropdown";
import "./CustomDatePicker.css";
import DropdownButton from "../Technician/Dropdown1";
import { useSelection } from "./selectionContext";
import DownloadDropdownButton from "../Technician/Downloaddrop";
import ReportDropdownButton from "./ReportsDrop";
import { formatInTimeZone } from 'date-fns-tz';

const 

Datatable = ({
  setShowModalh,
  showModalh,
  data,
  handleDownload,
  setShowModal,
  setConfirmationModalData,
  handleView,
  setFormData,
  formData,
  flag,
  radiologistData,
  consultedradiologistData,
  consultingradiologistData,
  active,
  handleModelView,
  setShowModal2

}) => {
  const {
    dateRange,
    setDateRange,
    aiFilter,
    setAiFilter,
    ageRange,
    setAgeRange,
    sexFilter,
    setSexFilter,
    bodyPartFilter,
    setBodyPartFilter,
    modalityFilter,
    setModalityFilter,
    reportStatusFilter,
    setReportStatusFilter,
    radiologistFilter,
    setRadiologistFilter,
    aiSelected,
    setAiSelected,
    sexSelected,
    setSexSelected,
    bodyPartSelected,
    setBodyPartSelected,
    modalitySelected,
    setModalitySelected,
    reportStatusSelected,
    setReportStatusSelected,
    radiologistSelected,
    selectedAgeKeys,
    setSelectedAgeKeys,
    setRadiologistSelected,
  } = useRadioFilterContext();

  const { selectedItems, handleCheckboxChange } = useSelection();
  console.log("scanid:", data);

  const extractPatientScanIds = (patients) => {
    if (!Array.isArray(patients)) {
      throw new TypeError("Expected an array of patients.");
    }
  
    const patientScanIds = {};
  
    patients.forEach((patient) => {
      const { Name, MRI_Scan_ID, CT_Scan_ID, US_Scan_ID, DX_Scan_ID } = patient;
      patientScanIds[Name] = {
        MRI: MRI_Scan_ID || [],
        CT: CT_Scan_ID || [],
        US: US_Scan_ID || [],
        DX: DX_Scan_ID || [],
      };
    });
  
    return patientScanIds;
  };
  
  console.log("scanid:", data);
  const patientScanIds = extractPatientScanIds(data || []);
  
  // const handleCheckboxChange = (record, checked) => {
  //   if (checked) {
  //     setSelectedRows([...selectedRows, record]);
  //   } else {
  //     setSelectedRows(selectedRows.filter((row) => row !== record)); 
  //   }
  // };
  const parentModalities = ['MRI', 'CT', 'US', 'DX']; // Define modalities here

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      
      // // Ensure the dates are Day.js objects and log their formatted values
      // console.log("Start Date:", startDate.format('DD/MM/YYYY'));
      // console.log("End Date:", endDate.format('DD/MM/YYYY'));
      
      // Optionally, you can also log the raw JavaScript Date objects
      // console.log("Start Date (raw):", startDate.toDate());
      // console.log("End Date (raw):", endDate.toDate());
    } else {
      console.log("Invalid date range");
    }
    
    // Set the date range state
    setDateRange(dates);
  };
  
  const handleAgeChange = (range) => {
    setAgeRange(range);
    setSelectedAgeKeys(range);
    console.log("datechange",range)

  };
  const handleAiChange = (ai) => {
    setAiFilter(ai);
  };
  const handlesexChange = (sex) => {
    setAiFilter(sex);
  };
  const formatDate = (date) => {
    try {
      // Use 'UTC' time zone for consistent formatting
      return formatInTimeZone(new Date(date), 'UTC', 'dd-MM-yyyy');
    } catch {
      return "Invalid Date";
    }
  };
  const formatDate1 = (date) => {
    try {
      // Use 'UTC' time zone for consistent formatting
      return formatInTimeZone(new Date(date), 'UTC', 'dd-MM-yyyy');
    } catch {
      return "Invalid Date";
    }
  };
  const extractPatientreport = (patients) => {
    const patientreport = {};
  
    patients.forEach((patient) => {
      const { Name, Radiologist_Report_Name } = patient;
      patientreport[Name] = {
        Radiologist_Report_Name: Radiologist_Report_Name || [],
      };
    });
  
    return patientreport;
  };
  const patienreport = active !== 0 
  ? extractPatientreport(consultingradiologistData.consultingPatientData || [])
  : extractPatientreport(radiologistData.patientsData || []);


  
  const filteredData = useMemo(() => {
    // console.log("Original Data:", data?.date);
  
    let filtered = data?.map((item) => {
      return {
        ...item,
        consultedBy: (item.Name_Consulted_By || []).join(', '),
        consultedTo: (item.Name_Consulted_To || []).join(', ')
      };
    });
  
    if (dateRange && dateRange.length === 2) {
      const [startDate, endDate] = dateRange;
      filtered = filtered.filter((record) => {
        const recordDate = record && record.$date ? formatDate1(record.$date) : null;
        
        if (!recordDate) {
          return false; // Exclude records without valid dates
        }
    
        // Ensure date comparisons are correct
        const formattedStartDate = formatDate1(startDate);
        const formattedEndDate = formatDate1(endDate);
        console.log("Data after mapping:", recordDate);

        return recordDate >= formattedStartDate && recordDate <= formattedEndDate;
      });
    }
   

    if (aiFilter && aiFilter.length > 0) {
      filtered = filtered.filter((record) => {
        // Check if the record's `ai` status matches any of the filters
        return aiFilter.includes(record.AI_Disease_Diagnosis_Status);
      });
    }
    if (ageRange && ageRange.length > 0) {
      filtered = filtered.filter((record) => {
        const age = parseInt(record.age);
        const [minAge, maxAge] = ageRange;
        return age >= parseInt(minAge) && age <= parseInt(maxAge);
      });
      console.log("Data after age range filter:", filtered);

    }
    if (sexFilter && sexFilter.length > 0) {
      filtered = filtered.filter((record) => sexFilter.includes(record.Sex));
    }
    if (bodyPartFilter && bodyPartFilter.length > 0) {
      filtered = filtered.filter((record) =>
        bodyPartFilter.includes(record.bodyPart)
      );
    }
    if (modalityFilter && modalityFilter.length > 0) {
      filtered = filtered.filter((record) =>
        modalityFilter.includes(record.modality)
      );
    }
    if (reportStatusFilter && reportStatusFilter.length > 0) {
      filtered = filtered.filter((record) =>
        reportStatusFilter.includes(record.reportStatus)
      );
    }
    if (radiologistFilter && radiologistFilter.length > 0) {
      filtered = filtered.filter((record) => {
        for (const element of radiologistFilter) {
          if (element === "Done" && record.radiologist.length > 0) {
            return true;
          } else if (element === "Pending" && record.radiologist.length === 0) {
            return true;
          } else {
            return false;
          }
        }
      });
    }

    return filtered;
  }, [
    data,
    ageRange,
    dateRange,
    aiFilter,
    sexFilter,
    bodyPartFilter,
    modalityFilter,
    radiologistFilter,
    reportStatusFilter,
  ]);

  
  const aiOptions = [
    { value: "Abnormal", label: "Abnormal" },
    { value: "Normal", label: "Normal" },
  ];

  const sexOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const bodyPartOptions = [
    { value: "CHEST", label: "Chest" },
    { value: "HEAD", label: "Head" },
    { value: "ARMS", label: "Arms" },
    { value: "LEGS", label: "Legs" },
  ];

  const modalityOptions = [
    { value: "CT", label: "CT" },
    { value: "DX", label: "DX" },
    { value: "MRI", label: "MRI" },
    { value: "USG", label: "USG" },
  ];

  const radiologistOptions = [
    { value: "Done", label: "Done" },
    { value: "Pending", label: "Pending" },
  ];

  const reportStatusOptions = [
    { value: "Done", label: "Done" },
    { value: "Pending", label: "Pending" },
  ];

  const columns = [
    {
      dataIndex: "select",
      render: (_, record) => (
        <Checkbox
          // Determine checked state based on selectedRows
          onChange={() => handleCheckboxChange(record.uid, record.name, record.id)}
          checked={selectedItems.some((row) => row.id.$oid === record.id.$oid)}
        />
      ),
    },
    {
      title: "AI",
      dataIndex: "AI_Disease_Diagnosis_Status",
      key: "AI_Disease_Diagnosis_Status",
      align: "center",
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDropdown
          setSelectedKeys={setAiSelected}
          selectedKeys={aiSelected}
          confirm={confirm}
          clearFilters={clearFilters}
          onChange={handleAiChange}
          options={aiOptions}
          setFilter={setAiFilter}
        />
      ),
      
      filterIcon: () => (
        <AiFillFilter
          style={{ color: aiFilter?.length > 0 ? "#36ae78" : "inherit" }}
        />
      ),
      render: (status) => {
        return status === "Abnormal" ? (
          <div className="h-[24px] w-[24px] text-[#FFFFFF] text-xs flex items-center justify-center rounded-full bg-[#E475A0]">
            A
          </div>
        ) : (
          <div className="h-[24px] w-[24px] text-[#FFFFFF] text-xs flex items-center justify-center rounded-full bg-[#36AE78]">
            N
          </div>
        );
      }
    },
    
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
      align: "center",
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <Slider
            range
            defaultValue={[0, 100]}
            min={0}
            max={100}
            onChange={handleAgeChange}
            value={selectedAgeKeys}
          />
          <Space>
            <Button
              type="button"
              onClick={() => {
                confirm(); // Confirm immediately to trigger filter update
              }}
            >
              OK
            </Button>
            <Button
              type="button"
              onClick={() => {
                setSelectedAgeKeys([0, 100]); // Reset the slider range
                setAgeRange([]); // Reset the slider range
                confirm(); // Confirm the reset
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: () => (
        <span>
          <AiFillFilter
            size={14}
            style={{ color: ageRange?.length > 0 ? "#36ae78" : "inherit" }}
          />
        </span>
      ),
      render: (age) => `${age}`,
    },

    {
      title: "Sex",
      dataIndex: "sex",
      key: "sex",
      align: "center",
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDropdown
          setSelectedKeys={setSexSelected}
          selectedKeys={sexSelected}
          confirm={confirm}
          clearFilters={clearFilters}
          options={sexOptions}
          setFilter={setSexFilter}
          onChange={handleAgeChange}
        />
      ),
      filterIcon: () => (
        <span>
          <AiFillFilter
            size={14}
            style={{ color: sexFilter?.length > 0 ? "#36ae78" : "inherit" }}
          />
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "$date",
      align: "center",
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <DatePicker.RangePicker
            onChange={handleDateRangeChange}
            value={dateRange}
            style={{ width: 200, marginBottom: 8, display: "block" }}
            popupStyle={{ transition: "none" }} // Disable sliding animation
            transitionName="" // Disable hover effect
          />
        </div>
      ),
      filterIcon: () => (
        <span>
          <AiFillFilter
            size={14}
            style={{ color: dateRange ? "#36ae78" : "inherit" }}
          />
        </span>
      ),
      // Hide default filter icon
      render: (text) => {
        // Access the $date field from the nested structure
        return text && text.$date ? formatDate1(text.$date) : 'N/A';
      },
    },
   
    {
      title: "Radiologist",
      dataIndex: "radiologist",
      key: "radiologist",
      align: "center",
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDropdown
          setSelectedKeys={setRadiologistSelected}
          selectedKeys={radiologistSelected}
          confirm={confirm}
          clearFilters={clearFilters}
          options={radiologistOptions}
          setFilter={setRadiologistFilter}
        />
      ),
      filterIcon: () => (
        <span>
          <AiFillFilter
            size={14}
            style={{
              color: radiologistFilter?.length > 0 ? "#36ae78" : "inherit",
            }}
          />
        </span>
      ),
      render: (_, { radiologist }) => {
        return !radiologist ? "Pending" : radiologist;
      },
    },

    flag && {
      title: "Consulted by",
      dataIndex: "consultedBy",
      key: "consultedBy",
      align: "center",
    },
    flag &&
    {
      title: "Consulted to",
      dataIndex: "consultedTo",
      key: "consultedTo",
      align: "center",
    },

    {
      title: "Final Report",
      dataIndex: "finalReport",
      key: "finalReport",
      align: "center",

      render: (_, record) => {
        return (
          <div className="flex justify-center gap-x-2">
            {/* <button
              onClick={() => {
                setShowModal(true);
                setConfirmationModalData(record);
                setFormData({
                  ...formData,
                  Patient_uid: record._id.$oid,
                });
              }}
              className="bg-[#FFFFFF8A] text-[#36AE78] border border-green-500 w-[24px] h-[24px] rounded-lg flex items-center justify-center"
            >
              <FaPen size={12} />
            </button> */}
            <ReportDropdownButton data={data} setShowModalh={setShowModalh} showModalh={showModalh} setShowModal2={setShowModal2} active={active} setShowModal={setShowModal} formData={formData} setConfirmationModalData={setConfirmationModalData} setFormData={setFormData}  record={record}   modalities={parentModalities} handleModelView={handleModelView}/>

            <DropdownButton  patientScanIds={patientScanIds}  handleView={handleView} record={record}   modalities={parentModalities} />

            <div className="flex justify-center">
            <DownloadDropdownButton size={12} handleDownload={handleDownload}  record={record} modalities={parentModalities} patienreport={patienreport}/>
           
          </div>
          </div>
        );
      },
    },
  ].filter(Boolean)

  return (
    <Table className="overflow-scroll no-scrollbar w-full bg-white"  formData={formData}  columns={columns} dataSource={filteredData} pagination={false} />
  );
};
export default Datatable;
