import React, { useEffect, useState } from 'react';
import { GrPrevious, GrNext } from 'react-icons/gr';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const DXReportPagination = ({ data = { CXR_AI_Report: [], CXR_AI_Report_Status: [], DX_Scan_ID: [] } }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Ensure the data arrays are valid
  const reports = Array.isArray(data.CXR_AI_Report) ? data.CXR_AI_Report : [];
  const statuses = Array.isArray(data.CXR_AI_Report_Status) ? data.CXR_AI_Report_Status : [];
  const scanIds = Array.isArray(data.DX_Scan_ID) ? data.DX_Scan_ID : [];

  useEffect(() => {
    console.log("DX_SCAN_ID:", data.DX_Scan_ID);
  }, [data.DX_Scan_ID]);

  const totalReports = reports.length;

  // Function to add <br /> after each full stop
  const addLineBreaks = (text) => {
    return text.replace(/(\.)(?=\s|$)/g, '.$1<br />');
  };

  // Handler for Previous button
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Handler for Next button
  const handleNext = () => {
    if (currentIndex < totalReports - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Safely handle undefined or malformed scan IDs
  const currentScanId = scanIds[currentIndex] ? scanIds[currentIndex].split('_')[1] : 'N/A';

  return (
    <div className="flex flex-col gap-y-5">
      {/* Report display */}
      {totalReports > 0 && (
        <div className="space-y-2">
          <p><strong>DX Scan ID:</strong> {currentScanId}</p>

          <p><strong>CXR AI Report Status:</strong> {statuses[currentIndex] || 'N/A'}</p>
          <div className="h-auto w-full text-sm font-medium bg-[#FFFFFF] border border-[#36AE78] rounded-xl p-3">
            {parse(DOMPurify.sanitize(addLineBreaks(reports[currentIndex] || '')))}
          </div>
        </div>
      )}
      
      {/* Pagination buttons */}
      <div className="flex justify-start items-center">
        <GrPrevious
          size={30} // Reduced size
          onClick={handlePrevious}
          className={`cursor-pointer hover:bg-gray-400 rounded-full p-1 ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          style={{ pointerEvents: currentIndex === 0 ? 'none' : 'auto' }}
        />
        <span className="ml-2 mr-2 text-sm">{totalReports > 0 ? `${currentIndex + 1} / ${totalReports}` : 'No reports'}</span>
        <GrNext
          size={30} // Reduced size
          onClick={handleNext}
          className={`cursor-pointer hover:bg-gray-400 rounded-full p-1 ${currentIndex === totalReports - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          style={{ pointerEvents: currentIndex === totalReports - 1 ? 'none' : 'auto' }}
        />
      </div>
    </div>
  );
};

export default DXReportPagination;
