import React from "react";
import { RxCross1 } from "react-icons/rx";
import Radiologist from "./Radiologist";
import Technician from "./Technician";
import UpdateHospital from "./UpdateHospital";
import Hospital from "./Hospital";
import axios from "axios";
import { Rings } from "react-loader-spinner";
import link from "../../../Utils/Settings";
import { currentUserID } from "../../../Utils/USER";
const Sidebar = ({sidebarPage, setSidebarOpen, sidebarOpen, superAdminData,reloadTable1,doctorspeData}) => { 
  const reloadTableData = () => {
    console.log("hhh")
   reloadTable1()
  };

  return (
    <> 
    {
      sidebarOpen &&
      <div className="fixed inset-0 bg-black opacity-50 z-40 " onClick={()=>setSidebarOpen(false)} >

      </div>
    }
    <div className={`z-50 fixed top-0 left-0 h-full pt-5 px-2 bg-white w-[25rem] shadow-lg ${!sidebarOpen? "-translate-x-full" : ""} transition-all duration-700 ease-in-out`}>
      <div onClick={()=>setSidebarOpen(false)} className="cursor-pointer bg-slate-200 p-2 w-fit rounded-full absolute right-3">
        <RxCross1 />
      </div>
      {
        sidebarPage == 0 ?
        <Radiologist setSidebarOpen={setSidebarOpen} superAdminData={superAdminData}   doctorspeData={doctorspeData}      reloadTable1={reloadTableData} // Pass the reload function to the Table component
        />
        : sidebarPage == 1 ?
        <Technician setSidebarOpen={setSidebarOpen} superAdminData={superAdminData}   reloadTable1={reloadTableData} />
        : sidebarPage == 2 ?
        <UpdateHospital setSidebarOpen={setSidebarOpen} superAdminData={superAdminData}   reloadTable1={reloadTableData} />
        : sidebarPage == 3 ?
        <Hospital setSidebarOpen={setSidebarOpen} superAdminData={superAdminData}   reloadTable1={reloadTableData} />
        :<></>
      }
    </div>
    </>
    
  );
};

export default Sidebar;