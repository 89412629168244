import React, {useEffect} from "react";
import Logout from "../../pages/Common/Logout";

export default function SearchBar({ searchQuery, setSearchQuery }) {
  const handleLogout = () => {
    // Logic to handle logout in the SearchBar component
    console.log('Logout successful in SearchBar component');
    // Add any other logic you want to execute on logout
  };
  
  return (
     <div className="flex justify-end w-full gap-x-6">

         <div className="relative ml-4 flex justify-start items-center gap-x-4">
          {/* <IoSearchOutline className="absolute text-2xl ml-3 text-[#7B7B7B]" /> */}
          <input
            value={searchQuery}
            onChange={(e)=>setSearchQuery(e.target.value)}
            className="px-4 py-1 w-[20rem] rounded-lg"
            type="text"
            placeholder="Search"
          />
          
        </div>
        <button>
        <Logout onLogout={handleLogout} />
      </button>
     </div>
       
  );
}
