import React, { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import logo from "../../assets/logo.png";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { currentUserID, currentUserRights } from "../../Utils/USER";
import { PiCloudArrowDown } from "react-icons/pi";
import { toast } from "react-hot-toast";
import axios from "axios";
import CTReportPagination from "./CT_Reportpages";
import DXReportPagination from "./DxReportpages";
import link from "../../Utils/Settings";
import RadioReport from "./RadioReport";
import Followup from "./Followup";
import Settings from '../../Utils/Settings1';
import FollowUps from "./Followupsec";
import { RxCross1 } from "react-icons/rx";
const { technicianDashboard,followup } = Settings;

const ConfirmationModal = ({ showModal, onConfirm, onCancel, data,  setPatientAdded,reloadTable,setShowModal,apiData
}) => {
  const [next, setNext] = useState(false);
  const [technicianData, setTechnicianData] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isRadiologistthere, setIsRadiologistthere] = useState(false);
  const [radiologistCurrent, setRadiologistCurrent] = useState(0);
  const [showConsultingReport, setShowConsultingReport] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [formData, setFormData] = useState({
    user_id: currentUserID,
    Scans: [],
  });
let dk=""
const reload = () => {
  console.log("meow");
  reloadTable();
};
function generateUniqueId() {
  const timestamp = new Date().getTime();
  const random = Math.random().toString(36).substring(2, 10); 
  return `${timestamp}-${random}`;
}
  useEffect(() => {
    console.log("sssss",data._id.$oid);
    if (data.Radiologist_Report || data.Consulting_Radiologist_Report) {
      setIsRadiologistthere(true);

      setShowConsultingReport(
        data.Consulting_Radiologist_Report ? true : false
      );
    }else{
      // setIsRadiologistthere(false);
      // setShowConsultingReport( false);
    }
  }, [data, next]);
  const handleDrop = (e) => {
    e.preventDefault();

    const items = e.dataTransfer.items;
    const scannedFiles = [];

    const traverseFilesAndDirectories = async (item) => {
      if (item.isFile) {
        const file = await new Promise((resolve) => item.file(resolve));
        scannedFiles.push(file);
      } else if (item.isDirectory) {
        const directoryReader = item.createReader();
        const directoryItems = await new Promise((resolve) =>
          directoryReader.readEntries(resolve)
        );
        for (const directoryItem of directoryItems) {
          await traverseFilesAndDirectories(directoryItem);
        }
      }
    };
    
    const scanDroppedItems = async () => {
      for (const item of items) {
        await traverseFilesAndDirectories(item.webkitGetAsEntry());
      }
      setFormData({ ...formData, Scans: [...formData.Scans, ...scannedFiles] });
      setIsDragging(true);
    };

    scanDroppedItems();
  };


  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFormData({ ...formData, Scans: [...formData.Scans, ...selectedFiles] });
    // setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };
  const handleRemoveFile = (index) => {
    const updatedScans = [...formData.Scans];
    updatedScans.splice(index, 1);
    setFormData({ ...formData, Scans: updatedScans });
  };
  function reset() {
    setFormData({
      user_id: currentUserID,
      Scans: [],
    });
    setIsDragging(false);
  }
  async function handleSubmit() {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", currentUserID);
    formDataToSend.append("patientRegUID", data._id.$oid);
  
    if (!formData.Scans || formData.Scans.length === 0) {
      toast.error("No files selected");
      return;
    }
  
    // Validate files
    for (let i = 0; i < formData.Scans.length; i++) {
      const file = formData.Scans[i];
      const fileName = file.name.toLowerCase();
      const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
  
      if (fileExtension !== "dcm" && fileExtension !== "dicom") {
        toast.error("Please select only DICOM files.");
        return;
      }
      formDataToSend.append("Scans", file);
    }
  
    const loadingToastId = toast.loading("Submitting...", { position: "top-center" });
  
    try {
      setLoading(true);
      const response = await axios.post(
        `${link}/Scanalyst/Upload_Files_Processors`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      // Log the response for debugging
      console.log("Response:", response);
  
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        setShowModal(false);
        toast.success("Form submitted successfully.", { id: loadingToastId });
        reload();
        reset();
      } else {
        toast.error("Submission failed. Please try again.", { id: loadingToastId });
      }
    } catch (error) {
      // Log error details
      console.error("Error occurred:", error.response || error);
      toast.error("Error submitting form.", { id: loadingToastId });
    } finally {
      setLoading(false);
    }
  }
  
  
  const loader = (
    <div className="w-[1.5rem] h-[1.5rem] mx-auto animate-spin bg-transparent rounded-full border-2 border-r-2 border-t-2 border-l-transparent border-b-transparent border-red"></div>
  );
  function nextRadiologist() {
    if (
      radiologistCurrent <
      (showConsultingReport
        ? data.Consulting_Radiologist_Report.length
        : data.Radiologist_Report.length) -
        1
    ) {
      setRadiologistCurrent((prev) => prev + 1);
    }
  }

  function prevRadiologist() {
    if (radiologistCurrent > 0) {
      setRadiologistCurrent((prev) => prev - 1);
    }
  }
  const formatDate = (date) => {
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${day}-${month}-${year}`;
    } else {
      return "Invalid Date";
    }
  };
  const handleAddPatientClick = () => {
    setSidebarOpen(true);
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    console.log(next,"state",isRadiologistthere,"radiolo")
  
  }, [next,isRadiologistthere])

  const testsArray = JSON.parse(data.AI_Tests.replace(/'/g, '"'));
  const formatText = (text) => {
    return text.replace(/\. /g, '.<br />');
  };

  
  
  //clean text for report 
  const cleanText = (text) => {
    if (!text) return "";
  
    // Regex to match JSON-like structures, including arrays and objects
    const jsonStructureRegex = /(\[.*?\]|\{.*?\})/g;
  
    return text.replace(jsonStructureRegex, (match) => {
      try {
        // Ensure proper JSON format
        const jsonString = match
          .replace(/'/g, '"') // Replace single quotes with double quotes
          .replace(/,\s*(}|\])/g, '$1'); // Remove trailing commas
  
        // Parse JSON
        const obj = JSON.parse(jsonString);
  
        // Format array or single object
        if (Array.isArray(obj)) {
          return obj.map(item => {
            const title = item.title ? `Title: ${item.title}` : '';
            const content = item.content ? `Content: ${item.content}` : '';
            return `${title}\n${content}`;
          }).join('\n\n');
        } else {
          const title = obj.title ? `Title: ${obj.title}` : '';
          const content = obj.content ? `Content: ${obj.content}` : '';
          return `${title}\n${content}`;
        }
      } catch (e) {
        console.error("Failed to parse JSON:", match, e);
        return `Invalid JSON: ${match}`;
      }
    }).replace(/[\r\n]+/g, '\n').trim();
  };
  
  //remove the title and content tag
  // const cleanText = (text) => {
  //   if (!text) return "";
  
  //   // Regex to remove JSON-like structures, including nested structures
  //   const jsonStructureRegex = /(\{.*?\})/g;
  
  //   // Regex to remove 'title:' and 'content:' labels and their content
  //   const titleContentRegex = /(title|content):\s*[^,\n]+/gi;
  
  //   return text
  //     .replace(jsonStructureRegex, '') // Remove JSON-like structures
  //     .replace(titleContentRegex, '')  // Remove title: and content: labels with their content
  //     .replace(/[\r\n]+/g, '\n')       // Normalize new lines
  //     .trim();
  // };
  
  let habitsArray = data.Habits;

  // Convert to array if it's a string (e.g., "habit1, habit2, habit3")
  if (typeof habitsArray === 'string') {
      habitsArray = habitsArray.split(',').map(item => item.trim());
  } else if (!Array.isArray(habitsArray)) {
      // Handle other cases, like converting objects or other structures to array
      console.log('data.Habits is neither an array nor a string:', data.Habits);
  }
  const sanitizedAIReport = cleanText(data.AI_Report);
  const sanitizedConsultingReport = showConsultingReport ? data.Consulting_Radiologist_Report.map(cleanText) : [];

  return (
    <>
      <div
        className={`${
          showModal
            ? " z-40 fixed top-0 inset-0 flex items-center justify-center h-full backdrop-blur-lg "
            : "hidden"
        } bg-black bg-opacity-50`}
      >
        <div className="modal-container-outer">
        <div className="bg-[#FFFFFFE0] relative flex flex-col justify-start w-[75rem] h-[660px] p-6 rounded-[40px] gap-x-3 modal-container-inner">
          <RxCross2
            size={30}
            onClick={() => {
              onCancel();
              setNext(false);
              setIsRadiologistthere(false);
            }}
            className=" cursor-pointer absolute hover:bg-gray-400 rounded-full p-1 left-[70rem]"
          />
          {isRadiologistthere ? (
            <>
              {!next ? (
                <div className=" flex flex-col justify-start h-full gap-y-4 ">
                  <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
  <div>
    <p><strong>Name:</strong>  <span className="ms-1">{data.Name}</span></p>
    <p><strong>Age: </strong>  <span className="ms-1">{data.Age}</span></p>
    <p><strong>Sex: </strong>  <span className="ms-1">{data.Sex}</span></p>

    <p><strong>Height: </strong> <span className="ms-1"> {data.Height} cm</span></p>
    <p><strong>Weight: </strong> <span className="ms-1"> {data.Weight} kg</span></p>
    <p><strong>SPO2: </strong> <span className="ms-1"> {data.SPO2} %</span></p>
    <p><strong>Blood Pressure: </strong> <span className="ms-1"> {data.SBP}/{data.DBP}</span></p>
    <p><strong>Temperature: </strong> <span className="ms-1"> {data.Temp} °C</span></p>
    <p><strong>Habits:</strong> <span className="ms-1"> {habitsArray.join(', ')}</span></p>
   
    <p><strong>Symptoms:</strong> <span className="ms-1">{data.Symptoms.join(', ')}</span></p>
  
  </div>
  <div>
  <p><strong>Date:</strong><span className="ms-1"> {formatDate(new Date(data.date.$date))}</span></p>
  <p><strong>Diagnosing Radiologist: </strong><span className="ms-1">{data?.Radiologist_Name?.join(", ")}</span></p>
  <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{data.Assigned_Radiologist_Name}</span></p>
  <p><strong>Patient Status:</strong> <span className="ms-1">{data.AI_Disease_Diagnosis_Status}</span></p>
    <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{data.AI_Disease_Label}</strong> with probability of <strong> {data.AI_Disease_confidence}</strong></span></p>
   
    {('MedicalHistory' in data) && (
      <p><strong>Medical History:</strong> <span className="ms-1"> {data.MedicalHistory}</span></p>
    )}
        <p className="text-sm"><strong>Tests:</strong>  <span className="ms-1">{testsArray.join(', ')}</span></p>

  </div>
    <button
        onClick={handleAddPatientClick}
        className={`flex justify-center w-[130px] items-center bg-[#36AE78] rounded-xl h-9 m-4 px-6 gap-1 active:scale-95 active:transform transition-transform duration-300 focus:outline-none ${
          currentUserRights === "View" ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <p className="text-[white] font-semibold text-[16px] leading-6 flex items-center gap-x-2">
Follow up        </p>
      </button>
  
      {showConfirmationModal && (
  <div className="fixed top-0 inset-0 flex items-center justify-center h-full bg-black bg-opacity-50 z-40 backdrop-blur-lg">
    <div className={`z-50 fixed top-0 left-0 h-full pt-5 px-2 bg-white w-[50%] shadow-lg ${!sidebarOpen ? "-translate-x-full" : ""} transition-all duration-700 ease-in-out`}>
      <div onClick={() => { setSidebarOpen(false); setShowConfirmationModal(false); }} className="cursor-pointer bg-slate-200 p-2 w-fit rounded-full absolute right-3">
        <RxCross1 />
      </div>
      <Followup setSidebarOpen={setSidebarOpen} data={data} setShowConfirmationModal={setShowConfirmationModal} showConfirmationModal={showConfirmationModal} technicianData={technicianData} reloadTable={reloadTable} apiData={apiData}  />
    </div>
  </div>
)}
   
</div>
{('followUps' in data) && (
  <FollowUps data={data}/>)}
       

                  <hr className="border-[#36AE78]" />
                  <RadioReport data={data}/>
                <>
                </>
                       <div className="flex justify-end items-end p-1">
            <GrPrevious
              size={40}
              onClick={() => setNext(false)}
              className=" cursor-pointer hover:bg-gray-400 rounded-full p-2 right-[45rem]"
            />
            <GrNext
              size={40}
              onClick={() => setNext(true)}
              className="cursor-pointer hover:bg-gray-400 rounded-full p-2"
            />
          </div>
                </div>
              ) : (
                <div className=" flex flex-col justify-start h-full gap-y-4 w-full ">
               <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
  <div>
    <p><strong>Name:</strong>  <span className="ms-1">{data.Name}</span></p>
    <p><strong>Age: </strong>  <span className="ms-1">{data.Age}</span></p>
    <p><strong>Sex: </strong>  <span className="ms-1">{data.Sex}</span></p>

    <p><strong>Height: </strong> <span className="ms-1"> {data.Height} cm</span></p>
    <p><strong>Weight: </strong> <span className="ms-1"> {data.Weight} kg</span></p>
    <p><strong>SPO2: </strong> <span className="ms-1"> {data.SPO2} %</span></p>
    <p><strong>Blood Pressure: </strong> <span className="ms-1"> {data.SBP}/{data.DBP}</span></p>
    <p><strong>Temperature: </strong> <span className="ms-1"> {data.Temp} °C</span></p>
    <p><strong>Habits:</strong> <span className="ms-1"> {habitsArray.join(', ')}</span></p>
  
    <p><strong>Symptoms:</strong> <span className="ms-1">{data.Symptoms.join(', ')}</span></p>
  
  </div>
  <div>
  <p><strong>Date:</strong><span className="ms-1"> {formatDate(new Date(data.date.$date))}</span></p>
  <p><strong>Diagnosing Radiologist: </strong><span className="ms-1">{data?.Radiologist_Name?.join(", ")}</span></p>
  <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{data.Assigned_Radiologist_Name}</span></p>
  <p><strong>Patient Status:</strong> <span className="ms-1">{data.AI_Disease_Diagnosis_Status}</span></p>
    <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{data.AI_Disease_Label}</strong> with probability of <strong> {data.AI_Disease_confidence}</strong></span></p>
   
    {('MedicalHistory' in data) && (
      <p><strong>Medical History:</strong> <span className="ms-1"> {data.MedicalHistory}</span></p>
    )}
        <p className="text-sm"><strong>Tests:</strong>  <span className="ms-1">{testsArray.join(', ')}</span></p>

  </div>
 
</div>
       
                  <div className="flex items-center justify-between">
                  <button
        onClick={handleAddPatientClick}
        className={`flex justify-center w-[130px] items-center bg-[#36AE78] rounded-xl h-9 m-4 px-6 gap-1 active:scale-95 active:transform transition-transform duration-300 focus:outline-none ${
          currentUserRights === "View" ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <p className="text-[white] font-semibold text-[16px] leading-6 flex items-center gap-x-2">
Follow up        </p>
      </button>
   
      {showConfirmationModal && (
  <div className="fixed top-0 inset-0 flex items-center justify-center h-full bg-black bg-opacity-50 z-40 backdrop-blur-lg">
    <div className={`z-50 fixed top-0 left-0 h-full pt-5 px-2 bg-white w-[50%] shadow-lg ${!sidebarOpen ? "-translate-x-full" : ""} transition-all duration-700 ease-in-out`}>
      <div onClick={() => { setSidebarOpen(false); setShowConfirmationModal(false); }} className="cursor-pointer bg-slate-200 p-2 w-fit rounded-full absolute right-3">
        <RxCross1 />
      </div>
      <Followup setSidebarOpen={setSidebarOpen} data={data} setShowConfirmationModal={setShowConfirmationModal} showConfirmationModal={showConfirmationModal}  technicianData={technicianData} reloadTable={reloadTable} apiData={apiData}  />
    </div>
  </div>
)}
  <p className="text-sm">{dk}</p>
  <div className="flex items-center gap-x-4 ml-auto">
    <div className="relative flex items-center border border-[#36AE78] border-dashed p-2 rounded-md">
      <input
        name="Scans"
        type="file"
        className="hidden"
        id="file-input"
        webkitdirectory=""
        directory=""
        multiple
        onChange={handleFileInputChange}
      />
      <label
        htmlFor="file-input"
        className="flex items-center justify-center text-blue-600 hover:underline cursor-pointer"
      >
        <PiCloudArrowDown
          className={`text-2xl font-normal ${
            isDragging ? "text-[#36AE78]" : "text-slate-400"
          }`}
        />
        <span className="text-slate-400 ml-2">Browse (Add Scan)</span>
      </label>
    </div>
    <button
      onClick={handleSubmit}
      className="bg-[#36AE78] text-white font-semibold px-4 py-2 rounded-xl active:scale-95 active:transform transition-transform duration-300 focus:outline-none"
    >
      {loading ? loader : "Add"}
    </button>
  </div>
</div>

          
            <div className="w-full">
              <ul className="w-full py-[3px] flex gap-x-3 px-2 overflow-x-scroll no-scrollbar">
                {formData.Scans.map((file, index) => (
                  <li
                    className="border border-[#36AE78] text-[#36AE78] bg-[#c1f6de] bg-opacity-15 rounded-full w-fit pl-2 pr-1 whitespace-nowrap flex items-center gap-x-1"
                    key={index}
                  >
                    {file.name.length > 15
                      ? file.name.substring(0, 14) + "..."
                      : file.name}
                    <RxCross2
                      className="cursor-pointer ml-2"
                      onClick={() => handleRemoveFile(index)}
                    />
                  </li>
                ))}
              </ul>
              <p className="text-left text-slate-500 text-sm pl-3 pt-2">
                {formData.Scans.length > 0 ? "Files: " + formData.Scans.length : ""}
              </p>
            </div>
            {('followUps' in data) && (
 
<FollowUps data={data}/>)}
                  <hr className="border-[#36AE78]" />
                  {('CT_AI_Report_Status' in data) && (
            <CTReportPagination data={data} />)}
            
 
            <div className="flex flex-col gap-y-5">
              <div className="space-y-2">
              {('CXR_AI_Report' in data) && (
            <DXReportPagination data={data} />
            )}
              


              </div>
              {/* Repeat the AI Report section if needed */}
            </div>
         
          
                  <div className="flex flex-col items-end justify-end ">
                    <img
                      src={logo}
                      alt="logo"
                      className="w-[7rem] h-[6rem] object-contain mb-5"
                    />
                  </div>
                  
                  <div className="flex justify-end items-end p-1">
            <GrPrevious
              size={40}
              onClick={() => setNext(false)}
              className=" cursor-pointer hover:bg-gray-400 rounded-full p-2 right-[45rem]"
            />
            <GrNext
              size={40}
              onClick={() => setNext(true)}
              className="cursor-pointer hover:bg-gray-400 rounded-full p-2"
            />
          </div>
                </div>

              )}
           
            </>
          ) : (
            <div className="flex flex-col justify-start h-full gap-y-4 w-full">
                <div className="grid grid-cols-2 text-sm gap-x-4 gap-y-1">
  <div>
    <p><strong>Name:</strong>  <span className="ms-1">{data.Name}</span></p>
    <p><strong>Age: </strong>  <span className="ms-1">{data.Age}</span></p>
    <p><strong>Sex: </strong>  <span className="ms-1">{data.Sex}</span></p>

    <p><strong>Height: </strong> <span className="ms-1"> {data.Height} cm</span></p>
    <p><strong>Weight: </strong> <span className="ms-1"> {data.Weight} kg</span></p>
    <p><strong>SPO2: </strong> <span className="ms-1"> {data.SPO2} %</span></p>
    <p><strong>Blood Pressure: </strong> <span className="ms-1"> {data.SBP}/{data.DBP}</span></p>
    <p><strong>Temperature: </strong> <span className="ms-1"> {data.Temp} °C</span></p>
    <p><strong>Habits:</strong> <span className="ms-1"> {habitsArray.join(', ')}</span></p>
  
    <p><strong>Symptoms:</strong> <span className="ms-1">{data.Symptoms.join(', ')}</span></p>
  
  </div>
  <div>
  <p><strong>Date:</strong><span className="ms-1"> {formatDate(new Date(data.date.$date))}</span></p>
  <p><strong>Diagnosing Radiologist: </strong><span className="ms-1">{data?.Radiologist_Name?.join(", ")}</span></p>
  <p><strong>Assigned Radiologist:</strong> <span className="ms-1">{data.Assigned_Radiologist_Name}</span></p>
  <p><strong>Patient Status:</strong> <span className="ms-1">{data.AI_Disease_Diagnosis_Status}</span></p>
    <p><strong>Other disease:</strong><span className="ms-1"> Most likely <strong>{data.AI_Disease_Label}</strong> with probability of <strong> {data.AI_Disease_confidence}</strong></span></p>
   
    {('MedicalHistory' in data) && (
      <p><strong>Medical History:</strong> <span className="ms-1"> {data.MedicalHistory}</span></p>
    )}
        <p className="text-sm"><strong>Tests:</strong>  <span className="ms-1">{testsArray.join(', ')}</span></p>

  </div>
  
</div>
          
            <div className="flex items-center justify-between">
            <button
        onClick={handleAddPatientClick}
        className={`flex justify-center w-[130px] items-center bg-[#36AE78] rounded-xl h-9 m-4 px-6 gap-1 active:scale-95 active:transform transition-transform duration-300 focus:outline-none ${
          currentUserRights === "View" ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <p className="text-[white] font-semibold text-[16px] leading-6 flex items-center gap-x-2">
Follow up        </p>
      </button>
  
      {showConfirmationModal && (
  <div className="fixed top-0 inset-0 flex items-center justify-center h-full bg-black bg-opacity-50 z-40 backdrop-blur-lg">
    <div className={`z-50 fixed top-0 left-0 h-full pt-5 px-2 bg-white w-[50%] shadow-lg ${!sidebarOpen ? "-translate-x-full" : ""} transition-all duration-700 ease-in-out`}>
      <div onClick={() => { setSidebarOpen(false); setShowConfirmationModal(false); }} className="cursor-pointer bg-slate-200 p-2 w-fit rounded-full absolute right-3">
        <RxCross1 />
      </div>
      <Followup setSidebarOpen={setSidebarOpen} data={data} setShowConfirmationModal={setShowConfirmationModal} showConfirmationModal={showConfirmationModal}  technicianData={technicianData} reloadTable={reloadTable} apiData={apiData}  />
    </div>
  </div>
)}
  <p className="text-sm">{dk}</p>
  <div className="flex items-center gap-x-4 ml-auto">
    <div className="relative flex items-center border border-[#36AE78] border-dashed p-2 rounded-md">
      <input
        name="Scans"
        type="file"
        className="hidden"
        id="file-input"
        webkitdirectory=""
        directory=""
        multiple
        onChange={handleFileInputChange}
      />
      <label
        htmlFor="file-input"
        className="flex items-center justify-center text-blue-600 hover:underline cursor-pointer"
      >
        <PiCloudArrowDown
          className={`text-2xl font-normal ${
            isDragging ? "text-[#36AE78]" : "text-slate-400"
          }`}
        />
        <span className="text-slate-400 ml-2">Browse (Add Scan)</span>
      </label>
    </div>
    <button
      onClick={handleSubmit}
      className="bg-[#36AE78] text-white font-semibold px-4 py-2 rounded-xl active:scale-95 active:transform transition-transform duration-300 focus:outline-none"
    >
      {loading ? loader : "Add"}
    </button>
  </div>
</div>

          
            <div className="w-full">
              <ul className="w-full py-[3px] flex gap-x-3 px-2 overflow-x-scroll no-scrollbar">
                {formData.Scans.map((file, index) => (
                  <li
                    className="border border-[#36AE78] text-[#36AE78] bg-[#c1f6de] bg-opacity-15 rounded-full w-fit pl-2 pr-1 whitespace-nowrap flex items-center gap-x-1"
                    key={index}
                  >
                    {file.name.length > 15
                      ? file.name.substring(0, 14) + "..."
                      : file.name}
                    <RxCross2
                      className="cursor-pointer ml-2"
                      onClick={() => handleRemoveFile(index)}
                    />
                  </li>
                ))}
              </ul>
              <p className="text-left text-slate-500 text-sm pl-3 pt-2">
                {formData.Scans.length > 0 ? "Files: " + formData.Scans.length : ""}
              </p>
            </div>
            {('followUps' in data) && (
              <>
<FollowUps data={data}/></>
)}
            <hr className="border-[#36AE78]" />
            {('CT_AI_Report_Status' in data) && (
            <CTReportPagination data={data} />)}
            
 
            <div className="flex flex-col gap-y-5">
              <div className="space-y-2">
              {('CXR_AI_Report' in data) && (
            <DXReportPagination data={data} />
            )}
              


              </div>
              {/* Repeat the AI Report section if needed */}
            </div>
         
            <div className="flex flex-col items-end justify-end p-6">
              <img
                src={logo}
                alt="logo"
                className="w-[7rem] h-[6rem] object-contain"
              />
            </div>
       
            
            <div className="flex justify-end items-end p-1">
            <GrPrevious
              size={40}
              onClick={() => setNext(false)}
              className=" cursor-pointer hover:bg-gray-400 rounded-full p-2 right-[45rem]"
            />
            <GrNext
              size={40}
              onClick={() => setNext(true)}
              className="cursor-pointer hover:bg-gray-400 rounded-full p-2"
            />
          </div>
          </div>
          
          
          
          
          )}

        </div>
        
        </div>
      </div>
    </>
  );
};
export default ConfirmationModal;
