import React from 'react'
import Logout from '../../pages/Common/Logout'
import logo from '../../assets/scan_logo.png'
// import { CiSettings, CiPower } from "react-icons/ci";
// import { IoPersonOutline } from "react-icons/io5";


const Header = () => {
  const handleLogout = () => {
    // Logic to handle logout in the SearchBar component
    console.log('Logout successful in mew component');
    // Add any other logic you want to execute on logout
  };
  return (
    <div className='flex justify-between items-center'>
        <div className=''>
            <img src={logo} alt="logo" className="w-[10rem] h-[4rem] object-contain" />
        </div>
        
        <button>
        <Logout onLogout={handleLogout} />
      </button>
    </div>
    
  )
}

export default Header